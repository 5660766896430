import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { downloadFile } from '@utils/file';
import { GetStatementReportParams, getStatementReport } from '../reportService';

export const useStatementReportMutation = () => {
  return useMutation<
    AxiosResponse<string>,
    ApiError,
    GetStatementReportParams,
    unknown
  >({
    mutationFn: (params: GetStatementReportParams) =>
      getStatementReport(params),
    onSuccess: (response, { type, endDate, startDate }) => {
      const { data, headers } = response;
      const startDateString = startDate
        ? `_${dayjs(startDate).utc().format('DD-MM-YYYY')}`
        : '';
      const endDateString = endDate
        ? `_${dayjs(endDate).utc().format('DD-MM-YYYY')}`
        : '';
      const fileName = `statement${startDateString}${endDateString}.${type}`;
      downloadFile(type, data, fileName, headers['content-type']);
    },
  });
};
