import api from '@services/api';
import { environment } from '@environments/environment';

import {
  ResponsePreSignIn,
  User,
  ResponseSignIn,
} from '../interfaces/Login.interface';
import { UserEmail } from '@interfaces/User.interfaces';
import { endpoint } from '@constants/endpoints';

export const preSignIn = async (
  payload: UserEmail
): Promise<ResponsePreSignIn> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.preSignIn}`,
    payload
  );
  const { data } = response;

  return data;
};

export const signIn = async (payload: User): Promise<ResponseSignIn> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.signIn}`,
    payload
  );
  const { data } = response;

  return data;
};

export const getMFA = async (): Promise<string> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.getMfa}`
  );
  const { data } = response;

  return data.secretCode;
};

export const verifyMFA = async (code: string): Promise<string> => {
  const response = await api.put(
    `${environment.identityBaseUrl}${endpoint.getMfa}`,
    {
      userCode: code,
      deviceName: 'Portao3',
    }
  );
  const { data } = response;

  return data.secretCode;
};
