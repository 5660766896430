import { useId } from 'react';
import { ToggleButton } from 'react-aria-components';

import { Typography } from '../typography/Typography';
import { ToggleProps } from './Toggle.interfaces';
import './Toggle.styles.scss';

export const Toggle = ({ label, description, ...props }: ToggleProps) => {
  const id = useId();

  return (
    <div className="toggle-button-container">
      <ToggleButton className="toggle-button" id={id} {...props} />
      {label && (
        <label htmlFor={id}>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n100)">
            {label}
          </Typography>
        </label>
      )}
      {description && (
        <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
          {description}
        </Typography>
      )}
    </div>
  );
};
