export enum SecurityOptions {
  MFA = 'MFA',
  PIN = 'PIN',
}

export enum SecurityFlow {
  PIN = 'PIN',
  MFA = 'MFA',
  DEFAULT = 'DEFAULT',
}
