import { endpoint } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

export const getMFA = async (): Promise<string> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.getMfa}`
  );
  const { data } = response;

  return data.secretCode;
};
