export function removeSpecialCharacters(value: string) {
  return value.replace(/[^a-zA-Z0-9]/g, '');
}

export function sanitizeString(name: string) {
  const sanitized = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const noNumbers = sanitized.replace(/[^a-zA-Z\s]/g, '');

  return noNumbers.trim().toUpperCase();
}

export function generateCardName(name: string) {
  if (!name) return '-';

  const sanitized = sanitizeString(name);

  if (sanitized.length <= 26) {
    return sanitized;
  } else {
    const words = sanitized.split(' ');

    let result = '';

    for (let i = 0; i < words.length; i++) {
      if (i === 0 || i === words.length - 1) {
        result += `${words[i]} `;
      } else if (words[i].length > 2) {
        result += `${words[i].charAt(0)} `;
      }
    }

    return result.substring(0, 26).trim();
  }
}
