import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useRef } from 'react';

import {
  MINUTE_IN_MILLISECONDS,
  SECOND_IN_MILLISECONDS,
} from '@constants/time';
import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';
import {
  UseFindStatementByIdParams,
  findStatementById,
} from '../statementService';
import { customersInvoiceStatementsKeys } from './customersInvoicesKeys';

interface UseFindStatementByIdQueryProps {
  props: UseFindStatementByIdParams;
  enabled?: boolean;
}

export const useFindStatementByIdQuery = ({
  props,
  enabled,
}: UseFindStatementByIdQueryProps) => {
  const queryClient = useQueryClient();
  const shouldRefetch = useRef(enabled);

  const onSuccess = (data: InvoiceStatementProps) => {
    const hasBankSlip =
      data.paymentData?.bankSlip?.digitableLine &&
      data.paymentData?.bankSlip?.barcode;

    if (hasBankSlip && !shouldRefetch.current) {
      queryClient.setQueryData(
        customersInvoiceStatementsKeys.list(props.invoiceId),
        (
          oldData: ListResponse<InvoiceStatementProps>
        ): ListResponse<InvoiceStatementProps> => {
          const newItems = oldData.items.map((item) => {
            if (item.id === props.statementId) {
              return data;
            }

            return item;
          });

          return {
            ...oldData,
            items: newItems,
          };
        }
      );
    }

    shouldRefetch.current = !hasBankSlip;

    return data;
  };

  return useQuery<
    InvoiceStatementProps,
    ApiError,
    InvoiceStatementProps,
    unknown[]
  >({
    queryKey: customersInvoiceStatementsKeys.detail(props),
    queryFn: () =>
      findStatementById({
        invoiceId: props.invoiceId,
        statementId: props.statementId,
      }),
    placeholderData: keepPreviousData,
    refetchInterval: shouldRefetch.current
      ? 3 * SECOND_IN_MILLISECONDS
      : 5 * MINUTE_IN_MILLISECONDS,
    select: onSuccess,
    enabled: !!enabled,
  });
};
