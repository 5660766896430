import dayjs from 'dayjs';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';

import { ContextualError, SidebarFooter, SidebarSection } from '@components';
import { ICONS } from '@constants/icons';
import { Button, Input, Sidebar, Spinner } from '@portao3-web/ui';
import { useDownloadInvoicesMutation } from '@services/billing/hooks';
import { useFindStatementByIdQuery } from '@services/invoices/hooks/useFindStatementByIdQuery';
import { copyToClipboard } from '@utils/copyClipboard';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { ValueWrapper } from '../drawerFundsIn/components/ValueWrapper';

import { InvoiceDealProps } from '@interfaces/Invoices';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';

interface DrawerInvoiceStatementBankSlipProps {
  invoice: InvoiceDealProps;
  statement: InvoiceStatementProps;
  onClose: () => void;
}

export const DrawerInvoiceStatementBankSlip = ({
  invoice,
  statement,
  onClose,
}: DrawerInvoiceStatementBankSlipProps) => {
  const { t } = useTranslation();

  const dueAt = statement.paymentData?.bankSlip?.dueAt || invoice?.dueAt;

  const { data, error, isError } = useFindStatementByIdQuery({
    props: {
      invoiceId: invoice.id,
      statementId: statement.id,
    },
    enabled:
      !statement.paymentData?.bankSlip?.barcode ||
      !statement.paymentData?.bankSlip?.digitableLine,
  });

  const {
    mutate: handleGenerateBillingFile,
    isPending: isDownloadingBilling,
    customError: customErrorDownload,
  } = useDownloadInvoicesMutation();

  const digitableLine =
    statement.paymentData?.bankSlip?.digitableLine ||
    data?.paymentData?.bankSlip?.digitableLine;

  const barcode =
    statement.paymentData?.bankSlip?.barcode ||
    data?.paymentData?.bankSlip?.barcode;

  const handleCopyBillingCode = () => {
    copyToClipboard(digitableLine);
  };

  const handleDownloadInvoiceBankSlip = () => {
    handleGenerateBillingFile({
      invoiceId: invoice.id,
      statementId: statement.id,
    });
  };

  return (
    <Sidebar
      open={!!statement}
      title={t('invoices.invoice-payment')}
      icon={ICONS['file-invoice']}
      onClose={onClose}
    >
      <SidebarSection
        titleSection={t('billing.billing-details')}
        subTitleSection={t('billing.who-receive-value')}
      >
        <div className="flex-col gap-6">
          <div className="grid grid-cols-2 gap-6">
            <ValueWrapper
              label={t('general.due-date')}
              value={dayjs?.utc(dueAt)?.format('DD/MM/YYYY')}
            />

            <ValueWrapper
              label={t('general.value')}
              value={calcCurrencyValue(statement?.originalAmount, 'BRL')}
            />

            {invoice?.fine?.amount && (
              <ValueWrapper
                label={t('general.fine')}
                value={invoice.fine.amount}
              />
            )}

            {invoice?.interest?.amount && (
              <ValueWrapper
                label={t('general.interest')}
                value={`${invoice.interest?.amount * 100}%`}
              />
            )}

            <ValueWrapper
              label={t('general.description')}
              value={statement?.description}
            />
          </div>

          <div className="my-4 flex flex-col gap-4">
            {barcode && digitableLine && (
              <>
                <div className="flex">
                  <div className="barcode">
                    <Barcode value={barcode} format="ITF" />
                  </div>
                </div>

                <div className="flex flex-col">
                  <Input
                    name="some"
                    register={() => ({})}
                    readOnly
                    data-testid="billing-code"
                    type="text"
                    label={t('general.digitable-line')}
                    value={digitableLine}
                  />

                  <div className="mb-4 ml-auto flex flex-row gap-3">
                    <Button
                      variant="secondary"
                      size="small"
                      className="max-content"
                      onClick={handleDownloadInvoiceBankSlip}
                      disabled={
                        !digitableLine || !statement.external?.b3BankSlip
                      }
                      isLoading={isDownloadingBilling}
                    >
                      {t('billing.download-bank-slip')}
                    </Button>

                    <Button
                      size="small"
                      className="max-content"
                      onClick={handleCopyBillingCode}
                    >
                      {t('billing.copy-code')}
                    </Button>
                  </div>

                  <ContextualError error={customErrorDownload} />
                </div>
              </>
            )}

            {(!digitableLine || !barcode) && (
              <div className="flex flex-col h-60 gap-4 items-center justify-center">
                <p className="text-p1 text-neutral-500">
                  {t('general.processing')} ...
                </p>
                <Spinner size="large" />
              </div>
            )}
          </div>

          {isError && (
            <ContextualError
              error={{
                message: t('error.default'),
                traceId: error?.response?.data?.traceId,
              }}
            />
          )}
        </div>
      </SidebarSection>

      <SidebarFooter>
        <Button size="large" onClick={onClose}>
          {t('button.finish')}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};
