import { Avatar, Button, Icon, Typography } from '..';
import { getSlug } from '../../utils/getSlug';
import { UserMenuProps } from './UserMenu.interface';
import './UserMenu.styles.scss';

export const UserMenu = ({
  list,
  hideDropDown,
  userName,
  imageSrc = '',
  email,
  ...props
}: UserMenuProps) => {
  const triggerActions = (action: (() => void) | null) => {
    action && action();
    hideDropDown && hideDropDown();
  };
  return (
    <div
      className="userMenu"
      onClick={(event) => {
        event.stopPropagation();
        document.body.click();
      }}
      {...props}
    >
      <div className="userMenu_content">
        <div className="userMenu_box_info">
          <Avatar
            imageSrc={imageSrc}
            isLoggedUser
            userName={userName}
            size="large"
          />
          <div className="userMenu_box_info_text">
            <Typography
              weight="p1"
              tag={'p'}
              color="var(--product-neutral-n500)"
            >
              {userName}
            </Typography>
            <Typography
              weight="p3"
              tag={'p'}
              color="var(--product-neutral-n300)"
            >
              {email}
            </Typography>
          </div>
        </div>

        <div className="userMenu_content_list">
          {list.map((elem, index) => {
            const isLastItem = index === list.length - 1;
            const listItemClass = isLastItem
              ? 'userMenu_content_list_item last_item'
              : 'userMenu_content_list_item';

            return (
              <div className={listItemClass} key={index}>
                <Button
                  size={'large'}
                  variant="tertiary"
                  className="navbar_button navbar_button_options"
                  key={index}
                  data-testid={`user-menu-${getSlug(elem.label)}`}
                  onClick={() => {
                    triggerActions(elem?.action || null);
                  }}
                >
                  <span className="userMenu_link">
                    {elem.icon && (
                      <Icon size={'xlarge'}>
                        <i className={`${elem.icon}`} />
                      </Icon>
                    )}
                    <Typography tag={'p'} color="var(--product-neutral-n300)">
                      {elem.label}
                    </Typography>

                    {elem.notification && (
                      <i
                        className={`text-p2 fa-solid fa-circle-exclamation ml-auto text-red-500 `}
                      />
                    )}
                  </span>
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
