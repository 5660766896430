import { Button, MultiSelectPopover } from '@portao3-web/ui';

export const TableColumnsFilter = (props: {
  options: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
  selection: string[];
  setSelection: (selection: string[]) => void;
}) => {
  return (
    <MultiSelectPopover title="Exibir colunas" align="end" {...props}>
      <Button size="medium" className="ml-auto" variant="tertiary">
        <i className="fa-regular fa-table-columns text-p1 text-primary-500" />
      </Button>
    </MultiSelectPopover>
  );
};
