import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { Icon } from '@portao3-web/ui';
import './StyledNavLink.styles.scss';
import { StyledLinkProps } from './StyledNavLink.interfaces';

export const StyledNavLink = ({
  label,
  to,
  icon,
  hideActiveStyle,
}: StyledLinkProps) => {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <div
          className={clsx('styled-link font-p1', {
            'styled-link--active': isActive && !hideActiveStyle,
          })}
        >
          <Icon size="large">
            <i className={`fa-regular fa-${icon} fa-1x`} />
          </Icon>
          {label}
        </div>
      )}
    </NavLink>
  );
};
