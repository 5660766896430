import { endpoint, organization, realm } from '@constants/endpoints';
import { PaymentMethod } from '@enums/BillingDeal.enum';
import { environment } from '@environments/environment';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';
import api from '@services/api';

export interface CreateBillingPaymentParams {
  invoiceId: string;
  params: {
    txnAmount: string;
    dueAt: Date | string;
    expiresAt: Date | string;
    paymentMethod: PaymentMethod;
  };
}

export const createBillingPayment = async ({
  invoiceId,
  params,
}: CreateBillingPaymentParams): Promise<InvoiceStatementProps> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/create-payment`,
    params
  );

  const { data } = response;

  return data;
};
