import { NewTicket, PaymentConfirmationBankSlip } from '@components';
import { BankSlip } from '@interfaces/BankSlip.interface';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { useState } from 'react';
import { BankSlipPaymentScheduleVoucher } from '../../../components/paymentVoucher/components/bankSlipPaymentVoucher/BankSlipPaymentScheduleVoucher';
import { BankSlipPaymentVoucher } from '../../../components/paymentVoucher/components/bankSlipPaymentVoucher/BankSlipPaymentVoucher';

interface DrawerNewBankSlipProps {
  walletId?: string;
  onClose: () => void;
  categoryType?: string;
}

type FlowBankSlip =
  | 'newPaymentTicket'
  | 'confirmationPayment'
  | 'paymentVoucher'
  | 'schedulePaymentVoucher';

export const DrawerNewBankSlip = ({
  walletId,
  categoryType,
  onClose,
}: DrawerNewBankSlipProps) => {
  const [flow, setFlow] = useState<FlowBankSlip>('newPaymentTicket');

  const redirectToVoucher = (isScheduling: boolean) => {
    if (isScheduling) {
      setFlow('schedulePaymentVoucher');
    } else {
      setFlow('paymentVoucher');
    }
  };

  const [confirmPaymentPixBankSlip, setConfirmPaymentPixBankSlip] = useState<
    BankSlip | CreateTransactionSchedule | null
  >(null);

  return (
    <>
      {flow === 'newPaymentTicket' && (
        <NewTicket
          back={onClose}
          onInitiate={() => setFlow('confirmationPayment')}
          confirmPayment={setConfirmPaymentPixBankSlip}
          walletId={walletId}
        />
      )}

      {flow === 'confirmationPayment' && confirmPaymentPixBankSlip && (
        <PaymentConfirmationBankSlip
          onConfirm={redirectToVoucher}
          back={() => setFlow('newPaymentTicket')}
          paymentConfirm={confirmPaymentPixBankSlip as BankSlip}
          paymentVoucher={setConfirmPaymentPixBankSlip}
          walletId={walletId}
          categoryType={categoryType}
        />
      )}

      {flow === 'paymentVoucher' && confirmPaymentPixBankSlip && (
        <BankSlipPaymentVoucher
          data={confirmPaymentPixBankSlip as BankSlip}
          onClose={onClose}
        />
      )}

      {flow === 'schedulePaymentVoucher' && confirmPaymentPixBankSlip && (
        <BankSlipPaymentScheduleVoucher
          onClose={onClose}
          scheduleId={
            (confirmPaymentPixBankSlip as CreateTransactionSchedule).id
          }
          walletId={
            (confirmPaymentPixBankSlip as CreateTransactionSchedule)?.wallet
          }
        />
      )}
    </>
  );
};
