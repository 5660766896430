import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

export const downloadBilling = async (
  walletId: string,
  billingId: string
): Promise<unknown> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.billings}/${billingId}/download-pdf`
  );
  const { data } = response;

  downloadPDF(data);

  return data;
};

export const downloadInvoiceBankSlip = async (
  invoiceId: string,
  statementId?: string
): Promise<unknown> => {
  const statement = statementId ? `?statement=${statementId}` : '';
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/invoices/${invoiceId}/download-pdf${statement}`
  );
  const { data } = response;
  downloadPDF(data);
  return data;
};

const downloadPDF = (base64pdf: string) => {
  const linkSource = `data:application/pdf;base64,${base64pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = 'boleto.pdf';
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};
