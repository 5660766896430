import {
  Button,
  InputOTP,
  Spinner,
  ToastrContainer,
  Typography,
} from '@portao3-web/ui';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';

import {
  ContextualError,
  GeneralPresentationLogin,
  HeaderForm,
} from '@components';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { AuthContext } from '@providers';
import { decryptToken } from '@utils/crypto';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getMFA, verifyMFA } from '../login/services/loginServices';

enum Step {
  ReadQRCode = 'readQrCode',
  VerifyQRCode = 'verifyQrCode',
}

export const ActiveMFA = () => {
  const { email, token: userToken } = useContext(AuthContext);
  const { t } = useTranslation();

  const [loadingQrcode, setLoadingQrcode] = useState(false);
  const [loadingVerifyQrCode, setLoadingVerifyQrCode] = useState(false);
  const [qrcode, setQrcode] = useState({ secretCode: '***', url: '' });
  const [userCode, setUserCode] = useState('');

  const [step, setStep] = useState<Step>(Step.ReadQRCode);
  const [error, setError] = useState('');
  const [traceId, setTraceId] = useState('');
  const firstRender = useRef(true);
  const history = useNavigate();

  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const getMFAToken = useCallback(async () => {
    try {
      setLoadingQrcode(true);
      const secretCode = await getMFA();
      setQrcode({
        secretCode,
        url: `otpauth://totp/${email?.value}?secret=${secretCode}&issuer=Portao3`,
      });
    } catch {
      setError('Erro ao carregar QR code');
    } finally {
      setLoadingQrcode(false);
    }
  }, [email?.value]);

  useEffect(() => {
    if (firstRender.current) {
      getMFAToken();
      firstRender.current = false;
    }
  }, [getMFAToken]);

  const confirmMFA = async () => {
    try {
      setLoadingVerifyQrCode(true);
      await verifyMFA(userCode);

      const getAccessToken = () => {
        const token = localStorage.getItem('token');

        if (token) {
          const parsedToken = JSON.parse(token);

          return decryptToken(parsedToken).accessToken;
        }

        return '';
      };

      userToken.setToken(getAccessToken());
    } catch (error: unknown) {
      setError(t('error.mfa-validation'));
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;

      setTraceId(errorInfo?.traceId);
    } finally {
      setLoadingVerifyQrCode(false);
    }
  };

  const readQrCode = () => {
    return (
      step === Step.ReadQRCode && (
        <>
          <div className="mb-8">
            <Typography
              tag="p"
              weight="p2"
              className="mb-2"
              color="var(--product-neutral-n500)"
            >
              Chave Manual: {qrcode.secretCode}
            </Typography>

            {qrcode.url && (
              <QRCode className="mx-auto mt-8 block" value={qrcode.url} />
            )}
            {loadingQrcode && <Spinner variant="primary" />}
          </div>

          <Button
            size="large"
            className="mb-2 w-full"
            data-testid="next-mfa"
            onClick={() => setStep(Step.VerifyQRCode)}
          >
            Avançar
          </Button>
          <Button
            size="large"
            className="w-full"
            variant="secondary"
            data-testid="next-mfa"
            onClick={() => history('/login')}
          >
            Cancelar
          </Button>
        </>
      )
    );
  };

  const verifyQrCode = () => {
    return (
      step === Step.VerifyQRCode && (
        <form
          onSubmit={handleSubmit(confirmMFA)}
          className="authentication_content_right_form_inputs"
        >
          <div className="authentication_submit">
            <section className="mx-auto mb-6">
              <InputOTP
                key="input-mfa-active"
                length={6}
                error={!!error && userCode.length === 6}
                hiddenPassword={false}
                value={userCode}
                setValue={(value) => {
                  setUserCode(value);
                  setError('');
                }}
              />
            </section>

            <Button
              size="large"
              type="submit"
              disabled={userCode.length < 6 || loadingVerifyQrCode}
              data-testid="validationCode"
            >
              {loadingVerifyQrCode ? (
                <Spinner variant="secondary" />
              ) : (
                'Validar código'
              )}
            </Button>

            <Button
              type="button"
              onClick={() => history('/login')}
              size="large"
              variant="secondary"
            >
              Cancelar
            </Button>
          </div>
        </form>
      )
    );
  };

  return (
    <div className="authentication">
      <div className="authentication_content">
        <header className="authentication_img_header" />
        <GeneralPresentationLogin />
        <div className="authentication_content_right">
          <div className="authentication_content_right_form">
            <HeaderForm
              title="Verificação em duas etapas"
              subTitle={`Escaneie o QR code abaixo utilizando o seu aplicativo TOTP, ou utilize a chave para cadastrá-lo manualmente.`}
            />

            {error && <ContextualError error={{ message: error, traceId }} />}

            {readQrCode()}
            {verifyQrCode()}
          </div>
        </div>
      </div>
      <ToastrContainer />
    </div>
  );
};
