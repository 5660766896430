import { TableProps } from './Table.interface';
import './Table.styles.scss';

export const Table = ({ children }: TableProps) => {
  return (
    <div className="table-wrapper">
      <table className="table">{children}</table>
    </div>
  );
};
