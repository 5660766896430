import { Button, ErrorMessage, Input, Spinner, Toastr } from '@portao3-web/ui';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { HeaderForm, StrongPassword } from '@components';
import { regex } from '@constants/regex';
import { AuthContext } from '@providers';
import { confirmForgotPassword } from '@services/changePassword';
import { NextStepProps } from '../../interfaces/NextStep.interface';
import './changePassword.styles.scss';
import { ChangePasswordProps } from '@interfaces/Password.interfaces';

export const ChangePassword = ({ handleNextStep }: NextStepProps) => {
  const { email, tokenRecoveryPassword } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, touchedFields },
  } = useForm<ChangePasswordProps>({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const password = watch('password');

  const submitForm = async () => {
    setLoading(!loading);
    try {
      const requestNewPassword = {
        newPassword: password,
        email: email.value,
        code: tokenRecoveryPassword.value,
      };
      await confirmForgotPassword<[]>(requestNewPassword);

      setLoading(false);
      Toastr.success('Senha alterada com sucesso!');
      handleNextStep();
    } catch {
      setLoading(false);
      Toastr.error('Estamos em manutenção');
    }
  };

  useEffect(() => {
    if (touchedFields.confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, trigger, touchedFields]);

  return (
    <>
      <HeaderForm
        title="Defina uma nova senha"
        subTitle="Preencha os campos abaixo para finalizar."
      />

      <form
        className="authentication_content_right_form_inputs"
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="authentication_submit">
          <div className="">
            <Input
              type="password"
              label="Nova senha"
              placeholder=""
              id="password"
              name="password"
              register={register}
              data-testid="password"
              error={!!errors['password']}
              validationSchema={{
                required: 'Senha obrigatória',
                pattern: {
                  value: regex.allConditions,
                  message: 'A senha deve seguir o padrão abaixo',
                },
              }}
              required
            >
              {errors['password']?.message && (
                <ErrorMessage message={errors['password'].message.toString()} />
              )}
            </Input>
          </div>

          <StrongPassword password={password} />

          <div className="authentication_submit_confirm_password">
            <Input
              label="Confirme a nova senha"
              type="password"
              placeholder=""
              id="confirmPassword"
              name="confirmPassword"
              data-testid="confirmPassword"
              register={register}
              error={!!errors['confirmPassword']}
              validationSchema={{
                required: 'Senha obrigatória',
                validate: (value: string) =>
                  password === value || 'As senhas devem ser iguais',
              }}
              required
            >
              {errors['confirmPassword']?.message && (
                <ErrorMessage
                  message={errors['confirmPassword'].message.toString()}
                />
              )}
            </Input>
          </div>

          <Button size="large" disabled={!isValid} data-testid="submitForm">
            {loading ? <Spinner variant="secondary" /> : 'Concluir'}
          </Button>

          <Button
            size="large"
            variant="secondary"
            data-testid="cancelButton"
            onClick={() => history('/login')}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </>
  );
};
