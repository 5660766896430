import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { PixPaymentResponse } from '@interfaces/Pix.interface';
import api from '@services/api';
import { FormPayment, FormPixQrCodeCopyPaste } from '../NewPix.interface';

export const initiatePixPayment = async (
  formData: FormPayment | FormPixQrCodeCopyPaste,
  walletId?: string
): Promise<PixPaymentResponse> => {
  const isWalletId = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/pix`,
    formData
  );
  const { data } = response;

  return data;
};
