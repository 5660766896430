import './Toastr.styles.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from '../typography/Typography';
import { Icon } from '..';

const toastrSuccess = (message: string, icon = true) => {
  toast.success(
    <Typography dataTestId="toast-success" tag="p">
      {message}
    </Typography>,
    {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light',
      progress: undefined,
      icon: icon && (
        <Icon size={'xlarge'} data-testid="sidebar">
          <i className="fa-solid fa-circle-check icon icon_success"></i>
        </Icon>
      ),
      progressClassName: 'success_progress',
    }
  );
};

const toastrError = (message: string, icon = true) => {
  toast.error(
    <Typography dataTestId="toast-error" tag="p">
      {message}
    </Typography>,
    {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: icon && (
        <Icon size={'xlarge'}>
          <i className="fa-solid fa-circle-exclamation icon icon_error"></i>
        </Icon>
      ),
      progressClassName: 'error_progress',
    }
  );
};

const toastrInfo = (message: string, icon = true) => {
  toast.info(
    <Typography dataTestId="toast-info" tag="p">
      {message}
    </Typography>,
    {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: icon && (
        <Icon size={'xlarge'} data-testid="sidebar">
          <i className="fa-solid fa-circle-info icon icon_info"></i>
        </Icon>
      ),
      progressClassName: 'info_progress',
    }
  );
};

const toastrWarning = (message: string, icon = true) => {
  toast.warn(
    <Typography dataTestId="toast-warning" tag="p">
      {message}
    </Typography>,
    {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: icon && (
        <Icon size={'xlarge'}>
          <i className="fa-solid fa-triangle-exclamation icon icon_warning"></i>
        </Icon>
      ),
      progressClassName: 'warning_progress',
    }
  );
};

export const ToastrContainer = () => {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export const Toastr = {
  success: toastrSuccess,
  error: toastrError,
  info: toastrInfo,
  warning: toastrWarning,
};
