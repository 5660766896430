import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { BankSlip } from '@interfaces/BankSlip.interface';
import { PixPaymentResponse } from '@interfaces/Pix.interface';
import api from '@services/api';
import { confirmationPayloadRequest } from '../PaymentConfirmation.interface';

export const pixConfirmPayment = async (
  confirmationPayload: confirmationPayloadRequest,
  walletId?: string,
  pin?: string
): Promise<PixPaymentResponse> => {
  const isWalletId = walletId || 'default';

  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/pix/${
      confirmationPayload.id
    }/confirm`,
    {
      amount: confirmationPayload.amount,
      category: confirmationPayload.category,
      customFields: confirmationPayload.customFields,
      processingDate: confirmationPayload.processingDate,
      description: confirmationPayload.description,
    },
    { headers: { pin } }
  );
  const { data } = response;

  return data;
};

export const cancelPixService = async (
  id: string,
  walletId?: string
): Promise<PixPaymentResponse> => {
  const isWalletId = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/pix/${id}/cancel`
  );
  const { data } = response;

  return data;
};

export const bankSlipConfirmPayment = async (
  confirmationPayload: confirmationPayloadRequest,
  walletId?: string,
  pin?: string
): Promise<BankSlip> => {
  const isWalletId = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/bank-slip/${
      confirmationPayload.id
    }/confirm`,
    {
      amount: confirmationPayload.amount,
      category: confirmationPayload.category,
      customFields: confirmationPayload.customFields,
      processingDate: confirmationPayload.processingDate,
      description: confirmationPayload.description,
    },
    { headers: { pin } }
  );
  const { data } = response;

  return data;
};

export const cancelBankSlipService = async (
  id: string,
  walletId?: string
): Promise<PixPaymentResponse> => {
  const isWalletId = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWalletId}/bank-slip/${id}/cancel`
  );
  const { data } = response;

  return data;
};
