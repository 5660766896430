import { Button, Spinner, Input, ErrorMessage } from '@portao3-web/ui';
import { HeaderForm } from '@components';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { NextStepProps } from '../../interfaces/NextStep.interface';
import { useForm } from 'react-hook-form';
import { regex } from '@constants/regex';
import { forgotPassword } from '../services/requestPasswordServices';
import { UserEmail } from '@interfaces/User.interfaces';
import { AuthContext } from '@providers';

export const RequestPassword = ({ handleNextStep }: NextStepProps) => {
  const history = useNavigate();
  const { email } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UserEmail>({
    mode: 'onBlur',
  });

  const submitForm = async (data: UserEmail) => {
    setLoading(!loading);
    email.setEmail(data.email);
    try {
      await forgotPassword(data);
      handleNextStep();
    } catch {
      setLoading(false);
      handleNextStep();
    }
  };

  return (
    <>
      <HeaderForm
        title="Esqueceu sua senha?"
        subTitle="Não se preocupe, preencha o campo abaixo:"
      />

      <form
        className="authentication_content_right_form_inputs"
        onSubmit={handleSubmit(submitForm)}
      >
        <Input
          type="email"
          placeholder="Digite seu e-mail"
          label="E-mail"
          id="email"
          name="email"
          data-testid="email"
          register={register}
          error={!!errors['email']}
          validationSchema={{
            required: 'E-mail obrigatório',
            pattern: {
              value: regex.email,
              message: 'E-mail inválido',
            },
          }}
          required
        >
          {errors['email']?.message && (
            <ErrorMessage message={errors['email'].message.toString()} />
          )}
        </Input>

        <div className="authentication_submit">
          <Button size="large" disabled={!isValid} data-testid="submitForm">
            {loading ? <Spinner variant="secondary" /> : 'Redefinir Senha'}
          </Button>

          <Button
            onClick={() => history('/login')}
            size="large"
            disabled={loading}
            variant="secondary"
          >
            Cancelar
          </Button>
        </div>
      </form>
    </>
  );
};
