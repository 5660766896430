import { user, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

export interface CreateUserPinPayload {
  pin: string;
}

export const createUserPin = async ({
  pin,
}: CreateUserPinPayload): Promise<unknown> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${realm()}/${user()}/pin`,
    { pin }
  );

  const { data } = response;

  return data;
};
