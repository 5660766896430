import { useEffect, useState } from 'react';

import { useQueryParams } from '@hooks';
import { TableSearch } from '@portao3-web/ui';

interface TableSearchQueryProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  query: string;
}

export const SearchQuery = ({
  query,
  title,
  ...props
}: TableSearchQueryProps) => {
  const { setQueryParams, queryParams } = useQueryParams();

  const queryValue = queryParams.get(query) || '';

  const [search, setSearch] = useState(queryValue ?? '');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const setFilter = (value: string) => {
    setQueryParams({
      [query]: value,
      page: '0',
    });
  };

  useEffect(() => {
    if (queryValue) return;

    setSearch('');
  }, [queryValue]);

  return (
    <TableSearch
      value={search}
      onChange={handleSearch}
      setQuery={setFilter}
      {...props}
    />
  );
};
