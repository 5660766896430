import { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Icon, Typography, UserMenu } from '..';
import { LoggedNavbarProps } from './LoggedNavBar.interface';

import './LoggedNavbar.styles.scss';

export const LoggedNavbar = ({
  avatarImage,
  user,
  dropDownMenuList,
  notificationsList,
  hasNotification,
}: LoggedNavbarProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const navbarRef = useRef<HTMLDivElement | null>(null);

  const renderNotifications = () => {
    return notificationsList.map((elem, index) => {
      return (
        <a href={elem.link} key={index} className="dropdown_link">
          <Typography tag={'p'} color="var(--product-primary-p500)">
            {elem.label}
          </Typography>
        </a>
      );
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showOptions &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node)
      ) {
        setShowOptions(!showOptions);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  return (
    <div className="navbar">
      {/* Bloco  referente ao input de pesquisa - Descomentar aqui e o CSS quando ele for usado */}
      {/* <Input
        label=""
        value=""
        type="search"
        placeholder="Pesquisar por despesa, usuário, conta"
        name="search"
        register={function <TFieldName extends string = string>(
          _name: TFieldName
        ): UseFormRegisterReturn<TFieldName> {
          throw new Error('Function not implemented.');
        }}
      /> */}
      {/* Bloco  referente ao ícone de notificação e avatar */}
      <div className="user_container">
        {/* Para exibir o ícone de notificações remover o 'style' */}
        <div className="dropdown" style={{ display: 'none' }}>
          <Button
            size={'medium'}
            variant="tertiary"
            className="navbar_button navbar_button_notifications"
          >
            <Icon size={'xlarge'}>
              <i className="fa-regular fa-bell" />
            </Icon>
          </Button>
          <div className="dropdown_content">{renderNotifications()}</div>
        </div>
        {/* Para exibir o ícone de configurações remover o 'style' */}
        <div className="dropdown" style={{ display: 'none' }}>
          <Button
            size={'medium'}
            variant="tertiary"
            className="navbar_button navbar_button_notifications"
          >
            <Icon size={'xlarge'}>
              <i className="fa-regular fa-gear" />
            </Icon>
          </Button>
          <div className="dropdown_content">{renderNotifications()}</div>
        </div>
        <div ref={navbarRef} className="relative">
          <Button
            data-testid="logged-avatar"
            size={'large'}
            variant="tertiary"
            className="navbar_button"
            onClick={() => setShowOptions(!showOptions)}
            onKeyDown={(event) => {
              event.key === 'Escape' && setShowOptions(false);
            }}
          >
            {hasNotification && !showOptions && (
              <i
                className={`text-p2 fa-solid fa-circle-exclamation ml-auto text-red-500 absolute -bottom-1 -right-0`}
              />
            )}
            <Avatar
              imageSrc={avatarImage}
              isLoggedUser
              userName={user?.email}
            />
          </Button>
          {showOptions && (
            <UserMenu
              list={dropDownMenuList}
              hideDropDown={() => setShowOptions(false)}
              userName={user.name || ''}
              email={user.email || ''}
            />
          )}
        </div>
      </div>
    </div>
  );
};
