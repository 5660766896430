import { Typography } from '..';
import { ErrorMessageProps } from './ErrorMessage.interface';
import './ErrorMessage.styles.scss';

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <div className="error">
      <Typography tag="p" weight="p3" color="var(--product-danger-d300)">
        {message}
      </Typography>
    </div>
  );
};
