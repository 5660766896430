import {
  P2PTransferScheduleVoucher,
  P2PTransferVoucher,
  P2PTransferVoucherProps,
  PaymentVoucher,
} from '@components';
import { ICONS } from '@constants/icons';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WalletController } from './components/walletController/WalletController';

export type VoucherTransferFlowType =
  | 'startUpdate'
  | 'transferVoucher'
  | 'transferScheduleVoucher';

export const DrawerUpdateWallet = () => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  const [flow, setFlow] = useState<VoucherTransferFlowType>('startUpdate');

  const [voucherTransfer, setVoucherTransfer] =
    useState<P2PTransferVoucherProps | null>(null);
  const [voucherScheduleTransfer, setVoucherScheduleTransfer] =
    useState<CreateTransactionSchedule | null>(null);

  return (
    <Sidebar
      title={t('general.user-wallet')}
      icon={ICONS.wallet}
      open
      onClose={closeDrawer}
    >
      {flow === 'startUpdate' && (
        <WalletController
          setVoucherTransfer={setVoucherTransfer}
          setVoucherScheduleTransfer={setVoucherScheduleTransfer}
          setFlow={setFlow}
        />
      )}

      {flow === 'transferVoucher' && voucherTransfer && (
        <PaymentVoucher
          title={`recibo-tranferencia-${dayjs().format('DD-MM-YYYY')}.pdf`}
          onClose={closeDrawer}
        >
          <P2PTransferVoucher {...voucherTransfer} />
        </PaymentVoucher>
      )}

      {flow === 'transferScheduleVoucher' && voucherScheduleTransfer && (
        <P2PTransferScheduleVoucher
          scheduleId={voucherScheduleTransfer.id}
          walletId={voucherScheduleTransfer.wallet}
        />
      )}
    </Sidebar>
  );
};
