import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { findBillingDealList } from '../findBillingDealList';
import { billingDealKeys } from './billingDealKeys';

import {
  Deal,
  FindBillingDealListParams,
} from '@interfaces/BillingDeal.interfaces';
import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';

export const useInfiniteBillingDealListQuery = (
  params: FindBillingDealListParams
) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    ListResponse<Deal>,
    ApiError,
    InfiniteData<ListResponse<Deal>>,
    unknown[],
    string
  >({
    queryKey: billingDealKeys.list({ ...params, organizationId }),
    queryFn: ({ pageParam }) =>
      findBillingDealList({ ...params, next: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage?.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    retry: 1,
    enabled: !!organizationId && !!params.customerId,
  });
};
