import {
  Alert,
  ContextualError,
  ContextualErrorType,
  SidebarFooter,
} from '@components';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { PixPaymentResponse } from '@interfaces/Pix.interface';
import { customerEvents } from '@lib';
import { Button, Spinner, Typography } from '@portao3-web/ui';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Camera } from '../../camera/Camera';
import { initiatePixPayment } from '../services/PixService';

interface PixQrCodeProps {
  back: () => void;
  confirmPayment: (value: PixPaymentResponse) => void;
  goNextStep: () => void;
  walletId?: string;
}

export const PixQrCode = ({
  back,
  confirmPayment,
  goNextStep,
  walletId,
}: PixQrCodeProps) => {
  const { t } = useTranslation();
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [cameraBlock, setCameraBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [messageErrorService, setMessageErrorService] =
    useState<ContextualErrorType>(null);

  const checkCameraPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
      setCameraBlock(false);
    } catch {
      if (!cameraBlock) {
        setCameraBlock(true);
      }
    }
  }, [cameraBlock]);

  useEffect(() => {
    checkCameraPermission();
  }, [checkCameraPermission]);

  useEffect(() => {
    const paymentPix = async () => {
      setLoading(true);
      const form = {
        emv: code,
        initiationType: 'UNDEFINED_QRCODE',
      };

      setMessageErrorService(null);
      try {
        const response = await initiatePixPayment(form, walletId);

        customerEvents.pixInitiated({
          type: 'QRCODE',
          dictType: response?.qrCodeType,
          amount: response.txnOriginalAmount,
          walletId: response.walletId,
        });
        confirmPayment(response);
        setLoading(false);
        goNextStep();
      } catch (error: unknown) {
        setLoading(false);

        const errorService = error as AxiosError;
        const errorInfo = errorService?.response?.data as ErrorRequest;

        setMessageErrorService({
          message: t(`error.CODE_ERROR.${errorInfo?.code}`, t('error.default')),
          traceId: errorInfo?.traceId || '',
        });
      }
    };

    if (code) {
      paymentPix();
    }
  }, [code, t, confirmPayment, goNextStep, walletId]);

  return (
    <>
      <ContextualError error={messageErrorService} />
      <Camera
        checkCameraPermission={checkCameraPermission}
        cameraStream={cameraStream}
        getCode={setCode}
        cameraBlock={cameraBlock}
      >
        {code && (
          <Alert status="success">
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {t('payment-qr-code.qr-code')}
            </Typography>
          </Alert>
        )}
      </Camera>

      <SidebarFooter>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          onClick={() => back()}
        >
          {t('general.button.back')}
        </Button>
        <Button
          type="submit"
          size="large"
          disabled={true}
          data-testid="submitForm"
          className="button_submit"
        >
          {loading ? (
            <Spinner variant="secondary" />
          ) : (
            t('general.button.continue')
          )}
        </Button>
      </SidebarFooter>
    </>
  );
};
