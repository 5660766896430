import { CancelTokenSource } from 'axios';

import { organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import { CardListParameters, CardProps } from '@interfaces/Card.interfaces';
import { TransactionsResponse } from '@interfaces/Transactions.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';
import { CardTransactionsListParams } from './hooks/useInfinityCardTransactions';

export const findCardTransactions = async (
  id: number,
  limitPerPage: number,
  nextPage: string,
  walletId: string | undefined,
  source?: CancelTokenSource
): Promise<TransactionsResponse> => {
  const nextPageElement = nextPage ? `&next=${nextPage}` : '';
  const isWallet = walletId || 'default';
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWallet}/cards/${id}/transactions?limit=${limitPerPage}${nextPageElement}`,
    {
      cancelToken: source?.token,
    }
  );
  const { data } = response;

  return data;
};

export const cardTransactionsService = async (
  props: CardTransactionsListParams & { next: string | null }
): Promise<TransactionsResponse> => {
  const { cardId, walletId, ...rest } = props;

  const params = {
    limit: 15,
    ...rest,
  };

  removeEmptyObjectAttributes(params);

  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${walletId}/cards/${cardId}/transactions`,
    {
      params,
    }
  );

  const { data } = response;

  return data;
};

export const companyCard = async (): Promise<CardProps> => {
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/default/cards`
  );
  const { data } = response;

  return data;
};

export const findCardList = async ({
  nextPage,
  status,
  walletId,
  query: name,
}: CardListParameters): Promise<CardProps> => {
  const params: { [key: string]: string } = { limit: limitPerPage.toString() };

  if (status) {
    params.status = status;
  }

  if (nextPage) {
    params.next = nextPage;
  }

  if (name) {
    params.name = name;
  }

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}${
      walletId ? `/wallets/${walletId}` : ''
    }/cards`,
    { params }
  );

  const { data } = response;

  return data;
};
