import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { TransactionsResponse } from '@interfaces/Transactions.interfaces';
import { cardTransactionsService } from '../CardsService';
import { cardTransactionKeys } from './cardKeys';

export interface CardTransactionsListParams {
  cardId: string | undefined;
  walletId: string | undefined;
  startDate: string | null;
  endDate: string | null;
  merchantName: string | null;
  financialImpactType: string | null;
}

export const useInfinityCardTransactions = (
  params: CardTransactionsListParams
) => {
  const organizationId = localStorage.getItem('organization') || '';

  return useInfiniteQuery<
    TransactionsResponse,
    ApiError,
    InfiniteData<TransactionsResponse>,
    unknown[],
    string
  >({
    queryKey: cardTransactionKeys.list({ ...params, organizationId }),
    queryFn: ({ pageParam }) =>
      cardTransactionsService({ ...params, next: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage?.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    enabled:
      !!organizationId &&
      !!params.cardId &&
      !!params.startDate &&
      !!params.walletId,
  });
};
