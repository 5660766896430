import { endpoint } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { Mfa } from '@interfaces/Mfa.interfaces';
import api from '@services/api';

export const mfa = async <T>(payload: Mfa): Promise<T> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.mfa}`,
    payload
  );
  const { data } = response;

  return data;
};
