import { ICONS } from '@constants/icons';
import { InvoiceDealProps } from '@interfaces/Invoices';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';
import { Button, Input, Sidebar } from '@portao3-web/ui';
import { useUser } from '@providers';
import { copyToClipboard } from '@utils/copyClipboard';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { documentMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { SidebarFooter } from '../../../components/sidebarFooter';
import { SidebarSection } from '../../../components/sidebarSection';
import { ValueWrapper } from '../drawerFundsIn/components/ValueWrapper';

interface DrawerInvoiceStatementPixProps {
  onClose: () => void;
  statement: InvoiceStatementProps;
  invoice: InvoiceDealProps;
}

export const DrawerInvoiceStatementPix = ({
  statement,
  onClose,
  invoice,
}: DrawerInvoiceStatementPixProps) => {
  const { t } = useTranslation();
  const organizationId = localStorage.getItem('organization');

  const dueAt = statement.paymentData?.pix?.dueAt || invoice?.dueAt;

  const { organizations } = useUser();

  const getDocument = () => {
    const document = organizations?.find(
      (org) => org.id === organizationId
    )?.document;

    return documentMask(document);
  };

  const clipboardBillingValue = statement?.paymentData?.pix?.emv;

  const handleCopyBillingCode = () => {
    copyToClipboard(clipboardBillingValue);
  };

  return (
    <Sidebar
      open={!!statement}
      title={t('invoices.invoice-payment')}
      icon={ICONS['file-invoice']}
      onClose={onClose}
    >
      <SidebarSection
        titleSection={t('billing.billing-details')}
        subTitleSection={t('billing.who-receive-value')}
      >
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-6">
            <ValueWrapper
              isLoading={false}
              label="CPF/CNPJ"
              value={getDocument()}
            />

            <ValueWrapper isLoading={false} label="Instituição" value="Swap" />

            <ValueWrapper
              label={t('general.due-date')}
              value={dayjs.utc(dueAt)?.format('DD/MM/YYYY')}
            />

            <ValueWrapper
              label={t('general.value')}
              value={calcCurrencyValue(statement?.originalAmount, 'BRL')}
            />

            {invoice?.fine?.amount && (
              <ValueWrapper
                label={t('general.fine')}
                value={`${invoice?.fine?.amount}%`}
              />
            )}

            {invoice?.interest?.amount && (
              <ValueWrapper
                label={t('general.interest')}
                value={`${invoice?.interest?.amount * 100}%`}
              />
            )}

            <ValueWrapper
              className="col-span-2"
              label={t('general.description')}
              value={statement?.description}
            />
          </div>

          {statement?.paymentData?.pix?.emv && (
            <div className="barcode-container">
              <div data-testid="pix-wrapper" className="pix-wrapper">
                <QRCode size={178} value={statement?.paymentData?.pix?.emv} />
              </div>
            </div>
          )}

          <div className="flex flex-col">
            <Input
              name="some"
              register={() => ({})}
              readOnly
              data-testid="billing-code"
              type="text"
              label={t('general.qr-code')}
              value={clipboardBillingValue}
            />

            <div className="ml-auto mb-4 flex flex-row gap-3">
              <Button
                size="small"
                className="max-content"
                onClick={handleCopyBillingCode}
              >
                {t('billing.copy-code')}
              </Button>
            </div>
          </div>
        </div>
      </SidebarSection>

      <SidebarFooter>
        <Button size="large" onClick={onClose}>
          {t('button.finish')}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};
