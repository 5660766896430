import { StatusBillingEnum } from '@enums/Billing.enum';
import { StatusBilling } from '@interfaces/Invoices.interfaces';

export const getStatusStyle = (
  status: StatusBilling
): { icon: string; background: string; color: string } => {
  let icon, background, color;

  switch (status) {
    case StatusBillingEnum.PAID:
      icon = 'fa-circle-check';
      background = 'var(--product-success-s50)';
      color = 'var(--product-success-s500)';
      break;
    case StatusBillingEnum.DELAYED:
    case StatusBillingEnum.FAILED:
      icon = 'fa-circle-exclamation';
      background = 'var(--product-danger-d50)';
      color = 'var(--product-danger-d500)';
      break;
    case StatusBillingEnum.OPEN:
    case StatusBillingEnum.CLOSED:
      icon = 'fa-circle-dashed';
      background = 'var(--product-warning-w50)';
      color = 'var(--product-warning-w700)';
      break;
    case StatusBillingEnum.PROCESSING:
      icon = 'fa-circle-dashed';
      background = 'var(--product-info-i50)';
      color = 'var(--product-info-i700)';
      break;
    default:
      icon = '';
      background = '';
      color = '';
      break;
  }

  return {
    icon,
    background,
    color,
  };
};
