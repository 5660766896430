export enum ErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  BAD_REQUEST = 'BAD_REQUEST',
  ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  GATEWAY_TIMEOUT = 'GATEWAY_TIMEOUT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  RESOURCE_CONFLICT = 'RESOURCE_CONFLICT',
  NO_CONTENT = 'NO_CONTENT',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  KEY_NOT_FOUND = 'KEY_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  EXT_KEY_NOT_FOUND = 'EXT_KEY_NOT_FOUND',
  EXT_ERR_BAD_REQUEST = 'EXT_ERR_BAD_REQUEST',
}

export enum ErrorMessages {
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  REJECTED_BY_FRAUD = 'REJECTED_BY_FRAUD',
}

export enum UserStatusCodes {
  BLOCKED_BY_PIN = 'BLOCKED_BY_PIN',
  BLOCKED_BY_ADMIN = 'BLOCKED_BY_ADMIN',
  BLOCKED_BY_MFA = 'BLOCKED_BY_MFA',
  USER_ACTIVE = 'USER_ACTIVE',
}
