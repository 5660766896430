import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

export const deleteCustomer = async (customerId: string): Promise<unknown> => {
  const response = await api.delete(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customers
    }/${customerId}`
  );
  const { data } = response;

  return data;
};
