import api from '@services/api';
import { environment } from '@environments/environment';
import {
  ForgotPassword,
  ChangePassword,
} from '@interfaces/ForgotPassword.interfaces';
import { endpoint } from '@constants/endpoints';

export const confirmForgotPassword = async <T>(
  payload: ForgotPassword
): Promise<T> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.confirmForgotPassword}`,
    payload
  );
  const { data } = response;

  return data;
};

export const changePassword = async <T>(
  payload: ChangePassword
): Promise<T> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.newPassword}`,
    payload
  );
  const { data } = response;

  return data;
};
