export enum WalletBalanceCategory {
  FLEX_NATIONAL = 'FLEX_NATIONAL',
  FLEX_INTERNATIONAL = 'FLEX_INTERNATIONAL',
  FOOD = 'FOOD',
  GAS = 'GAS',
  MOBILITY = 'MOBILITY',
  TOLL = 'TOLL',
  ADS = 'ADS',
  SAAS = 'SAAS',
  HOTEL = 'HOTEL',
  AIRLINES = 'AIRLINES',
  TRAVEL = 'TRAVEL',
}

export const viewCategoryWallet = {
  FLEX_NATIONAL: 'Flex Nacional',
  FLEX_INTERNATIONAL: 'Flex Internacional',
  FOOD: 'Alimentação',
  GAS: 'Combustível',
  MOBILITY: 'Mobilidade',
  TOLL: 'Pedágio',
  ADS: 'Anúncios',
  SAAS: 'SaaS',
  HOTEL: 'Hotel',
  AIRLINES: 'Aéreo',
  TRAVEL: 'Viagem',
};
