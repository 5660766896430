import { useTranslation } from 'react-i18next';

import { SidebarFooter } from '@components';
import { ICONS } from '@constants/icons';
import { SecurityOptions } from '@enums/Security.enum';
import { Button } from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { handleOpenWpp } from '@utils/openWpp';
import { HeaderFlowCard } from '../components/HeaderFlowCard';
import { CreateMFA } from './CreateMFA';

export const MFAWrapper = () => {
  const { hasMfa, email } = useUser();

  const { t } = useTranslation();
  const { openDrawer, closeDrawer } = useDrawer();

  const handleBack = () => {
    openDrawer('security', { flow: 'DEFAULT' });
  };

  return (
    <div>
      <button
        className="text-p3 mb-4 flex items-center gap-2 text-neutral-100"
        onClick={handleBack}
      >
        <i className={`${ICONS.arrowLeft} text-p2`} />
        {t('button.back')}
      </button>

      <HeaderFlowCard
        type={SecurityOptions.MFA}
        status={hasMfa ? 'active' : 'pending'}
      />

      {!hasMfa ? (
        <CreateMFA />
      ) : (
        <>
          <div className="mt-4">
            <h2 className="text-p2 mb-1 text-neutral-500">
              {t('auth.disable-mfa')}
            </h2>

            <p className="text-p3 mb-4 text-neutral-100">
              {t('auth.if-disable-mfa-description')}
            </p>

            <Button
              onClick={() =>
                handleOpenWpp(t('auth.reset-mfa-message', { email }))
              }
              variant="danger"
              size="small"
            >
              <i className={`${ICONS.wpp} text-p3`} />
              {t('general.contact-central')}
            </Button>
          </div>
          <SidebarFooter>
            <Button onClick={handleBack} variant="tertiary" size="large">
              {t('button.back')}
            </Button>

            <Button onClick={closeDrawer} size="large">
              {t('general.button.close')}
            </Button>
          </SidebarFooter>
        </>
      )}
    </div>
  );
};
