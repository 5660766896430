import { useEffect, useState } from 'react';
import { Typography } from '..';
import { ModalProps } from './Modal.interface';
import './Modal.styles.scss';

export const Modal = ({ children, open, closeModal, title }: ModalProps) => {
  const [openModal, setOpenModal] = useState(open);

  useEffect(() => {
    const closeSideBarClickingEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        setOpenModal(false);
        closeModal && closeModal(false);
      }
    };

    document.addEventListener('keydown', closeSideBarClickingEsc);

    return () => {
      document.removeEventListener('keydown', closeSideBarClickingEsc);
    };
  }, [closeModal, open]);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  return (
    <>
      <div
        data-testid="modal"
        className={`container_modal--${openModal ? 'show' : 'hidden'}`}
      />
      <div
        data-testid="container-children"
        className={`container_children--${openModal ? 'show' : 'hidden'}`}
      >
        <header className="header_modal">
          <Typography tag="h4" color="var(--product-neutral-n500)">
            {title}
          </Typography>
        </header>
        <div data-testid="modal-content">{children}</div>
      </div>
    </>
  );
};
