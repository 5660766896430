import { useTranslation } from 'react-i18next';

import { SidebarFooter } from '@components';
import { ICONS } from '@constants/icons';
import { Button } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { handleOpenWpp } from '@utils/openWpp';

export const PINHome = () => {
  const { t } = useTranslation();
  const { closeDrawer, openDrawer } = useDrawer();

  const handleBack = () => {
    openDrawer('security', { flow: 'DEFAULT' });
  };

  return (
    <>
      <div className="flex flex-col gap-4 divide-y-[1px] divide-solid divide-neutral-40">
        <div>
          <h2 className="text-p2 mb-1 text-neutral-500">
            {t('auth.forgot-pin')}
          </h2>

          <p className="text-p3 mb-4 text-neutral-100">
            {t('auth.forget-pin-step')}
          </p>

          <Button
            onClick={() =>
              handleOpenWpp(
                'Olá, gostaria de efetuar a troca do meu PIN de segurança.'
              )
            }
            variant="secondary"
            size="small"
          >
            <i className={`${ICONS.wpp} text-p3`} />

            {t('general.contact-central')}
          </Button>
        </div>

        <div>
          <h2 className="text-p2 mb-1 mt-4 text-neutral-500">
            {t('auth.disable-pin')}
          </h2>

          <p className="text-p3 mb-4 text-neutral-100">
            {t('auth.insert-code-disable')}
          </p>

          <Button
            onClick={() =>
              handleOpenWpp(
                'Olá, gostaria de efetuar a desativação do meu PIN de segurança.'
              )
            }
            variant="danger"
            size="small"
          >
            <i className={`${ICONS.wpp} text-p3`} />
            {t('general.contact-central')}
          </Button>
        </div>
      </div>

      <SidebarFooter>
        <Button onClick={handleBack} variant="tertiary" size="large">
          {t('button.back')}
        </Button>

        <Button onClick={closeDrawer} size="large">
          {t('general.button.close')}
        </Button>
      </SidebarFooter>
    </>
  );
};
