import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import {
  BatchTransaction,
  FindBatchTransactionsListPayload,
} from '@interfaces/BatchPayments.interfaces';
import { ListResponse } from '@interfaces/Common.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export const findBatchTransactionsList = async (
  batchId: string,
  walletId: string,
  params?: FindBatchTransactionsListPayload
): Promise<ListResponse<BatchTransaction>> => {
  const paramsCopy = { ...params };
  paramsCopy.limit = paramsCopy.limit || limitPerPage;
  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.batchPayment}/${batchId}`,
    { params: paramsCopy }
  );

  const { data } = response;

  return data;
};
