import { ColumnDef } from '@tanstack/react-table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { StatusTag } from '@components';
import { ICONS } from '@constants/icons';
import {
  TransactionBatchStatus,
  TransactionBatchTypes,
} from '@enums/BatchPayment.enum';
import { BillingStatusEnum } from '@enums/Billing.enum';
import { useQueryParams } from '@hooks';
import { BatchTransaction } from '@interfaces/BatchPayments.interfaces';
import { TableIcon, TableItemTitleSub } from '@portao3-web/ui';
import { faker } from '@utils/faker';
import { cnpjMask, cpfMask, moneyMask } from '@utils/formatMasks';
import { BatchTableItem } from '../DrawerBatchPayment.interfaces';
import { DownloadVoucherButton } from './DownloadVoucherButton';

const getMaskedDocument = (document: string) => {
  return document?.length === 14 ? cnpjMask(document) : cpfMask(document);
};

export const useReviewColumns = (): ColumnDef<BatchTransaction>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'transactionAt',
      header: t('general.date'),
      cell: ({ cell, row }) => (
        <span
          className={clsx({
            'line-through':
              row.original.status === TransactionBatchStatus.VALIDATION_ERROR,
          })}
          style={{ font: 'inherit' }}
        >
          {dayjs(cell.getValue<string>()).utc().format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      accessorKey: 'type',
      header: '',
      cell: ({ cell }) => {
        const paymentMethod = cell.getValue<TransactionBatchTypes>();
        const paymentIcons: Record<TransactionBatchTypes, string> = {
          [TransactionBatchTypes.BANK_SLIP]: ICONS.barcode,
          [TransactionBatchTypes.PIX_DICT]: ICONS.qrcode,
          [TransactionBatchTypes.PIX_BANK_DETAILS]: ICONS.qrcode,
        };

        return <TableIcon icon={paymentIcons[paymentMethod]} />;
      },
    },
    {
      accessorKey: 'name',
      header: t('general.recipient'),
      meta: {
        width: 'auto',
      },
      cell: ({ row }) => {
        const document = row.original.creditParty?.document || '00000000000000';
        const name = row.original.creditParty?.name || 'Nome do beneficiário';

        return (
          <TableItemTitleSub
            className={clsx({
              'line-through':
                row.original.status === TransactionBatchStatus.VALIDATION_ERROR,
            })}
            title={name}
            subtitle={getMaskedDocument(document)}
          />
        );
      },
    },
    {
      accessorKey: 'amount',
      header: t('general.value'),
      cell: ({ cell, row }) => (
        <span
          className={clsx({
            'line-through':
              row.original.status === TransactionBatchStatus.VALIDATION_ERROR,
          })}
          style={{ font: 'inherit' }}
        >
          {moneyMask(cell.getValue<number>().toString())}
        </span>
      ),
    },
  ];
};

export const useBatchDetailsColumns = (): ColumnDef<BatchTransaction>[] => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();

  return [
    {
      accessorKey: 'transactionAt',
      header: t('general.date'),
      cell: ({ cell }) =>
        dayjs(cell.getValue<string>()).utc().format('DD/MM/YYYY'),
    },
    {
      accessorKey: 'type',
      header: '',
      cell: ({ cell }) => {
        const paymentMethod = cell.getValue<TransactionBatchTypes>();
        const paymentIcons: Record<TransactionBatchTypes, string> = {
          [TransactionBatchTypes.BANK_SLIP]: ICONS.barcode,
          [TransactionBatchTypes.PIX_DICT]: ICONS.qrcode,
          [TransactionBatchTypes.PIX_BANK_DETAILS]: ICONS.qrcode,
        };

        return <TableIcon icon={paymentIcons[paymentMethod]} />;
      },
    },
    {
      accessorKey: 'name',
      header: t('general.recipient'),
      meta: {
        width: 'auto',
      },
      cell: ({ row }) => (
        <TableItemTitleSub
          title={row.original.creditParty.name}
          subtitle={getMaskedDocument(row.original.creditParty.document)}
        />
      ),
    },
    {
      accessorKey: 'amount',
      header: t('general.value'),
      cell: ({ cell }) => moneyMask(cell.getValue<number>().toString()),
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      cell: ({ cell }) => (
        <StatusTag
          status={cell.getValue<TransactionBatchStatus>()}
          type="BATCH_TRANSACTIONS"
        />
      ),
    },
    {
      id: 'actions',
      header: t('general.actions'),
      cell: ({ row }) => {
        const walletId = queryParams.get('walletId') ?? '';

        return (
          <DownloadVoucherButton
            walletId={walletId}
            transaction={row.original}
          />
        );
      },
    },
  ];
};

enum DocCNABType {
  CPF = '1',
  CNPJ = '2',
}

const createBatchMock = (): BatchTableItem => {
  const document_type = faker.helpers.enumValue(DocCNABType);
  const document =
    document_type === DocCNABType.CPF
      ? '000' + faker.document.cpf()
      : faker.document.cnpj();

  return {
    id: faker.string.uuid(),
    name: faker.person.fullName(),
    transactionAt: faker.date.soon({ days: 10 }).toISOString(),
    type: faker.helpers.enumValue(TransactionBatchTypes),
    amount: faker.number.int(100000),
    document,
    document_type,
    status: faker.helpers.enumValue(BillingStatusEnum),
    error: faker.datatype.boolean(),
  };
};

export const mockData = Array.from({ length: 40 }, createBatchMock);
