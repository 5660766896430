import { useTranslation } from 'react-i18next';

import { Alert, SidebarFooter } from '@components';
import { SecurityOptions } from '@enums/Security.enum';
import { Button } from '@portao3-web/ui';
import { BarSecurityProgress, useDrawer, useUser } from '@providers';
import { SecurityCards } from './SecurityCard';

export const Security = () => {
  const { t } = useTranslation();
  const { hasPin, hasMfa } = useUser();
  const { closeDrawer } = useDrawer();

  const getAlertText = () => {
    if (hasMfa && !hasPin) {
      return t('auth.improve-security-alert-pin');
    }

    if (hasPin && !hasMfa) {
      return t('auth.improve-security-alert-mfa');
    }

    return t('auth.improve-security-alert');
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          {(!hasMfa || !hasPin) && (
            <Alert status="warning">
              <span className="text-p2 text-neutral-500">{getAlertText()}</span>
            </Alert>
          )}

          <div className="border-neutral-30 rounded-md border border-solid shadow-sm">
            <BarSecurityProgress />
          </div>
        </div>

        <SecurityCards type={SecurityOptions.MFA} />

        <SecurityCards type={SecurityOptions.PIN} />
      </div>

      <SidebarFooter>
        <Button size="large" onClick={closeDrawer}>
          {t('general.button.close')}
        </Button>
      </SidebarFooter>
    </>
  );
};
