import { Bullet, Typography } from '..';
import { StatusProps } from './Status.interface';
import './Status.styles.scss';

export const Status = ({ status, children, icon, onClick }: StatusProps) => {
  return (
    <div
      className={`status ${!onClick ? 'hover:cursor-default' : ''}`}
      onClick={onClick}
    >
      <Bullet status={status} />{' '}
      <Typography tag="p" weight="p3">
        {children}
      </Typography>
      {icon && <i className="fa-solid fa-close status_close" />}
    </div>
  );
};
