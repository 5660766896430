export enum TransactionBatchTypes {
  PIX_DICT = 'PIX_DICT',
  BANK_SLIP = 'BANK_SLIP',
  PIX_BANK_DETAILS = 'PIX_BANK_DETAILS',
}

export enum BatchPaymentStatus {
  IN_VALIDATION = 'IN_VALIDATION',
  PROCESSING = 'PROCESSING',
  COMPLETED_SUCCESSFULLY = 'COMPLETED_SUCCESSFULLY',
  COMPLETED_WITH_ERROR = 'COMPLETED_WITH_ERROR',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export enum TransactionBatchStatus {
  IN_VALIDATION = 'IN_VALIDATION',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  PROCESSING = 'PROCESSING',
  INITIATED = 'INITIATED',
  SCHEDULED = 'SCHEDULED',
  EXECUTED = 'EXECUTED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}
