import { BillingDetail } from '@interfaces/Billing.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { findBillingById } from '../findBillingById';
import { billingKeys } from './billingKeys';

export const useBillingQuery = (walletId: string, billingId: string) => {
  return useQuery<BillingDetail, ApiError, BillingDetail, unknown[]>({
    queryKey: billingKeys.detail(walletId, billingId),
    queryFn: () => findBillingById(walletId, billingId),
    placeholderData: keepPreviousData,
    enabled: !!billingId,
  });
};
