import clsx from 'clsx';

import { TableRowProps } from './TableRow.interface';
import './TableRow.styles.scss';

export const TableRow = ({ children, className, ...props }: TableRowProps) => {
  return (
    <tr className={clsx('tr', className)} {...props}>
      {children}
    </tr>
  );
};
