import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BatchPaymentStatus } from '@enums/BatchPayment.enum';
import { useQueryParams } from '@hooks/useQueryParams';
import { Sidebar, SidebarSize } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { DrawerBatchPaymentFlow } from './DrawerBatchPayment.interfaces';
import { BatchDetail, BatchIdentity, BatchReview } from './components';

export const DrawerBatchPayment = () => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();

  const paramBatchStatus = queryParams.get(
    'batchStatus'
  ) as BatchPaymentStatus | null;

  const [flow, setFlow] = useState<DrawerBatchPaymentFlow>('IDENTITY');

  const { closeDrawer } = useDrawer();

  const flows: Record<DrawerBatchPaymentFlow, ReactNode> = {
    IDENTITY: <BatchIdentity setFlow={setFlow} />,
    REVIEW: <BatchReview setFlow={setFlow} />,
    DETAIL: <BatchDetail />,
  };

  const flowSize: Record<DrawerBatchPaymentFlow, SidebarSize> = {
    IDENTITY: 'small',
    REVIEW: 'medium',
    DETAIL: 'large',
  };

  useEffect(
    () => {
      if (paramBatchStatus === BatchPaymentStatus.IN_VALIDATION) {
        return setFlow('REVIEW');
      }

      if (paramBatchStatus) {
        setFlow('DETAIL');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Sidebar
      open={true}
      title={t('general.batch-payment')}
      icon="fa-regular fa-layer-group"
      onClose={closeDrawer}
      size={flowSize[flow]}
    >
      {flows[flow]}
    </Sidebar>
  );
};
