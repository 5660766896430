import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { Button } from '../../button/Button';
import { Skeleton } from '../../skeleton/Skeleton';
import { Spinner } from '../../spinner/Spinner';
import { Typography } from '../../typography/Typography';
import { ShadCheckbox } from '../shadCheckbox';
import {
  ShadTable,
  ShadTableBody,
  ShadTableCell,
  ShadTableHead,
  ShadTableHeader,
  ShadTableRow,
} from '../shadTable';
import './ShadTable.d.ts';

type AllOrNone<T> = Required<T> | Partial<Record<keyof T, undefined>>;

interface NextPageProps {
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
}

interface RowSelectionProps {
  rowSelection: Record<string, boolean>;
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>;
}

type DataProps<TData> = TData & {
  id: string;
  onRowClick?: () => void;
  disabledCheckbox?: boolean;
};

type TableDemoProps<TData, TValue> = {
  columns: ColumnDef<TData & { id: string }, TValue>[];
  data?: DataProps<TData>[];
  title?: string;
  pinLastColumn?: boolean;
  headerComponent?: React.ReactNode;
  isLoading?: boolean | number;
  defaultChecked?: boolean;
  emptyState?: React.ReactNode;
  loadMoreButtonLabel?: string;
  headerColor?: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  AllOrNone<NextPageProps> &
  AllOrNone<RowSelectionProps>;

// TODO - refactor sticky styles
// TODO - max-height
export const TableDemo = <TData, TValue>({
  columns,
  data,
  rowSelection,
  setRowSelection,
  pinLastColumn = false,
  className,
  headerComponent,
  title,
  isLoading = false,
  fetchNextPage,
  hasNextPage = false,
  isFetchingNextPage = false,
  defaultChecked = false,
  emptyState,
  loadMoreButtonLabel,
  headerColor,
}: TableDemoProps<TData, TValue>) => {
  const selectedRef = useRef<boolean>(false);
  const stickShadowRef = useRef<boolean>(false);
  const tableRef = useRef<HTMLTableElement>(null);

  const [showStickyShadow, setShowStickyShadow] = useState(false);

  const table = useReactTable({
    data: data ?? [],
    // TODO - move checkbox out of here
    columns: setRowSelection
      ? [
          {
            id: 'select',
            header: ({ table }) => (
              <ShadCheckbox
                checked={
                  table.getIsAllPageRowsSelected() ||
                  (table.getIsSomePageRowsSelected() && 'indeterminate')
                }
                onCheckedChange={(value) =>
                  table.toggleAllPageRowsSelected(!!value)
                }
                aria-label="Select all"
                disabled={
                  data?.every((row) => row.disabledCheckbox) ||
                  data?.length === 0
                }
              />
            ),
            cell: ({ row }) => (
              <ShadCheckbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="specific-cell-class"
                data-stop-propagation
                disabled={(row.original as DataProps<TData>)?.disabledCheckbox}
              />
            ),
            enableSorting: false,
            enableHiding: false,
          },
          ...columns,
        ]
      : columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: (row) =>
      !(row.original as DataProps<TData>)?.disabledCheckbox,
    onRowSelectionChange: setRowSelection,
    getRowId: (row: DataProps<TData>) => row.id,
    state: { rowSelection: rowSelection ?? {} },
  });

  const handleHorizontalScroll = () => {
    const container = tableRef.current;

    if (container) {
      const scrollLeft = Math.ceil(container.scrollLeft);
      const scrollableWidth = container.scrollWidth - container.clientWidth;
      setShowStickyShadow(scrollLeft < scrollableWidth);
    }
  };

  const handleRowClick = (
    event: React.MouseEvent<HTMLElement>,
    callback?: () => void
  ) => {
    const target = event.target as HTMLElement;
    const stopPropagation = target.getAttribute('data-stop-propagation');
    if (stopPropagation) return;
    callback?.();
  };

  useEffect(() => {
    if (!data || data.length === 0) return;

    if (!stickShadowRef.current) {
      handleHorizontalScroll();
      stickShadowRef.current = true;
    }

    if (setRowSelection && defaultChecked && !selectedRef.current) {
      setRowSelection(
        data.reduce((acc: Record<string, boolean>, data) => {
          acc[data.id] = !data.disabledCheckbox;
          return acc;
        }, {})
      );
      selectedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultChecked]);

  return (
    <>
      {title && (
        <div className="bg-neutral border-neutral-40 flex justify-between overflow-hidden rounded-t-md border border-solid px-4 py-3">
          <Typography tag="h3" color="var(--product-neutral-n500)">
            {title}
          </Typography>
          {headerComponent && headerComponent}
        </div>
      )}

      <div
        className={clsx(
          `bg-neutral border-neutral-40 overflow-hidden rounded-md border border-solid`,
          { 'rounded-t-none border-t-0': title },
          className
        )}
      >
        <ShadTable
          ref={tableRef}
          onScroll={pinLastColumn ? handleHorizontalScroll : undefined}
        >
          <ShadTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <ShadTableRow
                key={headerGroup.id}
                className={clsx(
                  title || headerColor ? 'bg-neutral-20' : 'bg-neutral'
                )}
              >
                {headerGroup.headers.map((header) => {
                  const isLastColumn = header.column.getIsLastColumn();
                  const width = header.column.columnDef.meta?.width;
                  const isSticky = isLastColumn && pinLastColumn;
                  const noPadding = header.column.columnDef.header === '';
                  return (
                    <ShadTableHead
                      key={header.id}
                      style={{
                        width: isSticky || !width ? '1px' : width,
                        position: isSticky ? 'sticky' : 'relative',
                        right: isSticky ? 0 : 'auto',
                        backgroundColor: isSticky ? 'inherit' : 'transparent',
                        filter:
                          isSticky && showStickyShadow
                            ? 'drop-shadow(-4px 0 4px rgba(0, 0, 0, 0.03))'
                            : 'none',
                        padding: noPadding ? 0 : undefined,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </ShadTableHead>
                  );
                })}
              </ShadTableRow>
            ))}
          </ShadTableHeader>
          <ShadTableBody>
            {isLoading ? (
              Array.from({
                length: typeof isLoading === 'number' ? isLoading : 5,
              }).map((_, index) => (
                <ShadTableRow key={index} className="bg-neutral">
                  {table
                    .getAllColumns()
                    .map((column) => column.columnDef.header)
                    .map((header, index) => {
                      return (
                        <ShadTableCell key={index}>
                          <Skeleton
                            isLoading
                            height={32}
                            width={header ? '100%' : 32}
                            variant={header ? 'rounded' : 'circular'}
                          />
                        </ShadTableCell>
                      );
                    })}
                </ShadTableRow>
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <ShadTableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={clsx('bg-neutral', {
                    'cursor-pointer': (row.original as DataProps<TData>)
                      .onRowClick,
                  })}
                  onClick={(event) =>
                    handleRowClick(
                      event,
                      (row.original as DataProps<TData>).onRowClick
                    )
                  }
                >
                  {row.getVisibleCells().map((cell) => {
                    const isLastColumn = cell.column.getIsLastColumn();
                    const width = cell.column.columnDef.meta?.width;
                    const isSticky = pinLastColumn && isLastColumn;
                    const noPadding = cell.column.columnDef.header === '';
                    return (
                      <ShadTableCell
                        key={cell.id}
                        style={{
                          width: isSticky || !width ? '1px' : width,
                          position: isSticky ? 'sticky' : 'relative',
                          right: isSticky ? 0 : 'auto',
                          backgroundColor: isSticky ? 'inherit' : 'transparent',
                          filter:
                            isSticky && showStickyShadow
                              ? 'drop-shadow(-4px 0 4px rgba(0, 0, 0, 0.03))'
                              : 'none',
                          padding: noPadding ? 0 : undefined,
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </ShadTableCell>
                    );
                  })}
                </ShadTableRow>
              ))
            ) : (
              <ShadTableRow>
                <ShadTableCell colSpan={columns.length} className="text-center">
                  {emptyState ?? 'Nenhum resultado encontrado.'}
                </ShadTableCell>
              </ShadTableRow>
            )}
            {hasNextPage && fetchNextPage && (
              <ShadTableRow>
                <ShadTableCell colSpan={columns.length} className="text-center">
                  <Button
                    type="button"
                    size="small"
                    variant="secondary"
                    className="mx-auto"
                    onClick={fetchNextPage}
                    disabled={isFetchingNextPage}
                  >
                    {isFetchingNextPage ? (
                      <Spinner />
                    ) : (
                      (loadMoreButtonLabel ?? 'Load more')
                    )}
                  </Button>
                </ShadTableCell>
              </ShadTableRow>
            )}
          </ShadTableBody>
        </ShadTable>
      </div>
    </>
  );
};
