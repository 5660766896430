import { useTranslation } from 'react-i18next';

import { Alert } from '@components';
import { Button, Icon, Modal, Spinner, Typography } from '@portao3-web/ui';
import { ChangeStatusModalProps } from './ChangeStatusModal.interface';
import './ChangeStatusModal.styles.scss';

export const ChangeStatusModal = ({
  open,
  closeModal,
  changeStatus,
  modalLabel = '',
  modalText,
  errorService,
  isLoading,
}: ChangeStatusModalProps) => {
  const { t } = useTranslation();
  const dispatchChangeStatus = () => {
    changeStatus();
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={modalText.modalTitle || modalLabel}
    >
      <div className="change_status_modal_body" data-testid="disclaimer">
        <Typography color="var(--product-neutral-n80)" tag="p" weight="p1">
          {modalText.disclaimer}
        </Typography>
        {modalText.warn && (
          <div className="change_status_modal_warn">
            <Icon size="xlarge">
              <i
                className="fa-solid fa-triangle-exclamation"
                style={{ color: 'var(--product-warning-w500)' }}
              />
            </Icon>
            <div
              className="change_status_modal_warn_text"
              data-testid="warnText"
            >
              <Typography
                color="var(--product-warning-w700)"
                tag="p"
                weight="p1"
              >
                {modalText.warn.title}
              </Typography>
              <Typography color="var(--product-neutral-n100)" tag="p">
                {modalText.warn.description}
              </Typography>
            </div>
          </div>
        )}
        <Typography
          color="var(--product-neutral-n80)"
          tag="p"
          weight="p1"
          data-testid="confirmation"
          className="mt-4"
        >
          {modalText.confirmation}
        </Typography>
        <div className="m-12">
          {errorService && (
            <Alert status="error">
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {t('error.default')}
              </Typography>
            </Alert>
          )}
        </div>
      </div>

      <footer className="footer_modal">
        <Button
          size="large"
          variant="secondary"
          onClick={closeModal}
          className="change_status_modal_button--no"
          data-testid="cancelButton"
          disabled={isLoading}
        >
          Não
        </Button>
        <Button
          size="large"
          onClick={dispatchChangeStatus}
          className="change_status_modal_button--yes"
          disabled={isLoading}
          data-testid="confirmButton"
        >
          {isLoading ? (
            <Spinner variant="secondary" />
          ) : (
            modalText.actionButtonLabel
          )}
        </Button>
      </footer>
    </Modal>
  );
};
