import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { SecurityOptions } from '@enums/Security.enum';
import { Icon } from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { Tag } from '../components/Tag';

interface SecurityCardsProps {
  type: SecurityOptions;
}

type SecurityItems = {
  [key in SecurityOptions]: {
    icon: string;
    title: string;
    description: string;
    status: 'active' | 'pending' | 'blocked';
    onClick?: () => void;
    enabled: boolean;
  };
};

export const SecurityCards = ({ type }: SecurityCardsProps) => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const { hasMfa, hasPin } = useUser();

  const securityItems: SecurityItems = {
    [SecurityOptions.MFA]: {
      icon: ICONS.fingerprint,
      title: t('auth.mfa-title'),
      description: t('auth.mfa-description'),
      onClick: () => openDrawer('security', { flow: 'MFA' }),
      status: hasMfa ? 'active' : 'pending',
      enabled: true,
    },
    [SecurityOptions.PIN]: {
      icon: ICONS['shield-keyhole'],
      title: t('auth.transaction-pin-title'),
      description: t('auth.transaction-pin-description'),
      onClick: () => openDrawer('security', { flow: 'PIN' }),
      status: hasPin ? 'active' : 'pending',
      enabled: true,
    },
  };

  const handleClick = () => {
    securityItems[type].onClick?.();
  };

  return (
    <div
      className={clsx({
        'border-neutral-40 flex flex-col gap-1 rounded-md border border-solid p-4':
          true,
        'opacity-70': !securityItems[type].enabled,
        'hover:bg-neutral-10': securityItems[type].enabled,
      })}
      data-testid={`security-card-${type.toLowerCase()}`}
      role="button"
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <Icon size="small">
          <i
            className={clsx({
              [securityItems[type].icon]: true,
              'fa-lg text-primary-500 xl': true,
            })}
          />
        </Icon>

        <h3 className="text-p2 flex-1 text-neutral-300">
          {securityItems[type].title}
        </h3>
        <Tag status={securityItems[type].status} />
      </div>

      <span className="text-p3 text-neutral-100">
        {securityItems[type].description}
      </span>
    </div>
  );
};
