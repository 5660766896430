import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { Toastr } from '@portao3-web/ui';
import { CancelScheduleRequest } from '../../interface/scheduling.interface';
import { cancelSchedule } from '../cancelSchedule';

export const useCancelScheduleMutation = () => {
  const { t } = useTranslation();

  return useMutation<void, ApiError, CancelScheduleRequest, unknown>({
    mutationFn: (payload) => cancelSchedule(payload),
    onError: () => {
      Toastr.error(t('error.default'));
    },
  });
};
