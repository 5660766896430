import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { BillingDetail } from '@interfaces/Billing.interfaces';
import api from '@services/api';

export const findBillingById = async (
  walletId: string,
  billingId: string
): Promise<BillingDetail> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.billings}/${billingId}`
  );
  const { data } = response;

  return data;
};
