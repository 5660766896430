import { ColumnDef } from '@tanstack/react-table';
import { useLocalStorage } from './useLocalStorage';

export const useFilterColumns = <T,>(key: string, columns: ColumnDef<T>[]) => {
  const defaultColumns = columns.reduce((acc, column, index) => {
    if (!column.meta?.filter?.noSelected) {
      acc.push(index.toString());
    }
    return acc;
  }, [] as string[]);

  const [selectedItems, setSelectedItems] = useLocalStorage(
    key,
    defaultColumns
  );

  const columnOptions = columns
    .map((column, index) => ({
      label: column.header as string,
      value: index.toString(),
      disabled: !!column?.meta?.filter?.disabled,
    }))
    .filter((column) => !!column.label);

  const filteredColumns = columns.filter((column, index) => {
    if (!column.header) {
      return selectedItems.includes((index + 1).toString());
    }

    return selectedItems.includes(index.toString());
  });

  return {
    columns: filteredColumns,
    filterColumnsProps: {
      options: columnOptions,
      selection: selectedItems,
      setSelection: setSelectedItems,
    },
  };
};
