import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CalculateBillingStatement,
  ContextualError,
  Input,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { ICONS } from '@constants/icons';
import {
  FinancialImpactType,
  InvoiceIdentifierType,
} from '@enums/Invoices.enum';
import { yupResolver } from '@hookform/resolvers/yup';

import { InvoiceDealProps } from '@interfaces/Invoices';
import { Button, Select, Sidebar, TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { moneyMask, removeMoneyMask } from '@utils/formatMasks';
import { validationSchema } from '@utils/validationSchema';
import { useInvoiceAdjustmentsColumns } from '../drawerInvoiceDetails/components/useInvoiceDetailsColumns';
import {
  useCreateAdjustmentStatementMutation,
  useCustomerStatementsQuery,
} from '@services/invoices/hooks';

interface DefaultValues {
  description: string;
  amount: string;
  adjustments: string;
}

interface DrawerInvoiceAdjustProps {
  invoice: InvoiceDealProps;
}

export const DrawerInvoiceAdjust = ({ invoice }: DrawerInvoiceAdjustProps) => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  const columns = useInvoiceAdjustmentsColumns();

  const defaultValues = {
    description: '',
    amount: '',
    adjustments: '',
  };

  const form = useForm<DefaultValues>({
    defaultValues,
    resolver: yupResolver(validationSchema(defaultValues)) as unknown as never,
  });

  const { data: statements, isLoading: isLoadingStatements } =
    useCustomerStatementsQuery({
      invoiceId: invoice.id,
      enabled: true,
    });

  const {
    mutate: createAdjustmentStatement,
    isPending: isPendingCreateCustomer,
    customError: createAdjustmentError,
  } = useCreateAdjustmentStatementMutation();

  const createAdjustment = (data: DefaultValues) => {
    const payload = {
      identifier: data.adjustments as InvoiceIdentifierType,
      description: data.description,
      billingAmount:
        data.adjustments === InvoiceIdentifierType.DISCOUNT
          ? Number(removeMoneyMask(data.amount))
          : 0,
      originalAmount: Number(removeMoneyMask(data.amount)),
      financialImpactType:
        data.adjustments === InvoiceIdentifierType.DISCOUNT
          ? FinancialImpactType.CREDIT
          : FinancialImpactType.DEBIT,
    };

    createAdjustmentStatement(
      { invoiceId: invoice.id, payload },
      {
        onSuccess: () => {
          form.reset(defaultValues);
        },
      }
    );
  };

  const validIdentifiers = new Set([
    InvoiceIdentifierType.INCREASE,
    InvoiceIdentifierType.DISCOUNT,
  ]);

  return (
    <Sidebar
      open
      title={t('invoices.invoice-adjust-drawer-title')}
      icon={ICONS['file-invoice']}
      onClose={closeDrawer}
    >
      <form onSubmit={form.handleSubmit(createAdjustment)}>
        <SidebarSection
          titleSection={t('invoices.invoice-adjust-launch')}
          subTitleSection={t('invoices.invoice-adjust-launch-subtitle')}
        >
          <div className="flex flex-col gap-6">
            {createAdjustmentError && (
              <ContextualError error={createAdjustmentError} />
            )}
            <div className="flex flex-col">
              <Select
                id="adjustments"
                name="adjustments"
                form={form}
                label={t('invoices.invoice-adjust-drawer-title')}
                placeholder={t('general.select')}
                options={[
                  { label: 'Desconto', value: InvoiceIdentifierType.DISCOUNT },
                  { label: 'Acréscimo', value: InvoiceIdentifierType.INCREASE },
                ]}
              />
              <div className="flex flex-col">
                <div className="flex gap-4">
                  <Input
                    name="description"
                    form={form}
                    label={t('general.description')}
                    placeholder="Ex: Desconto"
                  />

                  <Input
                    name="amount"
                    form={form}
                    onChange={({ target }) => {
                      form.setValue('amount', moneyMask(target.value));
                    }}
                    label={t('general.value')}
                    placeholder="Ex: R$ 1.000,00"
                  />
                </div>
                <div className="ml-auto mb-4 flex flex-row gap-3">
                  <Button
                    variant="secondary"
                    size="small"
                    className="max-content"
                    isLoading={isPendingCreateCustomer}
                  >
                    {t('general.add')}
                  </Button>
                </div>
              </div>
            </div>

            <TableDemo
              headerColor
              isLoading={isLoadingStatements ? 2 : undefined}
              columns={columns}
              data={statements?.items
                .filter(({ identifier }) =>
                  validIdentifiers.has(identifier as InvoiceIdentifierType)
                )
                .map((entry) => ({
                  financialImpactType: entry.financialImpactType,
                  id: entry.id,
                  product: entry.description,
                  value: entry.originalAmount.toString(),
                }))}
            />

            <CalculateBillingStatement
              invoiceId={invoice.id}
              invoiceAmount={invoice.amount}
              statement={statements}
            />
          </div>
        </SidebarSection>

        <SidebarFooter>
          <Button type="button" size="large" onClick={closeDrawer}>
            {t('general.button.close')}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};
