import { PaymentMethod } from '@enums/BillingDeal.enum';
import {
  FinancialImpactType,
  InvoiceStatementStatus,
} from '@enums/Invoices.enum';
import { ListResponse } from '@interfaces/Common.interfaces';
import {
  InvoiceDealProps,
  InvoiceStatus,
  PartyAccountBank,
  PartyAccountType,
  PaymentUnit,
} from '@interfaces/Invoices';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';

export const initialStateInvoice: ListResponse<InvoiceDealProps> = {
  next: '',
  items: Array.from({ length: 3 }, () => ({
    external: {
      b3BankSlip: '',
      b3Pix: '',
    },
    id: '8fe860c0-4dec-416e-b7cd-136288c0fa42',
    realm: 'c84ab0dc-bd38-4fd9-9ecf-24d7aff1361c',
    organization: '1054d804-1d62-407a-b9e3-74b9a605b1e1',
    contract: '47b26f99-1717-4d48-919c-043fa2a400c9',
    deal: 'f8186ccb-2b0d-4af9-98b2-11f4a33d27a5',
    description: '#Fatura(000000002)',
    allowedPaymentMethods: [PaymentMethod.BANK_SLIP],
    closingAt: new Date(),
    dueAt: new Date(),
    expiresAt: new Date(),
    debitParties: [
      {
        partyAccount: {
          address: {
            street: 'AV DOS VINHEDOS',
            number: '21',
            neighborhood: 'JARDIM SUL',
            city: 'UBERLANDIA',
            state: 'MG',
            postalCode: '38411-694',
          },
          document: '06136393000141',
          name: 'V4',
          email: 'financeiro@portao3.com.br',
          bank: 'P3' as PartyAccountBank,
          type: 'WALLET' as PartyAccountType,
          identifier: {
            realm: 'c84ab0dc-bd38-4fd9-9ecf-24d7aff1361c',
            organization: '1054d804-1d62-407a-b9e3-74b9a605b1e1',
            account: '64fa2b9132c9e02752512db7',
            wallet: '661863ad1c12b35d2cec2cbb',
          },
        },
        unit: 'PERCENTAGE' as PaymentUnit,
        amount: 100,
        platformWalletName: '[BILLING] DP',
        platformWalletId: 'b86a7623-6062-489e-a5b1-e98e9c6ff8c1',
      },
    ],
    status: 'OPEN' as InvoiceStatus,
    amount: 2000,
    createdBy: 'DEAL: f8186ccb-2b0d-4af9-98b2-11f4a33d27a5',
    updatedBy: 'DEAL: f8186ccb-2b0d-4af9-98b2-11f4a33d27a5',
    createdAt: new Date(),
    updatedAt: new Date(),
  })),
};

export const initialStateEntries: ListResponse<InvoiceStatementProps> = {
  next: '',
  items: Array.from({ length: 2 }, () => ({
    realm: '',
    organization: '',
    id: '',
    identifier: '',
    description: '',
    invoice: '',
    billingAmount: 0,
    originalAmount: 0,
    financialImpactType: '' as FinancialImpactType,
    status: '' as InvoiceStatementStatus,
    createdBy: '',
    updatedBy: '',
    effectiveAt: new Date(),
    paymentData: {
      pix: {
        emv: '',
        dueAt: new Date(),
      },
    },
    external: {
      b3Pix: '',
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  })),
};
