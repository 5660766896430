import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { cloneElement, createContext, useContext, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import {
  ContentProps,
  DownloadPdfContextProps,
  DownloadPdfRootProps,
  TriggerProps,
} from './DownloadPdf.interfaces';

const DownloadPdfContext = createContext<DownloadPdfContextProps | null>(null);

const useDownloadPdf = () => {
  const context = useContext(DownloadPdfContext);
  if (!context) {
    throw new Error('useDownloadPdf must be used within a DownloadPdfRoot');
  }
  return context;
};

const DownloadPdfRoot = ({ children, fileName }: DownloadPdfRootProps) => {
  const contentRef = useRef(null);

  return (
    <DownloadPdfContext.Provider value={{ contentRef, fileName }}>
      {children}
    </DownloadPdfContext.Provider>
  );
};

const Trigger = ({ children }: TriggerProps) => {
  const { contentRef, fileName } = useDownloadPdf();
  const { t } = useTranslation();

  const downloadPdf = () => {
    if (contentRef.current) {
      const voucher = contentRef.current as HTMLDivElement;
      voucher.style.display = 'block';
      html2canvas(contentRef.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 180;
          const imgHeight = (imgWidth * canvas.height) / canvas.width;
          const pageWidth = 210;
          const pageHeight = 297;
          const x = (pageWidth - imgWidth) / 2;
          const y = (pageHeight - imgHeight) / 2;
          const doc = new jsPDF();

          doc.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

          doc.save(
            t('payment-voucher.voucher-file-name', {
              name: fileName,
            })
          );
        })
        .then(() => {
          voucher.style.display = 'none';
        });
    }
  };

  return cloneElement(children, {
    onClick: downloadPdf,
  });
};

const Content = ({ children }: ContentProps) => {
  const { contentRef } = useDownloadPdf();

  return (
    <div
      ref={contentRef}
      style={{
        width: 608,
        position: 'absolute',
        top: -9999,
        left: -9999,
        display: 'none',
      }}
    >
      {children}
    </div>
  );
};

export const DownloadPdf = Object.assign(DownloadPdfRoot, { Trigger, Content });
