import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import {
  MINUTE_IN_MILLISECONDS,
  SECOND_IN_MILLISECONDS,
} from '@constants/time';
import {
  BatchTransaction,
  InfiniteBatchTransactionsListPayload,
} from '@interfaces/BatchPayments.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { useRef } from 'react';
import { findBatchTransactionsList } from '../findBatchTransactions';
import { batchPaymentKeys } from './batchPaymentKeys';
import { ListResponse } from '@interfaces/Common.interfaces';

export const useInfiniteBatchTransactionsListQuery = ({
  batchId,
  walletId,
  refetchStatus,
  params,
}: InfiniteBatchTransactionsListPayload) => {
  const shouldRefetch = useRef(false);

  return useInfiniteQuery<
    ListResponse<BatchTransaction>,
    ApiError,
    BatchTransaction[],
    unknown[],
    string
  >({
    queryKey: batchPaymentKeys.detail(batchId, walletId, params),
    queryFn: ({ pageParam }) =>
      findBatchTransactionsList(batchId, walletId, {
        ...params,
        next: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.next && lastPage.next !== '' ? lastPage.next : null;
    },
    refetchInterval: shouldRefetch.current
      ? 3 * SECOND_IN_MILLISECONDS
      : 5 * MINUTE_IN_MILLISECONDS,
    initialPageParam: '',
    retry: 1,
    placeholderData: keepPreviousData,
    select: (data) => {
      const formattedData = data.pages.flatMap((page) => page.items);

      if (!refetchStatus) {
        return formattedData;
      }

      const hasPendingTransactions = formattedData.some(
        (item) => item.status === refetchStatus
      );

      shouldRefetch.current = hasPendingTransactions;

      return formattedData;
    },
    enabled: !!batchId && !!walletId,
  });
};
