import { Button, ErrorMessage, Icon } from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { moneyMask } from '@utils/formatMasks';
import clsx from 'clsx';
import { useState } from 'react';

interface ValueComponentProps {
  currentValue: string;
  setCurrentValue: (value: string) => void | Promise<void>;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  icon: string;
  isEditable?: boolean;
  isLoading?: boolean;
  minAmount?: number;
  maxAmount?: number;
}

export const ValueCardComponent = ({
  currentValue,
  setCurrentValue,
  isEditing,
  setIsEditing,
  icon,
  isEditable = true,
  isLoading,
  minAmount,
  maxAmount,
}: ValueComponentProps) => {
  const [paymentValue, setPaymentValue] = useState<string>(currentValue);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setErrorMessage(null);

    setPaymentValue(calcCurrencyValue(Number(value.replace(/\D/g, '')), 'BRL'));
  };

  const handleConfirmChangeValue = () => {
    const amount = Number(paymentValue.replace(/\D/g, ''));

    if (minAmount && amount < minAmount) {
      return setErrorMessage(
        `O valor mínimo do pagamento é ${moneyMask(minAmount)}`
      );
    }

    if (maxAmount && amount > maxAmount && maxAmount !== 0) {
      return setErrorMessage(
        `O valor máximo do pagamento é ${moneyMask(maxAmount)}`
      );
    }

    if (amount === 0) {
      return setErrorMessage('O valor do pagamento deve ser maior que R$ 0,01');
    }

    setIsEditing(false);
    setCurrentValue(paymentValue);
  };

  const handleCancelChangeValue = () => {
    setIsEditing(false);
    setErrorMessage(null);
    setPaymentValue(currentValue);
  };

  return (
    <div className="border border-solid border-neutral-40 w-full p-4 rounded-md mb-2">
      <div className="flex">
        <div className="flex flex-col flex-1 gap-4">
          <p className="text-p1 text-neutral-80">
            {isEditing || isLoading ? 'Informe o novo valor' : 'Valor'}
          </p>

          {!isEditing && !isLoading && (
            <div className="flex gap-2 items-center">
              <p className="text-h3 text-neutral-500">{currentValue}</p>

              {isEditable && !isLoading && (
                <Icon size="large">
                  <i
                    className="fa-regular fa-pen pen_edit !text-neutral-700"
                    onClick={() => setIsEditing(true)}
                  />
                </Icon>
              )}
            </div>
          )}

          {(isEditing || isLoading) && (
            <div className="flex flex-col">
              <div className="box_input">
                <input
                  type="text"
                  className={clsx({
                    'h-[40px] w-[312px] p-3 outline-none border border-solid border-neutral-40 rounded-md text-h3':
                      true,
                    '!border-danger-500': errorMessage,
                  })}
                  autoFocus
                  maxLength={17}
                  onChange={handleInputChange}
                  value={paymentValue}
                  disabled={isLoading}
                />

                {isLoading && (
                  <i
                    className={`border-t-primary-500 ml-2 box-border inline-block h-5 w-5 animate-spin rounded-full border-r-2 border-t-2 border-solid border-r-transparent`}
                  />
                )}

                {!isLoading && (
                  <>
                    <Button
                      size="large"
                      variant="tertiary"
                      onClick={handleCancelChangeValue}
                      type="button"
                      className="text-p2 !text-neutral-100"
                    >
                      Cancelar
                    </Button>

                    <Button
                      size="large"
                      variant="tertiary"
                      type="button"
                      onClick={handleConfirmChangeValue}
                      className="text-p2 text-primary-500"
                    >
                      Aplicar
                    </Button>
                  </>
                )}
              </div>

              {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
          )}
        </div>

        <i className={`${icon} text-p2 text-primary-500`} />
      </div>
    </div>
  );
};
