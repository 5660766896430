import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  ContextualError,
  ContextualErrorType,
  InDrawerAuthentication,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { StatusEnum } from '@enums/Status.enum';
import { UserLevelEnum } from '@enums/UserEnum';
import { useAuthentication } from '@hooks';
import { customerEvents } from '@lib/customerIo';
import {
  Button,
  ErrorMessage,
  Icon,
  Input,
  Select,
  Sidebar,
  Skeleton,
  Spinner,
  Toastr,
  Toggle,
} from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { useInfiniteOrganizationListQuery } from '@services/organization/hooks';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserQuery,
} from '@services/user/hooks';
import { cpfMask, phoneMask } from '@utils/formatMasks';
import { validationSchema } from '@utils/validationSchema';
import { DrawerEditRealmUserForm } from './DrawerEditRealmUser.interfaces';
import './DrawerEditRealmUser.styles.scss';

const customValidation: Record<string, unknown> = {
  organizations: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required('error.required-company'),
        level: yup
          .string()
          .required('error.required-permission')
          .oneOf([UserLevelEnum.ADMIN, UserLevelEnum.USER]),
      })
    )
    .required('error.required-company'),
};

export const DrawerEditRealmUser = () => {
  const currentOrg = localStorage.getItem('organization') ?? '';

  const { closeDrawer, params } = useDrawer();
  const { realmId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isAdmin, isRealmAdmin } = useUser();

  const [hasModal, setHasModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [error, setError] = useState<ContextualErrorType>(null);

  const {
    data: user,
    isLoading: isLoadingUser,
    error: userError,
  } = useUserQuery(params?.userId ?? '');

  const defaultValues: DrawerEditRealmUserForm = {
    birthdate: '',
    cpf: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    realm: {
      level: UserLevelEnum.USER,
    },
    organizations: [
      {
        order: 1,
        id: realmId ? '' : (localStorage.getItem('organization') ?? ''),
        level: UserLevelEnum.USER,
      },
    ],
  };

  const form = useForm<DrawerEditRealmUserForm>({
    defaultValues,
    resolver: yupResolver(
      validationSchema(
        defaultValues,
        customValidation
      ) as yup.ObjectSchema<DrawerEditRealmUserForm>
    ),
  });

  const {
    controller,
    authSubmit,
    isAuthentication,
    onAuthError,
    onAuthSuccess,
    pin,
  } = useAuthentication();

  const { mutate: createUser, isPending: isCreatingUser } =
    useCreateUserMutation();

  const { mutate: updateUser, isPending: isUpdatingUser } =
    useUpdateUserMutation(params?.userId ?? '');

  const { data: organizations, isLoading: isLoadingOrgs } =
    useInfiniteOrganizationListQuery();

  const organizationsList = useMemo(() => {
    return (
      organizations
        ?.flatMap((item) => item.organization)
        ?.filter((org) => org.status === StatusEnum.ACTIVE)
        ?.map((organization) => ({
          label: organization.tradingName as string,
          value: organization.id as string,
        })) ?? []
    );
  }, [organizations]);

  const onSubmit = async (data: DrawerEditRealmUserForm) => {
    const formattedUser = { ...data };
    formattedUser.cpf = formattedUser.cpf.replace(/\D/g, '');
    formattedUser.phone = `+55${formattedUser.phone.replace(/\D/g, '')}`;

    user
      ? updateUser(
          {
            params: formattedUser,
            pin: pin || undefined,
          },
          {
            onError: (error) => {
              const isAuthError = onAuthError(error);
              if (isAuthError) return;

              setError({
                message: t(
                  `error.CODE_ERROR.${error?.response?.data?.code}`,
                  t('error.default')
                ),
                traceId: error?.response.data?.traceId,
              });
            },
            onSuccess: () => {
              onAuthSuccess();
              queryClient.refetchQueries({ queryKey: ['realm', 'users'] });
              Toastr.success(t('edit-user.edit-success'));
              customerEvents.userUpdated({
                id: user?.id,
                email: user?.email,
              });
              closeDrawer();
            },
          }
        )
      : createUser(
          {
            params: formattedUser,
            pin: pin || undefined,
          },
          {
            onError: (error) => {
              const isAuthError = onAuthError(error);
              if (isAuthError) return;

              setError({
                message: t(
                  `error.CODE_ERROR.${error?.response?.data?.code}`,
                  t('error.default')
                ),
                traceId: error?.response.data?.traceId,
              });
            },
            onSuccess: () => {
              onAuthSuccess();
              queryClient.refetchQueries({ queryKey: ['realm', 'users'] });
              Toastr.success(t('edit-user.create-success'));
              customerEvents.userCreated({ email: formattedUser.email });
              closeDrawer();
            },
          }
        );
  };

  const handlePermissionToggle = (isSelected: boolean) => {
    form.setValue(
      'realm.level',
      isSelected ? UserLevelEnum.ADMIN : UserLevelEnum.USER,
      { shouldDirty: true }
    );
  };

  const handleAddCompany = () => {
    const organizations = form.getValues('organizations');
    const prevOrder = organizations[organizations.length - 1]?.order;
    const order = prevOrder ? prevOrder + 1 : 1;

    form.setValue('organizations', [
      ...form.getValues('organizations'),
      { order, id: '', level: UserLevelEnum.USER },
    ]);
  };

  const handleRemoveCompany = (index: number) => {
    const organizations = form.getValues('organizations');
    organizations.splice(index, 1);
    form.setValue('organizations', organizations);
  };

  const canEdit = useMemo(() => {
    if (params?.userId && userError) return false;
    if (user?.status === StatusEnum.DEACTIVATED) return false;
    return isAdmin || isRealmAdmin;
  }, [user, isAdmin, isRealmAdmin, params?.userId, userError]);

  const canView = useMemo(
    () => realmId || isRealmAdmin,
    [realmId, isRealmAdmin]
  );

  useEffect(() => {
    setHasModal(form.formState.isDirty);
  }, [form.formState.isDirty]);

  useEffect(() => {
    if (user) {
      form.reset({
        birthdate: dayjs(user?.birthdate).format('YYYY-MM-DD'),
        cpf: cpfMask(user?.cpf),
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: phoneMask(user?.phone.replace('+55', '')),
        realm: { level: user?.realm.level },
        organizations: user?.organizations.map((org, index) => ({
          order: index + 1,
          id: org.id,
          level: org.level,
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Sidebar
      title={t(params?.userId ? 'edit-user.title-edit' : 'edit-user.title-new')}
      icon="fa-regular fa-user"
      open={true}
      onClose={closeDrawer}
      hasModal={hasModal}
      actionModal={actionModal}
      setActionModal={setActionModal}
    >
      <form
        onSubmit={form.handleSubmit((values) =>
          authSubmit(() => onSubmit(values))
        )}
      >
        {!isAuthentication && (
          <>
            <ContextualError error={error} />
            <SidebarSection
              numberSection={1}
              titleSection={t('edit-user.data-title')}
              subTitleSection={t('edit-user.data-subtitle')}
            >
              <div className="user-data-container">
                <Skeleton
                  height={'66px'}
                  className="mb-4"
                  isLoading={isLoadingUser}
                >
                  <Input
                    label={t('general.email')}
                    name="email"
                    register={form.register}
                    placeholder={t('edit-user.insert-email')}
                    error={!!form.formState.errors.email?.message}
                    disabled={!!params?.userId || !canEdit}
                  >
                    {form.formState.errors.email?.message && (
                      <ErrorMessage
                        message={t(form.formState.errors.email.message, '')}
                      />
                    )}
                  </Input>
                </Skeleton>
                <Skeleton
                  height={'66px'}
                  className="mb-4"
                  isLoading={isLoadingUser}
                >
                  <Input
                    label={t('general.name')}
                    name="firstName"
                    register={form.register}
                    placeholder={t('edit-user.insert-name')}
                    error={!!form.formState.errors.firstName?.message}
                    disabled={!canEdit}
                  >
                    {form.formState.errors.firstName?.message && (
                      <ErrorMessage
                        message={t(form.formState.errors.firstName.message, '')}
                      />
                    )}
                  </Input>
                </Skeleton>
                <Skeleton
                  height={'66px'}
                  className="mb-4"
                  isLoading={isLoadingUser}
                >
                  <Input
                    label={t('general.last-name')}
                    name="lastName"
                    register={form.register}
                    placeholder={t('edit-user.insert-last-name')}
                    error={!!form.formState.errors.lastName?.message}
                    disabled={!canEdit}
                  >
                    {form.formState.errors.lastName?.message && (
                      <ErrorMessage
                        message={t(form.formState.errors.lastName.message, '')}
                      />
                    )}
                  </Input>
                </Skeleton>
                <Skeleton
                  height={'66px'}
                  className="mb-4"
                  isLoading={isLoadingUser}
                >
                  <Input
                    type="tel"
                    label={t('general.phone')}
                    name="phone"
                    data-testid="phone"
                    register={form.register}
                    placeholder={t('edit-user.insert-phone')}
                    onChange={(event) => {
                      const value = event.target.value;
                      form.setValue('phone', phoneMask(value), {
                        shouldDirty: true,
                      });
                    }}
                    maxLength={15}
                    error={!!form.formState.errors.phone?.message}
                    disabled={!canEdit}
                  >
                    {form.formState.errors.phone?.message && (
                      <ErrorMessage
                        message={t(form.formState.errors.phone.message, '')}
                      />
                    )}
                  </Input>
                </Skeleton>
                <Skeleton
                  height={'66px'}
                  className="mb-4"
                  isLoading={isLoadingUser}
                >
                  <Input
                    type="date"
                    label={t('general.birthdate')}
                    name="birthdate"
                    form={form}
                    register={form.register}
                    error={!!form.formState.errors.birthdate?.message}
                    disabled={!canEdit}
                    data-testid="birthdate"
                  >
                    {form.formState.errors.birthdate?.message && (
                      <ErrorMessage
                        message={t(form.formState.errors.birthdate.message, '')}
                      />
                    )}
                  </Input>
                </Skeleton>
                <Skeleton
                  height={'66px'}
                  className="mb-4"
                  isLoading={isLoadingUser}
                >
                  <Input
                    label="CPF"
                    name="cpf"
                    register={form.register}
                    placeholder={t('edit-user.insert-cpf')}
                    onChange={(event) => {
                      const value = cpfMask(event.target.value);
                      form.setValue('cpf', value, { shouldDirty: true });
                    }}
                    maxLength={14}
                    error={!!form.formState.errors.cpf?.message}
                    disabled={!!params?.userId || !canEdit}
                  >
                    {form.formState.errors.cpf?.message && (
                      <ErrorMessage
                        message={t(form.formState.errors.cpf.message, '')}
                      />
                    )}
                  </Input>
                </Skeleton>
              </div>
            </SidebarSection>
            <SidebarSection
              numberSection={2}
              titleSection={t('edit-user.permission-title')}
              subTitleSection={t('edit-user.permission-subtitle')}
            >
              {canView && (
                <Skeleton height={'43px'} isLoading={isLoadingUser}>
                  <div className="mb-4">
                    <Toggle
                      isSelected={
                        form.watch('realm.level') === UserLevelEnum.ADMIN
                      }
                      onChange={handlePermissionToggle}
                      label={t('edit-user.is-admin-title')}
                      description={t('edit-user.is-admin-subtitle')}
                      data-testid="toggle-admin"
                      isDisabled={!canEdit || !canView}
                    />
                  </div>
                </Skeleton>
              )}
              <Skeleton
                height={'66px'}
                className="mb-4"
                isLoading={isLoadingUser || isLoadingOrgs}
              >
                <div className="user-permission-container">
                  {form.watch('organizations')?.map((organization, index) => {
                    if (!canView && organization.id !== currentOrg) return null;

                    return (
                      <Fragment key={organization.order}>
                        <Select
                          label={t('general.company')}
                          name={`organizations[${index}].id`}
                          form={form}
                          dataTestId={`select-company-${index}`}
                          placeholder={t('edit-user.select-company')}
                          options={organizationsList.filter((org) =>
                            form
                              .watch('organizations')
                              .every(
                                (item) =>
                                  item.id !== org.value ||
                                  item.id === organization.id
                              )
                          )}
                          disabled={!canEdit || (!realmId && !isRealmAdmin)}
                        />
                        <Select
                          label={t('general.permission')}
                          name={`organizations[${index}].level`}
                          form={form}
                          dataTestId={`select-permission-${index}`}
                          placeholder={t('edit-user.select-permission')}
                          options={[
                            {
                              label: t('general.admin'),
                              value: UserLevelEnum.ADMIN,
                            },
                            {
                              label: t('general.user'),
                              value: UserLevelEnum.USER,
                            },
                          ]}
                          disabled={!canEdit}
                        />
                        <Button
                          type="button"
                          variant="tertiary"
                          size="small"
                          className="mt-7 w-6"
                          onClick={() => handleRemoveCompany(index)}
                          disabled={
                            form.getValues('organizations').length === 1 ||
                            !canEdit ||
                            !canView
                          }
                        >
                          <Icon size="medium" key="remove-user-button">
                            <i className="fa-regular fa-trash fa-1x text-neutral-100" />
                          </Icon>
                        </Button>
                      </Fragment>
                    );
                  })}
                  {canView && (
                    <Button
                      type="button"
                      size="small"
                      variant="secondary"
                      className="new-company-button"
                      onClick={handleAddCompany}
                      disabled={!canEdit || !canView}
                    >
                      {t('edit-user.new-company')}
                    </Button>
                  )}
                </div>
              </Skeleton>
            </SidebarSection>
            <SidebarFooter>
              <Button
                type="button"
                variant="tertiary"
                size="large"
                onClick={() => {
                  setActionModal((prev) => !prev);
                }}
                disabled={isCreatingUser}
              >
                {t('general.button.back')}
              </Button>
              <Button
                type="submit"
                size="large"
                data-testid="submit-form"
                className="button_submit"
                disabled={isCreatingUser || isUpdatingUser || !canEdit}
              >
                {isLoadingUser || isCreatingUser || isUpdatingUser ? (
                  <Spinner />
                ) : params?.userId ? (
                  t('general.button.edit')
                ) : (
                  t('general.register')
                )}
              </Button>
            </SidebarFooter>
          </>
        )}

        <InDrawerAuthentication
          isPending={isCreatingUser || isUpdatingUser}
          {...controller}
        />
      </form>
    </Sidebar>
  );
};
