import { Alert } from '@components';
import { Typography } from '@portao3-web/ui';
import { CUSTOM_FIELD_TYPES_CONFIG } from '../../constants';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import { BooleanField } from '../booleanField';
import { CustomerField } from '../customerField';
import { DateField } from '../dateField';
import { NumberField } from '../numberField';
import { RadioButtonCard } from '../radioButtonCard/RadioButtonCard';
import { SelectField } from '../selectField';
import { TextField } from '../textField';
import './CustomFieldTypeSelection.styles.scss';

export const CustomFieldTypeSelection = () => {
  const {
    watch,
    formState: { errors },
  } = useDrawerEditCustomField();

  const selectedType = watch('type');

  const Component = selectedType
    ? COMPONENTS[selectedType as keyof typeof COMPONENTS]
    : null;

  return (
    <div className="custom-field-type-selection-container">
      {errors.type?.message && (
        <Alert status="error">
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {errors.type.message}
          </Typography>
        </Alert>
      )}
      <div
        className="radio-group-container"
        aria-label="Escolha o tipo de campo e faça customizações."
        role="radiogroup"
        aria-required
      >
        {Object.entries(CUSTOM_FIELD_TYPES_CONFIG).map(([key, value]) => (
          <RadioButtonCard
            key={key}
            value={key}
            icon={value.icon}
            label={value.label}
          />
        ))}
      </div>
      {Component && <Component />}
    </div>
  );
};

const COMPONENTS = {
  TEXT: TextField,
  NUMBER: NumberField,
  DATE: DateField,
  BOOLEAN: BooleanField,
  SELECT: SelectField,
  MULTI_SELECT: SelectField,
  CUSTOMER: CustomerField,
};
