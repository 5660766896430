import { Typography, Steps } from '@portao3-web/ui';
import './ForgotPassword.styles.scss';
import { useMemo, useState } from 'react';
import { GeneralPresentationLogin } from '@components';
import {
  ChangePassword,
  RequestPassword,
  CompletedPasswordRequest,
  Token,
} from './steps';

export const ForgotPassword = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = useMemo(() => {
    return [
      {
        number: 1,
        active: true,
        complete: false,
      },
      {
        number: 2,
        active: false,
        complete: false,
      },
      {
        number: 3,
        active: false,
        complete: false,
      },
    ];
  }, []);

  const handleNextStep = () => {
    steps[currentStep].complete = true;
    setCurrentStep((prevState) => prevState + 1);
    if (currentStep + 1 !== steps.length) {
      steps[currentStep + 1].active = true;
    }
  };

  const componentSteps = [
    <RequestPassword handleNextStep={handleNextStep} key={0} />,
    <Token handleNextStep={handleNextStep} key={1} />,
    <ChangePassword handleNextStep={handleNextStep} key={2} />,
  ];

  return (
    <div className="authentication">
      <div className="authentication_content">
        <header className="authentication_img_header" />
        <GeneralPresentationLogin />
        <div className="authentication_content_right">
          <div className="authentication_steps">
            <Steps steps={steps} />
          </div>
          <div className="authentication_content_right_form">
            {steps[currentStep]?.active && componentSteps[currentStep]}
            {currentStep === steps.length && <CompletedPasswordRequest />}
          </div>
          <Typography tag="p" className="authentication_copyright">
            © {new Date().getFullYear()} - Portão 3 - Todos os direitos
            reservados
          </Typography>
        </div>
      </div>
    </div>
  );
};
