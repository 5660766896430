export const removeEmptyObjectAttributes = (obj: object) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      removeEmptyObjectAttributes(value);

      if (Object.keys(value).length === 0) {
        delete obj[key as keyof typeof obj];
      }
    } else if (!value || value === '') {
      delete obj[key as keyof typeof obj];
    }
  });
};
