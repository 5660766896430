import { Alert, ContextualError } from '@components';
import {
  Button,
  Icon,
  Input,
  Modal,
  Skeleton,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InvoiceDealProps } from '@interfaces/Invoices';
import { useWalletQuery } from '@services/wallet/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { useEffect, useState } from 'react';
import { usePaymentInvoiceP2PMutation } from '../../hooks/useInvoiceMutation';
import './DebitWallet.styles.scss';

interface DebitWalletProps {
  closeModal: () => void;
  invoice: InvoiceDealProps;
}

export const DebitWallet = ({ closeModal, invoice }: DebitWalletProps) => {
  const { t } = useTranslation();

  const [validatePayment, setValidatePayment] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
  } = useForm<{ wallet: string }>({
    defaultValues: {
      wallet: invoice?.debitParties?.[0].platformWalletName || '',
    },
    mode: 'onChange',
  });

  const {
    data: wallet,
    fetchStatus,
    error,
  } = useWalletQuery({
    organizationId:
      invoice?.debitParties?.[0]?.partyAccount.identifier.organization,
    walletId: invoice?.debitParties?.[0].platformWalletId,
  });

  const {
    mutate: paymentInvoiceP2P,
    isPending: isLoadingEntries,
    customError: paymentError,
  } = usePaymentInvoiceP2PMutation();

  useEffect(() => {
    if (!wallet) return;
    const walletBalance = wallet?.totalBalance || 0;
    setValidatePayment(walletBalance < invoice.amount);
  }, [wallet, invoice.amount]);

  return (
    <Modal
      closeModal={closeModal}
      open
      title="Pagamento com Débito na Carteira"
    >
      <form className="debit_wallet_modal">
        <div className="debit_wallet_modal_content">
          {error && (
            <Alert status="error">
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {t('error.default')}
              </Typography>
            </Alert>
          )}

          {paymentError && <ContextualError error={paymentError} />}

          <Typography tag="p" weight="p1" color="var(--product-neutral-n80)">
            {t('invoices.debit-wallet.description')}
          </Typography>

          {validatePayment && (
            <Alert status="error">
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                {t('invoices.error.insuficient-funds')}
              </Typography>
            </Alert>
          )}

          <Input
            label="Carteira"
            name="wallet"
            register={register}
            maxLength={25}
            error={!!errors['wallet']}
            readOnly
            disabled
          />

          <Skeleton isLoading={fetchStatus === 'fetching'} height={56}>
            <div className="debit_wallet_modal_box_total">
              <div className="flex items-center">
                <Icon size="xlarge">
                  <i className="fa-regular fa-wallet" />
                </Icon>
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-neutral-n800)"
                >
                  Saldo total
                </Typography>
              </div>
              <Typography
                tag="p"
                weight="p1"
                color="var(--product-neutral-n800)"
              >
                {calcCurrencyValue(wallet?.totalBalance || 0, 'BRL')}
              </Typography>
            </div>
          </Skeleton>
        </div>
        <div className="debit_wallet_modal_footer">
          <Button
            type="button"
            variant="secondary"
            size="large"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            variant="primary"
            size="large"
            disabled={isLoadingEntries || validatePayment}
            onClick={() => {
              paymentInvoiceP2P(invoice.id, {
                onSuccess: () => {
                  closeModal();
                },
              });
            }}
          >
            {isLoadingEntries ? <Spinner variant="secondary" /> : 'Confirmar'}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
