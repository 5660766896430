import { regex } from '@constants/regex';
import { PixKeyType } from '@interfaces/Pix.interface';
import { cnpjMask } from '@utils/formatMasks';

export const PIX_KEY_MASKS = {
  [PixKeyType.CNPJ]: cnpjMask,
  // [PixKeyType.PHONE]: phoneMask,
};

export const PIX_ICONS = {
  [PixKeyType.EVP]: 'fa-key',
  [PixKeyType.EMAIL]: 'fa-envelope',
  // [PixKeyType.PHONE]: 'fa-mobile',
  [PixKeyType.CNPJ]: 'fa-user-tie',
};

export const PIX_KEY_VALIDATIONS = {
  [PixKeyType.CNPJ]: {
    regex: regex.cnpj,
    message: 'error.invalid-cnpj',
  },
  // [PixKeyType.PHONE]: {
  //   regex: regex.phone,
  //   message: 'error.invalid-phone',
  // },
  [PixKeyType.EMAIL]: {
    regex: regex.email,
    message: 'error.invalid-email',
  },
};

export const REMOVE_KEY_TEXT = {
  modalTitle: 'Deseja remover a chave Pix?',
  disclaimer: `Ao remover a chave Pix, você não poderá mais receber pagamentos por ela.`,
  warn: {
    title: 'Atenção, esta será uma ação permanente!',
    description:
      'Para excluir esta chave Pix, basta confirmar sua decisão no botão abaixo.',
  },
  confirmation: 'Deseja mesmo excluir a chave Pix?',
  actionButtonLabel: 'Sim, excluir',
};
