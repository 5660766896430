import { FutureTransactionsProps } from '@components';
import { FindScheduleByIdRequest } from '@services/scheduling';

export const schedulingTransactionKeys = {
  all: () => ['scheduling-transactions'],
  details: () => [...schedulingTransactionKeys.all(), 'details'],
  detail: (params: FindScheduleByIdRequest, organizationId: string | null) => [
    ...schedulingTransactionKeys.details(),
    params,
    organizationId,
  ],
  lists: () => [...schedulingTransactionKeys.all(), 'list'],
  list: (
    params: Omit<FutureTransactionsProps, 'next'>,
    organizationId: string | null
  ) => [...schedulingTransactionKeys.lists(), params, organizationId],
};
