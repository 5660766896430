import { useTranslation } from 'react-i18next';

import {
  CalculateBillingStatement,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { ICONS } from '@constants/icons';

import { InvoiceDealProps } from '@interfaces/Invoices';
import { getStatusStyle } from '@utils/BillingStatus';
import { Button, Sidebar, TableDemo, Tag } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import dayjs from 'dayjs';
import { ValueWrapper } from '../drawerFundsIn/components/ValueWrapper';
import { useInvoiceDetailsColumns } from './components/useInvoiceDetailsColumns';
import { useCustomerInvoiceEntriesQuery } from '@services/invoices/hooks';

interface DrawerInvoiceDetailsProps {
  invoice: InvoiceDealProps;
}

export const DrawerInvoiceDetails = ({
  invoice,
}: DrawerInvoiceDetailsProps) => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  const columns = useInvoiceDetailsColumns();

  const { data: entries, isLoading: isLoadingEntries } =
    useCustomerInvoiceEntriesQuery({
      invoiceId: invoice.id,
      enabled: true,
    });

  return (
    <Sidebar
      open={true}
      title={t('invoices.invoice-details-drawer-title')}
      icon={ICONS['file-invoice']}
      onClose={closeDrawer}
    >
      <SidebarSection titleSection={t('general.invoice-extract')}>
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-6">
            <ValueWrapper
              label={t('general.description')}
              value={invoice?.description}
            />

            <ValueWrapper
              label={t('general.total-value')}
              value={calcCurrencyValue(invoice?.amount, 'BRL')}
            />

            <ValueWrapper
              label={t('general.closing')}
              value={dayjs.utc(invoice?.closingAt).format('DD/MM/YYYY')}
            />

            <ValueWrapper
              label={t('general.due-date')}
              value={dayjs.utc(invoice?.dueAt).format('DD/MM/YYYY')}
            />
          </div>
          <div>
            <p className="text-p3 text-neutral-80 mb-1">
              {t('general.status')}
            </p>

            <Tag
              text={t(`invoices.invoiceStatus.${invoice.status}`)}
              showCloseButton={false}
              icon={
                <i
                  className={`text-p3 fa-regular ${
                    getStatusStyle(invoice?.status).icon
                  }`}
                />
              }
              textColor={getStatusStyle(invoice?.status).color}
              backgroundColor={getStatusStyle(invoice?.status).background}
            />
          </div>

          <TableDemo
            isLoading={isLoadingEntries ? 2 : undefined}
            headerColor
            columns={columns}
            data={entries?.items.map((entry) => ({
              id: entry.id,
              product: entry.identifier.toUpperCase(),
              type: t(`invoices.dealProductType.${entry.productType}`),
              value: entry.amount.toString(),
            }))}
          />

          <CalculateBillingStatement
            invoiceId={invoice.id}
            invoiceAmount={invoice.amount}
          />
        </div>
      </SidebarSection>

      <SidebarFooter>
        <Button
          type="button"
          variant="primary"
          size="large"
          onClick={closeDrawer}
        >
          {t('general.button.close')}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};
