import clsx from 'clsx';
import { Key, useEffect, useId, useRef, useState } from 'react';

import { Icon } from '../icon/Icon';
import { Dialog } from '../dialog';
import { Spinner } from '../spinner/Spinner';
import { Typography } from '../typography/Typography';
import { AutocompleteProps } from './Autocomplete.interfaces';
import './Autocomplete.styles.scss';
import { Skeleton } from '../skeleton/Skeleton';
import { ErrorMessage } from '../errorMessage/ErrorMessage';
import { t } from 'i18next';

export const Autocomplete = ({
  label,
  items,
  placeholder,
  queryFn,
  setValue,
  selectItem,
  value,
  delay = 1000,
  isFetching = false,
  isFetchingNextPage = false,
  disabled = false,
  fetchNextPage,
  multiselect = false,
  hasDefaultValue = false,
  isLoading = false,
  error,
}: AutocompleteProps) => {
  const inputId = useId();

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isDefaultValue, setIsDefaultValue] = useState(hasDefaultValue);

  const popoverRef = useRef<HTMLDivElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setIsOpen(true);
  };

  const handleSelectItem = (key: Key) => {
    if (selectItem) {
      selectItem(key.toString());
    } else {
      setValue(items[key.toString()]);
    }
    !multiselect && setIsOpen(false);
    setShouldFetch(false);
  };

  useEffect(() => {
    if (!queryFn) return;

    if (isDefaultValue && value !== '') {
      setIsDefaultValue(false);
      return;
    }

    if (!shouldFetch) {
      setShouldFetch(true);
      queryFn('');
      return;
    }

    if (timeoutId) clearTimeout(timeoutId);
    const timeout = setTimeout(() => queryFn(value), delay);
    setTimeoutId(timeout);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  const isEmpty = Object.keys(items).length === 0;

  useEffect(() => {
    const popover = popoverRef.current;
    if (!popover || !fetchNextPage) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = popover;
      if (scrollTop + clientHeight >= scrollHeight) {
        fetchNextPage();
      }
    };

    popover.addEventListener('scroll', handleScroll);
    return () => popover.removeEventListener('scroll', handleScroll);
  }, [isOpen, fetchNextPage]);

  return (
    <Skeleton height={62} isLoading={isLoading}>
      <div className="autocomplete autocomplete_container">
        <label htmlFor={inputId} className="mb-1">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n100)">
            {label}
          </Typography>
        </label>

        <Dialog
          variant="popover"
          position="bottom-full-width"
          className="autocomplete_trigger"
          isOpen={disabled ? false : isOpen}
          setIsOpen={disabled ? undefined : setIsOpen}
        >
          <input
            id={inputId}
            type="text"
            className={clsx('autocomplete_trigger_input', {
              invalid: error,
            })}
            placeholder={
              isEmpty && !isFetching ? 'Nenhum item encontrado' : placeholder
            }
            value={value}
            onChange={handleChange}
            data-testid="input"
            data-disabled={!!disabled}
          />
          <Dialog.Trigger>
            {isFetching && !isFetchingNextPage ? (
              <div className="autocomplete_trigger_button">
                <Spinner />
              </div>
            ) : (
              <button
                type="button"
                className={clsx('autocomplete_trigger_button', {
                  'autocomplete_trigger_button--open': isOpen,
                })}
                data-testid="toggle-list"
              >
                <Icon size="small">
                  <i
                    id="icon"
                    data-testid="select-icon"
                    className="fa fa-caret-down fa-lg"
                  />
                </Icon>
              </button>
            )}
          </Dialog.Trigger>

          <Dialog.Content data-testid="popover">
            <div
              className="autocomplete_popover"
              role="listbox"
              ref={popoverRef}
            >
              {isEmpty && !isFetching && (
                <div className="autocomplete_popover_empty-placeholder">
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n300)"
                  >
                    Nenhum item encontrado
                  </Typography>
                </div>
              )}

              {Object.entries(items)?.map(([key, value]) => (
                <div
                  key={key}
                  id={key}
                  className="autocomplete_popover_item"
                  role="listitem"
                  onClick={() => handleSelectItem(key)}
                >
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n300)"
                  >
                    {value}
                  </Typography>
                </div>
              ))}

              {isFetchingNextPage && (
                <div className="autocomplete_popover_spinner-wrapper">
                  <Spinner />
                </div>
              )}
            </div>
          </Dialog.Content>
        </Dialog>

        {error && <ErrorMessage message={t(error, '')} />}
      </div>
    </Skeleton>
  );
};
