import { useTranslation } from 'react-i18next';

import { HeaderCard, HeaderPage } from '@components';
import { useQueryParams } from '@hooks/useQueryParams';
import {
  Button,
  Card,
  Search,
  Skeleton,
  Spinner,
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableHeader,
  TableRow,
  Typography,
} from '@portao3-web/ui';
import React, { useEffect, useState } from 'react';
import './DataTable.styles.scss';

interface DataTableProps {
  pageTitle: string;
  tableTitle: string;
  tableHeader: string[];
  data?: object[] | null;
  isLoading?: boolean;
  isFetchingMore?: boolean;
  pageSubtitle?: string;
  pageActions?: React.ReactNode | React.ReactNode[];
  hasMoreItems?: boolean;
  loadMoreItems?: () => void;
  queryFn?: (query: string) => void;
  tableHeaderActions?: React.ReactNode | React.ReactNode[];
  emptyState?: React.ReactNode;
}

// TODO - add empty state
export const DataTable = ({
  pageTitle: title,
  pageSubtitle: subtitle,
  pageActions,
  tableHeader,
  hasMoreItems,
  loadMoreItems,
  data,
  tableTitle,
  isLoading = false,
  isFetchingMore = false,
  queryFn,
  tableHeaderActions,
  emptyState,
}: DataTableProps) => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();

  const [search, setSearch] = useState('');

  const handleClearSearch = () => {
    setSearch('');
    queryFn?.('');
  };

  useEffect(() => {
    const query = queryParams.get('query');
    if (query) setSearch(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="data-table">
      <HeaderPage title={title} subTitle={subtitle}>
        {pageActions}
      </HeaderPage>
      <Card>
        {queryFn ? (
          <Search
            variant="animated"
            value={search}
            setValue={setSearch}
            label={tableTitle}
            queryFn={queryFn}
            onClear={handleClearSearch}
            delay={500}
            actions={tableHeaderActions}
          />
        ) : (
          <HeaderCard title={tableTitle} />
        )}
        <Table>
          <TableHead>
            <TableRow style={{ zIndex: 300 }}>
              {tableHeader.map((item) => (
                <TableHeader key={item}>
                  <Typography
                    tag="p"
                    weight="p2"
                    color="var(--product-neutral-n200)"
                  >
                    {item}
                  </Typography>
                </TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <LoadingRows columnCount={tableHeader.length} />
            ) : data?.length && data?.length > 0 ? (
              data?.map((item, index) => (
                <TableRow key={index}>
                  {Object.values(item).map((value, index) => (
                    <TableCol key={index}>
                      {typeof value === 'string' ? (
                        <Typography
                          tag="p"
                          weight="p2"
                          color="var(--product-neutral-n500)"
                        >
                          {value}
                        </Typography>
                      ) : (
                        value
                      )}
                    </TableCol>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow style={{ border: 'none' }}>
                <TableCol colSpan={tableHeader.length}>{emptyState}</TableCol>
              </TableRow>
            )}
            {hasMoreItems && loadMoreItems && (
              <TableRow>
                <TableCol colSpan={tableHeader.length}>
                  <Button
                    type="button"
                    size="small"
                    variant="secondary"
                    className="mx-auto"
                    onClick={loadMoreItems}
                    disabled={isFetchingMore}
                  >
                    {isFetchingMore ? <Spinner /> : t('general.load-more')}
                  </Button>
                </TableCol>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

interface LoadingRowsProps {
  columnCount: number;
}

const LoadingRows = ({ columnCount }: LoadingRowsProps) => {
  return Array.from({ length: 5 }).map((_, index) => (
    <TableRow key={index}>
      {Array(columnCount)
        .fill(columnCount)
        .map((_, index) => (
          <TableCol key={index}>
            <Skeleton isLoading height={'100%'} />
          </TableCol>
        ))}
    </TableRow>
  ));
};
