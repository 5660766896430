import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { Icon } from '../icon/Icon';
import { Typography } from '../typography/Typography';
import { SearchProps } from './Search.interfaces';
import './Search.styles.scss';

export const Search = ({
  variant,
  value,
  setValue,
  placeholder,
  delay = 1000,
  queryFn,
  label,
  actions,
  onClear,
}: SearchProps) => {
  const hasLabel = variant === 'animated';

  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const openSearch = () => {
    setIsFocused(true);
  };

  const clearSearch = () => {
    if (onClear) {
      onClear();
    } else {
      setValue('');
      queryFn('');
    }
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    if (timeoutId) clearTimeout(timeoutId);
    const timeout = setTimeout(() => queryFn(value), delay);
    setTimeoutId(timeout);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const iconFocus =
    isFocused || variant === 'animated'
      ? 'search-field_search-button--focused'
      : '';

  return (
    <div
      className={`search-field_container search-field_container--${variant}`}
      data-animated-search-open={hasLabel && (isFocused || !!value)}
    >
      {variant === 'animated' && (
        <div
          className="search-field_label-wrapper"
          data-disabled={!!value || !!isFocused}
        >
          <Typography tag="h3" color="var(--product-neutral-n500)">
            {label}
          </Typography>
        </div>
      )}
      <button
        onClick={openSearch}
        className={`search-field_search-button ${iconFocus}`}
        data-testid="search-button"
      >
        <Icon size="xlarge">
          <i className="fa-regular fa-search search-field_search-icon" />
        </Icon>
      </button>
      {actions && !value && !isFocused && actions}
      <input
        ref={inputRef}
        className={`search-field_input search-field_input--${variant} text-p2`}
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={hasLabel && !value && !isFocused}
        data-testid="search-input"
      />
      <button
        className={`search-field_clear-button search-field_clear-button--${variant}`}
        onClick={clearSearch}
        disabled={
          (variant === 'default' && !value) ||
          (variant === 'animated' && !isFocused && !value)
        }
        aria-label="limpar busca"
        data-testid="clear-button"
      >
        <Icon size="xlarge">
          <i className="fa-regular fa-close" />
        </Icon>
      </button>
    </div>
  );
};
