import { GetUserListProps } from '../UserService';
import { UseInfiniteUserListProps } from './useInfiniteUserListQuery';

export const userKeys = {
  all: () => ['user'],
  current: () => [...userKeys.all(), 'current'],
  details: () => [...userKeys.all(), 'detail'],
  detail: (userId: string) => [...userKeys.details(), { userId }],
  lists: () => [...userKeys.all(), 'list'],
  list: (params: GetUserListProps & { organizationId: string }) => [
    ...userKeys.lists(),
    params,
  ],
  realmList: (params: GetUserListProps & { realmId?: string }) => [
    ...userKeys.lists(),
    'realm',
    params,
  ],
  orgList: (params: UseInfiniteUserListProps) => [...userKeys.lists(), params],
  memberGetMembers: () => [...userKeys.all(), 'memberGetMember'],
  memberGetMember: (userId: string) => [
    ...userKeys.memberGetMembers(),
    { userId },
  ],
};
