import { useMutation } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { confirmMFA } from '../confirmMFA';

export const useConfirmMFAMutation = () => {
  return useMutation<string, ApiError, string, unknown>({
    mutationFn: (code: string) => confirmMFA(code),
  });
};
