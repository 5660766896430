import { environment } from '@environments/environment';
import api from '@services/api';

export const confirmRecaptcha = async (
  token: string | null
): Promise<unknown> => {
  const response = await api.post(
    `${environment.identityBaseUrl}auth/validate-recaptcha?code=${token}`
  );

  const { data } = response;

  return data;
};
