export const regex = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
  cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  postalCode: /^\d{5}-\d{3}$/,
  cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
  phone: /^(\+\d{1,4}\s?)?\(\d{2}\)\s*\d{4,5}-\d{4}$/,
  date: /^\d{2}\/\d{2}\/\d{4}$/,
  money: /^(\d{1,3}(\.\d{3})*|\d+)(,\d{2})?$/,
  number: /([0-9])/,
  letters: /(?=.*?[A-Z])(?=.*?[a-z])/,
  specialCharacters: /[!@#$%^&*(),.?";:{}()\-+=<>?/{}|[\]\\'"`~_]/,
  removeSpecialCharacters: /[^a-zA-Z0-9]/g,
  allConditions:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?";:{}()\-+=<>?/{}|[\]\\'"`~_]).{8,}$/,
  image: /(.png|.jpg|.jpeg)$/i,
  pdf: /(.pdf)$/i,
  mfaUserCode: /^\d{6}$/,
  creditCardName: /^[A-Za-z]+ [A-Za-z ]+[A-Za-z]$/,
  apelidoCardName: /^[^\\/]{2,}$/,
  uuid: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
  token: /^\d{4}$/,
};
