import { useState } from 'react';
import './Checkbox.styles.scss';
import { CheckboxProps } from './Checkbox.interface.ts';
import { Typography } from '../typography/Typography.tsx';
import { Icon } from '../icon/Icon.tsx';

export const Checkbox = ({
  id = '',
  label,
  name,
  error,
  checked = false,
  disabled = false,
  setValue = () => null,
  ...props
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  return (
    <label className={`${disabled ? 'disabled' : ''} checkbox_container`}>
      <input
        id={id}
        data-testid={id}
        checked={isChecked}
        disabled={disabled}
        onChange={() => {
          setIsChecked(!isChecked);
          setValue(id, checked);
        }}
        className="input-checkbox"
        type="checkbox"
        {...props}
      />
      <div className="checkmark">
        <Icon size="large">
          <i className="fa-solid fa-check"></i>
        </Icon>
      </div>
      <Typography tag="p" weight="p3">
        {label}
      </Typography>
    </label>
  );
};
