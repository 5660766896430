import { endpoint } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

export const confirmMFA = async (code: string): Promise<string> => {
  const response = await api.put(
    `${environment.identityBaseUrl}${endpoint.getMfa}`,
    {
      userCode: code,
      deviceName: 'Portao3',
    }
  );
  const { data } = response;

  return data.secretCode;
};
