import { Icon, Typography } from '..';
import { StepsProps } from './Steps.interface';
import './Steps.styles.scss';

export const Steps = ({ steps }: StepsProps) => {
  const StateComplete = () => {
    return (
      <div className="steps_complete">
        <Icon size="xlarge">
          <i className="fa-regular fa-circle-check" />
        </Icon>
      </div>
    );
  };

  return (
    <div className="steps">
      {steps.map((step, index) => {
        const isActive = step.active ? 'steps_circle--active' : '';
        const isComplete = step.complete ? 'steps_circle--complete' : '';
        return (
          <div
            className={`steps_circle ${isActive} ${isComplete}`}
            key={index}
            data-testid={`steps-${step.number}`}
          >
            <div className="steps_number">
              {step.complete ? (
                <StateComplete />
              ) : (
                <Typography tag="p" weight="p2">
                  {step.number}
                </Typography>
              )}
            </div>
            {index !== steps.length - 1 && <div className="steps_line"></div>}
          </div>
        );
      })}
    </div>
  );
};
