import { CardTypeEnum } from '@enums/Card.enum';
import { StatusType } from '@interfaces/Status.type';
import {
  Transaction,
  TransactionsResponse,
} from '@interfaces/Transactions.interfaces';

export const lastTransactionsCompanyTHead = [
  'Data',
  '&nbsp;',
  'Descrição',
  'Valor',
];

export const cardTransactionsTHead = ['&nbsp;', 'Descrição', 'Valor'];

export const transactionCompany = {
  id: 1,
  exits: 0,
  available: 0,
  period_details: {
    data: [],
    legends: [''],
  },
};

export const transactions = {
  input: 0,
  output: 0,
  data: [],
};

export const companyTransactions = [
  {
    id: 1,
    createdAt: new Date(),
    value: 0,
    description: '',
    card_number: 0,
    usd: 0,
    euro: 0,
  },
];

export const cardCompanyList = [
  {
    id: -1,
    name: '',
    panMasked: '',
    expiryDate: '',
    status: 'ACTIVE' as StatusType,
    singleUse: false,
    type: '' as CardTypeEnum,
    total: 0,
    createdBy: '',
    organization: '',
    realm: '',
    tokenisationPermitted: false,
    updatedBy: '',
    wallet: '',
    walletName: '',
    ownerName: '',
  },
];

export const colorStroke = '#8884d8';

export const initialStateTransactionsPeriodCompany: TransactionsResponse = {
  input: 0,
  output: 0,
  items: [
    {
      walletId: '',
      cardTransaction: {
        txnAmountAuthorized: 0,
        localTime: new Date(),
        merchantName: '',
        txnCurrency: '',
        acquiringCountryCode: '',
        acquiringId: '0',
        txnAmount: 0,
        mcc: '',
        merchantCity: '',
        merchantCountry: '',
        merchantId: '',
        expired: false,
        cleared: false,
        overcleared: false,
        posEntryMode: '',
        reversed: false,
        _id: '',
      },
      pixTransaction: {
        description: '',
        txnCurrency: '0',
        txnOriginalAmount: 0,
        txnDiscountAmount: 0,
        txnFineAmount: 0,
        txnInterestAmount: 0,
        txnPurchaseAmount: 0,
        txnWithdrawalAmount: 0,
        txnUpdatedAmount: 0,
        txnUpdatedAmountAuthorized: 0,
        creditParty: {
          bankIspb: '',
          document: '',
          key: '',
          name: '',
          _id: '',
        },
        debitParty: {
          bankIspb: '',
          accountType: '',
          document: '',
          name: '',
          _id: '',
        },
      },
      _id: '',
      receiptsCount: 0,
      financialImpactType: '',
      billingAmountAuthorized: 0,
      billingAmount: 0,
      createdAt: new Date(),
      effectiveAt: new Date(),
    },
    {
      walletId: '',
      receiptsCount: 0,
      _id: '',
      cardTransaction: {
        txnAmountAuthorized: 0,
        localTime: new Date(),
        merchantName: '',
        txnCurrency: '',
        acquiringCountryCode: '',
        acquiringId: '0',
        txnAmount: 0,
        mcc: '',
        merchantCity: '',
        merchantCountry: '',
        merchantId: '',
        expired: false,
        cleared: false,
        overcleared: false,
        posEntryMode: '',
        reversed: false,
        _id: '',
      },
      pixTransaction: {
        description: '',
        txnCurrency: '0',
        txnOriginalAmount: 0,
        txnDiscountAmount: 0,
        txnFineAmount: 0,
        txnInterestAmount: 0,
        txnPurchaseAmount: 0,
        txnWithdrawalAmount: 0,
        txnUpdatedAmount: 0,
        txnUpdatedAmountAuthorized: 0,
        debitParty: {
          bankIspb: '',
          accountType: '',
          document: '',
          name: '',
          _id: '',
        },
        creditParty: {
          bankIspb: '',
          document: '',
          key: '',
          name: '',
          _id: '',
        },
      },
      financialImpactType: '',
      billingAmountAuthorized: 0,
      billingAmount: 0,
      createdAt: new Date(),
      effectiveAt: new Date(),
    },
    {
      walletId: '',
      receiptsCount: 0,
      _id: '',
      cardTransaction: {
        txnAmountAuthorized: 0,
        localTime: new Date(),
        merchantName: '',
        txnCurrency: '',
        acquiringCountryCode: '',
        acquiringId: '0',
        txnAmount: 0,
        mcc: '',
        merchantCity: '',
        merchantCountry: '',
        merchantId: '',
        expired: false,
        cleared: false,
        overcleared: false,
        posEntryMode: '',
        reversed: false,
        _id: '',
      },
      pixTransaction: {
        description: '',
        txnCurrency: '0',
        txnOriginalAmount: 0,
        txnDiscountAmount: 0,
        txnFineAmount: 0,
        txnInterestAmount: 0,
        txnPurchaseAmount: 0,
        txnWithdrawalAmount: 0,
        txnUpdatedAmount: 0,
        txnUpdatedAmountAuthorized: 0,
        creditParty: {
          bankIspb: '',
          document: '',
          key: '',
          name: '',
          _id: '',
        },
        debitParty: {
          bankIspb: '',
          accountType: '',
          document: '',
          name: '',
          _id: '',
        },
      },
      financialImpactType: '',
      billingAmountAuthorized: 0,
      billingAmount: 0,
      createdAt: new Date(),
      effectiveAt: new Date(),
    },
    {
      walletId: '',
      receiptsCount: 0,
      _id: '',
      cardTransaction: {
        txnAmountAuthorized: 0,
        localTime: new Date(),
        merchantName: '',
        txnCurrency: '',
        acquiringCountryCode: '',
        acquiringId: '0',
        txnAmount: 0,
        mcc: '',
        merchantCity: '',
        merchantCountry: '',
        merchantId: '',
        expired: false,
        cleared: false,
        overcleared: false,
        posEntryMode: '',
        reversed: false,
        _id: '',
      },
      pixTransaction: {
        description: '',
        txnCurrency: '0',
        txnOriginalAmount: 0,
        txnDiscountAmount: 0,
        txnFineAmount: 0,
        txnInterestAmount: 0,
        txnPurchaseAmount: 0,
        txnWithdrawalAmount: 0,
        txnUpdatedAmount: 0,
        txnUpdatedAmountAuthorized: 0,
        creditParty: {
          bankIspb: '',
          document: '',
          key: '',
          name: '',
          _id: '',
        },
        debitParty: {
          bankIspb: '',
          accountType: '',
          document: '',
          name: '',
          _id: '',
        },
      },
      financialImpactType: '',
      billingAmountAuthorized: 0,
      billingAmount: 0,
      createdAt: new Date(),
      effectiveAt: new Date(),
    },
  ],
  next: '',
};

export const initialStateTransactions: Transaction[] = [
  {
    walletId: '',
    receiptsCount: 0,
    _id: '',
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: '0',
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
  {
    walletId: '',
    _id: '',
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: '0',
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    receiptsCount: 0,
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
  {
    walletId: '',
    _id: '',
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: '0',
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    receiptsCount: 0,
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
  {
    walletId: '',
    _id: '',
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: '0',
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    receiptsCount: 0,
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
];
