import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { DrawerTransactionsDetails } from '../DrawerTransactionDetails.interface';

export const getTransactionDetails = async (
  transactionId: string,
  walletId?: string
): Promise<DrawerTransactionsDetails> => {
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${walletId}/transactions/${transactionId}`
  );
  const { data } = response;

  return data;
};

export const saveTransactionsDetails = async (
  transactionId: string,
  payload: unknown,
  walletId?: string
): Promise<DrawerTransactionsDetails> => {
  const isWallet = walletId || 'default';
  const response = await api.put(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWallet}/transactions/${transactionId}`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  const { data } = response;

  return data;
};
