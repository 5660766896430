import { ProgressBarProps } from './ProgressBar.interface';
import './ProgressBar.styles.scss';

export const ProgressBar = ({ balance, total }: ProgressBarProps) => {
  const renderBars = () => {
    return balance.map((elem, index) => {
      const barFillingPercentage = (elem.value / total) * 100;
      if (barFillingPercentage > 0) {
        return (
          <div
            data-testid={elem.status}
            key={index}
            className={`progressbar--load-${elem.status.toLowerCase()}`}
            style={{
              width: `${barFillingPercentage}%`,
            }}
          />
        );
      } else return null;
    });
  };

  return (
    <div data-testid="progress-bar" className="progressbar">
      {renderBars()}
    </div>
  );
};
