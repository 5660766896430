export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  CREATING = 'CREATING',
  BLOCKED = 'BLOCKED',
  ARCHIVED = 'ARCHIVED',
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum StatusDescriptionEnum {
  ACTIVE = 'Ativo',
  CREATING = 'Processando',
  BLOCKED = 'Bloqueado',
  ARCHIVED = 'Arquivado',
  APPROVED = 'Aprovado',
  REPROVED = 'Reprovado',
  DEACTIVATED = 'Desativado',
}
