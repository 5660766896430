import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { StatusTag } from '@components';
import { ICONS } from '@constants/icons';
import { StatusEnum } from '@enums/Status.enum';
import { UserLevelEnum } from '@enums/UserEnum';
import { RealmUser } from '@interfaces/User.interfaces';
import { Avatar, Button, Dialog, Icon, Tag } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { Dispatch, SetStateAction } from 'react';

import { useFilterColumns } from '@hooks/useFilterColumns';
import './RealmUsers.styles.scss';

const userAccess = {
  [UserLevelEnum.ROOT]: 'general.admin',
  [UserLevelEnum.ADMIN]: 'general.admin',
  [UserLevelEnum.USER]: 'general.collaborator',
};

export const useRealmUsersColumns = (
  setShowDeactivateUserModal: Dispatch<
    SetStateAction<{
      open: boolean;
      userId: string;
      fullName: string;
    }>
  >
) => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();

  const getSecurityStatus = (active: boolean) => {
    if (active) {
      return {
        icon: `${ICONS['check-circle-solid']} text-p3 text-success-400`,
        text: t('general.active'),
      };
    }

    return {
      icon: `${ICONS['triangle-exclamation']} text-p3 text-warning-400`,
      text: t('general.pending'),
    };
  };

  const columns: ColumnDef<RealmUser>[] = [
    {
      accessorKey: ' ',
      header: '',
      cell: ({ row }) => {
        const fullName = `${row.original.firstName} ${row.original.lastName}`;
        return (
          <div className="px-4">
            <Avatar imageSrc="" userName={fullName} size="small" />
          </div>
        );
      },
    },
    {
      accessorKey: 'name',
      header: t('general.name'),
      cell: ({ row }) => `${row.original.firstName} ${row.original.lastName}`,
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'access',
      header: t('general.access'),
      meta: {
        width: 120,
        filter: {},
      },
      cell: ({ cell }) => {
        const user = cell.row.original;

        return t(
          userAccess[user.realm.level as keyof typeof userAccess],
          user.realm.level
        );
      },
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      meta: {
        width: 120,
      },
      cell: ({ row }) => (
        <StatusTag status={row.original.status} type="GENERAL" />
      ),
    },
    {
      accessorKey: 'organizations',
      header: t('general.organizations'),
      meta: {
        width: 120,
      },
      cell: ({ row }) => {
        const user = row.original;
        const orgNames = user.organizations.map((org) => org.tradingName);
        return (
          <div className="flex flex-row gap-2">
            <OrgTag orgName={orgNames[0]} />
            {orgNames.length > 1 && (
              <OrgTooltip
                orgNames={orgNames.slice(1)}
                data-testid={`org-tooltip-${user.id}`}
              />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'email',
      header: t('general.email'),
      meta: {
        width: 240,
        filter: {
          disabled: true,
        },
      },
      cell: ({ cell }) => cell.getValue<string>(),
    },
    {
      accessorKey: 'hasPin',
      header: 'PIN',
      meta: {
        width: 120,
        filter: {
          noSelected: true,
        },
      },
      cell: ({ cell }) => (
        <Tag
          className="text-neutral-100 !bg-neutral-20"
          icon={
            <i className={getSecurityStatus(cell.getValue<boolean>()).icon} />
          }
          text={getSecurityStatus(cell.getValue<boolean>()).text}
          showCloseButton={false}
        />
      ),
    },
    {
      accessorKey: 'hasMfa',
      header: t('general.mfa'),
      meta: {
        width: 120,
        filter: {
          noSelected: true,
        },
      },
      cell: ({ cell }) => (
        <Tag
          className="text-neutral-100 !bg-neutral-20"
          icon={
            <i className={getSecurityStatus(cell.getValue<boolean>()).icon} />
          }
          text={getSecurityStatus(cell.getValue<boolean>()).text}
          showCloseButton={false}
        />
      ),
    },
    {
      accessorKey: '',
      header: t('general.actions'),
      meta: {
        width: 120,
        filter: {
          disabled: true,
        },
      },
      cell: ({ cell }) => {
        const user = cell.row.original;
        return (
          <div className="flex flex-row gap-2">
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              onClick={() => openDrawer('edit-realm-user', { userId: user.id })}
              data-testid={`edit-user-${user.id}`}
            >
              <Icon size="medium" key="edit-user-button">
                <i className={`${ICONS.pencil} fa-1x text-neutral-100`} />
              </Icon>
            </Button>
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              onClick={() =>
                setShowDeactivateUserModal({
                  open: true,
                  userId: user.id,
                  fullName: `${user.firstName} ${user.lastName}`,
                })
              }
              disabled={user.status === StatusEnum.DEACTIVATED}
              data-testid={`deactivate-user-${user.id}`}
            >
              <Icon size="medium" key="remove-user-button">
                <i
                  className={`${ICONS.trash} fa-1x  ${user.status !== StatusEnum.DEACTIVATED ? 'text-neutral-100' : ''}`}
                />
              </Icon>
            </Button>
          </div>
        );
      },
    },
  ];

  return useFilterColumns('realm-users-columns', columns);
};

const OrgTooltip = ({ orgNames, ...props }: { orgNames: string[] }) => {
  return (
    <Dialog variant="tooltip">
      <Dialog.Trigger>
        <div {...props}>
          <OrgTag orgName={`+${orgNames.length}`} />
        </div>
      </Dialog.Trigger>
      <Dialog.Content>
        <div className="org_tooltip__dialog">
          {orgNames.map((orgName, index) => (
            <Tag
              text={orgName}
              showCloseButton={false}
              textColor="var(--product-neutral-n40)"
              backgroundColor="transparent"
              borderColor="var(--product-neutral-n30)"
              key={index}
            />
          ))}
        </div>
      </Dialog.Content>
    </Dialog>
  );
};

const OrgTag = ({ orgName }: { orgName: string }) => {
  return (
    <Tag
      text={orgName}
      showCloseButton={false}
      textColor="var(--product-neutral-n100)"
      backgroundColor="var(--product-neutral-n20)"
    />
  );
};
