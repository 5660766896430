import { regex } from '@constants/regex';
import { PixDictType, PixInitiationType } from '@enums/newPix.enum';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents } from '@lib/customerIo';
import {
  Button,
  ErrorMessage,
  Icon,
  Input,
  Select,
  Typography,
} from '@portao3-web/ui';
import { cnpjValidate, cpfValidate } from '@utils/cpfValidate';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask, cpfMask, moneyMask, phoneDDIMask } from '@utils/formatMasks';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContextualError, ContextualErrorType } from '../../alerts';
import { SidebarFooter } from '../../sidebarFooter';
import { SidebarSection } from '../../sidebarSection';
import { FormPayment, NewPixProps } from '../NewPix.interface';
import { initiatePixPayment } from '../services/PixService';

export const ConfigPix = ({
  goNextStep,
  back,
  confirmPayment,
  walletId,
  setDictType,
  balance,
  setInitiatedValues,
}: NewPixProps & {
  setInitiatedValues: (value: FormPayment) => void;
  setDictType: (value: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [messageErrorService, setMessageErrorService] =
    useState<ContextualErrorType>(null);
  const { t } = useTranslation();

  const form = useForm<FormPayment>({
    defaultValues: {
      dict: '',
      key: '',
      amount: moneyMask('0'),
      description: '',
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = form;

  const typeKeyPix = watch('dict');
  const optionsSelect = [
    {
      value: PixDictType.EMAIL,
      label: 'E-mail',
      icon: 'fa-regular fa-envelope',
    },
    {
      value: PixDictType.EVP,
      label: 'Chave aleatória',
      icon: 'fa-regular fa-qrcode',
    },
    { value: PixDictType.CPF, label: 'CPF', icon: 'fa-regular fa-user' },
    { value: PixDictType.CNPJ, label: 'CNPJ', icon: 'fa-regular fa-user-tie' },
    {
      value: PixDictType.PHONE,
      label: 'Telefone',
      icon: 'fa-regular fa-mobile',
    },
  ];
  const formatDictKey = (formData: FormPayment) => {
    switch (typeKeyPix) {
      case PixDictType.CPF:
        return formData.key.replace(/\D/g, '');
      case PixDictType.CNPJ:
        return formData.key.replace(/\D/g, '');
      case PixDictType.EVP:
        return formData.key;
      case PixDictType.EMAIL:
        return formData.key.toLowerCase();
      case PixDictType.PHONE: {
        const hasDDI = formData.key.startsWith('+55')
          ? formData.key
          : `+55${formData.key}`;
        return hasDDI;
      }
      default:
        return '';
    }
  };

  const submitFormPix = async (formData: FormPayment) => {
    setLoading(!loading);
    setMessageErrorService(null);

    const form = {
      ...formData,
      amount: formData.amount.replace(/\D/g, ''),
      initiationType: PixInitiationType.DICT,
      key: formatDictKey(formData),
      dict: typeKeyPix,
    };

    try {
      const responsePix = await initiatePixPayment(form, walletId);
      customerEvents.pixInitiated({
        type: PixInitiationType.DICT,
        amount: +form.amount,
        dictType: typeKeyPix,
        walletId: responsePix.walletId,
      });

      setInitiatedValues(form);
      setDictType(typeKeyPix);
      confirmPayment(responsePix);
      setLoading(false);
      goNextStep();
    } catch (error: unknown) {
      setLoading(false);

      const errorService = error as AxiosError;
      const errorInfo = errorService?.response?.data as ErrorRequest;

      setMessageErrorService({
        message: t(
          `error.pix-transaction.${errorInfo?.message}`,
          t('error.default')
        ),
        traceId: errorInfo?.traceId || '',
      });
    }
  };

  useEffect(() => {
    setValue('key', '');
    if (typeKeyPix === PixDictType.PHONE) {
      const phone = '+55';
      setValue('key', phone);
    }
  }, [setValue, typeKeyPix]);
  useEffect(() => {
    const subscription = watch((value, { type }) => {
      setMessageErrorService(null);

      const amount = value.amount?.replace(/\D/g, '');
      if (Number(amount) <= balance) {
        clearErrors('amount');
      }

      if (typeKeyPix === PixDictType.CPF && type === 'change') {
        const cpf = cpfMask(value.key || '');
        setValue('key', cpf);
      }

      if (typeKeyPix === PixDictType.CNPJ && type === 'change') {
        const cnpj = cnpjMask(value.key || '');
        setValue('key', cnpj);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, typeKeyPix, clearErrors, balance]);

  const handleClearFields = () => {
    setMessageErrorService(null);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(submitFormPix)} data-testid="new-pix">
      <ContextualError error={messageErrorService} />
      <SidebarSection
        numberSection={1}
        titleSection="Para quem deseja transferir?"
        subTitleSection=""
      >
        <Select
          options={optionsSelect}
          label="Chave Pix"
          name="dict"
          form={form}
          placeholder="Selecione a chave"
          data-testid="typePix"
          validationSchema={{
            required: 'error.required-dict',
          }}
        />
        {typeKeyPix === PixDictType.CPF && (
          <Input
            type="tel"
            label="CPF"
            placeholder="Ex: 000.000.000-00"
            id="key"
            name="key"
            register={register}
            data-testid="key"
            error={!!errors['key']}
            maxLength={14}
            validationSchema={{
              required: 'O CPF é obrigatório',
              pattern: {
                value: regex.cpf,
                message: 'CPF inválido',
              },
              validate: (value: string) => {
                const cpf = cpfMask(value || '');
                setValue('key', cpf);
                return cpfValidate(value) ? 'CPF inválido' : null;
              },
            }}
          >
            {errors['key']?.message && (
              <ErrorMessage message={errors['key'].message.toString()} />
            )}
          </Input>
        )}
        {typeKeyPix === PixDictType.EVP && (
          <Input
            type="text"
            label="Chave aleatória"
            placeholder="Chave aleatória"
            id="key"
            name="key"
            register={register}
            data-testid="key"
            error={!!errors['key']}
            validationSchema={{
              required: 'A chave aleatória é obrigatória',
              pattern: {
                value: regex.uuid,
                message: 'Chave aleatória inválida',
              },
              validate: () => void 0,
            }}
          >
            {errors['key']?.message && (
              <ErrorMessage message={errors['key'].message.toString()} />
            )}
          </Input>
        )}
        {typeKeyPix === PixDictType.CNPJ && (
          <Input
            type="tel"
            label="CNPJ"
            placeholder="Ex: 00.000.000/0000-00"
            id="key"
            name="key"
            register={register}
            data-testid="key"
            error={!!errors['key']}
            maxLength={18}
            validationSchema={{
              required: 'O CNPJ é obrigatório',
              pattern: { value: regex.cnpj, message: 'CNPJ inválido' },
              validate: (value: string) => {
                const cnpj = cnpjMask(value || '');
                setValue('key', cnpj);
                return cnpjValidate(value) ? 'CNPJ inválido' : null;
              },
            }}
          >
            {errors['key']?.message && (
              <ErrorMessage message={errors['key'].message.toString()} />
            )}
          </Input>
        )}
        {typeKeyPix === PixDictType.PHONE && (
          <Input
            type="tel"
            label="Telefone"
            placeholder="Ex: +00000000000"
            id="key"
            name="key"
            register={register}
            data-testid="key"
            error={!!errors['key']}
            onChange={({ target }) => {
              form.setValue('key', phoneDDIMask(target.value));
            }}
            maxLength={14}
            validationSchema={{
              required: 'O telefone é obrigatório',
              pattern: {},
              validate: (item: string) => {
                return item.length <= 14 || 'Telefone inválido';
              },
            }}
          >
            {errors['key']?.message && (
              <ErrorMessage message={errors['key'].message.toString()} />
            )}
          </Input>
        )}
        {typeKeyPix === PixDictType.EMAIL && (
          <Input
            type="email"
            label="Email"
            placeholder="Email"
            id="key"
            name="key"
            register={register}
            data-testid="key"
            error={!!errors['key']}
            validationSchema={{
              required: 'O e-mail é obrigatório',
              pattern: {
                value: regex.email,
                message: 'E-mail inválido',
              },
              validate: () => void 0,
            }}
          >
            {errors['key']?.message && (
              <ErrorMessage message={errors['key'].message.toString()} />
            )}
          </Input>
        )}
      </SidebarSection>

      <SidebarSection
        numberSection={2}
        titleSection="Forneça os detalhes do Pix"
        subTitleSection=""
      >
        <div className="balance-card">
          <div className="flex items-center">
            <Icon size="xlarge">
              <i
                className="fa-regular fa-wallet"
                style={{ color: 'var(--product-neutral-n100)' }}
              />
            </Icon>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              Saldo disponível
            </Typography>
          </div>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(balance)}
          </Typography>
        </div>

        <Input
          type="tel"
          placeholder="Inserir valor"
          label="Valor"
          id="amount"
          name="amount"
          data-testid="amount"
          register={register}
          error={!!errors['amount']}
          onChange={(event) => {
            const value = moneyMask(event.target.value);
            setValue('amount', value, { shouldDirty: true });
          }}
          disabled={loading}
          validationSchema={{
            validate: (value: string) => {
              if (/^0*$/.test(value.replace(/\D/g, ''))) {
                return 'O valor é obrigatório';
              }
              if (Number(value.replace(/\D/g, '')) > balance) {
                return 'Saldo insuficiente';
              }
            },
          }}
          maxLength={17}
        >
          {errors['amount']?.message && (
            <ErrorMessage message={errors['amount'].message.toString()} />
          )}
        </Input>
      </SidebarSection>

      <SidebarFooter>
        {isDirty ? (
          <Button
            onClick={handleClearFields}
            variant="tertiary"
            size="large"
            type="reset"
            data-testid="resetForm"
            disabled={loading}
          >
            Limpar campos
            <Icon size="large">
              <i className="fa-regular fa-trash" />
            </Icon>
          </Button>
        ) : (
          <Button type="button" variant="tertiary" size="large" onClick={back}>
            Voltar
          </Button>
        )}
        <Button
          type="submit"
          size="large"
          data-testid="submitForm"
          className="button_submit"
          isLoading={loading}
          disabled={watch('amount') === moneyMask('0')}
        >
          Continuar
        </Button>
      </SidebarFooter>
    </form>
  );
};
