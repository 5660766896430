import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { StatusEnum } from '@enums/Status.enum';
import { environment } from '@environments/environment';
import { UserResponse } from '@interfaces/User.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export interface GetUserListProps {
  name?: string;
  status?: StatusEnum[];
  hasMfa?: string | null;
  hasPin?: string | null;
  level?: string;
  next?: string | null;
}

export const getUserList = async (
  params: GetUserListProps
): Promise<UserResponse> => {
  const paramsCopy = { ...params, limit: limitPerPage };

  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.identityBaseUrl}${realm()}/${organization()}/${
      endpoint.users
    }`,
    {
      params: paramsCopy,
    }
  );

  const { data } = response;

  return data;
};
