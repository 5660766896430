import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { UserResponse } from '@interfaces/User.interfaces';
import { userKeys } from '@services/user/hooks';
import { getUserList } from '../userManagementServices';
import { GetUserListProps } from '@services/user';

export const useInfiniteUserListQuery = (params: GetUserListProps) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    UserResponse,
    ApiError,
    InfiniteData<UserResponse>,
    unknown[],
    string
  >({
    queryKey: userKeys.list({
      ...params,
      organizationId: organizationId || '',
    }),
    queryFn: ({ pageParam }) =>
      getUserList({
        ...params,
        next: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    enabled: !!organizationId,
  });
};
