import { User } from '@interfaces/User.interfaces';
import {
  WalletDetailsProps,
  WalletRole,
  WalletType,
} from '@interfaces/Wallets.interfaces';

export const walletPermission = (wallet: WalletDetailsProps, user: User) => {
  if (!wallet) return false;
  return (
    wallet &&
    wallet.type !== WalletType.PERSONAL &&
    wallet.shared.findIndex(
      (elem) => elem.id === user.id && elem.role === WalletRole.OWNER
    ) > -1
  );
};
