import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { useQueryParams } from '@hooks';
import { DrawerBillingDeal } from '@modules/drawer/drawerBillingDeal';
import { Dialog, DropDownList, Icon, Tag } from '@portao3-web/ui';
import { useDrawer } from '@providers';

import { DealStatus } from '@enums/BillingDeal.enum';
import { Deal } from '@interfaces/BillingDeal.interfaces';

dayjs.extend(utc);

export const useCustomerBillingDealsColumns = (): ColumnDef<Deal>[] => {
  const { t } = useTranslation();

  const { setQueryParams } = useQueryParams();
  const { openDrawer } = useDrawer();

  const handleRedirectToInvoices = (dealId: string) => {
    setQueryParams({
      tab: 'invoices',
      dealId,
    });
  };

  const handleOpenBillingDeal = (deal: Deal) => {
    openDrawer(<DrawerBillingDeal deal={deal} />);
  };

  const getStatusTag = (status: DealStatus) => {
    const statusVariants = {
      [DealStatus.CANCELED]: {
        text: t('general.canceled'),
        icon: `${ICONS.circle} text-danger-500`,
      },
      [DealStatus.OPEN]: {
        text: t('general.active'),
        icon: `${ICONS.circle} text-success-500`,
      },
      [DealStatus.TERMINATED]: {
        text: t('general.terminated'),
        icon: `${ICONS.circle} text-success-500`,
      },
    };

    return (
      <Tag
        text={statusVariants[status].text}
        showCloseButton={false}
        className="bg-neutral-20 text-neutral-100"
        icon={
          <Icon size="small">
            <i className={`fa-1x ${statusVariants[status].icon}`} />
          </Icon>
        }
      />
    );
  };

  return [
    {
      accessorKey: 'icon',
      header: '',
      cell: () => {
        return (
          <div className="bg-neutral-20 ml-4 flex h-8 w-8 items-center justify-center rounded">
            <i className={` ${ICONS['file-lines']} text-p1 text-primary-400`} />
          </div>
        );
      },
    },
    {
      accessorKey: 'description',
      header: t('general.name'),
      cell: ({ cell }) => cell.getValue<string>(),
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      cell: ({ row }) => getStatusTag(row.original.status),
    },
    {
      accessorKey: 'startAt',
      header: t('general.start-date'),
      cell: ({ cell }) =>
        dayjs(cell.getValue<string>()).utc(false).format('DD/MM/YYYY'),
    },
    {
      accessorKey: '',
      header: t('general.actions'),
      cell: ({ row }) => (
        <div onClick={(event) => event.stopPropagation()}>
          <Dialog variant="popover">
            <Dialog.Trigger>
              <i
                data-testid={`actions-${row.index}`}
                role="button"
                className="fa-regular text-neutral-80 fa-ellipsis hover:bg-primary-50 text-p1 rounded-md px-2"
              />
            </Dialog.Trigger>
            <Dialog.Content>
              <DropDownList
                list={[
                  {
                    label: t('general.visualize'),
                    action: () => handleOpenBillingDeal(row.original),
                  },
                  {
                    label: t('general.invoices'),
                    action: () => handleRedirectToInvoices(row.original.id),
                  },
                ]}
              />
            </Dialog.Content>
          </Dialog>
        </div>
      ),
    },
  ];
};
