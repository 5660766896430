import { useEffect, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Icon, Typography } from '../../..';

import { DropDownSelected, WalletType } from '../../../../interfaces';
import { DropDownList } from '../DropDownItem';
import './DropDown.styles.scss';

interface DropDownProps {
  listItems: DropDownSelected[];
  setSearchWallet: (wallet: string) => void;
  isLoading: boolean;
  createWallet?: () => void;
  trackOpenDropdownUserAction?: () => void;
  trackCreateWalletUserAction?: () => void;
  trackShowAllWalletUserAction?: () => void;
  trackSearchWalletUserAction?: () => void;
}

export const DropDown = ({
  listItems,
  setSearchWallet,
  isLoading,
  createWallet,
  trackOpenDropdownUserAction,
  trackCreateWalletUserAction,
  trackSearchWalletUserAction,
  trackShowAllWalletUserAction,
}: DropDownProps) => {
  const loggedUser = JSON.parse(
    localStorage.getItem('user') || JSON.stringify('')
  );
  const [menuDropDownList, setMenuDropDownList] = useState(false);
  const [selectItem, setSelectItem] = useState(
    listItems.find((item) => item.type === WalletType.ORGANIZATION) ||
      listItems.find(
        (item) =>
          item.type === WalletType.PERSONAL &&
          item.secondaryName ===
            `${loggedUser.firstName} ${loggedUser.lastName}`
      ) ||
      listItems[0]
  );
  const { walletId } = useParams();
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const walletSelectedByUrl = listItems.find(
      (item) => item.walletId === walletId
    );

    if (walletSelectedByUrl !== undefined) {
      setSelectItem(walletSelectedByUrl || listItems[0]);
    } else {
      setSelectItem((prev) => {
        return (
          listItems.find((item) => item.walletId === prev?.walletId) ||
          listItems[0]
        );
      });
    }
  }, [walletId, listItems]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuDropDownList &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setSearchWallet('');
      }

      menuDropDownList &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        setMenuDropDownList(!menuDropDownList);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuDropDownList, setSearchWallet]);

  return (
    <div key="dropDown_container" className="dropDown_all">
      <div ref={menuRef} key="dropDown_trigger">
        <div
          className="dropDown"
          onClick={() => {
            setMenuDropDownList(!menuDropDownList);
            trackOpenDropdownUserAction &&
              !menuDropDownList &&
              trackOpenDropdownUserAction();
          }}
        >
          <Icon size="large">
            <i className="fa-regular fa-wallet dropDown_icon"></i>
          </Icon>
          {selectItem && (
            <div className="boxName">
              <Typography tag="p" weight="p1" className="name principalName">
                {selectItem.name}
              </Typography>
              <Typography tag="p" weight="p3" className="name secondaryName">
                {selectItem.secondaryName}
              </Typography>
            </div>
          )}
          <Icon size="large">
            <i className="fa-regular fa-chevron-down dropDown_icon"></i>
          </Icon>
        </div>
        {menuDropDownList && (
          <DropDownList
            selectedItem={(obj) => {
              setSelectItem(obj);
              setMenuDropDownList(false);
            }}
            listItems={listItems}
            itemSelected={selectItem}
            setSearchWallet={setSearchWallet}
            isLoading={isLoading}
            createWallet={createWallet}
            trackCreatelWalletsUserAction={trackCreateWalletUserAction}
            trackShowAllWalletsUserAction={trackShowAllWalletUserAction}
            trackSearchWalletsUserAction={trackSearchWalletUserAction}
          />
        )}
      </div>
      <ul key="dropDown_list" className="dropDown_list">
        {selectItem?.quickAccess.map((item, index) => (
          <li className="dropDown_list_item" key={index}>
            <NavLink
              to={item.url}
              className={({ isActive }) =>
                isActive
                  ? 'box_item_list_item box_item_list_item_selected'
                  : 'box_item_list_item'
              }
            >
              <div className="dropDown_list_item_link">
                <Icon size="medium">
                  <i className="fa-solid fa-circle-small dropDown_list_item_link_icon"></i>
                </Icon>
                <span>
                  <Typography
                    tag="p"
                    weight="p1"
                    className="name principalName"
                  >
                    {item.name}
                  </Typography>
                </span>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
