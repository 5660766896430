export const textBlockCard = {
  disclaimer: `Caso você opte por prosseguir, todas as transações pendentes serão interrompidas.`,
  warn: {
    title: 'Atenção!',
    description:
      'Para bloquear este cartão, basta confirmar sua decisão no botão abaixo.',
  },
  confirmation: 'Deseja mesmo bloquear?',
  actionButtonLabel: 'Sim, bloquear',
};

export const textUnblockCard = {
  disclaimer: `Caso você opte por prosseguir, as novas transações feitas com este cartão poderão ser aprovadas.`,

  confirmation: 'Deseja mesmo desbloquear?',
  actionButtonLabel: 'Sim, desbloquear',
};

export const cancelCardAction = {
  titleModal: 'Desistir de criar o cartão agora?',
  textModal:
    'Caso você opte por prosseguir, todas as informações preenchidas serão perdidas e não haverá possibilidade de recuperá-las.',
  confirmModal: 'Você quer mesmo continuar?',
  actionConfirm: 'Sim, quero desistir',
};

export const cancelRegisterCardPinAction = {
  titleModal: 'Desistir de criar o pin do cartão agora?',
  textModal:
    'Caso você opte por prosseguir, todas as informações preenchidas serão perdidas e não haverá possibilidade de recuperá-las.',
  confirmModal: 'Você quer mesmo continuar?',
  actionConfirm: 'Sim, quero desistir',
};
