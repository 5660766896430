import clsx from 'clsx';

import { Icon, Typography } from '@portao3-web/ui';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import { RadioButtonCardProps } from './RadioButtonCard.interfaces';
import './RadioButtonCard.styles.scss';

export const RadioButtonCard = ({
  value,
  icon,
  label,
}: RadioButtonCardProps) => {
  const { register, watch } = useDrawerEditCustomField();

  const selectedType = watch('type');
  const isSelected = selectedType === value;

  return (
    <label
      className={clsx('radio-button-card-container', {
        'radio-button-card-container--checked': isSelected,
      })}
    >
      <Icon size="large">
        <i className={clsx('fa-regular fa-1x type-icon', icon)} />
      </Icon>
      <Typography tag="p" weight="p3" color="var(--product-neutral-n90)">
        {label}
      </Typography>
      <Icon size="medium">
        <i
          className={clsx(
            'fa-circle fa-1x radio-icon',
            isSelected ? 'fa-solid radio-icon--checked' : 'fa-regular'
          )}
        />
      </Icon>
      <input
        type="radio"
        className="hidden-radio"
        value={value}
        {...register('type', { required: 'O tipo de campo é obrigatório.' })}
      />
    </label>
  );
};
