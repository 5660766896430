import { MutableRefObject } from 'react';

import { POPOVER_GAP } from '../constants';
import { PopoverPosition } from '../Dialog.interface';

interface PositioningFunctionParams {
  dialog: HTMLElement;
  dialogRect: DOMRect;
  containerRect: DOMRect;
}

const adjustForDevicePixelRatio = (value: number) =>
  value / window.devicePixelRatio;

const bottom = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  const bottomBoundary =
    containerRect.top + containerRect.height + dialogRect.height + POPOVER_GAP;

  if (bottomBoundary > window.innerHeight) {
    top({ containerRect, dialog, dialogRect });
  } else {
    dialog.style.left = `${adjustForDevicePixelRatio(
      containerRect.left + containerRect.width / 2 - dialogRect.width / 2
    )}px`;
    dialog.style.top = `${
      adjustForDevicePixelRatio(containerRect.top + containerRect.height) +
      POPOVER_GAP
    }px`;
  }
};

const bottomRight = ({ containerRect, dialog }: PositioningFunctionParams) => {
  dialog.style.left = `${containerRect.left}px`;
  dialog.style.top = `${
    containerRect.top + containerRect.height + POPOVER_GAP
  }px`;
};

const bottomLeft = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  dialog.style.left = `${
    containerRect.left + containerRect.width - dialogRect.width
  }px`;
  dialog.style.top = `${
    containerRect.top + containerRect.height + POPOVER_GAP
  }px`;
};

const bottomFullWidth = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  const bottomBoundary =
    containerRect.top + containerRect.height + dialogRect.height + POPOVER_GAP;

  if (bottomBoundary > window.innerHeight) {
    topFullWidth({ containerRect, dialog, dialogRect });
  } else {
    dialog.style.left = `${containerRect.left}px`;
    dialog.style.width = `${containerRect.width}px`;
    dialog.style.top = `${
      containerRect.top + containerRect.height + POPOVER_GAP
    }px`;
  }
};

const top = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  dialog.style.left = `${
    containerRect.left + containerRect.width / 2 - dialogRect.width / 2
  }px`;
  dialog.style.top = `${
    adjustForDevicePixelRatio(containerRect.top) -
    adjustForDevicePixelRatio(dialogRect.height) -
    POPOVER_GAP
  }px`;
};

const topRight = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  dialog.style.left = `${containerRect.left}px`;
  dialog.style.top = `${containerRect.top - dialogRect.height - POPOVER_GAP}px`;
};

const topLeft = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  dialog.style.left = `${
    containerRect.left + containerRect.width - dialogRect.width
  }px`;
  dialog.style.top = `${containerRect.top - dialogRect.height - POPOVER_GAP}px`;
};

const topFullWidth = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  dialog.style.left = `${containerRect.left}px`;
  dialog.style.width = `${containerRect.width}px`;
  dialog.style.top = `${containerRect.top - dialogRect.height - POPOVER_GAP}px`;
};

const auto = ({
  containerRect,
  dialog,
  dialogRect,
}: PositioningFunctionParams) => {
  let vertical = 'bottom';
  let horizontal = 'left';

  const bottomBoundary =
    containerRect.top + containerRect.height + dialogRect.height + POPOVER_GAP;
  const leftBoundary = containerRect.left + containerRect.width;

  if (bottomBoundary > window.innerHeight) {
    vertical = 'top';
  }

  if (leftBoundary - dialogRect.width < 0) {
    horizontal = 'right';
  }

  positionMapper[`${vertical}-${horizontal}` as keyof typeof positionMapper]({
    dialog,
    dialogRect,
    containerRect,
  });
};

const positionMapper = {
  bottom: bottom,
  'bottom-right': bottomRight,
  'bottom-left': bottomLeft,
  'bottom-full-width': bottomFullWidth,
  'top-right': topRight,
  'top-left': topLeft,
  'top-full-width': topFullWidth,
  auto: auto,
};

interface SetDialogPositionParams {
  dialogRef: MutableRefObject<HTMLDivElement | null>;
  containerRect: DOMRect | null;
  position: PopoverPosition;
}

export const setDialogPosition = ({
  containerRect,
  dialogRef,
  position,
}: SetDialogPositionParams) => {
  const dialog = dialogRef.current;
  const dialogRect = dialog?.getBoundingClientRect();

  if (!containerRect || !dialog || !dialogRect) return;

  positionMapper[position]({ dialog, dialogRect, containerRect });
};
