import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { CreateCustomer, Customer } from '@interfaces/Customer.interfaces';
import api from '@services/api';

export const updateCustomer = async (
  customerId: string,
  payload: CreateCustomer
): Promise<Customer> => {
  const response = await api.put(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customers
    }/${customerId}`,
    payload
  );
  const { data } = response;

  return data;
};
