import dayjs from 'dayjs';

import {
  Alert,
  CUSTOM_FIELDS_MAPPER,
  ContextualError,
  ContextualErrorType,
  DownloadPdf,
  FileUpload,
  SidebarFooter,
} from '@components';
import {
  Autocomplete,
  Button,
  Column,
  Icon,
  Row,
  Sidebar,
  Skeleton,
  TextArea,
  Toastr,
  Typography,
} from '@portao3-web/ui';

import { CardState } from '@components';
import { organization } from '@constants/endpoints';
import { viewTransactionsStatus } from '@enums/TransactionsStatusEnum';
import { viewCategoryWallet } from '@enums/WalletEnum';
import { TypePayment } from '@enums/typePayment.enum';
import {
  CustomFieldLocation,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { UploadedFile } from '@interfaces/File.interface';
import { User } from '@interfaces/User.interfaces';
import { customerEvents } from '@lib';
import { useDrawer } from '@providers';
import { useCustomFieldsQuery } from '@services/customFields/hooks';
import { useInfiniteUserListQuery } from '@services/user/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask } from '@utils/formatMasks';
import { normalizeString } from '@utils/normalizeString';
import { billingPayment } from '@utils/transactionFormatFunctions';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PaymentVoucher } from '../drawerBatchPayment';
import {
  DrawerTransactionsDetails,
  FormTransactionDetail,
  Owner,
} from './DrawerTransactionDetails.interface';
import './DrawerTransactionDetails.scss';
import {
  getTransactionDetails,
  saveTransactionsDetails,
} from './services/DrawerTransactionDetailsService';

interface DrawerTransactionDetailsProps {
  transactionId: string;
  walletId: string;
  openSidebar: boolean;
  setOpenSidebar?: (value: boolean) => void;
  updateCountFiles: (value: number) => void;
}

export const DrawerTransactionDetails = ({
  openSidebar,
  setOpenSidebar,
  updateCountFiles,
  transactionId,
  walletId,
}: DrawerTransactionDetailsProps) => {
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const [owner, setOwner] = useState<Owner>({} as Owner);
  const [userInput, setUserInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingGetDetails, setLoadingGetDetails] = useState(false);
  const [transactionDetails, setTransactionDetails] =
    useState<DrawerTransactionsDetails>({} as DrawerTransactionsDetails);
  const [fileState, setFileState] = useState<{ [key: string]: CardState }>({});
  const [query, setQuery] = useState('');

  const [contextualError, setContextualError] = useState<ContextualErrorType>();

  const [deleteFiles, setDeleteFiles] = useState<string[]>([]);

  const { data: customFields, error: customFieldsError } = useCustomFieldsQuery(
    {
      organization: localStorage.getItem('organization') || '',
      location: CustomFieldLocation.TRANSACTION,
    }
  );

  const {
    data: userList,
    fetchStatus: fetchUserStatus,
    fetchNextPage: fetchMoreUsers,
    isFetching: isFetchingUsers,
    isFetchingNextPage: isFetchingNextPageUsers,
  } = useInfiniteUserListQuery({ organization: organization(), query });

  const form = useForm<FormTransactionDetail>({
    mode: 'onTouched',
    defaultValues: {
      description: transactionDetails.paymentInfo?.description ?? '',
    },
  });

  const { register, handleSubmit, setValue } = form;

  const handleSetUserInput = (value: string) => {
    setUserInput(value);
  };

  const getTransactionType = (transaction: DrawerTransactionsDetails) => {
    if (transaction.cardTransaction) return 'CARD';
    if (transaction.pixTransaction) return 'PIX';
    if (transaction.boletoTransaction) return 'BOLETO';
    if (transaction.p2pTransaction) return 'P2P';
    if (transaction.feeTransaction) return 'FEE';

    return '';
  };

  const autocompleteUserList = useMemo(() => {
    const formatedUsers: { [key: string]: string } = {};

    userList?.forEach((user) => {
      formatedUsers[user.id] = `${user.firstName} ${user.lastName}`;
    });

    return formatedUsers;
  }, [userList]);

  const addUser = (value: string) => {
    const newUser = userList?.find((user) => user.id === value);
    setUserInput(`${newUser?.firstName} ${newUser?.lastName}`);

    setOwner(newUser || ({} as User));
  };

  const submitForm = async (data: FormTransactionDetail) => {
    if (transactionDetails?.internalErrorCode) {
      return closeDrawer();
    }

    setIsLoading(true);

    const payloads: Array<{
      fileLink: string;
      promise: () => Promise<DrawerTransactionsDetails>;
    }> = [];

    const formattedCustomFields = data.customFields
      ? Object.entries(data.customFields).map(([key, value]) => {
          const field = customFields?.items?.find(
            (field) => field.identifier === key
          );

          const getMultiSelectValue = () => {
            if (value.length < 1) return [];
            if (typeof value === 'string') return [value];
            return value;
          };

          const getFieldValues = () => {
            const result = value;
            if (field?.type === CustomFieldType.NUMBER) {
              return [value.replace(/\D/g, '')];
            }
            return result ? [result] : [];
          };

          return {
            id: field?.id ?? '',
            label: field?.label ?? '',
            values:
              field?.type === CustomFieldType.MULTI_SELECT
                ? getMultiSelectValue()
                : field?.type === CustomFieldType.DATE
                  ? dayjs(value).toISOString()
                  : getFieldValues(),
            identifier: key,
            version: field?.version ?? 1,
          };
        })
      : [];

    const form = {
      deleteFiles: deleteFiles,
      owner: owner.id,
      description: data.description,
      customFields: formattedCustomFields,
    };

    const transactionInfo = new FormData();
    transactionInfo.append('data', JSON.stringify(form));

    payloads.push({
      fileLink: '',
      promise: () =>
        saveTransactionsDetails(transactionId, transactionInfo, walletId),
    });

    data.files.forEach((file: UploadedFile) => {
      if (file.file) {
        const fileData = new FormData();
        fileData.append('files', file.file, normalizeString(file.file.name));
        payloads.push({
          fileLink: file.link,
          promise: () =>
            saveTransactionsDetails(transactionId, fileData, walletId),
        });
      }
    });

    const uploadAllFiles = async () => {
      for (const promise of payloads) {
        setFileState((prev) => ({
          ...prev,
          [promise.fileLink]: 'loading',
        }));
        try {
          await promise.promise();
          setFileState((prev) => ({
            ...prev,
            [promise.fileLink]: 'loaded',
          }));
        } catch (error) {
          console.log('🚀 ~ error:', error);
          setFileState((prev) => ({
            ...prev,
            [promise.fileLink]: 'error',
          }));
          throw error;
        }
      }
    };

    try {
      await uploadAllFiles();

      customerEvents.transactionDetailsUpdated({
        amount: transactionDetails.billingAmountAuthorized,
        transactionId,
        transactionStatus: transactionDetails?.responseCode,
        type: getTransactionType(transactionDetails),
      });

      setIsLoading(false);
      updateCountFiles(data.files.length);
      setOpenSidebar?.(false);
      Toastr.success('Detalhes da transação salva com sucesso');
      closeDrawer();
    } catch {
      setIsLoading(false);
      Toastr.error('Erro ao salvar transação');
    }
  };

  useEffect(() => {
    const getTransactionsDetails = async () => {
      setLoadingGetDetails(true);
      setContextualError(null);

      try {
        const response = await getTransactionDetails(transactionId, walletId);

        const owner = response.paymentInfo.owner;

        if (owner.firstName) {
          setUserInput(`${owner.firstName} ${owner.lastName}`);
        }

        setValue('description', response.paymentInfo.description);
        setOwner(owner);
        setTransactionDetails(response);
        customerEvents.transactionDetailsViewed({
          amount: response.billingAmountAuthorized,
          transactionId: transactionId,
          transactionStatus: response?.responseCode,
          type: getTransactionType(response),
        });
        setLoadingGetDetails(false);
      } catch (error) {
        const errorService = error as AxiosError;
        const errorInfo = errorService?.response?.data as ErrorRequest;
        setLoadingGetDetails(false);

        setContextualError({
          message: t(`error.CODE_ERROR.${errorInfo?.code}`, t('error.default')),
          traceId: errorInfo?.traceId,
        });
      }
    };

    if (!isMounted.current) {
      getTransactionsDetails();
      isMounted.current = true;
    }
  }, [transactionId, walletId, setValue, t]);

  const removeFile = (fileId: string) => {
    setDeleteFiles((prev) => [...prev, fileId]);
  };

  const getWalletName = (walletName: string | undefined) => {
    if (!walletName) return '-';
    if (walletName === 'default') {
      return t('general.company-account');
    }
    return walletName;
  };

  const showDownloadVoucher =
    transactionDetails?.financialImpactType === 'DEBIT' ||
    transactionDetails?.internalErrorCode;

  return (
    <Sidebar
      title="Detalhes da transação"
      icon="fa-regular fa-box"
      open={openSidebar}
      onClose={() => {
        setOpenSidebar?.(false);
        closeDrawer();
      }}
    >
      <>
        {contextualError && <ContextualError error={contextualError} />}

        {transactionDetails.processingType === 'DELAYED' &&
          !!transactionDetails.internalErrorCode && (
            <Alert status="error">
              <p className="text-p2 text-neutral-100">
                Erro ao processar pagamento
              </p>

              {transactionDetails.internalErrorCode === 'UNKNOWN_ERROR' && (
                <p className="text-p2 text-danger-500">
                  Erro ao processar pagamento do agendamento.
                </p>
              )}

              {transactionDetails.internalErrorCode !== 'UNKNOWN_ERROR' && (
                <p className="text-p2 text-danger-500">
                  {t(
                    `error.CODE_ERROR.${transactionDetails.internalErrorCode}`,
                    t('error.processing-error')
                  )}
                </p>
              )}
            </Alert>
          )}

        <div className="drawerDetailsTransaction">
          <div className="border-money">
            <Row>
              <Column lg={12} md={12} sm={12}>
                <div className="drawerDetailsTransaction_box_title">
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    Valor
                  </Typography>

                  <Icon size="xlarge">
                    {transactionDetails.cardTransaction && (
                      <i className="fa-regular fa-credit-card drawerDetailsTransaction_box_title_icon"></i>
                    )}

                    {transactionDetails.pixTransaction && (
                      <i className="fa-brands fa-pix drawerDetailsTransaction_box_title_icon"></i>
                    )}

                    {transactionDetails.boletoTransaction && (
                      <i className="fa-regular fa-barcode drawerDetailsTransaction_box_title_icon"></i>
                    )}

                    {transactionDetails.p2pTransaction && (
                      <i className="fa-solid fa-right-left drawerDetailsTransaction_box_title_icon"></i>
                    )}

                    {transactionDetails.feeTransaction && (
                      <i className="fa-regular fa-file-invoice-dollar"></i>
                    )}
                  </Icon>
                </div>
              </Column>
              <Column lg={12} md={12} sm={12}>
                <Skeleton isLoading={loadingGetDetails} width={80}>
                  <Typography
                    tag="p"
                    weight="p1"
                    color="var(--product-neutral-n500)"
                  >
                    {calcCurrencyValue(
                      transactionDetails.processingType === 'DELAYED' &&
                        transactionDetails.financialImpactType === 'NONE'
                        ? transactionDetails.billingAmount
                        : transactionDetails.billingAmountAuthorized,
                      'BRL'
                    )}
                  </Typography>
                </Skeleton>
              </Column>
            </Row>
          </div>
        </div>
        <Typography tag="p" weight="p1">
          Dados da transação
        </Typography>
        <div className="statement_sidebar_block">
          {transactionDetails.effectiveAt && (
            <Column md={6} lg={6}>
              <Row>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n80)"
                >
                  Data e hora
                </Typography>
              </Row>
              <Row>
                <Skeleton isLoading={loadingGetDetails} width={80}>
                  <Typography
                    tag="p"
                    weight="p2"
                    color="var(--product-neutral-n500)"
                  >
                    {`${dayjs(transactionDetails.effectiveAt).format(
                      'DD/MM/YYYY'
                    )} às ${dayjs(transactionDetails.effectiveAt).format(
                      'HH:mm'
                    )}`}
                  </Typography>
                </Skeleton>
              </Row>
            </Column>
          )}
          <Column md={6} lg={6}>
            <Row>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                Beneficiário
              </Typography>
            </Row>
            <Row>
              <Skeleton isLoading={loadingGetDetails} width={80}>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {transactionDetails.cardTransaction?.merchantName}
                  {transactionDetails.pixTransaction &&
                    transactionDetails.pixTransaction.creditParty.name}
                  {transactionDetails.boletoTransaction &&
                    transactionDetails.boletoTransaction.payee}
                  {transactionDetails.billingTransaction &&
                    billingPayment(transactionDetails)}
                  {transactionDetails.feeTransaction &&
                    transactionDetails.feeTransaction.description}
                  {transactionDetails.p2pTransaction && `Transferência interna`}
                </Typography>
              </Skeleton>
            </Row>
          </Column>
        </div>
        <div className="statement_sidebar_block">
          <Column md={6} lg={6}>
            <Row>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
                className="mt-2"
              >
                Tipo da transação
              </Typography>
            </Row>
            <Row>
              <Skeleton isLoading={loadingGetDetails} width={80}>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {transactionDetails.cardTransaction &&
                    `Cartão ${transactionDetails.paymentInfo.panMasked.slice(
                      -4
                    )}`}
                  {transactionDetails.pixTransaction && 'Pix'}
                  {transactionDetails.boletoTransaction && 'Boleto'}
                  {transactionDetails.billingTransaction && 'Fatura'}
                  {transactionDetails.feeTransaction &&
                    transactionDetails.feeTransaction.description}
                </Typography>
              </Skeleton>
            </Row>
          </Column>
        </div>
      </>

      <div className="pt-6">
        <Typography tag="p" weight="p1">
          Detalhes da transação
        </Typography>
        <div className="statement_sidebar_block">
          <Column md={6} lg={6}>
            <Row>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                Categoria
              </Typography>
            </Row>
            <Row>
              <Skeleton isLoading={loadingGetDetails} width={80}>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {viewCategoryWallet[transactionDetails.balanceCategory] ||
                    'Não informado'}
                </Typography>
              </Skeleton>
            </Row>
          </Column>
          <Column md={6} lg={6}>
            <Row>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                Status da transação
              </Typography>
            </Row>
            <Row>
              <Skeleton isLoading={loadingGetDetails} width={80}>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {viewTransactionsStatus[
                    transactionDetails.paymentInfo?.status
                  ] ||
                    (transactionDetails?.internalErrorCode &&
                      'Não processada') ||
                    'Não informado'}
                </Typography>
              </Skeleton>
            </Row>
          </Column>
        </div>
        <div className="statement_sidebar_block">
          <Column md={6} lg={6}>
            <Row>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n80)"
              >
                Carteira
              </Typography>
            </Row>
            <Row>
              <Skeleton isLoading={loadingGetDetails} width={80}>
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {getWalletName(transactionDetails.paymentInfo?.walletName)}
                </Typography>
              </Skeleton>
            </Row>
          </Column>
          {(transactionDetails?.boletoTransaction ||
            transactionDetails?.pixTransaction) &&
            transactionDetails.paymentInfo.owner.firstName && (
              <Column md={6} lg={6}>
                <Row>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    Responsável da transação
                  </Typography>
                </Row>
                <Row>
                  <Skeleton isLoading={loadingGetDetails} width={80}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      `${transactionDetails.paymentInfo.owner.firstName} $
                      {transactionDetails.paymentInfo.owner.lastName}`
                    </Typography>
                  </Skeleton>
                </Row>
              </Column>
            )}
        </div>
      </div>

      {transactionDetails.billingTransaction && (
        <div className="pt-6">
          <Typography tag="p" weight="p1">
            Detalhes da Fatura
          </Typography>
          <div>
            <div className="statement_sidebar_block">
              <Column md={6} lg={6}>
                <Row>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    Responsável da transação
                  </Typography>
                </Row>
                <Row>
                  <Skeleton isLoading={loadingGetDetails} width={80}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      {transactionDetails.billingTransaction?.debitParty.name}
                    </Typography>
                  </Skeleton>
                </Row>
              </Column>
              <Column md={6} lg={6}>
                <Row>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    CPF ou CNPJ
                  </Typography>
                </Row>
                <Row>
                  <Skeleton isLoading={loadingGetDetails} width={80}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      {cnpjMask(
                        transactionDetails.billingTransaction?.debitParty
                          .document || ''
                      )}
                    </Typography>
                  </Skeleton>
                </Row>
              </Column>
            </div>
            <div className="statement_sidebar_block">
              <Column md={12} lg={12}>
                <Row>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    ID da Fatura
                  </Typography>
                </Row>
                <Row>
                  <Skeleton isLoading={loadingGetDetails} width={80}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      {transactionDetails.billingTransaction.invoiceId}
                    </Typography>
                  </Skeleton>
                </Row>
              </Column>
            </div>
            <div className="statement_sidebar_block">
              <Column md={12} lg={12}>
                <Row>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    ID do Contrato
                  </Typography>
                </Row>
                <Row>
                  <Skeleton isLoading={loadingGetDetails} width={80}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      {transactionDetails.billingTransaction.contractId}
                    </Typography>
                  </Skeleton>
                </Row>
              </Column>
            </div>
            <div className="statement_sidebar_block">
              <Column md={12} lg={12}>
                <Row>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    ID da Negociação
                  </Typography>
                </Row>
                <Row>
                  <Skeleton isLoading={loadingGetDetails} width={80}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      {transactionDetails.billingTransaction.dealId}
                    </Typography>
                  </Skeleton>
                </Row>
              </Column>
            </div>
          </div>
        </div>
      )}

      <form className="new-wallet" onSubmit={handleSubmit(submitForm)}>
        {transactionDetails?.cardTransaction && (
          <div className="pt-6">
            <div className="statement_sidebar_block">
              <Column md={12} lg={12}>
                <Row>
                  <Autocomplete
                    label="Selecionar responsável"
                    value={userInput}
                    setValue={handleSetUserInput}
                    placeholder="Busque por nome"
                    items={autocompleteUserList}
                    selectItem={addUser}
                    queryFn={setQuery}
                    isFetching={isFetchingUsers}
                    isFetchingNextPage={isFetchingNextPageUsers}
                    fetchNextPage={
                      fetchUserStatus === 'fetching'
                        ? undefined
                        : fetchMoreUsers
                    }
                    hasDefaultValue
                  />
                </Row>
              </Column>
            </div>
          </div>
        )}

        {!transactionDetails.internalErrorCode && (
          <>
            <div
              className="pt-6"
              style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
            >
              <Typography tag="p" weight="p1">
                {t('customFields.drawerBlockTitle')}
              </Typography>
              <Skeleton isLoading={loadingGetDetails} width={520} height={30}>
                {customFieldsError ? (
                  <Alert status="error">
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      Houve um problema ao carregar o campo customizável. Por
                      favor, atualize a página e tente novamente.
                    </Typography>
                  </Alert>
                ) : (
                  customFields?.items
                    ?.slice()
                    .reverse()
                    .map((field, index) => {
                      const Component =
                        CUSTOM_FIELDS_MAPPER[
                          field.type as keyof typeof CUSTOM_FIELDS_MAPPER
                        ];

                      return (
                        <Column md={12} lg={12} key={field.id}>
                          <Row>
                            {Component && (
                              <Component
                                key={index}
                                form={form}
                                fieldSchema={field}
                                customFields={transactionDetails?.customFields}
                              />
                            )}
                          </Row>
                        </Column>
                      );
                    })
                )}
                <Column md={12} lg={12}>
                  <Row>
                    <TextArea
                      name="description"
                      placeholder="Digite informações para identificar a transação"
                      id="description"
                      data-testid="description"
                      label="Observações (Opcional)"
                      register={register}
                      style={{ resize: 'vertical' }}
                    />
                  </Row>
                </Column>
              </Skeleton>
            </div>

            <Column md={12} lg={12}>
              <Row>
                <Skeleton isLoading={loadingGetDetails} width={520} height={30}>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    Comprovante
                  </Typography>
                </Skeleton>

                {!loadingGetDetails && (
                  <FileUpload
                    maxFileSizeInMB={10}
                    maxFiles={5}
                    fileAccept=".jpg, .jpeg, .png, .pdf"
                    name="files"
                    setValue={setValue}
                    defaultFiles={transactionDetails.paymentInfo?.receipts}
                    deleteFile={(file: UploadedFile) =>
                      removeFile(file.id || '')
                    }
                  >
                    {({ uploadedFiles }) => (
                      <div className="drawer-transactions-file-upload-container">
                        <Skeleton
                          isLoading={loadingGetDetails}
                          width={520}
                          height={30}
                        >
                          <div className="receipt-upload">
                            {uploadedFiles?.length < 1 && (
                              <FileUpload.DragAndDrop />
                            )}
                            {uploadedFiles?.map((file) => (
                              <FileUpload.FileCard
                                key={file.link}
                                file={file}
                                cardState={fileState[file.link] ?? 'loaded'}
                              />
                            ))}
                            {uploadedFiles?.length > 0 && <FileUpload.Button />}
                          </div>
                        </Skeleton>
                      </div>
                    )}
                  </FileUpload>
                )}
              </Row>
            </Column>
          </>
        )}

        <SidebarFooter>
          {showDownloadVoucher && (
            <DownloadPdf
              fileName={dayjs(transactionDetails.createdAt).format(
                'DD-MM-YYYY'
              )}
            >
              <DownloadPdf.Trigger>
                <Button type="button" variant="secondary" size="large">
                  {t('payment-voucher.download-voucher')}
                  <i className="fa-regular fa-download fa-1x" />
                </Button>
              </DownloadPdf.Trigger>
              <DownloadPdf.Content>
                {transactionDetails.pixTransaction && (
                  <PaymentVoucher
                    creditParty={{
                      ...transactionDetails.pixTransaction.creditParty,
                      pixKey: transactionDetails.pixTransaction.creditParty.key,
                    }}
                    debitParty={transactionDetails.pixTransaction.debitParty}
                    transactionInfo={{
                      amount: transactionDetails.billingAmountAuthorized,
                      transactionAt: transactionDetails.effectiveAt.toString(),
                      type: TypePayment.PIX,
                      endToEndId:
                        transactionDetails.pixTransaction.receipt?.endToEndId,
                    }}
                    internalErrorCode={transactionDetails?.internalErrorCode}
                  />
                )}

                {transactionDetails.boletoTransaction && (
                  <PaymentVoucher
                    creditParty={{
                      name: transactionDetails.boletoTransaction.payee,
                      document:
                        transactionDetails.boletoTransaction.payeeDocument,
                    }}
                    debitParty={{
                      name: transactionDetails.boletoTransaction.payer,
                      document:
                        transactionDetails.boletoTransaction.payerDocument,
                    }}
                    transactionInfo={{
                      ...transactionDetails.boletoTransaction,
                      amount: transactionDetails.billingAmountAuthorized,
                      transactionAt: transactionDetails.effectiveAt.toString(),
                      type: TypePayment.BANK_SLIP,
                    }}
                    internalErrorCode={transactionDetails?.internalErrorCode}
                  />
                )}

                {transactionDetails.cardTransaction && (
                  <PaymentVoucher
                    creditParty={{
                      name: transactionDetails.cardTransaction.merchantName,
                      location: `${transactionDetails.cardTransaction.merchantCity} - ${transactionDetails.cardTransaction.merchantCountry}`,
                    }}
                    debitParty={{
                      name: t('payment-voucher.card-id', {
                        cardId:
                          transactionDetails.paymentInfo.panMasked.slice(-4),
                      }),
                    }}
                    transactionInfo={{
                      amount: transactionDetails.billingAmountAuthorized,
                      transactionAt: transactionDetails.effectiveAt.toString(),
                      type: TypePayment.CREDIT_CARD,
                      balanceCategory: transactionDetails.balanceCategory,
                    }}
                    internalErrorCode={transactionDetails?.internalErrorCode}
                  />
                )}

                {transactionDetails?.p2pTransaction && (
                  <PaymentVoucher
                    transactionInfo={{
                      balanceCategory: transactionDetails?.balanceCategory,
                      amount: transactionDetails.p2pTransaction.txnAmount,
                      transactionAt:
                        transactionDetails?.effectiveAt?.toString(),
                      type: TypePayment.P2P,
                    }}
                    internalErrorCode={transactionDetails?.internalErrorCode}
                  />
                )}
              </DownloadPdf.Content>
            </DownloadPdf>
          )}

          <Button
            type="submit"
            size="large"
            data-testid="complete"
            isLoading={isLoading}
            disabled={loadingGetDetails}
          >
            {transactionDetails.internalErrorCode
              ? t('general.button.close')
              : t('general.save')}
          </Button>
        </SidebarFooter>
      </form>
    </Sidebar>
  );
};
