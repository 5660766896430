import { TransactionScheduleStatus } from '@enums/SchedulePayment.enum';
import { customerEvents } from '@lib/customerIo';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { Skeleton } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useFindScheduleByIdQuery } from '@services/scheduling';
import { moneyMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../../alerts';
import { SidebarSection } from '../../../sidebarSection';
import { PaymentVoucher } from '../../PaymentVoucher';
import { ScheduleVoucherProps } from '../voucher.interfaces';

export const P2PTransferScheduleVoucher = ({
  scheduleId,
  walletId,
}: ScheduleVoucherProps) => {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const { data, isFetching: isLoading } = useFindScheduleByIdQuery({
    scheduleId,
    walletId,
  });

  const customerIoEvent = () => {
    if (!data) return;

    customerEvents.walletBalanceScheduleReceiptDownloaded({
      walletId: data?.wallet,
      amount: data?.amount,
      paymentDate: data?.scheduledAt,
      scheduleId: data?.id,
    });
  };

  return (
    <PaymentVoucher
      onClose={closeDrawer}
      title={`recibo-agendamento-transfer-${dayjs().format('DD/MM/YYYY')}.pdf`}
      onFinishDownload={customerIoEvent}
      className="flex flex-col gap-2"
      disabled={isLoading}
    >
      {data?.status === TransactionScheduleStatus.SCHEDULED && !isLoading && (
        <Alert status="success">
          <p className="text-p2 text-neutral-400">
            Sua transferência foi agendada com sucesso!
          </p>

          <p className="text-p2 text-neutral-100">
            Transferência de{' '}
            <strong className="text-p2 text-success-500">
              {moneyMask(data?.amount)}
            </strong>{' '}
            para {data?.additionalInfos?.p2pTransfer?.payeeName} agendada para{' '}
            {dayjs(data?.scheduledAt).utc(false).format('DD/MM/YYYY')}.
          </p>
        </Alert>
      )}

      {data?.status === TransactionScheduleStatus.CANCELLED && !isLoading && (
        <Alert status="error">
          <p className="text-p2 text-neutral-100">Agendamento cancelado</p>
          <p className="text-p2 text-danger-500">
            Agendamento no valor de{' '}
            <strong className="text-p2 text-danger-500">
              {moneyMask(data?.amount)}
            </strong>{' '}
            cancelado por {data?.cancelledByName} em{' '}
            {dayjs(data?.cancelledAt).format('DD/MM/YYYY')} às{' '}
            {dayjs(data?.cancelledAt).format('HH:mm')}
          </p>
        </Alert>
      )}

      <Skeleton isLoading={isLoading} height={80} />

      <div className="grid grid-cols-2 gap-4">
        <ValueWrapper
          isLoading={isLoading}
          label={t('general.scheduled-to')}
          value={
            data?.scheduledAt &&
            dayjs(data?.scheduledAt).utc(false).format('DD/MM/YYYY')
          }
        />

        <ValueWrapper
          isLoading={isLoading}
          label={t('payment-voucher.transfer-type')}
          value={t('general.transfer')}
        />
      </div>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('general.origin')}
        subTitleSection={t('payment-voucher.origin')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper
            isLoading={isLoading}
            label={t('general.wallet')}
            value={data?.additionalInfos?.p2pTransfer?.payerWalletName}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('general.category')}
            value={t(
              `enum.WalletBalanceCategory.${data?.additionalInfos?.p2pTransfer?.payerCategory}`,
              '-'
            )}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('general.type')}
            value={t(
              `enum.WalletType.${data?.additionalInfos?.p2pTransfer?.payerWalletType}`,
              '-'
            )}
          />
        </div>
      </SidebarSection>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('general.destination')}
        subTitleSection={t('payment-voucher.destination-info')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper
            isLoading={isLoading}
            label={t('general.wallet')}
            value={data?.additionalInfos?.p2pTransfer?.payeeWalletName}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('general.category')}
            value={t(
              `enum.WalletBalanceCategory.${data?.additionalInfos?.p2pTransfer?.payeeCategory}`,
              '-'
            )}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('general.type')}
            value={t(
              `enum.WalletType.${data?.additionalInfos?.p2pTransfer?.payeeWalletType}`,
              '-'
            )}
          />
        </div>
      </SidebarSection>
    </PaymentVoucher>
  );
};
