import { Button, Icon, Typography } from '..';
import { DropDownListProps } from './DropDownList.interface';
import './DropDownList.styles.scss';

export const DropDownList = ({
  list,
  hideDropDown,
  ...props
}: DropDownListProps) => {
  const triggerActions = (action: (() => void) | null) => {
    action && action();
    hideDropDown && hideDropDown();
  };
  return (
    <div
      className="dropdown"
      {...props}
      onClick={(event) => {
        event.stopPropagation();
        document.body.click();
      }}
    >
      <div className="dropdown_content">
        <div
          className="dropdown_content_list"
          data-testid="dropdown_content_list"
        >
          {list.map((elem, index) => {
            return (
              <div className="dropdown_content_list_item" key={index}>
                <Button
                  size={'large'}
                  variant="tertiary"
                  className="navbar_button navbar_button_options"
                  key={index}
                  data-testid={`dropdown-${index}`}
                  onClick={() => {
                    triggerActions(elem?.action || null);
                  }}
                  disabled={elem.disabled}
                >
                  <span className="dropdown_link">
                    {elem.icon && (
                      <Icon size={'xlarge'}>
                        <i className={`${elem.icon}`} />
                      </Icon>
                    )}
                    <Typography tag={'p'} color="var(--product-neutral-n300)">
                      {elem.label}
                    </Typography>
                  </span>
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
