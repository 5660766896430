import { AlertProps } from './Alert.interface';
import './Alert.styles.scss';

export const Alert = ({ children, status }: AlertProps) => {
  const icon = {
    success: 'fa-regular fa-check-circle',
    error: 'fa-regular fa-circle-exclamation',
    info: 'fa-regular fa-circle-info',
    warning: 'fa-solid fa-triangle-exclamation',
  };

  return (
    <div className={`alert alert_${status}`} data-testid={`alert-${status}`}>
      <div className="alert_icon">
        <i className={`${icon[status]} alert_icon--${status}`}></i>
      </div>
      <div className="alert_content">{children}</div>
    </div>
  );
};
