import { WalletBalanceCategory } from '@enums/WalletEnum';

export const renderIconCategory = (category: string) => {
  switch (category) {
    case WalletBalanceCategory.FLEX_NATIONAL:
      return { icon: 'earth-americas', color: 'blue' };

    case WalletBalanceCategory.FLEX_INTERNATIONAL:
      return { icon: 'globe', color: 'blue' };

    case WalletBalanceCategory.FOOD:
      return { icon: 'utensils', color: 'yellow' };

    case WalletBalanceCategory.GAS:
      return { icon: 'gas-pump', color: 'green' };

    case WalletBalanceCategory.MOBILITY:
      return { icon: 'car', color: 'green' };

    case WalletBalanceCategory.TOLL:
      return { icon: 'ticket-simple', color: 'green' };

    case WalletBalanceCategory.ADS:
      return { icon: 'megaphone', color: 'purple' };

    case WalletBalanceCategory.SAAS:
      return { icon: 'cube', color: 'purple' };

    case WalletBalanceCategory.HOTEL:
      return { icon: 'hotel', color: 'green' };

    case WalletBalanceCategory.AIRLINES:
      return { icon: 'plane', color: 'green' };

    case WalletBalanceCategory.TRAVEL:
      return { icon: 'plane', color: 'green' };

    default:
      break;
  }
};
