import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { Tag } from '@modules/drawer/drawerSecurity/flows/components/Tag';
import { Button, Icon, Modal } from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { BarSecurityProgress } from './BarSecurityProgress';

export const ModalProtectAccount = () => {
  const { t } = useTranslation();
  const { openDrawer, closeModal } = useDrawer();

  const { hasPin, hasMfa } = useUser();

  const handleClose = () => {
    closeModal();
  };

  const handleGoToCreateMFA = () => {
    openDrawer('security', {
      flow: 'DEFAULT',
    });

    closeModal();
  };

  return (
    <Modal closeModal={handleClose} open title={t('auth.protect-account')}>
      <div className="flex max-w-xl flex-col gap-4 px-6 py-4">
        <BarSecurityProgress />

        <div className="border-neutral-40 flex flex-col gap-1 rounded-md border border-solid p-4">
          <div className="flex items-center gap-2">
            <Icon size="small">
              <i className={`${ICONS.fingerprint} fa-lg text-primary-500 xl`} />
            </Icon>

            <h3 className="text-p2 flex-1 text-neutral-300">
              {t('auth.active-mfa-title')}
            </h3>
            <Tag status={hasMfa ? 'active' : 'pending'} />
          </div>

          <span className="text-p3 text-neutral-100">
            {t('auth.active-mfa-description')}
          </span>

          <span className="text-p3 text-neutral-100">
            {t('auth.have-your-phone-handy')}
          </span>
        </div>

        <div className="border-neutral-40 flex flex-col gap-1 rounded-md border border-solid p-4">
          <div className="flex items-center gap-2">
            <Icon size="small">
              <i
                className={`${ICONS['shield-keyhole']} fa-lg text-primary-500 xl`}
              />
            </Icon>

            <h3 className="text-p2 flex-1 text-neutral-300">
              {t('auth.active-pin-title')}
            </h3>
            <Tag status={hasPin ? 'active' : 'pending'} />
          </div>

          <span className="text-p3 text-neutral-100">
            {t('auth.transaction-pin-header-description')}
          </span>
        </div>
      </div>

      <footer className="footer_modal">
        <Button
          size="large"
          data-testid="skip-security-button"
          variant="secondary"
          onClick={handleClose}
        >
          {t('general.skip')}
        </Button>

        <div style={{ marginLeft: 10 }}>
          <Button
            size="large"
            data-testid="increase-security-button"
            variant="primary"
            onClick={handleGoToCreateMFA}
          >
            {t('auth.increase-security')}
          </Button>
        </div>
      </footer>
    </Modal>
  );
};
