import { useCallback, useEffect, useState } from 'react';
import { Button, Icon, Modal, Typography } from '..';
import { SidebarProps } from './Sidebar.interface';

import { confirmationModal } from '../../constants/modal';
import './Sidebar.styles.scss';

export const Sidebar = ({
  children,
  title,
  icon,
  hasModal = false,
  onClose,
  open,
  modalText = confirmationModal,
  actionModal = false,
  setActionModal,
  size = 'small',
}: SidebarProps) => {
  const [openModal, setOpenModal] = useState(false);

  const closeSidebar = useCallback(() => {
    if (hasModal) {
      setOpenModal(true);
    } else {
      onClose();
    }
  }, [hasModal, onClose]);

  useEffect(() => {
    actionModal && closeSidebar();
  }, [closeSidebar, actionModal, hasModal]);

  useEffect(() => {
    const closeSideBarClickingEsc = (event: KeyboardEvent) => {
      event.key === 'Escape' && closeSidebar();
    };

    document.addEventListener('keydown', closeSideBarClickingEsc);
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('keydown', closeSideBarClickingEsc);
      document.documentElement.style.overflow = 'scroll';
    };
  }, [closeSidebar]);

  return (
    <>
      {hasModal && openModal && (
        <Modal
          title={modalText?.titleModal}
          open={hasModal && openModal}
          closeModal={setOpenModal}
        >
          <div style={{ width: 500, padding: '32px' }}>
            <Typography tag="p" weight="p1" color="#A5A5B8">
              {modalText?.textModal}
            </Typography>
            <br />
            <Typography tag="p" weight="p1" color="var(--product-neutral-n80)">
              {modalText?.confirmModal}
            </Typography>
          </div>

          <footer className="footer_modal">
            <div>
              <Button
                size="large"
                variant="tertiary"
                onClick={() => {
                  setActionModal && setActionModal(false);
                  setOpenModal(false);
                }}
              >
                Não
              </Button>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  setOpenModal(false);
                  onClose();
                }}
              >
                {modalText?.actionConfirm}
              </Button>
            </div>
          </footer>
        </Modal>
      )}

      <div
        data-testid="sidebar"
        className={`background_overlay--${open ? 'show' : 'hidden'}`}
      />
      <div
        className={`container_sidebar_children--${
          open ? 'show' : 'hidden'
        } container_size_${size}`}
      >
        <header className="container_sidebar_children_header">
          <button
            onClick={() => closeSidebar()}
            data-testid="container_sidebar_children_close_btn"
            name="close button"
            className="container_sidebar_children_close_button"
            data-bs-toggle="tooltip"
            title="Fechar"
          >
            <Icon size="xlarge">
              <i className="fa fa-close" />
            </Icon>
          </button>
          <div className="container_sidebar_children_header_icon_container">
            <Icon size="xlarge">
              <i className={icon}></i>
            </Icon>
          </div>
          <Typography tag="h4" color="var(--product-neutral-n500)">
            {title}
          </Typography>
        </header>
        <div className="sidebar_content">{children}</div>
      </div>
    </>
  );
};
