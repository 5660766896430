import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import {
  CardItem,
  CardListParameters,
  CardProps,
} from '@interfaces/Card.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { findCardList } from '@services/cards';
import { cardKeys } from './cardKeys';

export const useInfiniteCardListQuery = (props: CardListParameters) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<CardProps, ApiError, CardItem[], unknown[], string>({
    queryKey: cardKeys.list(props),
    queryFn: ({ pageParam }) => findCardList({ ...props, nextPage: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage?.next && lastPage.next !== '' ? lastPage?.next : null;
    },
    initialPageParam: '',
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    retry: 1,
    placeholderData: keepPreviousData,
    select: (data) => data.pages.flatMap((page) => page.items),
    enabled: !!organizationId,
  });
};
