import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { PixPaymentResponse } from '@interfaces/Pix.interface';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { useDrawer } from '@providers';
import { documentMask, moneyMask } from '@utils/formatMasks';
import { Alert } from '../../../alerts';
import { SidebarSection } from '../../../sidebarSection';
import { PaymentVoucher } from '../../PaymentVoucher';

export const PixPaymentVoucher = ({ data }: { data: PixPaymentResponse }) => {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  return (
    <PaymentVoucher
      onClose={closeDrawer}
      title={`recibo-pix-${dayjs().format('DD/MM/YYYY')}.pdf`}
      className="flex flex-col gap-2"
    >
      <Alert status="success">
        <p className="text-p2 text-neutral-400">
          {t('payment-voucher.pix-success')}
        </p>
        <p className="text-p2 text-neutral-100">
          Transferência de{' '}
          <strong className="text-p2 text-success-500">
            {moneyMask(data?.confirmedAmount)}
          </strong>{' '}
          para {data?.creditParty?.name} realizada em{' '}
          {dayjs(data?.createdAt).utc(true).format('DD/MM/YYYY')} às{' '}
          {dayjs(data?.createdAt).utc(true).format('HH:mm')}.
        </p>
      </Alert>

      <div className="grid grid-cols-2 gap-4">
        <ValueWrapper
          label={t('general.date')}
          value={dayjs().utc(false).format('DD/MM/YYYY')}
        />
        <ValueWrapper
          label={t('payment-voucher.transfer-type')}
          value={t('general.pix')}
        />
      </div>

      <hr className="hr" />

      {data?.dueDate && (
        <>
          <SidebarSection
            titleSection={t('general.payment-details')}
            subTitleSection={t('payment-voucher.see-pix-data')}
          >
            <div className="grid grid-cols-2 gap-4">
              <ValueWrapper
                label={t('payment-voucher.total-to-pay')}
                value={moneyMask(data?.txnUpdatedAmount)}
              />
              <ValueWrapper
                label={t('payment-voucher.due-date')}
                value={dayjs(data?.dueDate).utc(false).format('DD/MM/YYYY')}
              />
              <ValueWrapper
                label={t('payment-voucher.expired-date')}
                value={
                  data?.expiresAt &&
                  dayjs(data?.expiresAt).utc(false).format('DD/MM/YYYY')
                }
              />
              <ValueWrapper
                label={t('general.original-amount')}
                value={moneyMask(data?.txnOriginalAmount)}
              />
              <ValueWrapper
                label={t('general.discounts')}
                value={moneyMask(data?.txnDiscountAmount)}
              />
              <ValueWrapper
                label={t('general.interest')}
                value={moneyMask(data?.txnInterestAmount)}
              />
              <ValueWrapper
                label={t('general.fine')}
                value={moneyMask(data?.txnFineAmount)}
              />
            </div>
          </SidebarSection>

          <hr className="hr" />
        </>
      )}

      <SidebarSection
        titleSection={t('payment-voucher.your-account')}
        subTitleSection={t('payment-voucher.origin-account')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper label={'De'} value={data?.debitParty?.name} />

          <ValueWrapper
            label={'CPF/CNPJ'}
            value={documentMask(data?.debitParty?.document)}
          />
        </div>
      </SidebarSection>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('payment-voucher.destination-account')}
        subTitleSection={t('payment-voucher.destination-account-info')}
      >
        <div className="grid grid-cols-1 gap-4">
          <ValueWrapper label="Para" value={data?.creditParty?.name} />

          <ValueWrapper
            label="CPF/CNPJ"
            value={documentMask(data?.creditParty?.document)}
          />

          <ValueWrapper
            label={t('general.pix-key')}
            value={data?.creditParty?.key}
          />
        </div>
      </SidebarSection>
    </PaymentVoucher>
  );
};
