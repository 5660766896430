import { Typography } from '../typography/Typography';
import { AvatarProps } from './Avatar.intereface';
import './Avatar.styles.scss';

export const Avatar = ({
  imageSrc,
  isLoggedUser = false,
  userName = '',
  size = 'default',
}: AvatarProps) => {
  const generateInitialsName = () => {
    if (userName && userName !== '') {
      const arrayName = userName.split(' ');
      const firstNameLetter = arrayName[0].slice(0, 1);
      const lastNameLetter =
        arrayName.length > 1 ? arrayName[arrayName.length - 1].slice(0, 1) : '';

      return firstNameLetter.concat(lastNameLetter).toUpperCase();
    } else {
      return 'N/A';
    }
  };

  const hasAvatarImage = imageSrc && imageSrc !== '';
  const userInitials = generateInitialsName();

  return (
    <>
      {hasAvatarImage && (
        <img
          className={`avatar ${
            isLoggedUser && 'avatar_grey_edge'
          } avatar--${size}`}
          src={imageSrc}
          alt="avatar"
        />
      )}
      {userInitials && !hasAvatarImage && (
        <div className={`avatar avatar_initials avatar--${size}`}>
          <Typography tag={'p'} color="var(--product-primary-p500)">
            {userInitials}
          </Typography>
        </div>
      )}
    </>
  );
};
