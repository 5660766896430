import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { useV4Validator } from '@hooks';
import { Customer } from '@interfaces/Customer.interfaces';
import { Avatar, Button, Icon } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { cnpjMask, cpfMask } from '@utils/formatMasks';
import { useNavigate } from 'react-router-dom';

export interface CostomersColumnsProps {
  (customerId: string, name: string, document: string): void;
}

export const useCustomersColumns = (
  onDeleteCustomer: CostomersColumnsProps
): ColumnDef<Customer>[] => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const { isFilialV4 } = useV4Validator();
  const history = useNavigate();

  return [
    {
      accessorKey: '',
      header: ' ',
      cell: ({ row }) => {
        return <Avatar imageSrc="" userName={row.original.name} size="small" />;
      },
      meta: {
        width: 50,
      },
    },
    {
      accessorKey: 'name',
      header: t('customers.customer-name'),
    },
    {
      accessorKey: 'trandingName',
      header: t('general.company'),
      cell: ({ cell }) => {
        const name = cell.getValue<string>();
        return name ? name : '-';
      },
    },
    {
      accessorKey: 'document',
      header: t('general.cpf/cnpj'),
      meta: {
        width: 'auto',
      },
      cell: ({ cell }) => {
        const document = cell.getValue<string>();
        return document.length > 11 ? cnpjMask(document) : cpfMask(document);
      },
    },
    // {
    //   accessorKey: 'status',
    //   header: t('general.status'),
    //   cell: ({ cell }) => {
    //     const status = cell.getValue<StatusEnum>();
    //     return <Status status={status}>{StatusDescriptionEnum[status]}</Status>;
    //   },
    // },
    {
      accessorKey: '',
      header: t('general.actions'),
      cell: ({ cell }) => {
        const id = cell.row.id;
        return (
          <div className="flex flex-row gap-2">
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              onClick={(event) => {
                event.stopPropagation();
                history(`/customers/${id}`);
              }}
              data-testid={`view-user-${id}`}
            >
              <Icon size="medium" key="view-user-button">
                <i className="fa-regular fa-eye fa-1x text-neutral-100" />
              </Icon>
            </Button>

            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              onClick={(event) => {
                event.stopPropagation();
                openDrawer('create-payer-client', { customerId: id });
              }}
              data-testid={`edit-user`}
            >
              <Icon size="medium" key="edit-user-button">
                <i className="fa-regular fa-pencil fa-1x text-neutral-100" />
              </Icon>
            </Button>
            {!isFilialV4 && (
              <Button
                type="button"
                variant="tertiary"
                size="small"
                className="w-6"
                data-testid={`deactivate-user`}
                onClick={(event) => {
                  event.stopPropagation();
                  onDeleteCustomer(
                    id,
                    cell.row.original.name,
                    cell.row.original.document
                  );
                }}
              >
                <Icon size="medium" key="remove-user-button">
                  <i className="fa-regular fa-trash fa-1x text-neutral-100" />
                </Icon>
              </Button>
            )}
          </div>
        );
      },
    },
  ];
};
