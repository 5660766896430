import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { StatementReportFileType } from '@enums/StatementReportEnum';
import { Button, Dialog, DropDownList, Spinner } from '@portao3-web/ui';
import { useStatementReportMutation } from '@services/organization/hooks';

interface ExportButtonProps {
  startDate?: string | null;
  endDate?: string | null;
  walletId?: string;
}

export const ExportStatementButton = ({
  endDate,
  startDate,
  walletId,
}: ExportButtonProps) => {
  const { t } = useTranslation();

  const { mutate: getStatementReport, isPending } =
    useStatementReportMutation();

  return (
    <Dialog variant="popover">
      <Dialog.Trigger>
        <Button
          size="large"
          variant="primary"
          disabled={isPending}
          data-testid="export-button-statement"
        >
          {isPending && <Spinner />}
          {t('general.export')}
          <i className={`${ICONS['chevron-down']} fa-1x`} />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <DropDownList
          list={[
            {
              icon: '',
              label: 'CSV',
              action: () => {
                getStatementReport({
                  type: StatementReportFileType.CSV,
                  startDate,
                  endDate,
                  walletId,
                });
              },
            },
            {
              icon: '',
              label: 'XLSX',
              action: () => {
                getStatementReport({
                  type: StatementReportFileType.XLSX,
                  startDate,
                  endDate,
                  walletId,
                });
              },
            },
            {
              icon: '',
              label: 'OFX',
              action: () => {
                getStatementReport({
                  type: StatementReportFileType.OFX,
                  startDate,
                  endDate,
                  walletId,
                });
              },
            },
          ]}
        />
      </Dialog.Content>
    </Dialog>
  );
};
