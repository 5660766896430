import { CardTypeEnum } from '@enums/Card.enum';
import { CardItem } from '@interfaces/Card.interfaces';
import { StatusType } from '@interfaces/Status.type';

export const initialStateCardList: CardItem[] = [
  {
    id: 0,
    name: '',
    panMasked: '',
    expiryDate: '',
    status: 'ACTIVE' as StatusType,
    singleUse: false,
    type: CardTypeEnum.VIRTUAL,
    createdBy: '',
    organization: '',
    realm: '',
    tokenisationPermitted: false,
    updatedBy: '',
    wallet: '',
    walletName: '',
    total: 0,
    ownerName: '',
  },
  {
    id: 1,
    name: '',
    panMasked: '',
    expiryDate: '',
    status: 'ACTIVE' as StatusType,
    singleUse: false,
    type: CardTypeEnum.VIRTUAL,
    createdBy: '',
    organization: '',
    realm: '',
    tokenisationPermitted: false,
    updatedBy: '',
    wallet: '',
    walletName: '',
    total: 0,
    ownerName: '',
  },
  {
    id: 2,
    name: '',
    panMasked: '',
    expiryDate: '',
    status: 'ACTIVE' as StatusType,
    singleUse: false,
    type: CardTypeEnum.VIRTUAL,
    createdBy: '',
    organization: '',
    realm: '',
    tokenisationPermitted: false,
    updatedBy: '',
    wallet: '',
    walletName: '',
    total: 0,
    ownerName: '',
  },
  {
    id: 3,
    name: '',
    panMasked: '',
    expiryDate: '',
    status: 'ACTIVE' as StatusType,
    singleUse: false,
    type: CardTypeEnum.VIRTUAL,
    createdBy: '',
    organization: '',
    realm: '',
    tokenisationPermitted: false,
    updatedBy: '',
    wallet: '',
    walletName: '',
    total: 0,
    ownerName: '',
  },
];

export const initialStateCardTransactions = [
  {
    _id: '',
    walletId: '',
    receiptsCount: 0,
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      linkedTransactions: [],
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: 0,
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
  {
    _id: '',
    walletId: '',
    receiptsCount: 0,
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      linkedTransactions: [],
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: 0,
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
  {
    _id: '',
    walletId: '',
    receiptsCount: 0,
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      linkedTransactions: [],
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: 0,
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
  {
    _id: '',
    walletId: '',
    receiptsCount: 0,
    cardTransaction: {
      txnAmountAuthorized: 0,
      localTime: new Date(),
      merchantName: '',
      txnCurrency: '',
      acquiringCountryCode: '',
      acquiringId: '0',
      txnAmount: 0,
      linkedTransactions: [],
      mcc: '',
      merchantCity: '',
      merchantCountry: '',
      merchantId: '',
      expired: false,
      cleared: false,
      overcleared: false,
      posEntryMode: '',
      reversed: false,
      _id: '',
    },
    pixTransaction: {
      description: '',
      txnCurrency: 0,
      txnOriginalAmount: 0,
      txnDiscountAmount: 0,
      txnFineAmount: 0,
      txnInterestAmount: 0,
      txnPurchaseAmount: 0,
      txnWithdrawalAmount: 0,
      txnUpdatedAmount: 0,
      txnUpdatedAmountAuthorized: 0,
      creditParty: {
        bankIspb: '',
        document: '',
        key: '',
        name: '',
        _id: '',
      },
      debitParty: {
        bankIspb: '',
        accountType: '',
        document: '',
        name: '',
        _id: '',
      },
    },
    financialImpactType: '',
    billingAmountAuthorized: 0,
    billingAmount: 0,
    createdAt: new Date(),
    effectiveAt: new Date(),
  },
];

export const thead = ['Data', '&nbsp;', 'Descrição', 'Valor'];
