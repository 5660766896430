import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertPix } from '@components';
import { ICONS } from '@constants/icons';
import { Button, Modal } from '@portao3-web/ui';
import { useUser } from '@providers/UserProvider';
import { handleOpenWpp } from '@utils/openWpp';
import { CardSupport } from './CardSuport';
import { useDrawer } from '../DrawerProvider';

export const ModalLimitAccess = () => {
  const { t } = useTranslation();
  const { closeModal } = useDrawer();
  const { email } = useUser();

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleOpenChat = () => {
    const atlasLauncher = document.querySelector(
      '[aria-label="atlas-launcher"]'
    ) as HTMLButtonElement;

    clearTimeout(timeoutRef.current);

    if (atlasLauncher) {
      atlasLauncher.click();
    } else {
      timeoutRef.current = setTimeout(handleOpenChat, 1000);
    }
  };

  return (
    <Modal open closeModal={closeModal} title={t('auth.limited-actions')}>
      <div className="flex max-w-xl flex-col px-6 py-4">
        <p className="text-p2 text-neutral-80 mb-4">
          {t('auth.modal-limit-actions-description')}
        </p>

        <AlertPix status="error">
          <div className="flex flex-col gap-2 mb-4">
            <p className="text-p1 text-danger-900">
              {t('auth.access-blocked-error-title')}
            </p>

            <p className="text-p2 text-neutral-100">
              {t('auth.access-blocked-error-description')}
            </p>
          </div>
        </AlertPix>

        <div>
          <h2 className="text-p2 font-bold text-neutral-100">
            {t('auth.access-block-instructions-title')}
          </h2>
          <p className="text-p2 text-neutral-80">
            {t('auth.access-block-instructions-description')}
          </p>
        </div>

        <div className="mt-4 flex gap-4">
          <CardSupport
            text={t('general.support-chat')}
            icon={ICONS.message}
            onClick={handleOpenChat}
          />

          <CardSupport
            text={t('general.support-wpp')}
            icon={ICONS.wpp}
            onClick={() =>
              handleOpenWpp(t('auth.reset-pin-message', { email }))
            }
          />
        </div>
      </div>

      <footer className="footer_modal">
        <Button size="large" onClick={closeModal}>
          {t('general.button.close')}
        </Button>
      </footer>
    </Modal>
  );
};
