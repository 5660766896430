import clsx from 'clsx';

import { SecurityOptions } from '@enums/Security.enum';
import { Icon } from '@portao3-web/ui';
import { useUser } from '@providers';
import { useTranslation } from 'react-i18next';
import { Tag } from '../components/Tag';

type StatusType = 'active' | 'pending' | 'blocked';

interface SecurityCardsProps {
  type: SecurityOptions;
  status: StatusType;
}

export const HeaderFlowCard = ({ type, status }: SecurityCardsProps) => {
  const { t } = useTranslation();
  const { hasPin, hasMfa } = useUser();

  const securityItems = {
    [SecurityOptions.MFA]: {
      icon: 'fa-solid fa-fingerprint',
      title: t('auth.mfa-title'),
      description: hasMfa
        ? t('auth.mfa-description')
        : t('auth.mfa-header-card-description'),
    },
    [SecurityOptions.PIN]: {
      icon: 'fa-solid fa-shield-keyhole',
      title: t('auth.transaction-pin-title'),
      description: hasPin
        ? t('auth.transaction-pin-description')
        : t('auth.transaction-pin-header-description'),
    },
  };

  return (
    <div className="border-neutral-40 flex flex-col gap-1 rounded-md border border-solid p-4">
      <div className="flex items-center gap-2">
        <Icon size="small">
          <i
            className={clsx({
              [securityItems[type].icon]: true,
              'fa-lg text-primary-500 xl': true,
            })}
          />
        </Icon>

        <h3 className="text-p2 flex-1 text-neutral-300">
          {securityItems[type].title}
        </h3>

        <div className="flex gap-2">
          <Tag status={status} />
        </div>
      </div>

      <span className="text-p3 text-neutral-100">
        {securityItems[type].description}
      </span>
    </div>
  );
};
