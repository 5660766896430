import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

import {
  CreateBatchPayment,
  BatchTransactionResponse,
} from '@interfaces/BatchPayments.interfaces';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

export const createBatchPayment = async ({
  payload,
  walletId,
}: CreateBatchPayment): Promise<BatchTransactionResponse> => {
  removeEmptyObjectAttributes(payload);

  const { data } = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.batchPayment}`,
    payload
  );

  return data;
};
