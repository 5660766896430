import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateCustomer, Customer } from '@interfaces/Customer.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { createCustomer } from '../createCustomer';
import { customerKeys } from './customerKeys';

export const useCreateCustomerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<Customer, ApiError, CreateCustomer, unknown>({
    mutationFn: (params: CreateCustomer) => createCustomer(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customerKeys.lists() });
    },
  });
};
