import { environment } from '@environments/environment';
import { AuthToken } from '@interfaces/Auth.interfaces';
import CryptoJS from 'crypto-js';
import forge from 'node-forge';

const encrypt = (value: string) => {
  const encrypted = CryptoJS.AES.encrypt(
    value,
    environment.secretCryptoKey
  ).toString();
  return encrypted;
};

const decrypt = (encryptedValue: string) => {
  const bytes = CryptoJS.AES.decrypt(
    encryptedValue,
    environment.secretCryptoKey
  );
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export const encryptToken = (token: AuthToken) => {
  const parsedToken = { ...token };

  parsedToken.refreshToken = encrypt(parsedToken.refreshToken);
  parsedToken.accessToken = encrypt(parsedToken.accessToken);

  return parsedToken;
};

export const decryptToken = (token: AuthToken) => {
  const parsedToken = { ...token };

  parsedToken.refreshToken = decrypt(parsedToken.refreshToken);
  parsedToken.accessToken = decrypt(parsedToken.accessToken);

  return parsedToken;
};

export const encryptPin = (pin: string) => {
  if (pin && pin.length === 4) {
    const decoded = atob(environment.secretCryptoPin);
    const publicKey = forge.pki.publicKeyFromPem(decoded);
    const encrypted = publicKey.encrypt(pin, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });

    return btoa(encrypted);
  }

  return pin;
};
