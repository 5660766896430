import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { UserResponse } from '@interfaces/User.interfaces';
import { useAuth, useDrawer, useUser } from '@providers';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { userKeys } from '@services/user/hooks';
import { encryptPin } from '@utils/crypto';

export const useAuthentication = () => {
  const { hasPin, pinErrorsCount } = useUser();
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [pin, setPin] = useState('');
  const queryClient = useQueryClient();
  const [hasError, setHasError] = useState(false);
  const { closeDrawer } = useDrawer();
  const { logout } = useAuth();

  function authSubmit(onSubmit: () => void) {
    if (hasPin && !isAuthentication) {
      return setIsAuthentication(true);
    }

    onSubmit();
  }

  const onAuthError = (error: ApiErrorPin) => {
    if (!hasPin) return;

    const pinErrorsCount = error?.response?.data?.pinErrorsCount;
    setPin('');

    if (pinErrorsCount >= 3) {
      closeDrawer();
      logout('/login?blocked=true');
    }

    if (pinErrorsCount && pinErrorsCount < 3) {
      queryClient.setQueryData(userKeys.current(), (oldData: UserResponse) => {
        return {
          ...oldData,
          pinErrorsCount,
        };
      });

      setHasError(true);

      return true;
    }

    if (!pinErrorsCount) {
      queryClient.setQueryData(userKeys.current(), (oldData: UserResponse) => {
        return {
          ...oldData,
          pinErrorsCount: 0,
        };
      });
    }

    setIsAuthentication(false);
    setPin('');
    return false;
  };

  const onClose = () => {
    setIsAuthentication(false);
    setPin('');
  };

  const onAuthSuccess = () => {
    if (!hasPin) return;

    queryClient.setQueryData(userKeys.current(), (oldData: UserResponse) => {
      return {
        ...oldData,
        pinErrorsCount: 0,
      };
    });

    setIsAuthentication(false);
    setPin('');
  };

  const handleUpdatePin = (value: string) => {
    setPin(value);
    setHasError(false);
  };

  return {
    pin: encryptPin(pin),
    isAuthentication,
    authSubmit,
    onAuthError,
    onAuthSuccess,
    setIsAuthentication,
    controller: {
      pinErrorsCount,
      error: hasError,
      setPin: handleUpdatePin,
      pin,
      isOpen: isAuthentication,
      onClose,
    },
  };
};
