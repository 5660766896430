import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import {
  CreateStatementProps,
  InvoiceStatementProps,
} from '@interfaces/Invoices.interfaces';
import api from '@services/api';

export interface UseFindStatementByIdParams {
  invoiceId: string;
  statementId: string;
}

export const findStatementsInvoiceList = async (
  invoiceId: string,
  buyer = false
): Promise<ListResponse<InvoiceStatementProps>> => {
  const roles = buyer ? '?role=BUYER' : '';
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/statements${roles}`
  );
  const { data } = response;

  return data;
};

export const createAdjustmentStatement = async (
  invoiceId: string,
  payload: CreateStatementProps
): Promise<ListResponse<InvoiceStatementProps>> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/statements`,
    payload
  );
  const { data } = response;

  return data;
};

export const findStatementById = async ({
  invoiceId,
  statementId,
}: UseFindStatementByIdParams): Promise<InvoiceStatementProps> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.invoices
    }/${invoiceId}/statements/${statementId}`
  );

  const { data } = response;

  return data;
};
