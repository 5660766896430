import { Button, Input, Skeleton, Spinner, Typography } from '@portao3-web/ui';
import { useEffect, useRef } from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

import { useDrawer, useUser } from '@providers';
import { useBillingQuery } from '@services/billing/hooks/useBillingQuery';

import { Alert, SidebarFooter, SidebarSection } from '@components';

import { SECOND_IN_MILLISECONDS } from '@constants/time';
import { copyToClipboard } from '@utils/copyClipboard';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask, cpfMask } from '@utils/formatMasks';

import { useWalletQuery } from '@services/wallet/hooks';

import { BillingStatusEnum } from '@enums/Billing.enum';
import { PixKey, PixKeyType } from '@interfaces/Pix.interface';

import { useDownloadBillingMutation } from '@services/billing/hooks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ValueWrapper } from './ValueWrapper';
import './components.scss';

export const BillingDetail = () => {
  const { t } = useTranslation();
  const organizationId = localStorage.getItem('organization') ?? '';

  const { params, closeDrawer } = useDrawer();
  const { organizations } = useUser();

  const timerRef = useRef<NodeJS.Timeout>();
  const isBankSlip = params?.flow === 'bank-slip';

  const { mutate: handleGenerateBillingFile, isPending: isDownloadingBilling } =
    useDownloadBillingMutation();

  const {
    data: billing,
    isLoading: isLoadingBilling,
    isRefetching: isRefetchingBilling,
    refetch: refetchBilling,
  } = useBillingQuery(params?.walletId || '', params?.billingId || '');

  useEffect(() => {
    if (billing?.status === BillingStatusEnum.PROCESSING) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        refetchBilling();
      }, 2 * SECOND_IN_MILLISECONDS);
      return;
    }

    if (isBankSlip && !billing?.hasPdfCreated) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        refetchBilling();
      }, 10 * SECOND_IN_MILLISECONDS);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [billing, isRefetchingBilling, refetchBilling, isBankSlip]);

  const { data: wallet, isLoading: isWalletLoading } = useWalletQuery({
    walletId: params?.walletId || '',
    organizationId,
    enabled: !isBankSlip,
  });

  const clipboardBillingValue = isBankSlip
    ? billing?.digitableLine || ''
    : billing?.billingCode || '';
  const billingCode = billing?.billingCode || '';

  const handleCopyBillingCode = () => {
    copyToClipboard(clipboardBillingValue);
  };

  const getPixDict = (dict?: PixKey) => {
    if (!dict) return '';
    if (dict.type === PixKeyType.CNPJ) {
      return cnpjMask(dict.value);
    }
    return dict.value;
  };

  const getDocument = () => {
    const document = organizations?.find(
      (org) => org.id === organizationId
    )?.document;

    if (!document) return '';

    return document.length === 11 ? cpfMask(document) : cnpjMask(document);
  };

  return (
    <>
      {isLoadingBilling || billing?.status === BillingStatusEnum.PROCESSING ? (
        <div className="loading-spinner">
          <Spinner size="large" />

          <Typography tag="p" color="var(--product-neutral-n500)">
            {t('general.processing')}...
          </Typography>
        </div>
      ) : (
        <SidebarSection
          titleSection={t('billing.billing-details')}
          subTitleSection={t('billing.who-receive-value')}
        >
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              {!isBankSlip && (
                <ValueWrapper
                  isLoading={isWalletLoading}
                  label="Chave Pix"
                  value={getPixDict(wallet?.dict) || '-'}
                />
              )}

              <div className="description-wrapper">
                {!isBankSlip && (
                  <>
                    <ValueWrapper
                      isLoading={isLoadingBilling}
                      label="CPF/CNPJ"
                      value={getDocument()}
                    />

                    <ValueWrapper
                      isLoading={isLoadingBilling}
                      label="Instituição"
                      value="Swap"
                    />
                  </>
                )}

                <ValueWrapper
                  isLoading={isLoadingBilling}
                  label={t('general.due-date')}
                  value={dayjs(billing?.dueDate).format('DD/MM/YYYY') || '-'}
                />

                <ValueWrapper
                  isLoading={isLoadingBilling}
                  label={t('general.value')}
                  value={calcCurrencyValue(billing?.amount || 0, 'BRL')}
                />

                {billing?.fine && (
                  <ValueWrapper
                    isLoading={isLoadingBilling}
                    label={t('general.fine')}
                    value={calcCurrencyValue(billing.fine.value, 'BRL')}
                  />
                )}

                {billing?.interest && (
                  <ValueWrapper
                    isLoading={isLoadingBilling}
                    label={t('general.interest')}
                    value={`${billing?.interest.value * 100}% ao dia`}
                  />
                )}
              </div>

              <div className="description-wrapper">
                <ValueWrapper
                  isLoading={isLoadingBilling}
                  label={t('general.status')}
                  value={t(
                    `status.${billing?.status.toLowerCase().replace('_', '-')}`,
                    ''
                  )}
                />

                <ValueWrapper
                  isLoading={isLoadingBilling}
                  label={t('billing.received-value')}
                  value={calcCurrencyValue(billing?.amountPaid || 0)}
                />
              </div>

              {billing?.description && (
                <ValueWrapper
                  isLoading={isLoadingBilling}
                  label={t('general.description')}
                  value={billing?.description}
                />
              )}
            </div>

            <div className="flex flex-col">
              <Skeleton isLoading={isLoadingBilling} height={100}>
                <div className="barcode-container">
                  {isBankSlip && (
                    <div className="barcode">
                      <Barcode value={billingCode} format="ITF" />
                    </div>
                  )}

                  {!isBankSlip && (
                    <div data-testid="pix-wrapper" className="pix-wrapper">
                      <QRCode size={178} value={billingCode} />
                    </div>
                  )}
                </div>
              </Skeleton>

              <Skeleton isLoading={isLoadingBilling} height={60}>
                <div className="flex flex-col">
                  <Input
                    name="some"
                    register={() => ({})}
                    readOnly
                    data-testid="billing-code"
                    type="text"
                    label={
                      isBankSlip
                        ? t('general.digitable-line')
                        : t('general.qr-code')
                    }
                    value={clipboardBillingValue}
                  />

                  <div className="ml-auto mb-4 flex flex-row gap-8">
                    <Button
                      variant="secondary"
                      size="small"
                      className="w-max"
                      onClick={() =>
                        handleGenerateBillingFile({
                          billingId: params?.billingId ?? '',
                          walletId: params?.walletId ?? '',
                        })
                      }
                      disabled={
                        (isBankSlip && !billing?.hasPdfCreated) ||
                        isDownloadingBilling
                      }
                    >
                      {isDownloadingBilling ? (
                        <Spinner />
                      ) : isBankSlip ? (
                        t('billing.download-bank-slip')
                      ) : (
                        t('billing.download-qr-code')
                      )}
                    </Button>

                    <Button
                      size="small"
                      className="w-max"
                      onClick={handleCopyBillingCode}
                    >
                      {t('billing.copy-code')}
                    </Button>
                  </div>

                  {isBankSlip && !billing?.hasPdfCreated && (
                    <Alert status="info">
                      <Typography
                        tag="p"
                        weight="p3"
                        className="ml-auto"
                        color="var(--product-neutral-n500)"
                      >
                        {t('billing.bank-slip-not-created')}
                      </Typography>
                    </Alert>
                  )}
                </div>
              </Skeleton>
            </div>
          </div>
        </SidebarSection>
      )}

      <SidebarFooter>
        <Button size="large" onClick={closeDrawer}>
          {t('button.finish')}
        </Button>
      </SidebarFooter>
    </>
  );
};
