import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { Balance } from '@interfaces/Balance.interfaces';
import api from '@services/api';

// TODO - refactor into getWalletData and remove this function
export const getBalance = async (walletId?: string): Promise<Balance> => {
  const hasWalletId = walletId || 'default';
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${hasWalletId}`
  );
  const { data } = response;
  return data;
};
