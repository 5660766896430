import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { StatusDescriptionEnum } from '@enums/Status.enum';
import { useFilterColumns } from '@hooks/useFilterColumns';
import {
  WalletDetailsProps,
  WalletRole,
  WalletStatus,
} from '@interfaces/Wallets.interfaces';
import { walletTypes } from '@pages/wallets/constants/wallets';
import {
  Avatar,
  Button,
  Dialog,
  DropDownList,
  Icon,
  Status,
  TableDate,
  WalletType,
} from '@portao3-web/ui';
import { useDrawer, UserContext, useUser } from '@providers';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { walletPermission } from '@utils/walletPermission';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface WalletRowProps {
  setWallet: Dispatch<SetStateAction<WalletDetailsProps>>;
  setOpenActionModal: Dispatch<
    SetStateAction<{
      open: boolean;
      action: string;
    }>
  >;
}

export const useWalletColumns = ({
  setOpenActionModal,
  setWallet,
}: WalletRowProps) => {
  const { t } = useTranslation();

  const { isAdmin } = useUser();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { openDrawer } = useDrawer();

  const getActions = (wallet: WalletDetailsProps) => {
    const walletPath =
      wallet.type === WalletType.ORGANIZATION
        ? `/company`
        : `/wallets/${wallet.id}/geral`;

    const list = [
      {
        label: 'Visualizar',
        action: () => {
          navigate(walletPath);
        },
      },
    ];

    wallet.type !== WalletType.ORGANIZATION &&
      (isAdmin || walletPermission(wallet, user)) &&
      list.push(
        {
          label: 'Gerenciar saldo',
          action: () => {
            openDrawer('wallet-balance', { walletId: wallet.id });
          },
        },
        {
          label: 'Editar',
          action: () => {
            openDrawer('edit-wallet', { walletId: wallet.id });
          },
        },
        {
          label: 'Desativar',
          action: () => {
            setWallet(wallet);
            setOpenActionModal({ open: true, action: 'DEACTIVATE' });
          },
        },
        {
          label: 'Zerar saldo',
          action: () => {
            setWallet(wallet);
            setOpenActionModal({ open: true, action: 'RESET' });
          },
        }
      );

    return list;
  };

  const columns: ColumnDef<WalletDetailsProps>[] = [
    {
      accessorKey: 'name',
      header: t('general.wallet'),
      cell: ({ row }) => {
        const wallet = row.original;

        const isOrgWallet = wallet.type === WalletType.ORGANIZATION;

        return (
          <p className="text-p2">
            {wallet.name}{' '}
            {isOrgWallet && (
              <i className="fa-regular ml-1 text-p1 text-warning-600 fa-bank name-icon" />
            )}
          </p>
        );
      },
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'createdAt',
      header: t('general.created-date'),
      cell: ({ row }) => (
        <TableDate date={row.original?.createdAt?.toString()} />
      ),
    },
    {
      accessorKey: 'avatar-responsible',
      header: '',
      cell: ({ row }) => {
        const walletOwner = row.original?.shared?.find(
          (item) => item.role === WalletRole.OWNER
        );
        const ownerName = walletOwner?.firstName
          ? `${walletOwner?.firstName} ${walletOwner?.lastName}`
          : `-`;

        return (
          <Avatar
            className="pl-4"
            imageSrc=""
            userName={ownerName}
            size="small"
          />
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'responsible',
      header: t('general.responsible'),
      cell: ({ row }) => {
        const walletOwner = row.original?.shared?.find(
          (item) => item.role === WalletRole.OWNER
        );

        const ownerName = walletOwner?.firstName
          ? `${walletOwner?.firstName} ${walletOwner?.lastName}`
          : `-`;

        return ownerName;
      },
      meta: {
        width: 160,
      },
    },
    {
      accessorKey: 'type',
      header: t('general.type'),
      cell: ({ row }) => walletTypes[row.original.type],
      meta: {
        width: 160,
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'status',
      header: t('general.status'),
      cell: ({ row }) => {
        const status = row.original.status;
        return <Status status={status}>{StatusDescriptionEnum[status]}</Status>;
      },
      meta: {
        width: 160,
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'totalBalance',
      header: t('general.balance'),
      cell: ({ cell }) => calcCurrencyValue(cell.getValue<number>()),
      meta: {
        width: 160,
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: '',
      header: t('general.actions'),
      cell: ({ cell }) => {
        const wallet = cell.row.original;

        return (
          <Dialog
            variant="popover"
            className="flex justify-center items-center !w-full"
          >
            <Dialog.Trigger>
              <Button
                data-testid="buttonSelectActions"
                size="medium"
                variant="tertiary"
                className="w-6"
                disabled={wallet.status === WalletStatus.DEACTIVATED}
              >
                <Icon size="xlarge">
                  <i className="fa-regular fa-ellipsis-vertical" />
                </Icon>
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <DropDownList
                list={
                  wallet?.id !== 'default' &&
                  (isAdmin || walletPermission(wallet, user))
                    ? getActions(wallet)
                    : getActions(wallet).filter((elem) =>
                        ['Visualizar'].includes(elem.label)
                      )
                }
              />
            </Dialog.Content>
          </Dialog>
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
  ];

  return useFilterColumns('wallet-columns', columns);
};
