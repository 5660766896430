import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { Icon, Typography } from '..';
import { Checkbox } from '../checkbox/Checkbox';
import { MultiSelectCheckboxProps } from './MultiCheckboxSelect.interface';
import './MultiCheckboxSelect.styles.scss';

export const MultiCheckboxSelect = ({
  id,
  label,
  options,
  disabled = false,
  setValues,
  placeholder = '',
  error,
  onFocus,
}: MultiSelectCheckboxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleCheckboxChange = (value: string) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter((option) => option !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stringChange = () => {
    let stringSelected = '';
    selectedValues.forEach((element) => {
      const findElement = options.find((option) => option.id === element);
      if (stringSelected) {
        stringSelected = `${stringSelected}, ${findElement?.label}`;
      } else {
        stringSelected = `${findElement?.label}`;
      }
    });

    setSelectedOption(stringSelected);
  };

  useEffect(() => {
    if (!isOpen) {
      stringChange();
      setValues(selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    stringChange();
  }, [stringChange]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    options.forEach((option) => {
      if (option.checked === true) {
        setSelectedValues((prev) => [
          ...prev.filter((value) => value !== option.id),
          option.id,
        ]);
      }
    });

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [options]);

  return (
    <div
      id={id}
      className={clsx('multi_select', { 'multi_select--disabled': disabled })}
      ref={optionsRef}
    >
      <div className="multi_select_container" onFocus={onFocus}>
        <Typography color="var(--product-neutral-n100)" weight="p3" tag="p">
          {label}
        </Typography>
      </div>
      <div
        data-testid={id}
        className={clsx('multi_select_trigger', {
          open: isOpen,
          'multi_select_trigger--selected': selectedValues.length > 0,
          invalid: error,
        })}
        onClick={() => toggleDropdown()}
        role="button"
      >
        {selectedOption || placeholder}
        <Icon size="small">
          <i
            id={`icon-${id}`}
            data-testid="select-icon"
            className={`fa fa-caret-down fa-lg ${
              isOpen && 'multi_select_trigger--open'
            }`}
          />
        </Icon>
      </div>
      {isOpen && (
        <div className="multi_select_container_options">
          <div className="multi_select_container_options_scroll">
            {options.map((option) => (
              <div key={option.id} className={`option option-${option.id}`}>
                <Checkbox
                  disabled={disabled}
                  name={option.label}
                  id={option.id}
                  label={option.label}
                  checked={selectedOption.includes(option.label)}
                  setValue={() => {
                    handleCheckboxChange(option.id);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
