import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { downloadFile } from '@utils/file';
import dayjs from 'dayjs';
import { getCustomersReport } from './service';

export const useCustomersReport = () => {
  return useMutation<AxiosResponse<string>, ApiError, null, unknown>({
    mutationFn: () => getCustomersReport(),
    onSuccess: (response) => {
      const type = 'csv';
      const { data, headers } = response;
      const today = dayjs().format('DD-MM-YYYY');

      const fileName = `customers_until_${today}.${type}`;

      downloadFile(type, data, fileName, headers['content-type']);
    },
  });
};
