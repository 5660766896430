import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BillingDetail, CreateBilling } from '@interfaces/Billing.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { createBilling } from '../createBilling';
import { billingKeys } from './billingKeys';

interface CreateBillingMutationParams {
  walletId: string | undefined;
  params: CreateBilling;
}

export const useCreateBillingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    BillingDetail,
    ApiError,
    CreateBillingMutationParams,
    unknown
  >({
    mutationFn: ({ walletId, params }: CreateBillingMutationParams) =>
      createBilling(walletId || '', params),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: billingKeys.lists() });
      queryClient.setQueryData(
        [billingKeys.detail(response.wallet.id, response.organization)],
        response
      );
    },
  });
};
