import { useTranslation } from 'react-i18next';

import { HeaderPage } from '@components';
import {
  Avatar,
  Button,
  Card,
  Column,
  Icon,
  Row,
  Skeleton,
  Tabs,
  Typography,
} from '@portao3-web/ui';
import { useNavigate, useParams } from 'react-router-dom';

import { useQueryParams } from '@hooks';
import { useCustomerQuery } from '@services/customers/hooks';
import { cnpjMask, phoneMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { cpfMask } from '../../../../shared/utils/formatMasks';
import { CustomersBillingDeals } from '../customersBillingDeals';
import { CustomerInvoices } from '../customersInvoices';
import './CustomerDetails.styles.scss';

export const CustomerDetails = () => {
  const organizationId = localStorage.getItem('organization');

  const history = useNavigate();
  const { t } = useTranslation();
  const { customerId } = useParams();
  const { queryParams, setQueryParams } = useQueryParams();
  const { data: customer, isLoading: isLoadingCustomer } = useCustomerQuery(
    customerId,
    organizationId
  );

  const tabs = [
    {
      id: 'billing-deal',
      label: t('general.deals'),
      content: <CustomersBillingDeals />,
    },
    {
      id: 'invoices',
      label: t('general.invoices'),
      content: <CustomerInvoices />,
    },
  ];

  const tabParam = queryParams.get('tab');
  const selectedTabIndex = tabs.findIndex((tab) => tab.id === tabParam);

  const handleTabChange = (tab: string) => {
    const activeTab = tabs.find((t) => t.id === tab);
    setQueryParams({
      tab: activeTab?.id,
      query: undefined,
      status: undefined,
      invoice: undefined,
      dealId: undefined,
    });
  };

  return (
    <div className="customerDetails">
      <HeaderPage
        title={t('general.client-details')}
        breadcrumbList={[
          {
            label: t('general.clients'),
            url: '/customers',
            isDisabled: false,
          },
          {
            label: `${customer?.name ? customer?.name : t('general.client')}`,
            url: `/customers/${customerId}`,
            isDisabled: false,
          },
        ]}
      >
        <Button
          variant="tertiary"
          size="large"
          onClick={() => history(-1)}
          className="customerDetails__backButton"
        >
          <Icon size="large">
            <i className={`fa-regular fa-arrow-left fa-1x`} />
          </Icon>
          {t('button.back')}
        </Button>
      </HeaderPage>

      <Row>
        <Column lg={3} md={4}>
          <Skeleton isLoading={isLoadingCustomer}>
            <Card className="card__customerProfile flex flex-col gap-[16px]">
              <div className="customerProfile_details flex-row gap-2">
                <div className="flex items-center">
                  <Avatar imageSrc="" userName={customer?.name} size="small" />
                </div>
                <div>
                  <Typography tag="p" color="var(--product-neutral-n500)">
                    {customer?.name}
                  </Typography>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                  >
                    {customer?.documentType === 'CPF'
                      ? cpfMask(customer.document)
                      : cnpjMask(customer?.document)}
                  </Typography>
                </div>
              </div>
              <div className="customerProfile_details flex-col items-start gap-3">
                <Typography tag="p" color="var(--product-neutral-n100)">
                  {t('general.client-details')}
                </Typography>

                <div className="txt-color flex items-center gap-2">
                  <Icon size="medium">
                    <i className="fa-regular fa-envelope fa-1x text-primary-400" />
                  </Icon>
                  <Typography tag="p" className="text-neutral-500">
                    {customer?.email}
                  </Typography>
                </div>
                <div className="txt-color flex items-center gap-2">
                  <Icon size="medium">
                    <i className="fa-regular fa-phone fa-1x text-primary-400" />
                  </Icon>
                  <Typography tag="p" className="text-neutral-500">
                    {phoneMask(customer?.phoneNumber)}
                  </Typography>
                </div>
                <div className="flex gap-2">
                  <Icon size="medium">
                    <i className="fa-regular fa-location-pin fa-1x text-primary-400 " />
                  </Icon>
                  <Typography tag="p" className="text-neutral-500">
                    {customer?.address.street}, {customer?.address.number},{' '}
                    {customer?.address.city}-{customer?.address.state},{' '}
                    {customer?.address.complement}
                  </Typography>
                </div>
              </div>
              <div className="customerProfile_details border-neutral-30 flex-col items-start gap-3 border-t-2 border-solid pt-[16px]">
                <p className="text-p2 text-neutral-100">
                  {t('general.created').toUpperCase()}:
                </p>
                <Typography tag="p" color="var(--product-neutral-n700)">
                  por{' '}
                  <span className="text-info-400" style={{ font: 'inherit' }}>
                    {customer?.createdBy?.name}
                  </span>{' '}
                  em
                  <span className="text-info-400" style={{ font: 'inherit' }}>
                    {' '}
                    {dayjs.utc(customer?.createdAt).format('DD/MM/YYYY')}
                  </span>
                </Typography>
              </div>
              {/* <div className="customerProfile_details border-neutral-30 flex-col items-start gap-3 border-t-2 border-solid pt-[16px]">
              <Typography tag="p" color="var(--product-neutral-n100)">
                RESPONSÁVEL
              </Typography>
              <div className="flex w-full gap-2">
                <div className="flex items-center">
                  <Avatar
                    imageSrc=""
                    userName={'Guilherme Chiara'}
                    size="small"
                  />
                </div>
                <div className="flex flex-col overflow-hidden">
                  <Typography tag="p" color="var(--product-neutral-n500)">
                    Guilherme Chiara
                  </Typography>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n80)"
                    className="overflow-hidden overflow-ellipsis"
                  >
                    guilherme.chiara@portao3.com.br
                  </Typography>
                </div>
              </div>
            </div> */}
              {/* <div className="customerProfile_details border-neutral-30 flex-col items-start gap-3 border-t-2 border-solid pt-[16px]">
              <Typography tag="p" color="var(--product-neutral-n100)">
                ANOTAÇÕES:
              </Typography>
              <Typography tag="p" color="var(--product-neutral-n100)">
                lorem porem ipsum dolor sit amet consectetur adipiscing elit sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim ad minim veniam quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur excepteur sint occaecat cupidatat non proident
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </Typography>
            </div> */}
            </Card>
          </Skeleton>
        </Column>
        <Column lg={9} md={8}>
          <Tabs
            className="[&>div]:overflow-y-visible !overflow-y-visible"
            tabs={tabs}
            start={selectedTabIndex !== -1 ? selectedTabIndex : 0}
            onTabChange={handleTabChange}
            key={selectedTabIndex}
          />
        </Column>
      </Row>
    </div>
  );
};
