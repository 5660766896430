import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import securityDangerImg from '@assets/img/security-progress-danger.svg';
import securitySuccessImg from '@assets/img/security-progress-success.svg';
import securityWarningImg from '@assets/img/security-progress-warning.svg';
import { useUser } from '@providers';

enum BarSecurityStatus {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

interface BarSecurityProps {
  label: string;
  status: BarSecurityStatus;
}

export enum SecurityStatus {
  MINIMUM = 'MINIMUM',
  MEDIUM = 'MEDIUM',
  FULL = 'FULL',
}

export const BarSecurityProgress = () => {
  const { t } = useTranslation();

  const { hasPin, hasMfa } = useUser();

  const securityOptions = {
    [SecurityStatus.MINIMUM]: {
      color: {
        first: BarSecurityStatus.DANGER,
        second: BarSecurityStatus.DEFAULT,
        third: BarSecurityStatus.DEFAULT,
      },
      icon: securityDangerImg,
      percent: 33,
    },
    [SecurityStatus.MEDIUM]: {
      color: {
        first: BarSecurityStatus.WARNING,
        second: BarSecurityStatus.WARNING,
        third: BarSecurityStatus.DEFAULT,
      },
      icon: securityWarningImg,
      percent: 66,
    },
    [SecurityStatus.FULL]: {
      color: {
        first: BarSecurityStatus.SUCCESS,
        second: BarSecurityStatus.SUCCESS,
        third: BarSecurityStatus.SUCCESS,
      },
      icon: securitySuccessImg,
      percent: 100,
    },
  };

  const getCurrentProtectionStatus = () => {
    if (hasPin && hasMfa) {
      return SecurityStatus.FULL;
    }

    if ((hasPin && !hasMfa) || (hasMfa && !hasPin)) {
      return SecurityStatus.MEDIUM;
    }

    return SecurityStatus.MINIMUM;
  };

  const currentSecurity = securityOptions[getCurrentProtectionStatus()];

  return (
    <div className="bg-neutral-10 flex gap-2 rounded-md p-2">
      <img
        className="h-10 w-10"
        src={currentSecurity.icon}
        style={{ background: 'transparent' }}
        alt="Alerta segurança"
      />

      <div className="border-neutral-40 flex flex-col border-r border-solid pr-2">
        <h2 className="text-p1 text-neutral-100">
          {t('general.your-security')}
        </h2>
        <span className="text-p5 uppercase text-neutral-100">
          {t('auth.coverage-percent', { percent: currentSecurity.percent })}
        </span>
      </div>

      <div className="flex flex-1  gap-2">
        <BarSecurity
          label={t('general.weak')}
          status={currentSecurity.color.first}
        />
        <BarSecurity
          label={t('general.medium')}
          status={currentSecurity.color.second}
        />
        <BarSecurity
          label={t('general.strong')}
          status={currentSecurity.color.third}
        />
      </div>
    </div>
  );
};

const BarSecurity = ({ status, label }: BarSecurityProps) => {
  const color = {
    danger: 'bg-danger-400',
    warning: 'bg-warning-500',
    success: 'bg-success-400',
    default: 'bg-neutral-40',
  };

  return (
    <div className="flex w-full flex-col justify-center gap-2">
      <span className="text-p5 text-center uppercase text-neutral-100">
        {label}
      </span>

      <div
        className={clsx({
          'h-2 w-full rounded-xl': true,
          [color[status]]: true,
        })}
      />
    </div>
  );
};
