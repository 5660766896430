import { useTranslation } from 'react-i18next';

import { SidebarFooter } from '@components';
import { ICONS } from '@constants/icons';
import { SecurityOptions } from '@enums/Security.enum';
import { Button } from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { HeaderFlowCard } from '../components/HeaderFlowCard';
import { CreateUserAuthPin } from './CreateUserAuthPin';
import { PINHome } from './PINHome';

export const PINWrapper = () => {
  const { t } = useTranslation();

  const { hasPin } = useUser();
  const { openDrawer, closeDrawer } = useDrawer();

  const handleBack = () => {
    openDrawer('security', { flow: 'DEFAULT' });
  };

  return (
    <>
      <button
        className="text-p3 mb-4 flex items-center gap-2 text-neutral-100"
        onClick={handleBack}
      >
        <i className={`${ICONS.arrowLeft} text-p2`} />
        {t('button.back')}
      </button>

      <div className="flex flex-col gap-4">
        <HeaderFlowCard
          type={SecurityOptions.PIN}
          status={hasPin ? 'active' : 'pending'}
        />

        {hasPin ? (
          <>
            <PINHome />
            <SidebarFooter>
              <Button onClick={handleBack} variant="tertiary" size="large">
                {t('button.back')}
              </Button>

              <Button onClick={closeDrawer} size="large">
                {t('general.button.close')}
              </Button>
            </SidebarFooter>
          </>
        ) : (
          <CreateUserAuthPin />
        )}
      </div>
    </>
  );
};
