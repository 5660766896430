import { useEffect, useRef, useState } from 'react';

import activeNotifications from '@assets/img/activeNotifications.svg';

import { Alert, SidebarFooter, SidebarSection } from '@components';
import {
  Button,
  ErrorMessage,
  Input,
  Spinner,
  Toastr,
  Toggle,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useUpdateWalletMutation } from '@services/wallet/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { moneyMask } from '@utils/formatMasks';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import {
  ActiveNotificationProps,
  FormData,
} from './ActiveNotifications.interface';
import './ActiveNotifications.styles.scss';

export const ActiveNotifications = ({
  wallet,
  setHasConfirmationModal,
  setOpenConfirmationModal,
}: ActiveNotificationProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { closeDrawer } = useDrawer();
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { mutate: updateWallet } = useUpdateWalletMutation();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      amount:
        wallet?.settings.notification &&
        wallet?.settings.notification.balanceLimit.amount
          ? moneyMask(
              wallet?.settings.notification.balanceLimit.amount?.toString() ||
                ''
            )
          : '',
      email: wallet?.settings.notification
        ? wallet?.settings.notification.balanceLimit.email
        : false,
      isActive: wallet?.settings.notification
        ? wallet?.settings.notification.balanceLimit.isActive
        : false,
    },
  });

  const watchAll = watch();

  useEffect(() => {
    setHasConfirmationModal(isDirty);
  }, [isDirty, setHasConfirmationModal]);

  const submitForm = async (data: FormData) => {
    setIsCreatingWallet(true);
    const payload = {
      settings: {
        notification: {
          balanceLimit: {
            amount: Number(data.amount.replace(/\D/g, '')),
            email: data.email,
            isActive: data.isActive,
          },
        },
      },
      shared: wallet?.shared,
    };

    try {
      if (wallet) {
        updateWallet(
          { walletId: wallet.id, payload },
          {
            onSuccess: () => {
              Toastr.success('Sua carteira foi atualizada com sucesso');

              closeDrawer();
            },
          }
        );
      }
    } catch (error) {
      const { response } = error as AxiosError;

      const messages = (key: number) => {
        const errors: { [key: number]: string } = {
          400: 'Erro 400: Dados inválidos. Por favor, revise e tente novamente.',
          403: 'Erro 403: Acesso negado. Verifique suas permissões ou contate o suporte.',
        };

        return (
          errors[key] ??
          'Erro 500: Falha no servidor. Estamos trabalhando para resolver. Tente novamente mais tarde.'
        );
      };

      if (formRef.current?.parentElement?.scrollTo) {
        formRef.current.parentElement.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }

      setAlertMessage(messages(response?.status ?? 500));
      setIsCreatingWallet(false);
    }
  };

  const submitText = () => {
    return watchAll.isActive ? 'Salvar' : 'Concluir';
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'amount' && type === 'change') {
        const money = moneyMask(value.amount || '');
        setValue('amount', money);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <>
      {alertMessage && (
        <Alert status="error">
          <Typography tag="p" weight="p2" color="var(--product-error-d500)">
            {alertMessage}
          </Typography>
        </Alert>
      )}
      <div className="activeNotifications">
        <img src={activeNotifications} alt="Notificações" />

        <div>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            Notificação de saldo
          </Typography>
          <Typography tag="p" weight="p3" color="var(--product-neutral-n100)">
            Habilite e configure como deseja ser notificado sobre o saldo de sua
            carteira.
          </Typography>
        </div>

        <Toggle
          isSelected={watchAll.isActive}
          onChange={(isSelected) => {
            setValue('isActive', isSelected);
            setHasConfirmationModal(true);
          }}
          data-testid="open-notifications"
          label={''}
        />
      </div>

      <form
        ref={formRef}
        className="new-wallet"
        onSubmit={handleSubmit(submitForm)}
      >
        {watchAll.isActive && (
          <>
            <SidebarSection
              numberSection={1}
              titleSection="Saldo mínimo para notificação"
              subTitleSection="Receba notificações sempre que seu saldo chegar à um valor mínimo."
            >
              <Input
                type="tel"
                placeholder={calcCurrencyValue(Number(watchAll.amount), 'BRL')}
                label="Digite o novo valor"
                id="amount"
                name="amount"
                data-testid="amount"
                register={register}
                error={!!errors['amount']}
                validationSchema={{
                  required: 'O valor é obrigatório',
                  validate: (value: string) => {
                    if (value === 'R$ 0,00') {
                      setError('amount', {
                        type: 'manual',
                        message: 'O valor deve ser maior que zero',
                      });
                      return false;
                    } else {
                      clearErrors('amount');
                    }
                  },
                }}
                required
              >
                {errors['amount']?.message && (
                  <ErrorMessage message={errors['amount'].message.toString()} />
                )}
              </Input>
            </SidebarSection>

            <SidebarSection
              numberSection={2}
              titleSection="Tipo de notificação"
              subTitleSection="Personalize como prefere ser notificado."
            >
              <div className="">
                <div className="setNotification">
                  <Toggle
                    isSelected={watchAll.email}
                    onChange={(isSelected) => {
                      setValue('email', isSelected);
                      setHasConfirmationModal(true);
                    }}
                    data-testid="email"
                    label="E-mail"
                    description="Seja notificado por e-mail quando o saldo chegar ao valor
                    especificado."
                  />
                </div>
              </div>
            </SidebarSection>
          </>
        )}
        <SidebarFooter>
          <Button
            type="button"
            size="large"
            variant="tertiary"
            onClick={() => {
              setOpenConfirmationModal((prev) => !prev);
            }}
          >
            Fechar
          </Button>
          <Button
            type="submit"
            size="large"
            variant="primary"
            disabled={!isValid}
          >
            {isCreatingWallet ? <Spinner variant="secondary" /> : submitText()}
          </Button>
        </SidebarFooter>
      </form>
    </>
  );
};
