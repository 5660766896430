import { Typography } from '..';
import { EmptyStateProps } from './EmptyState.interface';
import './EmptyState.styles.scss';

export const EmptyState = ({
  image,
  title,
  subtitle,
  children,
  direction = 'column',
}: EmptyStateProps) => {
  return (
    <div
      className={`container_empty_state container_empty_state--${direction}`}
    >
      <div className={`container_empty_state_img_container`}>
        {image && (
          <img
            className={`container_empty_state--${direction}_image`}
            alt="empty-section"
            id="empty-section"
            src={image}
          />
        )}
      </div>
      <div className={`container_empty_state_text_container`}>
        <div className={`container_empty_state_text_container_title`}>
          <Typography tag="h4" color="#504F75" className="text-pretty">
            {title}
          </Typography>
        </div>
        <Typography tag="p" weight="p2" color="#A5A5B8" className="text-pretty">
          {subtitle}
        </Typography>
      </div>
      {children}
    </div>
  );
};
