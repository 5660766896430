import { organization, realm } from '@constants/endpoints';
import { TransactionScheduleStatus } from '@enums/SchedulePayment.enum';
import { environment } from '@environments/environment';
import api from '@services/api';
import { clearEmptyObjectAttributes } from '@utils/clearEmptyObjectAttributes';
import { AxiosResponse } from 'axios';

export interface GetFutureTransactionsExport {
  walletId?: string;
  type: 'csv' | 'xls' | 'xlsx';
  scheduledAtStart: string | null;
  scheduledAtEnd: string | null;
  status: TransactionScheduleStatus[];
  description: string | null;
}

export const getFutureTransactionsReport = async (
  props: GetFutureTransactionsExport
): Promise<AxiosResponse<string>> => {
  const { walletId, ...params } = props;

  clearEmptyObjectAttributes(params);

  return await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}${walletId ? `/wallets/${walletId}` : ''}/schedules/report`,
    { params }
  );
};
