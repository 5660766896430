import { NavLink } from 'react-router-dom';

import { Logo } from '../logo';
import './RealmMenu.styles.scss';

interface MenuProps {
  children: React.ReactNode | React.ReactNode[];
}

const Menu = ({ children }: MenuProps) => {
  return (
    <div className="realm_menu">
      <div className="realm_menu_fixed_content">
        <div className="realm_menu__box_logo">
          <NavLink to={'/organizations'}>
            <Logo.Wide />
          </NavLink>
        </div>
        <div className="realm_menu__content">{children}</div>
      </div>
    </div>
  );
};

interface SectionProps extends MenuProps {
  title?: string;
}

const Section = ({ title, children }: SectionProps) => {
  return (
    <div className="flex flex-col gap-2">
      {title && (
        <p className="font-p3 font-semibold text-neutral-50">
          {title.toUpperCase()}
        </p>
      )}
      {children}
    </div>
  );
};

export const RealmMenu = Object.assign(Menu, { Section });
