import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { clearWallet } from '../WalletServices';
import { walletKeys } from './walletKeys';

export const useClearWalletBalanceMutation = () => {
  const queryClient = useQueryClient();
  const organizationId = localStorage.getItem('organization') ?? '';

  return useMutation<unknown, ApiError, string, unknown>({
    mutationFn: (walletId: string) => clearWallet(walletId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: walletKeys.lists() });
      queryClient.invalidateQueries({
        queryKey: walletKeys.detail({ walletId: variables, organizationId }),
      });
    },
  });
};
