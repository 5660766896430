import { useContext, useState } from 'react';

import { customerEvents, resetCustomerIoIdentity } from '@lib/customerIo';
import { AuthContext } from '@providers';

interface AuthProviderRenderProps {
  isLogged: boolean;
}

interface AuthProviderProps {
  children:
    | React.ReactNode
    | React.ReactNode[]
    | ((props: AuthProviderRenderProps) => React.ReactNode | React.ReactNode[]);
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [session, setSession] = useState('');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [tokenRecoveryPassword, setTokenRecoveryPassword] = useState('');

  const isLogged = !!localStorage.getItem('token');

  const logout = (path?: string) => {
    localStorage.clear();
    customerEvents.userLoggedOut();
    resetCustomerIoIdentity();
    sessionStorage.clear();
    window.location.replace(path || '/login');
  };

  return (
    <AuthContext.Provider
      value={{
        session: { value: session, setSession },
        token: { value: token, setToken },
        email: { value: email, setEmail },
        tokenRecoveryPassword: {
          value: tokenRecoveryPassword,
          setTokenRecoveryPassword,
        },
        logout,
      }}
    >
      {typeof children === 'function' ? children({ isLogged }) : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
