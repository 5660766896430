import { Typography } from '@portao3-web/ui';
import { HeaderCardProps } from './HeaderCard.interface';

import './HeaderCard.styles.scss';

export const HeaderCard = ({ children, title }: HeaderCardProps) => {
  const renderTitle = () => {
    return (
      <Typography tag="h3" color="var(--product-neutral-n500)">
        {title}
      </Typography>
    );
  };

  return (
    <div className="header_card">
      <div className="title">
        {title && renderTitle()}
        {children}
      </div>
    </div>
  );
};
