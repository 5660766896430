import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EditUserParams, RealmUser } from '@interfaces/User.interfaces';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { createUserByRealm } from '@services/user';
import { userKeys } from './userKeys';

interface CreateUserMutationParams {
  params: EditUserParams;
  pin?: string;
}

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<RealmUser, ApiErrorPin, CreateUserMutationParams, unknown>(
    {
      mutationFn: ({ params, pin }: CreateUserMutationParams) =>
        createUserByRealm(params, pin),
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: userKeys.lists() });
        queryClient.setQueryData(userKeys.detail(response.id), response);
      },
    }
  );
};
