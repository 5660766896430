import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { WalletDetailsProps } from '@interfaces/Wallets.interfaces';
import { getWalletByUser } from '@services/wallet/WalletServices';
import { walletKeys } from './walletKeys';

export interface UseUserWalletQueryParams {
  userId: string;
  organizationId: string;
}

export const useUserWalletQuery = ({
  organizationId,
  userId,
}: UseUserWalletQueryParams) => {
  return useQuery<WalletDetailsProps, ApiError, WalletDetailsProps, unknown[]>({
    queryKey: walletKeys.userDetail({ organizationId, userId }),
    queryFn: () => getWalletByUser(userId),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    placeholderData: keepPreviousData,
    enabled: !!userId && !!organizationId,
  });
};
