import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { CardItem, CardPayloadRequest } from '@interfaces/Card.interfaces';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { useTranslation } from 'react-i18next';
import { ContextualErrorType } from '../../../components/alerts';
import { createCard } from '../createCardService';
import { cardKeys } from './cardKeys';

interface CreateCardMutation {
  walletId: string;
  payload: CardPayloadRequest;
}

export const useCreateCardMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<
    CardItem,
    ApiErrorPin,
    CreateCardMutation,
    unknown
  >({
    mutationFn: ({ walletId, payload }: CreateCardMutation) =>
      createCard(payload, walletId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cardKeys.lists() });
    },
    onError: (error: ApiErrorPin) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
