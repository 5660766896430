import { User } from '@interfaces/User.interfaces';
import * as Sentry from '@sentry/react';

export const setSentryUser = (user: User) => {
  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: `${user.firstName} ${user.lastName}`,
    realmName: user.realm.name,
    realmId: user.realm.id,
    realmLevel: user.realm.level,
  });
};

export const setSentryContext = (
  name: string,
  context: { [key: string]: unknown }
) => {
  Sentry.setContext(name, context);
};

export const createSentryClickBreadcrumb = (message: string) => {
  Sentry.addBreadcrumb({
    level: 'info',
    type: 'user',
    category: 'user-click',
    message,
  });
};
