import { Authentication, AuthenticationProps } from '@components';
import { ICONS } from '@constants/icons';
import { Sidebar } from '@portao3-web/ui';
import { useTranslation } from 'react-i18next';

interface DrawerAuthenticationProps extends AuthenticationProps {
  onSubmit: () => void;
  isOpen: boolean;
}

export const DrawerAuthentication = ({
  isPending,
  onClose,
  onSubmit,
  pin,
  setPin,
  error,
  pinErrorsCount,
  isOpen,
}: DrawerAuthenticationProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <Sidebar
          title={t('general.security')}
          open={isOpen}
          icon={ICONS.shield}
          onClose={onClose}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit();
            }}
          >
            <Authentication
              pin={pin}
              error={error}
              pinErrorsCount={pinErrorsCount}
              setPin={setPin}
              isPending={isPending}
              onClose={onClose}
            />
          </form>
        </Sidebar>
      )}
    </>
  );
};
