import { useRef, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';

import './QrCodeScanner.styles.scss';

interface QrCodeScannerProps {
  setCode: (code: string) => void;
}

export const QrCodeScanner = ({ setCode }: QrCodeScannerProps) => {
  const videoElementRef = useRef(null);
  const [scanner, setScanner] = useState<QrScanner | null>(null);

  useEffect(() => {
    const video: HTMLVideoElement | null = videoElementRef.current;
    if (!video) {
      return;
    }
    const qrScanner = new QrScanner(
      video,
      (result) => {
        setCode(result?.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: false,
        highlightCodeOutline: true,
      }
    );

    setScanner(qrScanner);
    qrScanner.setInversionMode('both');
    qrScanner.start();
  }, [setCode]);

  useEffect(() => {
    return () => {
      if (scanner) {
        scanner.destroy();
      }
    };
  }, [scanner]);

  return (
    <div>
      <div className="videoWrapper">
        <video className="qrVideo" ref={videoElementRef} />
      </div>
    </div>
  );
};
