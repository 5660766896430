import { ReactNode } from 'react';
import { RouterProvider } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';

interface ReactAriaRouterProviderProps {
  children: ReactNode | ReactNode[];
}

export const ReactAriaRouterProvider = ({
  children,
}: ReactAriaRouterProviderProps) => {
  const navigate = useNavigate();
  return <RouterProvider navigate={navigate}>{children}</RouterProvider>;
};
