import { Typography } from '@portao3-web/ui';
import { HeaderFormProps } from './HeaderForm.interface';
import './HeaderForm.styles.scss';

export const HeaderForm = ({ title, subTitle }: HeaderFormProps) => {
  return (
    <div className="header_form">
      <Typography tag="h1" className="header_form_title">
        {title}
      </Typography>
      <Typography tag="h4" className="header_form_subtitle">
        {subTitle}
      </Typography>
    </div>
  );
};
