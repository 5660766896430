import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { TransactionScheduleByIdProps } from '@interfaces/Schedule.interfaces';

export interface FindScheduleByIdRequest {
  walletId: string;
  scheduleId: string;
}

export const findScheduleById = async ({
  walletId,
  scheduleId,
}: FindScheduleByIdRequest): Promise<TransactionScheduleByIdProps> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/wallets/${walletId}/${
      endpoint.schedules
    }/${scheduleId}`
  );

  const { data } = response;

  return data;
};
