export const brazilianRealCurrency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const americanDollarCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const europeEuroCurrency = new Intl.NumberFormat('pt', {
  style: 'currency',
  currency: 'EUR',
});

export const calcCurrencyValue = (
  value: number | null,
  currency?: 'BRL' | 'USD' | 'EUR'
) => {
  const currencyValue = (value || 0) / 100;
  switch (currency) {
    case 'BRL':
      return brazilianRealCurrency.format(currencyValue);
    case 'USD':
      return americanDollarCurrency.format(currencyValue);
    case 'EUR':
      return europeEuroCurrency.format(currencyValue);
    default:
      return brazilianRealCurrency.format(currencyValue);
  }
};

export const currencyFormats = {
  '8': { locale: 'sq-AL', currency: 'ALL' }, // Albanian Lek
  '12': { locale: 'ar-DZ', currency: 'DZD' }, // Algerian Dinar
  '32': { locale: 'es-AR', currency: 'ARS' }, // Argentine Peso
  '36': { locale: 'en-AU', currency: 'AUD' }, // Australian Dollar
  '44': { locale: 'en-BS', currency: 'BSD' }, // Bahamian Dollar
  '48': { locale: 'ar-BH', currency: 'BHD' }, // Bahraini Dinar
  '50': { locale: 'bn-BD', currency: 'BDT' }, // Bangladeshi Taka
  '51': { locale: 'bg-BG', currency: 'BGN' }, // Bulgarian Lev
  '52': { locale: 'en-BB', currency: 'BBD' }, // Barbadian Dollar
  '60': { locale: 'en-BM', currency: 'BMD' }, // Bermudian Dollar
  '64': { locale: 'dz-BT', currency: 'BTN' }, // Bhutanese Ngultrum
  '68': { locale: 'es-BO', currency: 'BOB' }, // Bolivian Boliviano
  '72': { locale: 'en-BW', currency: 'BWP' }, // Botswana Pula
  '84': { locale: 'en-BZ', currency: 'BZD' }, // Belize Dollar
  '90': { locale: 'fr-BI', currency: 'BIF' }, // Burundian Franc
  '96': { locale: 'ms-BN', currency: 'BND' }, // Brunei Dollar
  '104': { locale: 'my-MM', currency: 'MMK' }, // Myanmar Kyat
  '108': { locale: 'fr-BI', currency: 'BIF' }, // Burundian Franc
  '116': { locale: 'km-KH', currency: 'KHR' }, // Cambodian Riel
  '124': { locale: 'en-CA', currency: 'CAD' }, // Canadian Dollar
  '132': { locale: 'pt-CV', currency: 'CVE' }, // Cape Verdean Escudo
  '136': { locale: 'en-KY', currency: 'KYD' }, // Cayman Islands Dollar
  '144': { locale: 'si-LK', currency: 'LKR' }, // Sri Lankan Rupee
  '152': { locale: 'es-CL', currency: 'CLP' }, // Chilean Peso
  '156': { locale: 'zh-CN', currency: 'CNY' }, // Chinese Yuan
  '170': { locale: 'es-CO', currency: 'COP' }, // Colombian Peso
  '174': { locale: 'fr-KM', currency: 'KMF' }, // Comorian Franc
  '188': { locale: 'es-CR', currency: 'CRC' }, // Costa Rican Colón
  '191': { locale: 'hr-HR', currency: 'HRK' }, // Croatian Kuna
  '192': { locale: 'es-CU', currency: 'CUP' }, // Cuban Peso
  '203': { locale: 'cs-CZ', currency: 'CZK' }, // Czech Koruna
  '208': { locale: 'da-DK', currency: 'DKK' }, // Danish Krone
  '214': { locale: 'es-DO', currency: 'DOP' }, // Dominican Peso
  '222': { locale: 'es-HN', currency: 'HNL' }, // Honduran Lempira
  '230': { locale: 'am-ET', currency: 'ETB' }, // Ethiopian Birr
  '232': { locale: 'ti-ER', currency: 'ERN' }, // Eritrean Nakfa
  '238': { locale: 'en-FK', currency: 'FKP' }, // Falkland Islands Pound
  '242': { locale: 'en-FJ', currency: 'FJD' }, // Fijian Dollar
  '262': { locale: 'fr-DJ', currency: 'DJF' }, // Djiboutian Franc
  '270': { locale: 'en-GM', currency: 'GMD' }, // Gambian Dalasi
  '292': { locale: 'en-GI', currency: 'GIP' }, // Gibraltar Pound
  '320': { locale: 'es-GT', currency: 'GTQ' }, // Guatemalan Quetzal
  '324': { locale: 'fr-GN', currency: 'GNF' }, // Guinean Franc
  '328': { locale: 'en-GY', currency: 'GYD' }, // Guyanese Dollar
  '332': { locale: 'ht-HT', currency: 'HTG' }, // Haitian Gourde
  '340': { locale: 'es-HN', currency: 'HNL' }, // Honduran Lempira
  '344': { locale: 'zh-HK', currency: 'HKD' }, // Hong Kong Dollar
  '348': { locale: 'hu-HU', currency: 'HUF' }, // Hungarian Forint
  '352': { locale: 'is-IS', currency: 'ISK' }, // Icelandic Króna
  '356': { locale: 'hi-IN', currency: 'INR' }, // Indian Rupee
  '360': { locale: 'id-ID', currency: 'IDR' }, // Indonesian Rupiah
  '364': { locale: 'fa-IR', currency: 'IRR' }, // Iranian Rial
  '368': { locale: 'ar-IQ', currency: 'IQD' }, // Iraqi Dinar
  '376': { locale: 'he-IL', currency: 'ILS' }, // Israeli New Shekel
  '388': { locale: 'en-JM', currency: 'JMD' }, // Jamaican Dollar
  '392': { locale: 'ja-JP', currency: 'JPY' }, // Japanese Yen
  '398': { locale: 'kk-KZ', currency: 'KZT' }, // Kazakhstani Tenge
  '400': { locale: 'ar-JO', currency: 'JOD' }, // Jordanian Dinar
  '404': { locale: 'sw-KE', currency: 'KES' }, // Kenyan Shilling
  '408': { locale: 'ko-KP', currency: 'KPW' }, // North Korean Won
  '410': { locale: 'ko-KR', currency: 'KRW' }, // South Korean Won
  '414': { locale: 'ar-KW', currency: 'KWD' }, // Kuwaiti Dinar
  '417': { locale: 'ky-KG', currency: 'KGS' }, // Kyrgyzstani Som
  '418': { locale: 'lo-LA', currency: 'LAK' }, // Lao Kip
  '422': { locale: 'ar-LB', currency: 'LBP' }, // Lebanese Pound
  '426': { locale: 'en-LS', currency: 'LSL' }, // Lesotho Loti
  '430': { locale: 'en-LR', currency: 'LRD' }, // Liberian Dollar
  '434': { locale: 'ar-LY', currency: 'LYD' }, // Libyan Dinar
  '446': { locale: 'zh-MO', currency: 'MOP' }, // Macanese Pataca
  '454': { locale: 'en-MW', currency: 'MWK' }, // Malawian Kwacha
  '458': { locale: 'ms-MY', currency: 'MYR' }, // Malaysian Ringgit
  '462': { locale: 'dv-MV', currency: 'MVR' }, // Maldivian Rufiyaa
  '480': { locale: 'en-MU', currency: 'MUR' }, // Mauritian Rupee
  '484': { locale: 'es-MX', currency: 'MXN' }, // Mexican Peso
  '496': { locale: 'mn-MN', currency: 'MNT' }, // Mongolian Tögrög
  '498': { locale: 'ro-MD', currency: 'MDL' }, // Moldovan Leu
  '504': { locale: 'ar-MA', currency: 'MAD' }, // Moroccan Dirham
  '512': { locale: 'ar-OM', currency: 'OMR' }, // Omani Rial
  '516': { locale: 'en-NA', currency: 'NAD' }, // Namibian Dollar
  '524': { locale: 'ne-NP', currency: 'NPR' }, // Nepalese Rupee
  '532': { locale: 'nl-AW', currency: 'AWG' }, // Netherlands Antillean Guilder
  '533': { locale: 'nl-AW', currency: 'AWG' }, // Aruban Florin
  '548': { locale: 'bi-VU', currency: 'VUV' }, // Vanuatu Vatu
  '554': { locale: 'en-NZ', currency: 'NZD' }, // New Zealand Dollar
  '558': { locale: 'es-NI', currency: 'NIO' }, // Nicaraguan Córdoba
  '566': { locale: 'en-NG', currency: 'NGN' }, // Nigerian Naira
  '578': { locale: 'nb-NO', currency: 'NOK' }, // Norwegian Krone
  '586': { locale: 'ur-PK', currency: 'PKR' }, // Pakistani Rupee
  '590': { locale: 'es-PA', currency: 'PAB' }, // Panamanian Balboa
  '598': { locale: 'en-PG', currency: 'PGK' }, // Papua New Guinean Kina
  '600': { locale: 'es-PY', currency: 'PYG' }, // Paraguayan Guaraní
  '604': { locale: 'es-PE', currency: 'PEN' }, // Peruvian Sol
  '608': { locale: 'en-PH', currency: 'PHP' }, // Philippine Peso
  '634': { locale: 'ar-QA', currency: 'QAR' }, // Qatari Riyal
  '643': { locale: 'ru-RU', currency: 'RUB' }, // Russian Ruble
  '646': { locale: 'rw-RW', currency: 'RWF' }, // Rwandan Franc
  '654': { locale: 'en-SH', currency: 'SHP' }, // Saint Helena Pound
  '682': { locale: 'ar-SA', currency: 'SAR' }, // Saudi Riyal
  '690': { locale: 'en-SC', currency: 'SCR' }, // Seychellois Rupee
  '694': { locale: 'en-SL', currency: 'SLL' }, // Sierra Leonean Leone
  '702': { locale: 'en-SG', currency: 'SGD' }, // Singapore Dollar
  '704': { locale: 'vi-VN', currency: 'VND' }, // Vietnamese Dong
  '706': { locale: 'so-SO', currency: 'SOS' }, // Somali Shilling
  '710': { locale: 'en-ZA', currency: 'ZAR' }, // South African Rand
  '960': { locale: 'en-IMF', currency: 'XDR' }, // Special Drawing Rights
  '965': { locale: 'en-ZW', currency: 'ZWL' }, // Zimbabwean Dollar
  '967': { locale: 'fr-CM', currency: 'XAF' }, // Central African CFA Franc
  '968': { locale: 'fr-SN', currency: 'XOF' }, // West African CFA Franc
  '969': { locale: 'fr-PF', currency: 'XPF' }, // CFP Franc
  '970': { locale: 'es-VE', currency: 'VES' }, // Venezuelan Bolívar Soberano
  '971': { locale: 'af-AF', currency: 'AFN' }, // Afghan Afghani
  '972': { locale: 'he-IL', currency: 'ILS' }, // Israeli New Shekel
  '973': { locale: 'ka-GE', currency: 'GEL' }, // Georgian Lari
  '975': { locale: 'bg-BG', currency: 'BGN' }, // Bulgarian Lev
  '976': { locale: 'fr-CM', currency: 'XAF' }, // Central African CFA Franc
  '977': { locale: 'fr-SN', currency: 'XOF' }, // West African CFA Franc
  '978': { locale: 'fr-FR', currency: 'EUR' }, // Euro
  '979': { locale: 'fr-PF', currency: 'XPF' }, // CFP Franc
  '980': { locale: 'uk-UA', currency: 'UAH' }, // Ukrainian Hryvnia
  '981': { locale: 'ka-GE', currency: 'GEL' }, // Georgian Lari
  '984': { locale: 'es-BO', currency: 'BOV' }, // Bolivian Mvdol
  '985': { locale: 'pl-PL', currency: 'PLN' }, // Polish Zloty
  '986': { locale: 'pt-BR', currency: 'BRL' }, // Brazilian Real
  '990': { locale: 'en-ZW', currency: 'ZWL' }, // Zimbabwean Dollar
  '994': { locale: 'az-AZ', currency: 'AZN' }, // Azerbaijani Manat
  '997': { locale: 'en-ZW', currency: 'ZWL' }, // Zimbabwean Dollar
  '999': { locale: 'en-ZW', currency: 'ZWL' }, // Zimbabwean Dollar
};

export const getCurrencyByCode = (
  currencyCode: string | undefined,
  value: number | undefined
) => {
  if (!currencyCode || !value) return '-';

  const currency =
    currencyFormats[currencyCode as keyof typeof currencyFormats];

  if (!currency) return '-';

  return new Intl.NumberFormat(currency.locale, {
    style: 'currency',
    currency: currency.currency,
    minimumFractionDigits: 2,
  }).format(value / 100);
};
