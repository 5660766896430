export enum PixDictType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  EVP = 'EVP',
}

export enum PixInitiationType {
  MANUAL = 'MANUAL', // Dados do recebedor são inseridos manualmente
  DICT = 'DICT', // Chave PIX
  STATIC_QRCODE = 'STATIC_QRCODE', // QR Code Estático
  DYNAMIC_QRCODE = 'DYNAMIC_QRCODE', // QR Code de Cobrança
}
