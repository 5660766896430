import { useEffect, useRef } from 'react';

import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import '../customFieldTypeSelection/CustomFieldTypeSelection.styles.scss';
import { CustomFieldLocation } from '@interfaces/CustomFields.interfaces';

export const CustomerField = () => {
  const isMounted = useRef(false);

  const { resetField, unregister, setValue } = useDrawerEditCustomField();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    setValue('label', 'Cliente');
    setValue('identifier', 'Cliente');
    setValue('location', [CustomFieldLocation.WALLET]);

    return () => {
      resetField('label');
      resetField('externalIdentifier');
    };
  }, [resetField, unregister, setValue]);

  return null;
};
