import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

import { CreateCustomer, Customer } from '@interfaces/Customer.interfaces';

export const createCustomer = async (
  payload: CreateCustomer
): Promise<Customer> => {
  removeEmptyObjectAttributes(payload);

  const { data } = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customers
    }`,
    payload
  );

  return data;
};
