import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { TransactionScheduleStatus } from '@enums/SchedulePayment.enum';
import { customerEvents } from '@lib/customerIo';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { Skeleton } from '@portao3-web/ui';
import { useUser } from '@providers';
import { useFindScheduleByIdQuery } from '@services/scheduling';
import {
  bankSlipMask,
  documentMask,
  documentMaskHashed,
  moneyMask,
} from '@utils/formatMasks';
import { Alert } from '../../../alerts';
import { SidebarSection } from '../../../sidebarSection';
import { PaymentVoucher } from '../../PaymentVoucher';
import { ScheduleVoucherProps } from '../voucher.interfaces';

interface BankSlipPaymentScheduleVoucherProps extends ScheduleVoucherProps {
  onClose: () => void;
}

export const BankSlipPaymentScheduleVoucher = ({
  scheduleId,
  walletId,
  onClose,
}: BankSlipPaymentScheduleVoucherProps) => {
  const { t } = useTranslation();

  const user = useUser();
  const organizationId = localStorage.getItem('organization');

  const currentOrg = user.organizations.find(
    (org) => org.id === organizationId
  );

  const { data, isFetching: isLoading } = useFindScheduleByIdQuery({
    scheduleId,
    walletId,
  });

  const customerIoEvent = () => {
    if (!data) return;

    customerEvents.boletoScheduleReceiptDownloaded({
      walletId: data?.wallet,
      dueDate: data?.additionalInfos?.dueDate || null,
      originalAmount: data?.additionalInfos?.originalAmount,
      scheduleDate: data?.scheduledAt,
      updatedAmount: data?.amount,
      scheduleId: data?.id,
    });
  };

  const discountAmount =
    dayjs(data?.scheduledAt)
      .utc(false)
      .isSame(dayjs(data?.additionalInfos?.dueDate).utc(false)) ||
    dayjs(data?.scheduledAt)
      .utc(false)
      .isAfter(dayjs(data?.additionalInfos?.dueDate).utc(false))
      ? 0
      : data?.additionalInfos?.discountAmount;

  return (
    <PaymentVoucher
      onClose={onClose}
      title={`recibo-agendamento-boleto-${dayjs().format('DD/MM/YYYY')}.pdf`}
      onFinishDownload={customerIoEvent}
      className="flex flex-col gap-2"
      disabled={isLoading}
    >
      <Skeleton isLoading={isLoading} height={80} />

      {data?.status === TransactionScheduleStatus.SCHEDULED && !isLoading && (
        <Alert status="success">
          <p className="text-p2 text-neutral-400">
            {t('payment-voucher.bank-slip-schedule-success')}
          </p>
          <p className="text-p2 text-neutral-100">
            Transferência de{' '}
            <strong className="text-p2 text-success-500">
              {moneyMask(data?.amount)}
            </strong>{' '}
            para {data?.additionalInfos?.payee} agendada para{' '}
            {dayjs(data?.scheduledAt).utc(false).format('DD/MM/YYYY')}
          </p>
        </Alert>
      )}

      {data?.status === TransactionScheduleStatus.CANCELLED && !isLoading && (
        <Alert status="error">
          <p className="text-p2 text-neutral-100">Agendamento cancelado</p>
          <p className="text-p2 text-danger-500">
            Agendamento cancelado por {data?.cancelledByName} em{' '}
            {dayjs(data?.cancelledAt).format('DD/MM/YYYY')} às{' '}
            {dayjs(data?.cancelledAt).format('HH:mm')}
          </p>
        </Alert>
      )}

      <div className="grid grid-cols-2 gap-4">
        <ValueWrapper
          isLoading={isLoading}
          label={t('general.scheduled-to')}
          value={
            data?.scheduledAt &&
            dayjs(data?.scheduledAt).utc(false).format('DD/MM/YYYY')
          }
        />
        <ValueWrapper
          isLoading={isLoading}
          label={t('payment-voucher.transfer-type')}
          value={t('general.bank-slip')}
        />
      </div>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('general.schedule-payment-details')}
        subTitleSection={t('payment-voucher.see-bank-slip-data')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper
            label={t('payment-voucher.total-to-pay')}
            value={moneyMask(data?.amount)}
            isLoading={isLoading}
          />

          {data?.additionalInfos?.dueDate && (
            <>
              <ValueWrapper
                label={t('payment-voucher.due-date')}
                value={dayjs(data?.additionalInfos?.dueDate)
                  .utc(false)
                  .format('DD/MM/YYYY')}
              />

              <ValueWrapper
                label={t('general.original-amount')}
                value={moneyMask(data?.additionalInfos?.originalAmount)}
              />

              <ValueWrapper
                label={t('general.discount')}
                value={moneyMask(discountAmount)}
              />

              <ValueWrapper
                label={t('general.interest')}
                value={moneyMask(data?.additionalInfos?.interestAmount)}
              />

              <ValueWrapper
                label={t('general.fine')}
                value={moneyMask(data?.additionalInfos?.fineAmount)}
              />
            </>
          )}

          <ValueWrapper
            isLoading={isLoading}
            label={t('payment-voucher.payee-name')}
            value={data?.additionalInfos?.payee}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('payment-voucher.payee-document')}
            value={documentMask(data?.additionalInfos?.payeeDocument)}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('payment-voucher.payer-name')}
            value={data?.additionalInfos?.payer}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={t('payment-voucher.payer-document')}
            value={documentMask(data?.additionalInfos?.payerDocument)}
          />
          <ValueWrapper
            isLoading={isLoading}
            className="col-span-2"
            label={'Emissor'}
            value={data?.additionalInfos?.bankSlip?.issuer}
          />

          <ValueWrapper
            isLoading={isLoading}
            className="col-span-2"
            label={t('general.barcode')}
            value={bankSlipMask(data?.additionalInfos?.bankSlip?.digitableLine)}
          />
        </div>
      </SidebarSection>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('payment-voucher.your-account')}
        subTitleSection={t('payment-voucher.origin-account')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper
            isLoading={isLoading}
            label={'De'}
            value={currentOrg?.legalName}
          />

          <ValueWrapper
            isLoading={isLoading}
            label={'CPF/CNPJ'}
            value={documentMaskHashed(currentOrg?.document)}
          />
        </div>
      </SidebarSection>
    </PaymentVoucher>
  );
};
