import { DealProductType } from '@enums/BillingDeal.enum';
import { DealProduct } from '@interfaces/BillingDeal.interfaces';
import { ColumnDef } from '@tanstack/react-table';
import { moneyMask } from '@utils/formatMasks';
import { useTranslation } from 'react-i18next';

export const useBillingDealColumns = (): ColumnDef<DealProduct>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'identifier',
      header: t('general.description'),
      cell: ({ cell }) => cell.getValue<string>(),
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'type',
      header: t('general.type'),
      cell: ({ cell }) => {
        const type = cell.getValue<DealProductType>();

        return t(`invoices.dealProductType.${type}`, '');
      },
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'price',
      header: t('general.value'),
      cell: ({ cell }) => moneyMask(cell.getValue<number>()),
    },
  ];
};
