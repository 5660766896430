import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  Alert,
  CardState,
  ContextualError,
  FileUpload,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadedFile } from '@interfaces/File.interface';
import {
  Button,
  ErrorMessage,
  Input,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { batchPaymentKeys } from '@services/batchPayment/hooks';
import { useCreateBatchPaymentMutation } from '@services/batchPayment/hooks/useCreateBatchPaymentMutation';
import { validationSchema } from '@utils/validationSchema';
import { DrawerBatchPaymentFlow } from '../DrawerBatchPayment.interfaces';
import { CNABParsedData, parseCNAB240 } from './utils';

interface DefaultValues {
  files: UploadedFile[];
  firstName: string;
}

interface BatchIdentityProps {
  setFlow: Dispatch<SetStateAction<DrawerBatchPaymentFlow>>;
}

export const BatchIdentity = ({ setFlow }: BatchIdentityProps) => {
  const defaultValues: DefaultValues = {
    files: [],
    firstName: '',
  };

  const [cnabData, setCnabData] = useState<CNABParsedData[]>([]);
  const [fileState, setFileState] = useState<CardState>('loading');
  const [errors, setErrors] = useState<string[]>([]);

  const { walletId } = useParams();
  const { t } = useTranslation();
  const { closeDrawer, openDrawer } = useDrawer();
  const queryClient = useQueryClient();

  const form = useForm<DefaultValues>({
    defaultValues,
    resolver: yupResolver(
      validationSchema(defaultValues) as yup.ObjectSchema<DefaultValues>
    ),
  });

  const files = form.watch('files');

  const {
    mutate: createBatchPaymentMutation,
    isPending: isLoading,
    customError,
  } = useCreateBatchPaymentMutation();

  const submit = (form: DefaultValues) => {
    createBatchPaymentMutation(
      {
        walletId: walletId || 'default',
        payload: {
          name: form.firstName,
          totalBatchAmount: cnabData.reduce(
            (acc, item) => acc + item.amount,
            0
          ),
          transactions: cnabData.map((item) => item),
        },
      },
      {
        onSuccess: (data) => {
          setFlow('REVIEW');

          openDrawer('batch-payment', {
            batchId: data?.id,
          });

          queryClient.invalidateQueries({
            queryKey: batchPaymentKeys.lists(),
          });
        },
      }
    );
  };

  useEffect(() => {
    if (!files || !files[0]) return;

    const validateCNAB240 = (data: string) => {
      // TODO refactor
      try {
        const cnabData = parseCNAB240(data);
        const lines = data.trim().replaceAll(`\r`, '').split('\n');
        const errorsLineSize: string[] = [];
        const MAX_ERROR_LINES_TO_BE_GENERAL_ERROR = 10;
        const atLeast5Lines = lines.length >= 5;

        lines.forEach((line, index) => {
          const lastLine = index === lines.length - 1;
          // Última linha não precisa ter necessariamente 240 caracteres
          if (line.length !== 240 && !lastLine) {
            errorsLineSize.push((index + 1).toString());
          }
        });

        const hasDateAfterToday = cnabData.some((item) =>
          dayjs(item.transactionAt).isAfter(dayjs(), 'day')
        );

        const hasAllDocuments = cnabData.every((item) => !!item.document);
        const withoutErrors =
          !hasDateAfterToday &&
          hasAllDocuments &&
          atLeast5Lines &&
          errorsLineSize.length === 0;

        if (withoutErrors) {
          setFileState('loaded');
          setCnabData(cnabData);
        } else {
          const getErrorMessage = () => {
            const errorText: string[] = [];

            if (!atLeast5Lines) {
              errorText.push(
                t('transactions.file-cnab-min-lines', {
                  minLines: 5,
                })
              );
            }

            if (!hasAllDocuments) {
              errorText.push(t('transactions.missing-documents'));
            }

            if (hasDateAfterToday) {
              errorText.push(t('transactions.batch-date-not-after-today'));
            }

            if (
              errorsLineSize.length > 0 &&
              errorsLineSize.length < MAX_ERROR_LINES_TO_BE_GENERAL_ERROR
            ) {
              errorText.push(
                t('transactions.file-error-lines', {
                  errorLines: errorsLineSize.join(', '),
                })
              );
            }

            if (errorText.length > 0) {
              return errorText;
            }

            return [
              t('transactions.file-general-error-lines', {
                maxErrorLines: MAX_ERROR_LINES_TO_BE_GENERAL_ERROR,
              }),
            ];
          };

          setFileState('error');
          setErrors(getErrorMessage());
        }
      } catch {
        setFileState('error');
        setErrors([]);
      }
    };

    fetch(files[0]?.link)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const text = reader.result as string;
          validateCNAB240(text);
        };
        reader.readAsText(blob);
      });
  }, [files, t]);

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <SidebarSection
        subTitleSection=""
        numberSection={1}
        titleSection={t('transactions.define-external-batch-id')}
      >
        <ContextualError error={customError} />

        <Alert status="warning">
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            No momento, pagamentos somente serão processados na data atual.{' '}
            <span style={{ font: 'inherit', fontWeight: 'bold' }}>
              Datas futuras não são aceitas
            </span>
            .
          </Typography>
        </Alert>

        <Input
          label={t('transactions.batch-name')}
          name="firstName"
          placeholder="Ex: Lote 01 - Fornecedores"
          register={form.register}
          error={!!form.formState.errors.firstName}
        >
          {form.formState.errors['firstName']?.message && (
            <ErrorMessage
              message={t(
                form.formState.errors['firstName'].message.toString(),
                ''
              )}
            />
          )}
        </Input>

        <Typography tag="p" weight="p3" color="var(--product-neutral-n100">
          {t('transactions.upload-batch')} (.TXT e .RET padrão CNAB 240)
        </Typography>

        <FileUpload
          maxFileSizeInMB={50}
          maxFiles={1}
          name="files"
          fileAccept=".txt, .ret"
          setValue={form.setValue}
          defaultFiles={[]}
          deleteFile={() => {
            setFileState('loading');
            setErrors([]);
          }}
        >
          {({ uploadedFiles }) => (
            <div className="drawer-transactions-file-upload-container">
              <div className="receipt-upload">
                {uploadedFiles?.length < 1 && <FileUpload.DragAndDrop />}
                {uploadedFiles?.map((file) => (
                  <FileUpload.FileCard
                    key={file.link}
                    file={file}
                    cardState={fileState}
                  />
                ))}
                {uploadedFiles?.length > 0 && <FileUpload.Button />}
              </div>
            </div>
          )}
        </FileUpload>

        <div className="mt-4" />

        {fileState === 'error' && (
          <Alert status="error">
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {t('transactions.error-upload-batch-file')}
            </Typography>
            <div className="mt-1" />
            {errors.length > 0 && (
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>
                    <Typography
                      tag="p"
                      weight="p2"
                      color="var(--product-neutral-n500)"
                    >
                      • {error}
                      <br />
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </Alert>
        )}
      </SidebarSection>

      <SidebarFooter>
        <Button
          type="button"
          size="large"
          variant="tertiary"
          className="button_submit"
          onClick={closeDrawer}
          disabled={isLoading}
        >
          {t('button.back')}
        </Button>

        <Button
          type="submit"
          size="large"
          className="button_submit"
          disabled={
            !(form.watch('files')?.length > 0) ||
            fileState !== 'loaded' ||
            isLoading
          }
        >
          {isLoading ? <Spinner variant="secondary" /> : t('general.review')}
        </Button>
      </SidebarFooter>
    </form>
  );
};
