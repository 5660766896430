import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import './SpinnerPage.styles.scss';

import spinner from '@assets/lottie/spinner.json';

export const SpinnerPage = () => {
  const lottieRef = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    const loadSpinner = async () => {
      lottie.loadAnimation({
        autoplay: true,
        loop: true,
        container: lottieRef.current as unknown as HTMLElement,
        animationData: spinner,
        renderer: 'svg',
      });
    };
    if (!isMounted.current) {
      loadSpinner();
      isMounted.current = true;
    }
  }, []);

  return <div className="spinner-page" ref={lottieRef}></div>;
};
