import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import {
  CreateWallet,
  CreateWalletResponse,
  ListWalletsProps,
  UpdateWalletParams,
  WalletCategory,
  WalletDetailsProps,
  WalletStatus,
} from '@interfaces/Wallets.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';
import { UpdateWalletResponse } from './Wallet.interfaces';

export const getWalletByUser = async (
  userId: string
): Promise<WalletDetailsProps> => {
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/users/${userId}/wallets/default?details=true`
  );
  const { data } = response;

  return data;
};

export const createWallet = async (
  payload: CreateWallet
): Promise<CreateWalletResponse> => {
  const { data } = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }`,
    payload
  );

  return data;
};

export const updateWallet = async ({
  walletId,
  payload,
}: UpdateWalletParams): Promise<CreateWalletResponse> => {
  const { data } = await api.put(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}`,
    payload
  );

  return data;
};

export const getWalletData = async (
  walletId: string
): Promise<WalletDetailsProps> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}`
  );
  const { data } = response;

  return data;
};

export const getWalletsList = async (params?: {
  status: WalletStatus;
  name?: string;
  orderBy?: string;
  order?: string;
  next?: string;
  ownerIds?: string[];
}): Promise<ListWalletsProps> => {
  removeEmptyObjectAttributes(params || {});

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }`,
    { params }
  );
  const { data } = response;

  return data;
};

export const getWalletsListDefault = async (): Promise<WalletDetailsProps> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/default`
  );
  const { data } = response;

  return data;
};

export const clearWallet = async (walletId: string): Promise<unknown> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/clear-balance`
  );
  const { data } = response;

  return data;
};

export const deactivateWallet = async (walletId: string): Promise<unknown> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/block`
  );
  const { data } = response;

  return data;
};

export const updateWalletValues = async (
  walletId: string,
  payload: WalletCategory
): Promise<UpdateWalletResponse> => {
  const { pin, ...params } = payload;
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/transfers`,
    params,
    {
      headers: {
        pin,
      },
    }
  );

  const { data } = response;

  return data;
};
