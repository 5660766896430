import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { emptyInvoices, errorInvoices } from '@constants/emptyState';
import {
  Button,
  Card,
  EmptyState,
  Skeleton,
  Typography,
} from '@portao3-web/ui';
import { initialStateInvoice } from './constants/initialStateInvoice';
import { useInvoiceQuery } from './hooks/useInvoiceQuery';
import { AccordionItem } from './components/AccordionItem';

import './Invoices.styles.scss';
import { DebitWallet } from './components/DebitWallet';
import { InvoiceDealProps } from '@interfaces/Invoices';

export const Invoices = () => {
  const { t } = useTranslation();

  const [showDebitWallet, setShowDebitWallet] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDealProps>(
    {} as InvoiceDealProps
  );

  const {
    data: invoices,
    isLoading,
    isError,
    refetch: getServiceInvoice,
  } = useInvoiceQuery();

  return (
    <div className="invoices">
      <div className="invoices_accordion">
        {(isLoading ? initialStateInvoice.items : invoices?.items)?.map(
          (invoice, index) => {
            const accordionId = `accordion-${index}`;
            return (
              <Skeleton
                height={60}
                isLoading={isLoading}
                className="mb-2"
                key={index}
              >
                <AccordionItem
                  invoice={invoice}
                  accordionId={accordionId}
                  showDebitWallet={(value) => setShowDebitWallet(value)}
                  selectInvoice={(invoice) => setSelectedInvoice(invoice)}
                />
              </Skeleton>
            );
          }
        )}

        {invoices?.items?.length !== 0 && !isError && (
          <div className="invoices_pages">
            <Skeleton isLoading={isLoading} width={200} className="mb-16">
              <Typography
                tag="p"
                weight="p4"
                color="var(--product-neutral-n100)"
              >
                {t('invoices.quantity-invoices', {
                  perPage: invoices?.items?.length,
                  allItems: invoices?.items?.length,
                })}
              </Typography>
            </Skeleton>
          </div>
        )}
      </div>

      {invoices?.items?.length === 0 && (
        <div className="invoices_empty">
          <Card>
            <EmptyState
              direction="column"
              title={emptyInvoices.title}
              subtitle={emptyInvoices.subtitle}
              image={emptyInvoices.image}
            />
          </Card>
        </div>
      )}

      {isError && (
        <div className="invoices_empty">
          <Card>
            <EmptyState
              direction="column"
              title={errorInvoices.title}
              subtitle={errorInvoices.subtitle}
              image={errorInvoices.image}
            >
              <Button
                type="button"
                size="large"
                variant="primary"
                data-testid="try-again"
                onClick={() => getServiceInvoice()}
              >
                {t('button.try-again')}
              </Button>
            </EmptyState>
          </Card>
        </div>
      )}

      {showDebitWallet && (
        <DebitWallet
          closeModal={() => setShowDebitWallet(false)}
          invoice={selectedInvoice}
        />
      )}
    </div>
  );
};
