import dayjs from 'dayjs';

import { DownloadPdf } from '@components';
import { ICONS } from '@constants/icons';
import { TransactionBatchStatus } from '@enums/BatchPayment.enum';
import { TypePayment } from '@enums/typePayment.enum';
import { BatchTransaction } from '@interfaces/BatchPayments.interfaces';
import { Button, Spinner } from '@portao3-web/ui';
import { useTransactionQuery } from '@services/transactions/hooks';
import { PaymentVoucher } from './PaymentVoucher';

interface DownloadVoucherButtonProps {
  transaction: BatchTransaction;
  walletId: string;
}

export const DownloadVoucherButton = ({
  transaction,
  walletId,
}: DownloadVoucherButtonProps) => {
  const { data, isLoading, refetch } = useTransactionQuery({
    walletId,
    transactionId: transaction.transactionId ?? '',
    enabled: false,
  });

  const handleGetTransactionData = async () => {
    if (data) {
      downloadVoucher();
    } else {
      await refetch().then(() => setTimeout(() => downloadVoucher(), 500));
    }
  };

  const downloadVoucher = () => {
    document.getElementById(`download-voucher-${transaction.id}`)?.click();
  };

  const getTransactionType = (): TypePayment => {
    // TODO - refactor "if" chain (maybe improve the data structure to avoid it)
    let type = TypePayment.PIX;
    if (!data) return type;

    if ('pixTransaction' in data) {
      type = TypePayment.PIX;
    }
    if ('cardTransaction' in data) {
      type = TypePayment.CREDIT_CARD;
    }
    if ('boletoTransaction' in data) {
      type = TypePayment.BANK_SLIP;
    }
    if ('p2pTransaction' in data) {
      type = TypePayment.P2P;
    }

    return type;
  };

  return (
    <>
      <Button
        type="button"
        variant="tertiary"
        size="small"
        disabled={
          transaction.status !== TransactionBatchStatus.EXECUTED || isLoading
        }
        onClick={handleGetTransactionData}
      >
        {isLoading ? (
          <Spinner size="xsmall" />
        ) : (
          <i className="fa-regular fa-download fa-1x" />
        )}
      </Button>
      <DownloadPdf
        fileName={dayjs(transaction.transactionAt).format('DD-MM-YYYY')}
      >
        <DownloadPdf.Trigger>
          <button
            id={`download-voucher-${transaction.id}`}
            type="button"
            className="hidden"
          >
            <i className={`${ICONS.download} fa-1x`} />
          </button>
        </DownloadPdf.Trigger>
        <DownloadPdf.Content>
          <PaymentVoucher
            transactionInfo={{
              amount: transaction.amount,
              transactionAt: data?.effectiveAt.toString() ?? '',
              type: getTransactionType(),
              endToEndId: data?.pixTransaction?.receipt?.endToEndId,
            }}
            creditParty={transaction.creditParty}
          />
        </DownloadPdf.Content>
      </DownloadPdf>
    </>
  );
};
