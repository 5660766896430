export const MenuController = [
  {
    title: 'Carteiras',
    dropDown: {
      listItems: [],
    },
    menuList: [],
  },
  {
    title: 'Geral',
    dropDown: {},
    menuList: [
      {
        label: 'Carteiras',
        url: '/wallets?order=createdAt-DESC',
        icon: 'wallet',
        visibleOption: true,
      },
      {
        label: 'Transações',
        url: '/transactions',
        icon: 'arrow-right-arrow-left',
        visibleOption: false,
      },
      {
        label: 'Cartões',
        url: '/cards',
        icon: 'credit-card',
        visibleOption: false,
      },
      {
        label: 'Clientes',
        url: '/customers',
        icon: 'briefcase',
        visibleOption: false,
      },
      {
        label: 'Usuários',
        url: '/users',
        icon: 'users',
        visibleOption: false,
      },
    ],
  },
];
