import { InvoiceCustomerListParams } from '@interfaces/Invoices.interfaces';
import { UseFindStatementByIdParams } from '@services/invoices';

export const customersInvoicesKeys = {
  all: () => ['customersinvoices'],
  details: () => [...customersInvoicesKeys.all(), 'detail'],
  detail: (invoiceId: string) => [
    ...customersInvoicesKeys.details(),
    invoiceId,
  ],
  lists: () => [...customersInvoicesKeys.all(), 'list'],
  list: (invoiceId: string) => [...customersInvoicesKeys.lists(), invoiceId],
  listInvoices: (
    params?: InvoiceCustomerListParams & { organizationId: string | null }
  ) => [...customersInvoicesKeys.lists(), params],
  listGeneralInvoices: (
    params?: Omit<InvoiceCustomerListParams, 'customerId'> & {
      organizationId: string | null;
    }
  ) => [...customersInvoicesKeys.lists(), params],
};

export const customersInvoiceStatementsKeys = {
  all: () => ['customersInvoiceStatementsKeys'],
  details: () => [...customersInvoiceStatementsKeys.all(), 'detail'],
  detail: (props: UseFindStatementByIdParams) => [
    ...customersInvoiceStatementsKeys.details(),
    props,
  ],
  lists: () => [...customersInvoiceStatementsKeys.all(), 'list'],
  list: (invoiceId: string, buyer?: boolean) => [
    ...customersInvoiceStatementsKeys.lists(),
    invoiceId,
    buyer,
  ],
  listInvoices: (params?: InvoiceCustomerListParams) => [
    ...customersInvoiceStatementsKeys.lists(),
    params,
  ],
};
