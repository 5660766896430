import { Tabs } from '@portao3-web/ui';
import { ConfigPix, PixCopyPaste, PixQrCode } from './components';

import { NewPixProps } from './NewPix.interface';
import './NewPix.styles.scss';

export const NewPix = ({
  goNextStep,
  back,
  confirmPayment,
  walletId,
  balance,
  setDictType,
  setInitiatedValues,
}: NewPixProps) => {
  const tabs = [
    {
      id: '1',
      label: 'Pagar',
      content: (
        <ConfigPix
          goNextStep={goNextStep}
          back={back}
          confirmPayment={confirmPayment}
          walletId={walletId}
          balance={balance}
          setInitiatedValues={setInitiatedValues}
          setDictType={setDictType}
        />
      ),
    },
    {
      id: '2',
      label: 'QR Code',
      content: (
        <PixQrCode
          back={back}
          confirmPayment={confirmPayment}
          goNextStep={goNextStep}
          walletId={walletId}
        />
      ),
    },
    {
      id: '3',
      label: 'Copia e Cola',
      content: (
        <PixCopyPaste
          back={back}
          confirmPayment={confirmPayment}
          goNextStep={goNextStep}
          walletId={walletId}
          setDictType={setDictType}
        />
      ),
    },
  ];

  const resetDictKey = (value: string) => {
    if (value === '1') return;

    setInitiatedValues(null);
  };

  return (
    <div className="newPix">
      <Tabs onTabChange={resetDictKey} tabs={tabs}></Tabs>
    </div>
  );
};
