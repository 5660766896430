import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  ContextualError,
  ContextualErrorType,
  CUSTOM_FIELDS_MAPPER,
  InDrawerAuthentication,
  SidebarFooter,
  SidebarSection,
} from '@components';

import { ICONS } from '@constants/icons';
import { viewCategoryWallet, WalletBalanceCategory } from '@enums/WalletEnum';
import { useAuthentication } from '@hooks';
import {
  CustomFieldLocation,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { PixPaymentResponse } from '@interfaces/Pix.interface';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { CurrentUser } from '@interfaces/User.interfaces';
import { customerEvents } from '@lib';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import {
  Button,
  Column,
  DatePicker,
  Row,
  Select,
  Skeleton,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { useCustomFieldsQuery } from '@services/customFields/hooks';
import { userKeys } from '@services/user/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { documentMask, moneyMask } from '@utils/formatMasks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { FormPayment } from '../newPix/NewPix.interface';
import { initiatePixPayment } from '../newPix/services/PixService';
import './PaymentConfirmation.styles.scss';
import { ValueCardComponent } from './components/ValueCardComponent';
import { usePixPaymentConfirmationMutation } from './hooks/usePixPaymentConfirmationMutation';
import { cancelPixService } from './services/PaymentConfirmationService';

import { schedulingTransactionKeys } from '@services/scheduling';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface PaymentPixConfirmationProps {
  onConfirm: (isScheduling: boolean) => void;
  back: () => void;
  initiatedValues: FormPayment | null;
  paymentConfirm: PixPaymentResponse;
  paymentVoucher: (
    value: PixPaymentResponse | CreateTransactionSchedule
  ) => void;
  walletId?: string;
  walletName?: string;
  categoryType?: string;
  dictType?: string;
}

export const PaymentConfirmationPix = ({
  back,
  onConfirm,
  paymentConfirm,
  paymentVoucher,
  walletId,
  walletName,
  categoryType,
  dictType,
  initiatedValues,
}: PaymentPixConfirmationProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingCancelPix, setLoadingCancelPix] = useState(false);
  const [contextualError, setContextualError] =
    useState<ContextualErrorType | null>(null);
  const [isEditingValuePayment, setIsEditingValuePayment] = useState(false);
  const [hasEditedAmount, setHasEditedAmount] = useState(false);
  const [isUpdatingInitiatedPix, setIsUpdatingInitiatedPix] = useState(false);

  const {
    controller,
    authSubmit,
    pin,
    onAuthError,
    onAuthSuccess,
    isAuthentication,
  } = useAuthentication();

  const {
    mutate: confirmPixPaymentMutate,
    isPending: isPendingPixConfirmation,
  } = usePixPaymentConfirmationMutation();

  const {
    data: customFields,
    error: customFieldsError,
    isLoading: isLoadingCustomFields,
  } = useCustomFieldsQuery({
    organization: localStorage.getItem('organization'),
    location: CustomFieldLocation.TRANSACTION,
  });

  const form = useForm<FormPayment>({
    mode: 'onBlur',
    defaultValues: {
      amount: calcCurrencyValue(paymentConfirm.txnUpdatedAmount, 'BRL'),
      processingDate: dayjs()
        .startOf('day')
        .toDate()
        .toISOString() as unknown as Date,
    },
  });

  const isCOBV = paymentConfirm?.qrCodeType === 'COBV';
  const isAfterDueDate = dayjs()
    .startOf('day')
    .isAfter(dayjs(paymentConfirm?.dueDate).utc(false).startOf('day'));

  const isPaymentToday =
    dayjs(form.watch('processingDate')).utc(false).format('YYYY-MM-DD') ===
    dayjs().format('YYYY-MM-DD');

  const confirmPixPayment = async (
    formData: FormPayment,
    confirmationPaymentId: string
  ) => {
    const formattedCustomFields = formData.customFields
      ? Object.entries(formData.customFields).map(([key, value]) => {
          const field = customFields?.items?.find(
            (field) => field.identifier === key
          );

          const getMultiSelectValue = () => {
            if (value.length < 1) return [];
            if (typeof value === 'string') return [value];
            return value;
          };

          const getFieldValues = () => {
            const result = value;
            if (field?.type === CustomFieldType.NUMBER) {
              return [value.replace(/\D/g, '')];
            }
            return result ? [result] : [];
          };

          return {
            id: field?.id ?? '',
            label: field?.label ?? '',
            values:
              field?.type === CustomFieldType.MULTI_SELECT
                ? getMultiSelectValue()
                : field?.type === CustomFieldType.DATE
                  ? dayjs(value)?.toISOString()
                  : getFieldValues(),
            identifier: key,
            version: field?.version ?? 1,
          };
        })
      : [];

    const payload = {
      amount: Number(form.watch('amount').replace(/\D/g, '')),
      category: categoryType || WalletBalanceCategory.FLEX_INTERNATIONAL,
      id: confirmationPaymentId,
      customFields: formattedCustomFields,
      processingDate: dayjs(formData.processingDate)
        .utc(false)
        .format('YYYY-MM-DD'),
      description: paymentConfirm?.creditParty?.name,
    };

    confirmPixPaymentMutate(
      { payload, walletId, pin: pin || undefined },
      {
        onSuccess: (response) => {
          onAuthSuccess();
          paymentVoucher(response);

          const pixType =
            dictType && dictType === 'COPY_PASTE'
              ? 'COPY_PASTE'
              : !dictType
                ? 'QRCODE'
                : 'DICT';

          if ('initiationType' in response) {
            if ('qrCodeType' in response) {
              customerEvents.pixConfirmed({
                amount: response.txnOriginalAmount,
                type: 'QRCODE',
                dictType: response.qrCodeType,
                walletId: response.walletId,
              });
            } else {
              customerEvents.pixConfirmed({
                amount: response.txnOriginalAmount,
                type: response.initiationType,
                dictType,
                walletId: response.walletId,
              });
            }
          }

          if (
            !isPaymentToday &&
            ('pixPayment' in response || 'pixTransfer' in response)
          ) {
            customerEvents.pixScheduledConfirmed({
              originalAmount: paymentConfirm.txnOriginalAmount,
              updatedAmount: payload.amount,
              pixType,
              walletId: response?.wallet,
              dueDate: response?.pixPayment?.dueAt || null,
              scheduleDate: response?.scheduledAt,
              scheduleId: response?.id,
              qrCodeType: paymentConfirm?.qrCodeType,
              initiationType: paymentConfirm.initiationType,
            });
          }

          refetchWalletData();
          setLoading(false);
          onConfirm(!isPaymentToday);

          queryClient.setQueryData(
            userKeys.current(),
            (oldData: CurrentUser) => {
              return {
                ...oldData,
                pinErrorsCount: 0,
              };
            }
          );

          queryClient.invalidateQueries({
            queryKey: schedulingTransactionKeys.lists(),
          });
        },
        onError: (error) => {
          onAuthError(error);
          setLoading(false);

          setContextualError({
            message: t(
              `error.CODE_ERROR.${error?.response?.data?.code}`,
              t('error.default')
            ),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const submitFormPix = async (formData: FormPayment) => {
    setLoading(true);
    setContextualError(null);
    confirmPixPayment(formData, paymentConfirm._id);
  };

  const cancelPix = async () => {
    setLoadingCancelPix(true);
    try {
      await cancelPixService(paymentConfirm._id, walletId);

      setLoadingCancelPix(false);
      back();
    } catch (error) {
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;
      setLoadingCancelPix(false);

      setContextualError({
        message: t('error.default'),
        traceId: errorInfo?.traceId,
      });
    }
  };

  const renderCustomFields = () => {
    return customFieldsError ? (
      <Alert status="error">
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {t('error.loadCustomFields')}
        </Typography>
      </Alert>
    ) : (
      customFields?.items
        ?.slice()
        ?.reverse()
        ?.map((field) => {
          const Component =
            CUSTOM_FIELDS_MAPPER[
              field.type as keyof typeof CUSTOM_FIELDS_MAPPER
            ];

          return (
            Component && (
              <Component form={form} fieldSchema={field} key={field.id} />
            )
          );
        })
    );
  };

  const refetchWalletData = () => {
    queryClient.refetchQueries({
      queryKey: [
        'wallet',
        {
          walletId,
          organizationId: localStorage.getItem('organization'),
        },
      ],
    });
  };

  const confirmPaymentValue = async (value: string) => {
    setHasEditedAmount(true);
    if (value === form.watch('amount')) return;

    if (paymentConfirm?.txnAllowAmountChange) {
      return form.setValue('amount', value);
    }

    if (!initiatedValues) return;

    cancelPixService(paymentConfirm._id, walletId);

    try {
      setIsUpdatingInitiatedPix(true);
      form.setValue('amount', value);

      const pixInitiated = await initiatePixPayment(
        { ...initiatedValues, amount: value?.replace(/\D/g, '') },
        walletId
      );

      paymentVoucher(pixInitiated);
      form.setValue('amount', moneyMask(pixInitiated.txnUpdatedAmount));
    } catch (error) {
      form.setValue('amount', moneyMask(paymentConfirm.txnUpdatedAmount));
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;
      setContextualError({
        message: t('error.default'),
        traceId: errorInfo?.traceId,
      });
    } finally {
      setIsUpdatingInitiatedPix(false);
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit((values) =>
        authSubmit(() => submitFormPix(values))
      )}
      data-testid="pix-copy-paste"
    >
      {!isAuthentication && (
        <div className="flex flex-col gap-2">
          {contextualError && <ContextualError error={contextualError} />}

          <SidebarSection
            titleSection={t('payment-voucher.pix-confirmation')}
            subTitleSection={t('payment-voucher.confirm-transaction-value')}
          >
            <ValueCardComponent
              key={
                form.watch('processingDate').toString() + isEditingValuePayment
              }
              currentValue={form.watch('amount')}
              setCurrentValue={confirmPaymentValue}
              isEditing={isEditingValuePayment}
              setIsEditing={setIsEditingValuePayment}
              isLoading={isUpdatingInitiatedPix}
              isEditable={
                paymentConfirm.txnAllowAmountChange || !!initiatedValues?.dict
              }
              icon={ICONS.pix}
            />
          </SidebarSection>

          <div className="flex flex-col">
            <p className="text-p3 text-neutral-80">
              {t('payment-voucher.transfer-type')}
            </p>

            <p className="text-p2 text-neutral-400">{t('general.pix')}</p>
          </div>

          <hr className="hr" />

          <SidebarSection
            titleSection={t('payment-voucher.payment-date')}
            subTitleSection={t('payment-voucher.when-will-paid')}
          >
            {!isCOBV && (
              <div onClick={() => setIsEditingValuePayment(false)}>
                <DatePicker
                  size="md"
                  date={form.watch('processingDate')}
                  setDate={(date) => {
                    form.setValue('processingDate', date || new Date());
                  }}
                  disabled={{
                    before: dayjs().startOf('day').toDate(),
                    after: paymentConfirm?.expiresAt
                      ? dayjs(paymentConfirm?.expiresAt).startOf('day').toDate()
                      : undefined,
                  }}
                />
              </div>
            )}

            {isCOBV && (
              <div
                onClick={() => setIsEditingValuePayment(false)}
                className={clsx({
                  '!w-[254px] [&>div]:mb-0': true,
                  '[&_option]:text-primary-500 [&_*]:text-primary-500':
                    !isAfterDueDate,
                  '[&_option]:text-neutral-80 [&_*]:text-neutral-80':
                    isAfterDueDate,
                })}
              >
                <Select
                  label=""
                  options={[
                    {
                      value: dayjs().startOf('day').toDate().toISOString(),
                      label: `${dayjs().format('DD/MM/YYYY')} (Hoje)`,
                    },
                    {
                      value: dayjs(paymentConfirm?.dueDate)
                        .utc(false)
                        .toString(),
                      label: `${dayjs(paymentConfirm?.dueDate).utc(false).format('DD/MM/YYYY')} (Vencimento)`,
                    },
                  ]}
                  name="processingDate"
                  form={form}
                  onclick={(value) => {
                    form.setValue('processingDate', value as unknown as Date);

                    if (hasEditedAmount) return;

                    const isToday =
                      value.toString() ===
                      dayjs().startOf('day').toDate().toISOString();

                    if (isToday) {
                      return form.setValue(
                        'amount',
                        moneyMask(paymentConfirm.txnUpdatedAmount)
                      );
                    }

                    form.setValue(
                      'amount',
                      moneyMask(paymentConfirm.txnOriginalAmount)
                    );
                  }}
                  icon={ICONS.calendar}
                  disabled={isAfterDueDate}
                />
              </div>
            )}
          </SidebarSection>

          <hr className="hr mt-4" />

          {isCOBV && (
            <>
              <SidebarSection
                titleSection={t('general.payment-details')}
                subTitleSection={t('payment-voucher.see-pix-data')}
              >
                <div className="grid grid-cols-2 gap-4">
                  <ValueWrapper
                    label={t('payment-voucher.total-to-pay')}
                    value={form.watch('amount')}
                  />

                  {paymentConfirm.dueDate && (
                    <ValueWrapper
                      label={t('payment-voucher.due-date')}
                      value={dayjs(paymentConfirm.dueDate)
                        .utc(false)
                        .format('DD/MM/YYYY')}
                    />
                  )}

                  <ValueWrapper
                    label={t('payment-voucher.expired-date')}
                    value={dayjs(paymentConfirm.expiresAt)
                      .utc(false)
                      .format('DD/MM/YYYY')}
                  />

                  <ValueWrapper
                    label={t('general.original-amount')}
                    value={moneyMask(paymentConfirm.txnOriginalAmount)}
                  />

                  <ValueWrapper
                    label={t('general.discounts')}
                    value={moneyMask(
                      isPaymentToday ? paymentConfirm.txnDiscountAmount : 0
                    )}
                  />
                  <ValueWrapper
                    label={t('general.interest')}
                    value={moneyMask(
                      isPaymentToday ? paymentConfirm.txnInterestAmount : 0
                    )}
                  />
                  <ValueWrapper
                    label={t('general.fine')}
                    value={moneyMask(
                      isPaymentToday ? paymentConfirm.txnFineAmount : 0
                    )}
                  />
                </div>
              </SidebarSection>

              <hr className="hr" />
            </>
          )}

          {'debitParty' in paymentConfirm && (
            <SidebarSection
              titleSection={t('payment-voucher.your-account')}
              subTitleSection={t('payment-voucher.origin-account')}
            >
              <div className="grid grid-cols-2 gap-4">
                <ValueWrapper
                  label={t('general.from')}
                  value={paymentConfirm?.debitParty?.name}
                />

                <ValueWrapper label={t('general.wallet')} value={walletName} />

                <ValueWrapper
                  label={t('general.category')}
                  value={
                    viewCategoryWallet[
                      categoryType as keyof typeof viewCategoryWallet
                    ]
                  }
                />

                <ValueWrapper
                  label={'CPF/CNPJ'}
                  value={documentMask(paymentConfirm?.debitParty?.document)}
                />
              </div>
            </SidebarSection>
          )}

          <hr className="hr" />

          {'creditParty' in paymentConfirm && (
            <SidebarSection
              titleSection={t('payment-voucher.destination-account')}
              subTitleSection={t('payment-voucher.destination-account-info')}
            >
              <div className="grid grid-cols-1 gap-4">
                <ValueWrapper
                  label={t('general.to')}
                  value={paymentConfirm?.creditParty?.name}
                />

                <ValueWrapper
                  label="CPF/CNPJ"
                  value={documentMask(paymentConfirm?.creditParty?.document)}
                />

                <ValueWrapper
                  label={t('general.pix-key')}
                  value={paymentConfirm?.creditParty.key}
                />
              </div>
            </SidebarSection>
          )}

          {customFields?.items && customFields?.items?.length > 0 && (
            <>
              <hr className="hr" />

              <div style={{ marginTop: 16 }}>
                <SidebarSection
                  titleSection={t('customFields.drawerBlockTitle')}
                >
                  <Skeleton
                    isLoading={loading || isLoadingCustomFields}
                    width={520}
                    height={30}
                  >
                    <Row>
                      <Column lg={12} md={12} sm={12}>
                        {renderCustomFields()}
                      </Column>
                    </Row>
                  </Skeleton>
                </SidebarSection>
              </div>
            </>
          )}

          <SidebarFooter>
            <Button
              type="button"
              variant="tertiary"
              size="large"
              data-testid="cancelPix"
              disabled={loadingCancelPix || isUpdatingInitiatedPix}
              onClick={cancelPix}
            >
              {loadingCancelPix ? (
                <Spinner variant="primary" />
              ) : (
                t('general.button.cancel')
              )}
            </Button>

            <Button
              type="submit"
              size="large"
              className="button_submit"
              data-testid="submitForm"
              isLoading={loading}
              disabled={loadingCancelPix || isUpdatingInitiatedPix}
            >
              {isPaymentToday
                ? t('schedule-payment.end-transfer')
                : t('schedule-payment.schedule-transfer')}
            </Button>
          </SidebarFooter>
        </div>
      )}

      <InDrawerAuthentication
        isPending={isPendingPixConfirmation}
        {...controller}
      />
    </form>
  );
};
