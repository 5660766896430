import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import {
  getInvoiceEntries,
  paymentInvoiceP2P,
} from '../services/InvoicesService';
import { ContextualErrorType } from '@components';
import { Entry, InvoicesProps } from '@interfaces/Invoices.interfaces';
import { invoiceKeys } from './InvoiceKeys';
import { Toastr } from '@portao3-web/ui';
import { useTranslation } from 'react-i18next';
import { ListResponse } from '@interfaces/Common.interfaces';

export const useGetEntriesInvoice = () => {
  return useMutation<ListResponse<Entry>, ApiError, string, unknown>({
    mutationFn: (invoiceId: string) => getInvoiceEntries(invoiceId),
  });
};

export const usePaymentInvoiceP2PMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<InvoicesProps, ApiError, string, unknown>({
    mutationFn: (invoiceId: string) => paymentInvoiceP2P(invoiceId),
    onSuccess: (response) => {
      queryClient.setQueryData(
        invoiceKeys.list(),
        (oldData: ListResponse<InvoicesProps>) => {
          return {
            ...oldData,
            items: oldData.items.map((item) => {
              if (item.id === response.id) {
                return {
                  ...item,
                  status: 'PAID',
                };
              }
              return item;
            }),
          };
        }
      );
      Toastr.success(`Fatura paga com sucesso!`);
    },
    onError: (error: ApiError) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
