export enum BillingStatusEnum {
  ISSUED = 'ISSUED',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
}

export enum BillingType {
  PIX_QR_CODE = 'PIX_QR_CODE',
  BILLET = 'BILLET',
}

export enum FeeType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export enum StatusBillingEnum {
  PAID = 'PAID',
  DELAYED = 'DELAYED',
  OPEN = 'OPEN',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
}
