import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { customerEvents } from '@lib/customerIo';
import { ModalLimitAccess, ModalProtectAccount } from '@providers';
import { useUser } from '..';
import { DRAWER_CONFIG, DrawerKey, DrawerParams } from './drawerConfig';

type CurrentModal = 'closed' | 'protectAccount' | 'limitAccess';

type ModalMap = {
  [key in CurrentModal]: React.ReactNode | null;
};

interface DrawerContextProps {
  drawer: ReactElement | null;
  openDrawer: <K extends DrawerKey>(
    drawer: K | ReactElement | null,
    params?: DrawerParams<K>
  ) => void;
  closeDrawer: () => void;
  removeParams: (params: string[]) => void;
  params?: Record<string, string | undefined>;
  // Modal
  currentModal: CurrentModal;
  openModal: (key: CurrentModal) => void;
  closeModal: () => void;
}

interface DrawerProviderProps {
  children: ReactNode | ReactNode[];
}

export const startDrawerContext: DrawerContextProps = {
  drawer: null,
  openDrawer: () => '',
  closeDrawer: () => '',
  removeParams: () => '',
  params: {},
  currentModal: 'closed',
  openModal: () => {
    return;
  },
  closeModal: () => {
    return;
  },
};

export const DrawerContext =
  createContext<DrawerContextProps>(startDrawerContext);

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();

  const [drawer, setDrawer] = useState<ReactElement | null>(null);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );

  const openDrawer = <K extends DrawerKey>(
    component: K | ReactElement | null,
    params?: DrawerParams<K>
  ) => {
    if (typeof component === 'string') {
      searchParams.set('drawer', component);
      if (params) {
        Object.entries(params).forEach(([key, value]) => {
          value && searchParams.set(key, value);
        });
      }
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    } else {
      setDrawer(component);
    }
  };

  const closeDrawer = () => {
    const drawerKey = searchParams.get('drawer') as DrawerKey;
    const drawer = DRAWER_CONFIG[drawerKey as keyof typeof DRAWER_CONFIG];

    if (drawer?.params?.length && drawer.params.length > 0) {
      drawer.params.forEach((param) => {
        searchParams.delete(param);
      });
    }
    searchParams.delete('drawer');

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });

    setDrawer(null);
  };

  const removeParams = (params: string[]) => {
    params.forEach((param) => {
      searchParams.delete(param);
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    const drawerKey = searchParams.get('drawer');
    const drawerRoute = DRAWER_CONFIG[drawerKey as keyof typeof DRAWER_CONFIG];

    const canRender = () => {
      if (!user) return false;

      if (drawerRoute?.permissions) {
        for (const permission of drawerRoute.permissions) {
          if (user[permission as keyof typeof user]) return true;
        }
        return false;
      }
      return true;
    };

    if (!drawerRoute) return;

    if (canRender()) {
      const Component = drawerRoute.component;
      Component && setDrawer(<Component />);
    } else {
      searchParams.delete('drawer');
      setDrawer(null);
    }
  }, [searchParams, user]);

  useEffect(() => {
    const handlePopState = () => {
      if (window.location.href.includes('drawer')) {
        navigate(`${location.pathname}`, {
          replace: true,
        });
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO - Separate Drawer and Modal Providers
  const [currentModal, setCurrentModal] = useState<CurrentModal>('closed');

  const closeModal = () => setCurrentModal('closed');

  const modalMap: ModalMap = {
    closed: null,
    protectAccount: <ModalProtectAccount />,
    limitAccess: <ModalLimitAccess />,
  };

  const openModal = (key: CurrentModal) => {
    setCurrentModal(key);
  };

  useEffect(() => {
    const isSecurityAlertSeen = localStorage.getItem('securityAlertSeen');
    const hasAllProtections = user.hasPin && user.hasMfa;

    if (!!user.id && !hasAllProtections && !isSecurityAlertSeen) {
      setCurrentModal('protectAccount');

      localStorage.setItem('securityAlertSeen', 'true');

      customerEvents.securityAlertSeen({
        hasMfa: !!user.hasMfa,
        hasPin: !!user.hasPin,
      });
    }
  }, [user]);

  return (
    <DrawerContext.Provider
      value={{
        drawer,
        openDrawer,
        closeDrawer,
        params: Object.fromEntries(searchParams.entries()),
        removeParams,
        currentModal,
        openModal,
        closeModal,
      }}
    >
      {children}

      {drawer && createPortal(drawer, document.body)}

      {modalMap[currentModal]}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }

  return context;
};
