import { ErrorCodes, UserStatusCodes } from '@enums/ErrorCodes.enum';
import { AuthToken } from '@interfaces/Auth.interfaces';
import { resetCustomerIoIdentity } from '@lib/customerIo';
import { refreshToken } from '@services/auth/AuthService';
import { decryptToken, encryptToken } from '@utils/crypto';
import axios from 'axios';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

const api = axios.create({
  paramsSerializer: { indexes: null },
});

const getAccessToken = () => {
  const token = localStorage.getItem('token');

  if (token) {
    const parsedToken = JSON.parse(token);

    return decryptToken(parsedToken).accessToken;
  }
};

const getRefreshToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const parsedToken = JSON.parse(token);

    return decryptToken(parsedToken);
  }
};

api.interceptors.request.use(
  (config) => {
    if (getAccessToken() && !config.url?.includes('viacep.com.br')) {
      config.headers.Authorization = `Bearer ${getAccessToken()}`;
      config.headers.timeZone = dayjs.tz.guess();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status === 404 &&
      error?.response?.data?.code !== ErrorCodes.USER_NOT_FOUND &&
      error?.response?.data?.code !== 'PIX_KEY_NOT_FOUND' &&
      !error?.config?.url?.includes('member-get-member')
    ) {
      window.location.replace('/unavailable');
    }

    if (
      error?.response?.status === 401 &&
      error?.response?.data?.code !== ErrorCodes.INVALID_CREDENTIALS
    ) {
      try {
        const response = await refreshToken(
          (getRefreshToken() as AuthToken) || ''
        );

        localStorage.setItem('token', JSON.stringify(encryptToken(response)));
        const originalRequest = error?.config;
        return api(originalRequest);
      } catch {
        localStorage.clear();
        resetCustomerIoIdentity();
        window.location.href = '/';
      }
    }

    if (error?.response?.status === 403 || error?.response?.status === 503) {
      const isPin = !!error?.response?.data?.pinErrorsCount;
      const isBlockedByPin =
        error?.response?.data?.code === UserStatusCodes.BLOCKED_BY_PIN;

      if (!isPin && !isBlockedByPin) {
        window.location.replace('/unauthorized');
      }
    }
    return Promise.reject(error);
  }
);

export default api;
