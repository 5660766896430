import { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContextualError, SidebarFooter, SidebarSection } from '@components';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import { customerEvents } from '@lib/customerIo';
import { Button, ErrorMessage, Icon, Input } from '@portao3-web/ui';
import { bankSlipMask, removeBankSlipMask } from '@utils/formatMasks';
import { FormTicket, NewTicketProps } from './NewTicket.interface';
import { initiateBankSlipPayment } from './services/BankSlipService';

export const NewTicket = ({
  onInitiate,
  confirmPayment,
  back,
  walletId,
}: NewTicketProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorService, setErrorService] = useState<string | null>(null);
  const [traceId, setTraceId] = useState('');

  const form = useForm<FormTicket>({
    mode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const submitFormBankSlip = async (formData: FormTicket) => {
    const form = {
      ...formData,
      barcode: removeBankSlipMask(formData.barcode),
    };

    setLoading(!loading);
    setErrorService(null);
    try {
      const response = await initiateBankSlipPayment(form, walletId);
      confirmPayment(response);

      customerEvents.boletoInitiated({
        dueDate: response.dueDate,
        txnOriginalAmount: response.txnOriginalAmount,
        txnUpdatedAmount: response.txnUpdatedAmount,
        walletId: response.walletId,
      });

      setLoading(false);
      onInitiate();
    } catch (error) {
      const errorService = error as AxiosError;
      const errorInfo = errorService.response?.data as ErrorRequest;

      setErrorService(
        t(`error.CODE_ERROR.${errorInfo?.code}`, t('error.default'))
      );
      setLoading(false);
      setTraceId(errorInfo.traceId || '');
    }
  };

  return (
    <div className="newTicket">
      {errorService && (
        <ContextualError error={{ message: errorService, traceId }} />
      )}
      <form
        onSubmit={handleSubmit(submitFormBankSlip)}
        data-testid="new-ticket"
      >
        <SidebarSection
          numberSection={1}
          titleSection="Pagamento do boleto"
          subTitleSection="Digite para fazer o pagamento"
        >
          <Input
            type="text"
            label="Código de barras"
            placeholder="Código de barras"
            id="barcode"
            name="barcode"
            register={register}
            data-testid="barcode"
            error={!!errors['barcode']}
            onChange={(value) => {
              form.setValue('barcode', bankSlipMask(value.target.value), {
                shouldValidate: true,
              });
            }}
            maxLength={60}
            required
            validationSchema={{
              required: 'O código de barras é obrigatório',
              validate: (value: string) => {
                if (
                  removeBankSlipMask(value).length !== 47 &&
                  removeBankSlipMask(value).length !== 48
                ) {
                  return 'Código de barras inválido';
                }
              },
            }}
          >
            {errors['barcode']?.message && (
              <ErrorMessage message={errors['barcode'].message.toString()} />
            )}
          </Input>
        </SidebarSection>

        <SidebarFooter>
          {form.watch('barcode') && form.watch('barcode')?.length > 0 ? (
            <Button
              onClick={() => {
                reset();
              }}
              variant="tertiary"
              size="large"
              type="reset"
              data-testid="resetForm"
              disabled={loading}
            >
              Limpar campos
              <Icon size="large">
                <i className="fa-regular fa-trash"></i>
              </Icon>
            </Button>
          ) : (
            <Button
              type="button"
              variant="tertiary"
              size="large"
              onClick={back}
              disabled={loading}
            >
              Voltar
            </Button>
          )}

          <Button
            type="submit"
            size="large"
            disabled={
              !form.watch('barcode') || form.watch('barcode')?.length <= 46
            }
            data-testid="submitForm"
            className="button_submit"
            isLoading={loading}
          >
            Continuar
          </Button>
        </SidebarFooter>
      </form>
    </div>
  );
};
