import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StatusTag } from '@components';
import { useInfiniteOrganizationListQuery } from '@services/organization/hooks';
import { DataTable } from '@templates';
import { cnpjMask } from '@utils/formatMasks';

export const RealmCompanies = () => {
  const { t } = useTranslation();

  const { data, hasNextPage, isLoading, fetchNextPage, fetchStatus } =
    useInfiniteOrganizationListQuery();

  const organizations = useMemo(
    () =>
      data?.map((item) => ({
        legalName: item.organization.legalName,
        tradingName: item.organization.tradingName,
        cnpj: cnpjMask(item.organization.document),
        status: <StatusTag status={item.organization.status} type="GENERAL" />,
      })) ?? [],
    [data]
  );

  return (
    <DataTable
      pageTitle={t('companies.page-title')}
      pageSubtitle={t('companies.page-subtitle')}
      tableTitle={t('companies.table-title')}
      tableHeader={[
        t('general.legal-name'),
        t('general.trading-name'),
        'CNPJ',
        t('general.status'),
      ]}
      hasMoreItems={hasNextPage}
      isLoading={isLoading}
      loadMoreItems={fetchNextPage}
      isFetchingMore={fetchStatus === 'fetching'}
      data={organizations}
    />
  );
};
