import { UseInfiniteWalletListQuery } from './useInfiniteWalletsQuery';
import { UseUserWalletQueryParams } from './useUserWalletQuery';
import { UseWalletQueryParams } from './useWalletQuery';

export const walletKeys = {
  all: () => ['wallet'],
  details: () => [...walletKeys.all(), 'detail'],
  detail: (params: UseWalletQueryParams) => [...walletKeys.details(), params],
  userDetail: (params: UseUserWalletQueryParams) => [
    ...walletKeys.details(),
    'user',
    params,
  ],
  lists: () => [...walletKeys.all(), 'list'],
  list: (params: UseInfiniteWalletListQuery) => [...walletKeys.lists(), params],
};
