import { endpoint } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { AuthToken } from '@interfaces/Auth.interfaces';
import api from '@services/api';

export const refreshToken = async (token: AuthToken): Promise<AuthToken> => {
  const response = await api.post(
    `${environment.identityBaseUrl}${endpoint.refreshToken}`,
    {
      refreshToken: token.refreshToken,
      accessToken: token.accessToken,
    }
  );
  const { data } = response;

  return data;
};
