import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { deleteCustomer } from '../deleteCustomer';
import { customerKeys } from './customerKeys';

export const useDeleteCustomerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ApiError, string, unknown[]>({
    mutationFn: (customerId: string) => deleteCustomer(customerId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customerKeys.all() });
    },
  });
};
