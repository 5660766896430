import { Icon } from '@portao3-web/ui';

interface CardSuportProps {
  text: string;
  icon: string;
  onClick: () => void;
}

export const CardSupport = ({ onClick, icon, text }: CardSuportProps) => {
  return (
    <div
      onClick={onClick}
      role="button"
      className="bg-neutral-20 hover:bg-neutral-30 flex h-32 w-full flex-col items-center justify-center gap-4 rounded-lg p-4 shadow transition delay-100 ease-in-out"
    >
      <Icon size="large">
        <i className={`${icon} text-primary-500 fa-2xl`} />
      </Icon>

      <span className="text-p2 text-neutral-100">{text}</span>
    </div>
  );
};
