import { Icon, Tag } from '@portao3-web/ui';
import { IconType, StatusTypeMap } from './StatusTag.interfaces';
import { statusHookMapper } from './useIcons';

type EnumStatusTypeMapper = {
  [K in IconType]: {
    status: StatusTypeMap[K];
    type: K;
  };
};

type StatusTagProps<T extends IconType> = EnumStatusTypeMapper[T];

export const StatusTag = <T extends IconType>({
  status,
  type,
}: StatusTagProps<T>) => {
  const items = statusHookMapper[type]();
  const item = items[status as keyof typeof items];

  return (
    <Tag
      text={item?.label}
      showCloseButton={false}
      textColor="var(--product-neutral-n100)"
      backgroundColor="var(--product-neutral-n20)"
      icon={
        <Icon size="small">
          <i className={`fa-1x ${item?.icon}`} />
        </Icon>
      }
    />
  );
};
