import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { schedulingTransactionKeys } from '@services/scheduling';
import {
  FutureTransactionsProps,
  TransactionScheduleProps,
} from '../../interface/scheduling.interface';
import { findScheduleList } from '../findScheduleList';

export const useInfiniteScheduleList = (
  props: Omit<FutureTransactionsProps, 'next'>
) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    ListResponse<TransactionScheduleProps>,
    ApiError,
    InfiniteData<ListResponse<TransactionScheduleProps>>,
    unknown[],
    string
  >({
    queryKey: schedulingTransactionKeys.list(props, organizationId),
    queryFn: ({ pageParam }) => findScheduleList({ ...props, next: pageParam }),
    getNextPageParam: (lastPage) => lastPage?.next || null,
    initialPageParam: '',
    enabled: !!organizationId,
  });
};
