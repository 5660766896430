import { ReactElement } from 'react';

import {
  DrawerBatchPayment,
  DrawerCreateCard,
  DrawerCreateCustomer,
  DrawerEditRealmUser,
  DrawerEditWallet,
  DrawerFundsIn,
  DrawerSecurity,
  DrawerUpdateWallet,
} from '@modules/drawer';

import { BatchPaymentStatus } from '@enums/BatchPayment.enum';

type DrawerPermission = 'isRealmAdmin' | 'isAdmin';

type DrawerConfig<K extends keyof DrawerKeyParamsMap> = {
  component: React.FC;
  params?: (keyof DrawerKeyParamsMap[K])[];
  permissions?: DrawerPermission[];
};

export type DrawerKeyParamsMap = {
  'edit-wallet': { walletId?: string };
  'edit-realm-user': { userId?: string };
  'create-payer-client': { flow?: string; customerId?: string };
  'wallet-balance': { walletId?: string; userId?: string };
  'create-card': { walletId?: string };
  'funds-in': { flow?: string; billingId?: string; walletId?: string };
  'batch-payment': {
    batchId?: string;
    walletId?: string;
    batchStatus?: BatchPaymentStatus;
  };
  security: {
    flow?: 'PIN' | 'MFA' | 'DEFAULT';
  };
};

export type OpenDrawerParams = DrawerKey | ReactElement | null;

export type DrawerKey = keyof DrawerKeyParamsMap;

export type DrawerParams<K extends DrawerKey> = DrawerKeyParamsMap[K];

export const DRAWER_CONFIG: { [K in DrawerKey]: DrawerConfig<K> } = {
  'edit-wallet': { component: DrawerEditWallet, params: ['walletId'] },
  'edit-realm-user': {
    component: DrawerEditRealmUser,
    params: ['userId'],
    permissions: ['isRealmAdmin', 'isAdmin'],
  },
  'create-payer-client': {
    component: DrawerCreateCustomer,
    params: ['flow', 'customerId'],
  },
  'funds-in': {
    component: DrawerFundsIn,
    params: ['flow', 'billingId', 'walletId'],
  },
  'wallet-balance': {
    component: DrawerUpdateWallet,
    params: ['walletId', 'userId'],
  },
  'create-card': {
    component: DrawerCreateCard,
    params: ['walletId'],
  },
  'batch-payment': {
    component: DrawerBatchPayment,
    params: ['batchId', 'walletId', 'batchStatus'],
  },
  security: {
    component: DrawerSecurity,
    params: ['flow'],
  },
};
