import { useUser } from '@providers';

export const useV4Validator = () => {
  const user = useUser();

  const organizationId = localStorage.getItem('organization') || '';

  const realmIdV4 = '80bc8e96-16e2-4cb8-a603-4e987027e2b9';
  const organizationAdminV4 = '85da5755-74db-4ba2-8fc2-fd02fbff9782';

  const unlimitedActionsOrgs = [
    organizationAdminV4,
    'bb294b05-0870-4aed-b114-ae26672ec741',
    '965996c5-76db-4b42-9be2-b827819634e1',
    '845a13fc-cc62-4b63-8dbf-67b8c6f68d37',
    '2132cab9-4532-4f5b-8bb5-5f079dc67878',
    '5e1abc5a-c701-4c06-b0cd-efddf7226e1a',
  ];

  const isV4 = user.realm?.id === realmIdV4;
  const hasLimitedActions = !unlimitedActionsOrgs.includes(organizationId);
  const isFilialV4 = isV4 && hasLimitedActions;

  return {
    isV4,
    isFilialV4,
  };
};

// Unlimited organizations
// bb294b05-0870-4aed-b114-ae26672ec741 - YURI F DA SILVA RAMOS LTDA
// 965996c5-76db-4b42-9be2-b827819634e1 - V4 COMPANY NUNES E ASSOCIADOS SERVICOS DE MARKETING LTDA
// 845a13fc-cc62-4b63-8dbf-67b8c6f68d37 - ACAIACA MARKETING DE RESULTADO LTDA
// 2132cab9-4532-4f5b-8bb5-5f079dc67878 - MATHEUS MUNDIM MARKETING DIGITAL LTDA
// 5e1abc5a-c701-4c06-b0cd-efddf7226e1a - LORENSI MARKETING & CO. LTDA
