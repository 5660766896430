import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContextualErrorType } from '@components';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { useUser } from '@providers/UserProvider';
import { createMemberGetMemberId } from '@services/user';
import { userKeys } from './userKeys';

export const useMemberGetMemberIdMutation = () => {
  const queryClient = useQueryClient();
  const { id } = useUser();
  const { t } = useTranslation();

  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<
    {
      id: string;
      user: string;
    },
    ApiError,
    void,
    unknown
  >({
    mutationFn: () => createMemberGetMemberId(),
    onSuccess: (response) => {
      queryClient.setQueryData(userKeys.memberGetMember(id), response);
    },
    onError: (error) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
