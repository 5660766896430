import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { Customer } from '@interfaces/Customer.interfaces';
import api from '@services/api';

export const findCustomerById = async (
  customerId: string
): Promise<Customer> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customers
    }/${customerId}`
  );
  const { data } = response;

  return data;
};
