import {
  BankSlipPaymentScheduleVoucher,
  P2PTransferScheduleVoucher,
  PixPaymentVoucherSchedule,
} from '@components';
import { ICONS } from '@constants/icons';
import { TransactionScheduleType } from '@enums/SchedulePayment.enum';
import { Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useTranslation } from 'react-i18next';

interface ScheduleTransactionVoucherProps {
  type: TransactionScheduleType;
  scheduleId: string;
  walletId: string;
}

export const ScheduleTransactionVoucher = ({
  scheduleId,
  type,
  walletId,
}: ScheduleTransactionVoucherProps) => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  return (
    <Sidebar
      open
      title={t('general.scheduling')}
      icon={ICONS.calendar}
      onClose={closeDrawer}
    >
      {type === TransactionScheduleType.P2P_TRANSFER && (
        <P2PTransferScheduleVoucher
          scheduleId={scheduleId}
          walletId={walletId}
        />
      )}

      {(type === TransactionScheduleType.PIX_PAYMENT ||
        type === TransactionScheduleType.PIX_TRANSFER) && (
        <PixPaymentVoucherSchedule
          scheduleId={scheduleId}
          walletId={walletId}
        />
      )}

      {type === TransactionScheduleType.BANK_SLIP_PAYMENT && (
        <BankSlipPaymentScheduleVoucher
          scheduleId={scheduleId}
          walletId={walletId}
          onClose={closeDrawer}
        />
      )}
    </Sidebar>
  );
};
