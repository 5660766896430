import { useEffect, useId } from 'react';
import { useForm } from 'react-hook-form';

import { Option } from '@interfaces/CustomFields.interfaces';
import { Button, ErrorMessage, Input } from '@portao3-web/ui';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import { orderOptions } from '../../utils/orderOptions';

export const EditOptionForm = ({
  defaultOption,
}: {
  defaultOption?: Option;
}) => {
  const { setValue, watch } = useDrawerEditCustomField();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      label: defaultOption?.label || '',
      value: defaultOption?.value || '',
    },
  });

  const values = watch('values');

  const onSubmit = (data: Option) => {
    let label = data.label;

    const getLabel = (options: Option[]) => {
      const isLabelRepeated = options?.find(
        (item: Option) => item.label === data.label
      );

      if (isLabelRepeated) {
        const repeatedLabels = options
          .filter((item: Option) =>
            new RegExp(`^${data.label}-[0-9]+$`).test(item.label)
          )
          .map((item: Option) => item.label);

        if (repeatedLabels.length > 0) {
          const lastLabel = repeatedLabels.sort().pop();
          const lastLabelNumber = Number(lastLabel?.split('-').pop());

          label = `${data.label}-${lastLabelNumber < 9 ? 0 : ''}${
            lastLabelNumber + 1
          }`;
        } else {
          label = `${data.label}-01`;
        }
      }
    };

    if (defaultOption) {
      const currentOptions = values?.filter(
        (item: Option) => item.label !== defaultOption.label
      );

      getLabel(currentOptions);

      setValue(
        'values',
        orderOptions([
          ...currentOptions,
          { label, value: data.value === '' ? label : data.value },
        ])
      );
    } else {
      getLabel(values);

      setValue(
        'values',
        values
          ? orderOptions([...values, { label, value: data.value || label }])
          : [{ label, value: data.value || label }]
      );
    }

    document.body.click();
  };

  const inputId = useId();

  useEffect(() => {
    document.getElementById(inputId)?.focus();
  }, [inputId]);

  return (
    <form
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      className="edit-option-popover"
    >
      <div className="edit-option-popover_input-container">
        <Input
          name="label"
          id={inputId}
          label="Nome de exibição"
          placeholder="Ex: Marketing"
          register={register}
          validationSchema={{ required: 'Nome é obrigatório' }}
          error={!!errors.label?.message}
        >
          {errors.label?.message && (
            <ErrorMessage message={errors.label.message} />
          )}
        </Input>
        <Input
          name="value"
          label="Valor"
          placeholder="Ex: marketing"
          register={register}
        />
      </div>
      <div className="edit-option-popover_button-container">
        <Button
          type="button"
          variant="secondary"
          size="medium"
          onClick={() => document.body.click()}
        >
          Cancelar
        </Button>
        <Button type="submit" variant="primary" size="medium">
          Salvar
        </Button>
      </div>
    </form>
  );
};
