import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { useQueryParams } from '@hooks';
import { Button } from '@portao3-web/ui';

interface ClearFiltersProps {
  queries: string[];
}

export const ClearFiltersQuery = ({ queries }: ClearFiltersProps) => {
  const { t } = useTranslation();
  const { setQueryParams, queryParams } = useQueryParams();

  const hasFilter = queries.some((query) => queryParams.get(query));

  const paramsCleared = Object.fromEntries(
    queries.map((key) => [key, undefined])
  );

  const handleClearFilters = () => {
    setQueryParams({
      ...paramsCleared,
      page: '0',
    });
  };

  return (
    <>
      {hasFilter && (
        <Button
          data-testid="clear-filters"
          variant="tertiary"
          size="medium"
          onClick={handleClearFilters}
        >
          {t('general.clear-filters')} <i className={`${ICONS.x} fa-1x`} />
        </Button>
      )}
    </>
  );
};
