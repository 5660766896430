import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { Transaction } from '@interfaces/Transactions.interfaces';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  FindTransactionParams,
  findTransactionById,
} from '../transactions.services';
import { transactionKeys } from './transactionKeys';

interface UseTransactionQueryParams extends FindTransactionParams {
  enabled?: boolean;
}

export const useTransactionQuery = ({
  enabled = true,
  ...params
}: UseTransactionQueryParams) => {
  return useQuery<Transaction, ApiError, Transaction, unknown[]>({
    queryKey: transactionKeys.detail(params),
    queryFn: () => findTransactionById(params),
    placeholderData: keepPreviousData,
    enabled: enabled && (!!params.transactionId || !!params.walletId),
  });
};
