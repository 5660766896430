import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateCustomer, Customer } from '@interfaces/Customer.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { updateCustomer } from '../updateCustomer';
import { customerKeys } from './customerKeys';

interface UpdateCustomerMutation {
  customerId: string;
  payload: CreateCustomer;
}

export const useUpdateCustomerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<Customer, ApiError, UpdateCustomerMutation, unknown>({
    mutationFn: ({ customerId, payload }: UpdateCustomerMutation) =>
      updateCustomer(customerId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customerKeys.all() });
    },
  });
};
