import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { useQueryParams } from '@hooks';
import { Option } from '@interfaces/CustomFields.interfaces';
import { Button, TableMultiSelectFilter } from '@portao3-web/ui';

interface SelectQueryProps {
  query: string;
  options: Option[];
  title: string;
  maxSelectedShow?: number;
  isLoading?: boolean;
  type?: 'multi' | 'single';
  onlyOptions?: boolean;
  icon?: string;
  required?: boolean;
}

export const SelectQuery = ({
  query,
  options,
  title,
  maxSelectedShow,
  isLoading,
  onlyOptions,
  icon,
  type = 'multi',
  required,
}: SelectQueryProps) => {
  const { t } = useTranslation();
  const { queryParams, setQueryParams } = useQueryParams();

  if (isLoading) {
    return (
      <Button
        variant="secondary"
        size="medium"
        style={{ paddingRight: 8, paddingLeft: 8, borderStyle: 'dashed' }}
      >
        <i className={`${ICONS.loading} text-p2 mr-1`} />
        {title}
      </Button>
    );
  }

  return (
    <TableMultiSelectFilter
      type={type}
      testId={`multi-select-query-${query}`}
      maxSelectedShow={maxSelectedShow}
      selection={queryParams.getAll(query)}
      setSelection={(value) => {
        setQueryParams({
          [query]: value,
          page: '0',
        });
      }}
      title={title}
      required={required}
      icon={icon}
      labels={{
        clearFilters: t('general.clear-filters'),
        notFound: t('general.not-found'),
        selected: t('general.selected', {
          count: queryParams.getAll(query).length,
        }),
      }}
      options={options}
      onlyOptions={onlyOptions}
    />
  );
};
