import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CustomFieldType,
  NumberFieldMask,
} from '@interfaces/CustomFields.interfaces';
import {
  Autocomplete,
  ErrorMessage,
  Input,
  MultiCheckboxSelect,
  Select,
  Toggle,
} from '@portao3-web/ui';
import { useInfiniteCustomerListQuery } from '@services/customers/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { CustomFieldsMapperProps } from './CustomFields.interfaces';

const TextComponent = ({
  form,
  fieldSchema,
  customFields,
  onFocus,
}: CustomFieldsMapperProps) => {
  useEffect(() => {
    if (customFields) {
      const field = customFields?.find(
        (field) => field.identifier === fieldSchema.identifier
      );
      form.setValue(
        `customFields.${fieldSchema.identifier}`,
        field?.values[0] ?? undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      name={`customFields.${fieldSchema.identifier}`}
      label={fieldSchema.label}
      placeholder=""
      register={form.register}
      validationSchema={{
        required: fieldSchema.required ? 'Campo obrigatório' : false,
      }}
      error={
        !!form.formState.errors?.customFields?.[`${fieldSchema.identifier}`]
          ?.message
      }
      onFocus={onFocus}
    >
      {form.formState.errors?.customFields?.[`${fieldSchema.identifier}`]
        ?.message && (
        <ErrorMessage
          message={
            form.formState.errors.customFields[`${fieldSchema.identifier}`]
              ?.message
          }
        />
      )}
    </Input>
  );
};

const NumberComponent = ({
  form,
  fieldSchema,
  customFields,
  onFocus,
}: CustomFieldsMapperProps) => {
  const formatNumber = (mask: string, value: string): string => {
    const currencyFormat = mask as keyof typeof NumberFieldMask;
    if (
      ![NumberFieldMask.PERCENT, NumberFieldMask.NONE].includes(
        NumberFieldMask[currencyFormat]
      )
    ) {
      return calcCurrencyValue(
        Number(value),
        currencyFormat.toString() as 'BRL' | 'USD' | 'EUR'
      );
    } else if (NumberFieldMask.PERCENT === NumberFieldMask[currencyFormat]) {
      return `${Number(value)}%`;
    }
    return value;
  };

  const handleFormatNumber = (value: string) => {
    form.setValue(
      `customFields.${fieldSchema.identifier}`,
      formatNumber(
        fieldSchema.mask.toString(),
        value.replaceAll(/\D/g, '') || '0'
      )
    );
  };

  useEffect(() => {
    if (customFields) {
      const field = customFields?.find(
        (field) => field.identifier === fieldSchema.identifier
      );
      const value = field?.values[0].replace(/\D/g, '');
      if (value) {
        form.setValue(
          `customFields.${fieldSchema.identifier}`,
          formatNumber(fieldSchema.mask.toString(), value)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      name={`customFields.${fieldSchema.identifier}`}
      type="tel"
      label={fieldSchema.label}
      register={form.register}
      validationSchema={{
        required: fieldSchema.required ? 'Campo obrigatório' : false,
      }}
      error={
        !!form.formState.errors.customFields?.[`${fieldSchema.identifier}`]
          ?.message
      }
      onChange={(event) => handleFormatNumber(event.target.value)}
      onFocus={onFocus}
    >
      {form.formState.errors?.customFields?.[`${fieldSchema.identifier}`]
        ?.message && (
        <ErrorMessage
          message={
            form.formState.errors?.customFields[`${fieldSchema.identifier}`]
              ?.message
          }
        />
      )}
    </Input>
  );
};

const DateComponent = ({
  form,
  fieldSchema,
  customFields,
  onFocus,
}: CustomFieldsMapperProps) => {
  useEffect(() => {
    if (customFields) {
      const field = customFields?.find(
        (field) => field.identifier === fieldSchema.identifier
      );
      const value = field?.values[0] ?? undefined;
      form.setValue(
        `customFields.${fieldSchema.identifier}`,
        value?.toString() === 'Invalid Date' ? undefined : value
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginBottom: 16, width: '100%' }}>
      <Controller
        name={`customFields.${fieldSchema.identifier}`}
        control={form.control}
        rules={{ required: fieldSchema.required ? 'Campo obrigatório' : false }}
        render={({ fieldState }) => (
          <Input
            onFocus={onFocus}
            label={fieldSchema.label}
            name={`customFields.${fieldSchema.identifier}`}
            type="date"
            register={form.register}
            error={!!fieldState.error?.message}
            form={form}
          >
            {fieldState.error?.message && (
              <ErrorMessage message={fieldState.error.message} />
            )}
          </Input>
        )}
      />
    </div>
  );
};

const BooleanComponent = ({
  form,
  fieldSchema,
  customFields,
  onFocus,
}: CustomFieldsMapperProps) => {
  const handleToggleRequired = (isSelected: boolean) => {
    form.setValue(`customFields.${fieldSchema.identifier}`, isSelected);
  };

  useEffect(() => {
    if (customFields) {
      const field = customFields?.find(
        (field) => field.identifier === fieldSchema.identifier
      );
      form.setValue(
        `customFields.${fieldSchema.identifier}`,
        Boolean(field?.values[0])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginBottom: 16, width: '100%' }}>
      <Controller
        name={`customFields.${fieldSchema.identifier}`}
        control={form.control}
        rules={{
          validate: (value) => {
            if (!fieldSchema.required) return true;
            return value === false ? 'Confirmação obrigatória' : value;
          },
        }}
        render={({ field, fieldState }) => (
          <div>
            <Toggle
              label={fieldSchema.label}
              isSelected={field.value}
              description={
                fieldSchema.type === CustomFieldType.BOOLEAN
                  ? fieldSchema.description
                  : undefined
              }
              onChange={() => handleToggleRequired(!field.value)}
              onFocus={onFocus}
            />
            {fieldState.error?.message && (
              <ErrorMessage message={fieldState.error.message} />
            )}
          </div>
        )}
      />
    </div>
  );
};

const SelectComponent = ({
  form,
  fieldSchema,
  customFields,
  onFocus,
}: CustomFieldsMapperProps) => {
  useEffect(() => {
    if (customFields) {
      const field = customFields?.find(
        (field) => field.identifier === fieldSchema.identifier
      );
      const value = field?.values[0] ?? '';
      const hasValue = fieldSchema.values.some(
        (option) => option.value === value
      );
      form.setValue(
        `customFields.${fieldSchema.identifier}`,
        hasValue ? field?.values[0] : undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      form={form}
      label={fieldSchema.label}
      name={`customFields.${fieldSchema.identifier}`}
      options={
        fieldSchema.type === CustomFieldType.SELECT ? fieldSchema.values : []
      }
      placeholder="Selecione uma opção"
      validationSchema={{
        required: fieldSchema.required ? 'error.required-field' : false,
      }}
      onFocus={onFocus}
    />
  );
};

const MultiSelectComponent = ({
  form,
  fieldSchema,
  customFields,
  onFocus,
}: CustomFieldsMapperProps) => {
  const handleMultiSelectChange = (values: string[]) => {
    form.setValue(`customFields.${fieldSchema.identifier}`, values);
  };

  const field = customFields?.find(
    (field) => field.identifier === fieldSchema.identifier
  );

  const options =
    fieldSchema.type === CustomFieldType.MULTI_SELECT
      ? fieldSchema.values.map((value) => ({
          checked: field?.values ? field.values.includes(value.value) : false,
          label: value.label,
          id: value.value,
        }))
      : [];

  useEffect(() => {
    const values = options
      .map((option) => (option.checked ? option.id : null))
      .filter((value) => value !== null) as string[];
    handleMultiSelectChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginBottom: 16, width: '100%' }}>
      <Controller
        name={`customFields.${fieldSchema.identifier}`}
        control={form.control}
        rules={{ required: fieldSchema.required ? 'Campo obrigatório' : false }}
        render={({ fieldState }) => (
          <div>
            <MultiCheckboxSelect
              id={`customFields.${fieldSchema.identifier}`}
              label={fieldSchema.label}
              name={`customFields.${fieldSchema.identifier}`}
              register={form.register}
              setValues={handleMultiSelectChange}
              placeholder="Selecionar opções"
              options={options}
              error={!!fieldState.error?.message}
              onFocus={onFocus}
            />
            {fieldState.error?.message && (
              <ErrorMessage message={fieldState.error.message} />
            )}
          </div>
        )}
      />
    </div>
  );
};

const CustomerComponent = ({
  form,
  fieldSchema,
  customFields,
}: CustomFieldsMapperProps) => {
  const organizationId = localStorage.getItem('organization') ?? '';

  const { t } = useTranslation();

  const [customerInput, setCustomerInput] = useState('');
  const [customerQuery, setCustomerQuery] = useState('');

  const {
    data: customerList,
    fetchStatus: fetchCustomerStatus,
    isFetching: isFetchingCustomers,
    fetchNextPage: fetchMoreCustomers,
    isFetchingNextPage: isFetchingMoreCustomers,
  } = useInfiniteCustomerListQuery(organizationId, customerQuery);

  const autocompleteCustomerList = useMemo(() => {
    const customerOptions: Record<string, string> = {};
    customerList?.forEach(
      (customer) =>
        (customerOptions[customer.id] = `${
          customer.trandingName ?? customer.name
        } - ${customer.email}`)
    );
    return customerOptions;
  }, [customerList]);

  useEffect(() => {
    if (customFields) {
      const field = customFields?.find(
        (field) => field.identifier === fieldSchema.identifier
      );
      const value = field?.values[0] ?? '';
      const label = field?.values[1] ?? '';
      form.setValue(`customFields.${fieldSchema.identifier}`, value);
      handleSetCustomerInput(label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetCustomerInput = (value: string) => {
    setCustomerInput(value);
  };

  const handleQueryCustomers = async (value?: string) => {
    setCustomerQuery(value ?? '');
  };

  const handleSelectCustomer = (value: string) => {
    setCustomerInput(autocompleteCustomerList[value]);
    form.setValue(
      `customFields.${fieldSchema.identifier}`,
      [value, autocompleteCustomerList[value]],
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  };

  return (
    <Autocomplete
      label={t('general.select-customer')}
      value={customerInput}
      setValue={handleSetCustomerInput}
      placeholder="Busque por nome"
      items={autocompleteCustomerList}
      selectItem={handleSelectCustomer}
      queryFn={handleQueryCustomers}
      isFetching={isFetchingCustomers}
      isFetchingNextPage={isFetchingMoreCustomers}
      delay={500}
      fetchNextPage={
        fetchCustomerStatus === 'fetching' ? undefined : fetchMoreCustomers
      }
      error={form.formState.errors['customer']?.message?.toString()}
    />
  );
};

export const CUSTOM_FIELDS_MAPPER = {
  TEXT: TextComponent,
  NUMBER: NumberComponent,
  DATE: DateComponent,
  BOOLEAN: BooleanComponent,
  SELECT: SelectComponent,
  MULTI_SELECT: MultiSelectComponent,
  CUSTOMER: CustomerComponent,
};
