import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';
import {
  FutureTransactionsProps,
  TransactionScheduleProps,
} from '../interface/scheduling.interface';

export const findScheduleList = async (
  props: FutureTransactionsProps
): Promise<ListResponse<TransactionScheduleProps>> => {
  const { walletId, ...params } = props;
  removeEmptyObjectAttributes(params);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}${walletId ? `/wallets/${walletId}` : ''}/${
      endpoint.schedules
    }`,
    { params }
  );

  const { data } = response;

  return data;
};
