import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { ConfirmBatchTransactionsPayload } from '@interfaces/BatchPayments.interfaces';
import api from '@services/api';

export const confirmBatchTransactions = async ({
  batchId,
  transactionIds,
  walletId,
}: ConfirmBatchTransactionsPayload): Promise<unknown> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.batchPayment}/${batchId}/confirm`,
    { transactionIds }
  );

  const { data } = response;

  return data;
};
