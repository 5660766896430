import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import {
  CustomField,
  CustomFieldArrayProps,
  CustomFieldLocation,
  CustomFieldPostParams,
  CustomFieldStatus,
} from '@interfaces/CustomFields.interfaces';
import api from '@services/api';

export const createCustomField = async (
  field: CustomFieldPostParams
): Promise<CustomField> => {
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customFields
    }`,
    field
  );

  const { data } = response;

  return data;
};

export const updateCustomField = async (
  id: string,
  field: CustomFieldPostParams
): Promise<CustomField> => {
  const response = await api.put(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customFields
    }/${id}`,
    field
  );

  const { data } = response;

  return data;
};

export const getCustomFields = async (
  limit = 15,
  nextPage: string,
  selectedLocation?: CustomFieldLocation,
  searchCustomFieldIdentifier?: string,
  status?: CustomFieldStatus
): Promise<CustomFieldArrayProps> => {
  const nextPageElement = nextPage ? `&next=${nextPage}` : '';
  const location = selectedLocation ? `&location=${selectedLocation}` : '';
  const identifier = searchCustomFieldIdentifier
    ? `&identifier=${searchCustomFieldIdentifier}`
    : '';

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.customFields
    }?limit=${limit}${nextPageElement}&status=${
      status || CustomFieldStatus.ACTIVE
    }${location}${identifier}`
  );

  const { data } = response;
  return data;
};
