import { HeaderPage } from '@components';
import { useQueryParams } from '@hooks';
import { Tabs } from '@portao3-web/ui';
import { useTranslation } from 'react-i18next';
import { CustomersManagement } from './customersManagement';
import { CustomersInvoices } from './generalInvoices';

export const Customers = () => {
  const { t } = useTranslation();
  const { queryParams, setQueryParams } = useQueryParams();

  const currentTab = queryParams.get('tab');
  const isInvoice = currentTab === 'invoices';

  const tabs = [
    {
      id: 'clients',
      label: t('general.clients'),
      content: <CustomersManagement />,
    },
    {
      id: 'invoices',
      label: t('general.invoices'),
      content: <CustomersInvoices />,
    },
  ];

  const handleTabChange = (tab: string) => {
    const activeTab = tabs.find((currentTab) => currentTab.id === tab);
    const invoice = activeTab?.id === 'invoices';

    setQueryParams({
      invoice: undefined,
      customerId: undefined,
      tab: activeTab?.id,
      status: invoice ? ['CLOSED', 'OPEN'] : undefined,
    });
  };

  return (
    <>
      <HeaderPage
        title={t('general.clients')}
        subTitle={t('customers.page-subtitle')}
      />
      <Tabs
        tabs={tabs}
        start={isInvoice ? 1 : 0}
        onTabChange={handleTabChange}
        key={currentTab}
      />
    </>
  );
};
