import { LineChartProps } from './LineChart.interface';
import './LineChart.styles.scss';

import {
  LineChart as Chart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

export const LineChart = ({
  tooltip,
  legend,
  data,
  lines,
  height,
}: LineChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <Chart data={data}>
        <CartesianGrid strokeDasharray="3" />
        <XAxis dataKey="name" />
        <YAxis />
        {tooltip ? <Tooltip /> : null}
        {legend ? <Legend /> : null}
        {lines.map((line, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.stroke}
            name={line.name}
          />
        ))}
      </Chart>
    </ResponsiveContainer>
  );
};
