import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Search, Spinner, Typography } from '../../..';
import {
  DropDownListProps,
  DropDownSelected,
  WalletType,
} from '../../../../interfaces';
import './DropDownList.styles.scss';

export const DropDownList = ({
  selectedItem,
  listItems,
  itemSelected,
  setSearchWallet,
  isLoading,
  createWallet,
  trackShowAllWalletsUserAction,
  trackCreatelWalletsUserAction,
  trackSearchWalletsUserAction,
}: DropDownListProps) => {
  const history = useNavigate();
  const [value, setValue] = useState('');
  const loggedUser = JSON.parse(
    localStorage.getItem('user') || JSON.stringify('')
  );

  const selectedItemMenu = (item: DropDownSelected) => {
    selectedItem(item);
  };

  const activeItem = (item: DropDownSelected) => {
    return item?.name === itemSelected?.name
      ? 'dropDownList_item_text--active'
      : '';
  };

  const organizationWallet = listItems.find(
    (item) => item.type === WalletType.ORGANIZATION
  );

  const personalWallet = listItems.find(
    (item) =>
      item.type === WalletType.PERSONAL &&
      item.secondaryName === `${loggedUser.firstName} ${loggedUser.lastName}`
  );

  const othersElementsArray = listItems
    .filter(
      (item) =>
        item.name.toLowerCase() !== 'individual' &&
        item.type !== WalletType.ORGANIZATION
    )
    .slice(0, 3);

  return (
    <div className="dropDownList">
      <div className="dropDownList_search">
        <Search
          value={value}
          queryFn={(value) => {
            trackSearchWalletsUserAction && trackSearchWalletsUserAction();
            setSearchWallet(value);
          }}
          setValue={setValue}
          variant="default"
          delay={500}
        />
      </div>

      {!isLoading && listItems.length !== 0 && (
        <div>
          {organizationWallet && (
            <div className="dropDownList_item">
              <div className="dropDownList_item_title">
                <Typography
                  tag="p"
                  weight="p3"
                  className="dropDownList_item_title_text"
                  color="var(--product-neutral-n70)"
                >
                  Minha empresa
                </Typography>
              </div>

              <div
                className="dropDownList_item_element"
                onClick={() => {
                  history(`/company`);
                  selectedItemMenu(organizationWallet);
                }}
              >
                <Typography
                  tag="p"
                  weight="p2"
                  className={`dropDownList_item_text ${activeItem(
                    organizationWallet
                  )}`}
                >
                  {organizationWallet.name}
                </Typography>
                <Typography
                  tag="p"
                  weight="p3"
                  className="dropDownList_item_name"
                  color="var(--product-neutral-n80)"
                >
                  {organizationWallet.secondaryName}
                </Typography>
              </div>
            </div>
          )}

          {personalWallet && (
            <div className="dropDownList_item">
              <div className="dropDownList_item_title">
                <Typography
                  tag="p"
                  weight="p3"
                  className="dropDownList_item_title_text"
                  color="var(--product-neutral-n70)"
                >
                  Sua carteira
                </Typography>
              </div>

              <div
                className="dropDownList_item_element"
                onClick={() => {
                  selectedItemMenu(personalWallet);
                  history(`/wallets/${personalWallet.walletId}/geral`);
                }}
              >
                <Typography
                  tag="p"
                  weight="p2"
                  className={`dropDownList_item_text ${activeItem(
                    personalWallet
                  )}`}
                >
                  {personalWallet.name}
                </Typography>
                <Typography
                  tag="p"
                  weight="p3"
                  className="dropDownList_item_name"
                  color="var(--product-neutral-n80)"
                >
                  {personalWallet.secondaryName}
                </Typography>
              </div>
            </div>
          )}

          <div className="dropDownList_item">
            <div className="dropDownList_item_title">
              <Typography
                tag="p"
                weight="p3"
                className="dropDownList_item_title_text"
                color="var(--product-neutral-n70)"
              >
                outras
              </Typography>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n100)"
              >
                <a
                  href="/wallets"
                  className="dropDownList_item_title_more_items"
                  onClick={() =>
                    trackShowAllWalletsUserAction &&
                    trackShowAllWalletsUserAction()
                  }
                >
                  Ver todas
                </a>
              </Typography>
            </div>

            {othersElementsArray.map((item, index) => {
              return (
                <div
                  className="dropDownList_item_element"
                  key={index}
                  onClick={() => {
                    history(`/wallets/${item.walletId}/geral`);
                    selectedItem(item);
                  }}
                >
                  <Typography
                    tag="p"
                    weight="p2"
                    className={`dropDownList_item_text ${activeItem(item)}`}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    tag="p"
                    weight="p3"
                    className="dropDownList_item_name"
                    color="var(--product-neutral-n80)"
                  >
                    {item.secondaryName}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!isLoading && othersElementsArray.length === 0 && (
        <div className="dropDownList_search">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n70)">
            Nenhuma carteira encontrada
          </Typography>
        </div>
      )}

      {isLoading && (
        <div className="dropDownList_loading">
          <Spinner />
        </div>
      )}

      {createWallet && (
        <div className="dropDownList_new_element">
          <button
            onClick={() => {
              trackCreatelWalletsUserAction && trackCreatelWalletsUserAction();
              createWallet();
            }}
            style={{ all: 'unset', cursor: 'pointer' }}
          >
            <Typography
              tag="p"
              weight="p3"
              className="dropDownList_new_element_text"
              color="var(--product-neutral-n70)"
            >
              + Nova carteira
            </Typography>
          </button>
        </div>
      )}
    </div>
  );
};
