import { PixKeyType } from '@interfaces/Pix.interface';

export enum DrawerPixAreaType {
  PIX = 'PIX',
  FUNDS_IN = 'FUNDS_IN',
  KEYS = 'KEYS',
}

export interface DrawerPixAreaProps {
  area?: DrawerPixAreaType;
  walletId: string;
}

export interface PixDictResponse {
  items: {
    id: string;
    type: PixKeyType;
    value: string;
    realmId: string;
    organizationId: string;
    walletId: string;
    status: string;
    createdAt: Date | string;
    updatedAt: Date | string;
  }[];
}
