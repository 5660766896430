import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { ListResponse } from '@interfaces/Common.interfaces';
import { InvoiceDealProps } from '@interfaces/Invoices';

import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';
import { AxiosResponse } from 'axios';

export interface InvoiceCustomerListParams {
  status?: string[];
  id?: string;
  description?: string;
  endDate?: string;
  startDate?: string;
  dealId?: string;
  customerId: string;
  next: string | null;
}

export const getCustomersInvoicesList = async (
  params: Omit<InvoiceCustomerListParams, 'customerId'>
): Promise<ListResponse<InvoiceDealProps>> => {
  const paramsCopy = { ...params, order: 'DESC' };
  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/invoices`,
    {
      params: paramsCopy,
    }
  );

  const { data } = response;

  return data;
};

export const getInvoicesReport = async (): Promise<AxiosResponse<string>> => {
  return await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/invoices/report`
  );
};
