import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { TransactionScheduleByIdProps } from '@interfaces/Schedule.interfaces';
import { useQuery } from '@tanstack/react-query';
import { findScheduleById, FindScheduleByIdRequest } from '../findScheduleById';
import { schedulingTransactionKeys } from './schedulingTransactionKeys';

export const useFindScheduleByIdQuery = (payload: FindScheduleByIdRequest) => {
  const organizationId = localStorage.getItem('organization');

  return useQuery<
    TransactionScheduleByIdProps,
    ApiError,
    TransactionScheduleByIdProps,
    unknown[]
  >({
    queryKey: schedulingTransactionKeys.detail(payload, organizationId),
    queryFn: () => findScheduleById(payload),
    enabled: !!organizationId && !!payload.scheduleId && !!payload.walletId,
  });
};
