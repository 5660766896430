import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useQueryParams = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const setQueryParams = (
    value: Record<string, string | string[] | undefined>
  ) => {
    Object.entries(value).forEach(([key, value]) => {
      if (value) {
        if (Array.isArray(value)) {
          queryParams.delete(key);
          value.forEach((v) => queryParams.append(key, v));
        } else {
          queryParams.set(key, value);
        }
      } else {
        queryParams.delete(key);
      }
    });
    navigate({ search: queryParams.toString() }, { replace: true });
  };

  return { setQueryParams, queryParams };
};
