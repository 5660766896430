export const handleOpenWpp = (message?: string) => {
  const messageEncoded = encodeURIComponent(message || '');
  const wppSupportPhone = '558005912633';

  window.open(
    `https://wa.me/${wppSupportPhone}${
      message ? `?text=${messageEncoded}` : ''
    }`,
    '_blank'
  );
};
