import * as ProgressPrimitive from '@radix-ui/react-progress';
import * as React from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { cn } from '@portao3-ui/lib/utils';

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    disabled?: boolean;
  }
>(({ className, value, disabled, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      'bg-secondary relative h-2 w-full overflow-hidden rounded-full',
      className
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className={cn(
        'bg-primary h-full w-full flex-1 rounded-full transition-all',
        {
          'bg-neutral-100': disabled,
        }
      )}
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress as ShadProgress };
