import { useMutation } from '@tanstack/react-query';

import { BankSlip } from '@interfaces/BankSlip.interface';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { confirmationPayloadRequest } from '../PaymentConfirmation.interface';
import { bankSlipConfirmPayment } from '../services/PaymentConfirmationService';

interface UsePaymentConfirmationMutatePayload {
  payload: confirmationPayloadRequest;
  walletId?: string;
  pin?: string;
}

export const useBankSlipPaymentConfirmationMutation = () => {
  return useMutation<
    BankSlip | CreateTransactionSchedule,
    ApiErrorPin,
    UsePaymentConfirmationMutatePayload,
    unknown
  >({
    mutationFn: ({
      payload,
      walletId,
      pin,
    }: UsePaymentConfirmationMutatePayload) =>
      bankSlipConfirmPayment(payload, walletId, pin),
  });
};
