import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { CancelScheduleRequest } from '../interface/scheduling.interface';

export const cancelSchedule = async ({
  schedulingId,
  walletId,
}: CancelScheduleRequest): Promise<void> => {
  await api.delete(
    `${environment.platformBaseUrl}${realm()}/${organization()}/wallets/${walletId}/${
      endpoint.schedules
    }/${schedulingId}`
  );
};
