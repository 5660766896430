import clsx from 'clsx';

import { SpinnerProps } from './Spinner.interface';
import './Spinner.styles.scss';

export const Spinner = ({
  className = '',
  variant = 'primary',
  size = 'default',
}: SpinnerProps) => {
  return (
    <div
      data-testid="spinner"
      className={clsx(`loader loader_${variant}`, {
        className,
        [`loader_${size}`]: size !== 'default',
      })}
    />
  );
};
