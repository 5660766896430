import { UseCustomFieldListParams } from './useCustomFieldListQuery';

export const customFieldKeys = {
  all: () => ['custom-field'],
  details: () => [customFieldKeys.all(), 'detail'],
  lists: () => [customFieldKeys.all(), 'list'],
  list: (params: UseCustomFieldListParams) => [
    customFieldKeys.all(),
    'list',
    params,
  ],
};
