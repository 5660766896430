import { Button, Spinner } from '@portao3-web/ui';
import './ButtonMoreItems.styles.scss';

import { ButtonMoreItemsProps } from './ButtonMoreItems.interface';

export const ButtonMoreItems = ({
  onClick,
  colSpan,
  isLoadingMoreItems,
}: ButtonMoreItemsProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="button_more_item">
          {isLoadingMoreItems ? (
            <Spinner />
          ) : (
            <Button
              size="small"
              variant="secondary"
              onClick={onClick}
              disabled={isLoadingMoreItems}
            >
              Carregar mais
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};
