import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import * as React from 'react';
import { DateRange, Matcher } from 'react-day-picker';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { cn } from '@portao3-ui/lib/utils';
import { ShadButton } from '../shadButton';
import { ShadCalendar } from '../shadCalendar';

export function DatePickerWithRange({
  size = 'lg',
  className,
  defaultDate,
  onChangeDate,
  disabled,
}: React.HTMLAttributes<HTMLDivElement> & {
  defaultDate?: DateRange;
  size?: 'md' | 'lg';
  onChangeDate: (date: DateRange | undefined) => void;
  disabled?: Matcher | Matcher[] | undefined;
}) {
  const [date, setDate] = React.useState<DateRange | undefined>(defaultDate);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setDate(defaultDate);
  }, [defaultDate]);

  const onReset = () => {
    setDate(defaultDate);
  };

  const handleSetDate = (selectedDate: DateRange | undefined) => {
    setDate((prev) => {
      if (prev?.from && prev?.to) {
        return {
          from:
            prev?.from === selectedDate?.from
              ? selectedDate?.to
              : selectedDate?.from,
          to: undefined,
        };
      }

      return selectedDate;
    });
  };

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onReset();

      setIsOpen(false);
      return;
    }

    setIsOpen(true);
  };

  const onResetOrClose = () => {
    if (date) {
      setDate(undefined);
      return;
    }

    onOpenChange(false);
  };

  const onSetDate = () => {
    onChangeDate(date);
    setIsOpen(false);
  };

  const isLarge = size === 'lg';

  const textSize = isLarge ? 'text-p2' : 'text-p3';
  const width = isLarge ? 'w-[220px]' : 'w-[180px]';
  const height = isLarge ? 'h-10' : 'h-8';

  return (
    <div className={cn('grid', className)}>
      <Popover key={'calendar-range'} onOpenChange={onOpenChange} open={isOpen}>
        <PopoverTrigger key={'calendar-trigger'} asChild>
          <ShadButton
            id="date"
            variant={'outline'}
            data-testid="date-picker-range-trigger"
            className={cn(
              `p-0 border-primary-500 ${width} ${height} items-center justify-start border`,
              !date && 'text-p3 text-muted-foreground'
            )}
          >
            <div className="flex w-full items-center justify-center">
              <i
                className={`fa-regular fa-calendar ${textSize} text-primary-500 mr-2`}
              />
              {date?.from ? (
                date?.to ? (
                  <span className={`${textSize} text-primary-500 font-light`}>
                    {dayjs(date.from).format('DD/MM/YYYY')} -{' '}
                    {dayjs(date.to).format('DD/MM/YYYY')}
                  </span>
                ) : (
                  <span className={`${textSize} text-primary-500 font-light`}>
                    {dayjs(date.from).format('DD/MM/YYYY')}
                  </span>
                )
              ) : (
                <span className={`${textSize} text-primary-500  font-light`}>
                  Escolha uma data
                </span>
              )}
            </div>
          </ShadButton>
        </PopoverTrigger>

        <PopoverContent
          key={'calendar-picker-container'}
          className="z-50 w-auto p-0"
          align="start"
        >
          <ShadCalendar
            key={'calendar-picker'}
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSetDate}
            onResetOrClose={onResetOrClose}
            onSetDate={onSetDate}
            disabled={disabled}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
