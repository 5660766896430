import { LineChart, Skeleton } from '@portao3-web/ui';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import { EmptyStateBlock, HeaderCard } from '@components';
import { errorState } from '@constants/emptyState';
import { colorStroke } from '@constants/TransactionCompany';
import { PeriodDetailsResponse, PeriodInfo } from '@interfaces/Chart.interface';
import { getChart } from '@services/organization';
import { ExpenseChartProps } from './ExpenseChart.interface';
import './ExpenseChart.styles.scss';

export const ExpenseChart = ({ organization }: ExpenseChartProps) => {
  const isMounted = useRef(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [chart, setChart] = useState<PeriodDetailsResponse>();
  const [errorServiceDataGraph, setErrorServiceDataGraph] = useState(false);

  const formatDataGraph = () => {
    const data = chart?.data?.map((item: PeriodInfo) => {
      return {
        name: item.week.replace('-', 'até'),
        totalAmount: item.totalAmount / 100,
      };
    });

    return data;
  };

  const dataGraph = {
    height: 200,
    tooltip: true,
    legend: true,
    data: formatDataGraph() || [],
    lines: [
      {
        dataKey: 'totalAmount',
        stroke: colorStroke,
        name: 'Gasto no período',
      },
    ],
  };

  useEffect(() => {
    const chartOrganization = async () => {
      setErrorServiceDataGraph(false);
      setChartLoading(true);
      const endDate = dayjs().endOf('day').subtract(3, 'hours').toISOString();
      const startDate = dayjs()
        .subtract(60, 'day')
        .startOf('day')
        .subtract(3, 'hours')
        .toISOString();
      try {
        const chart = await getChart(startDate, endDate);
        setChart(chart);
        setChartLoading(false);
      } catch {
        setChartLoading(false);
        setErrorServiceDataGraph(true);
      }
    };

    if (!isMounted.current) {
      chartOrganization();
      isMounted.current = true;
    }
  }, [organization]);

  return (
    <div className="expense_chart">
      <div className="expense_chart_graph">
        <HeaderCard title="Gastos nos últimos 2 meses"></HeaderCard>
        <div className="expense_chart_box_linechart">
          <div
            className={`${
              chartLoading ? 'expense_chart_box_linechart_skeleton' : ''
            }`}
          >
            <Skeleton width={'100%'} height={200} isLoading={chartLoading} />
          </div>
          {!chartLoading && dataGraph?.data?.length !== 0 && (
            <LineChart {...dataGraph} />
          )}
        </div>
        {errorServiceDataGraph && <EmptyStateBlock details={errorState} />}
      </div>
    </div>
  );
};
