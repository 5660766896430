import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { InvoiceCustomerListParams } from '@interfaces/Invoices.interfaces';
import { customersInvoicesKeys } from '@services/invoices/hooks/customersInvoicesKeys';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { getCustomerInvoicesList } from '../services/CustomersInvoicesService';
import { ListResponse } from '@interfaces/Common.interfaces';
import { InvoiceDealProps } from '@interfaces/Invoices';

export const useInfinityInvoicesCustomerQuery = (
  params: InvoiceCustomerListParams
) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    ListResponse<InvoiceDealProps>,
    ApiError,
    InfiniteData<ListResponse<InvoiceDealProps>>,
    unknown[],
    string
  >({
    queryKey: customersInvoicesKeys.listInvoices({ ...params, organizationId }),
    queryFn: ({ pageParam }) =>
      getCustomerInvoicesList({
        ...params,
        customerId: params.customerId || '',
        next: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    enabled: !!organizationId && !!params.customerId,
  });
};
