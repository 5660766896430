import { Skeleton, Typography } from '@portao3-web/ui';

import './components.scss';

interface ValueWrapperProps {
  label: string;
  value: string | number | undefined;
  isLoading?: boolean;
  className?: string;
}

export const ValueWrapper = ({
  label,
  value,
  isLoading,
  className,
}: ValueWrapperProps) => {
  return (
    <Skeleton testId="wrapper-loading" isLoading={!!isLoading} height={40}>
      <div className={`flex flex-col ${className || ''}`}>
        <Typography
          tag="p"
          color="var(--product-neutral-n80)"
          weight="p3"
          className="amount-card__value"
        >
          {label}
        </Typography>

        <Typography
          tag="p"
          color="var(--product-neutral-n400)"
          weight="p2"
          className="amount-card__value"
        >
          {value || '-'}
        </Typography>
      </div>
    </Skeleton>
  );
};
