const validateIsSequenceEquals = (
  digit: number,
  prevDigit: number,
  nextDigit: number
): boolean => {
  const isEqualSequence = prevDigit === digit && digit === nextDigit;

  return isEqualSequence;
};

const validateIsSequence = (
  digit: number,
  prevDigit: number,
  nextDigit: number
): boolean => {
  const isIncreaseSequence = prevDigit === digit - 1 && nextDigit === digit + 1;
  const isDecreaseSequence = prevDigit === digit + 1 && nextDigit === digit - 1;

  return isIncreaseSequence || isDecreaseSequence;
};

const validateIsSequenceOrEquals = (
  digit: number,
  prevDigit: number,
  nextDigit: number
): boolean => {
  return (
    validateIsSequence(digit, prevDigit, nextDigit) ||
    validateIsSequenceEquals(digit, prevDigit, nextDigit)
  );
};

export const validateTokenSequenceEqual = (value: string) => {
  const numbers = value
    .trim()
    .split('')
    .map((onlyNumber) => Number(onlyNumber));

  const hasError =
    validateIsSequenceEquals(numbers[1], numbers[0], numbers[2]) ||
    validateIsSequenceEquals(numbers[2], numbers[1], numbers[3]);

  return !hasError;
};

export const validateTokenSequence = (value: string) => {
  const numbers = value
    .trim()
    .split('')
    .map((onlyNumber) => Number(onlyNumber));

  const hasError =
    validateIsSequence(numbers[1], numbers[0], numbers[2]) ||
    validateIsSequence(numbers[2], numbers[1], numbers[3]);

  return !hasError;
};

export const validateTokenRules = (value: string) => {
  const numbers = value
    .trim()
    .split('')
    .map((onlyNumber) => Number(onlyNumber));

  const hasError =
    validateIsSequenceOrEquals(numbers[1], numbers[0], numbers[2]) ||
    validateIsSequenceOrEquals(numbers[2], numbers[1], numbers[3]);

  return !hasError;
};
