import { endpoint, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { OrganizationList } from '@interfaces/Organization.interfaces';
import api from '@services/api';

export const findOrganizationList = async (): Promise<OrganizationList> => {
  const { data } = await api.get(
    `${environment.identityBaseUrl}${realm()}/${endpoint.organizations}`
  );

  return data;
};
