import { useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { CurrentUser } from '@interfaces/User.interfaces';
import { findCurrentUser } from '@services/user';
import { userKeys } from './userKeys';

export const useGetCurrentUserQuery = () => {
  return useQuery<CurrentUser, ApiError, CurrentUser, unknown[]>({
    queryKey: userKeys.current(),
    queryFn: () => findCurrentUser(),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    retry: 1,
  });
};
