import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ContextualError, SidebarFooter } from '@components';
import { TransactionBatchStatus } from '@enums/BatchPayment.enum';
import { useQueryParams } from '@hooks/useQueryParams';
import { Button, Spinner, TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useInfiniteBatchTransactionsListQuery } from '@services/batchPayment/hooks';
import { useConfirmBatchTransactionsMutation } from '@services/batchPayment/hooks/useConfirmBatchTransactionsMutation';
import { useBatchDetailsColumns } from './TableColumns';

export const BatchDetail = () => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const { walletId: walletIdParams } = useParams();
  const { closeDrawer } = useDrawer();

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const batchId = queryParams.get('batchId') ?? '';
  const walletIdQuery = queryParams.get('walletId') ?? '';
  const walletId = walletIdParams || walletIdQuery || 'default';

  const transactionIds = Object.keys(rowSelection);
  const selectedCount = transactionIds.length;

  const {
    data,
    refetch: refetchBatchTransactionList,
    ...rest
  } = useInfiniteBatchTransactionsListQuery({
    batchId,
    walletId,
    params: {
      limit: 9999,
    },
    refetchStatus: TransactionBatchStatus.PROCESSING,
  });

  const {
    mutate: confirmTransactionsMutate,
    isPending: isPendingConfirmTransactions,
    customError,
  } = useConfirmBatchTransactionsMutation(refetchBatchTransactionList);

  const transactionRows = useMemo(() => {
    const transactionsFiltered = data?.filter(
      (transaction) =>
        transaction.status !== TransactionBatchStatus.INITIATED &&
        transaction.status !== TransactionBatchStatus.CANCELED &&
        transaction.status !== TransactionBatchStatus.VALIDATION_ERROR
    );

    return transactionsFiltered?.map((transaction) => {
      const disabledCheckbox =
        transaction.status === TransactionBatchStatus.IN_VALIDATION ||
        transaction.status === TransactionBatchStatus.VALIDATION_ERROR ||
        transaction.status === TransactionBatchStatus.EXECUTED ||
        transaction.status === TransactionBatchStatus.SCHEDULED ||
        transaction.status === TransactionBatchStatus.PROCESSING;

      return { ...transaction, disabledCheckbox };
    });
  }, [data]);

  const handleSubmit = () => {
    confirmTransactionsMutate({
      batchId,
      walletId,
      transactionIds,
    });
  };

  return (
    <>
      <ContextualError error={customError} />

      <TableDemo
        title={t('transactions.batch-details')}
        columns={useBatchDetailsColumns()}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        pinLastColumn
        data={transactionRows}
        loadMoreButtonLabel={t('general.load-more')}
        {...rest}
      />

      <SidebarFooter>
        <Button
          type="button"
          size="large"
          variant="tertiary"
          className="button_submit"
          onClick={closeDrawer}
        >
          {t('general.button.close')}
        </Button>
        <Button
          type="submit"
          size="large"
          className="button_submit"
          disabled={selectedCount === 0 || isPendingConfirmTransactions}
          onClick={handleSubmit}
        >
          {isPendingConfirmTransactions ? (
            <Spinner variant="secondary" />
          ) : (
            `${t('general.reprocess')} ${
              selectedCount > 0 ? `(${selectedCount})` : ''
            }`
          )}
        </Button>
      </SidebarFooter>
    </>
  );
};
