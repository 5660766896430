import { Icon } from '../icon/Icon';
import { Spinner } from '../spinner/Spinner';
import { Typography } from '../typography/Typography';
import './ProgressIndicator.styles.scss';

interface ProgressIndicatorProps {
  total: number;
  consumed: number;
  status: ProgressIndicatorStatus;
}

export type ProgressIndicatorStatus =
  | 'loading'
  | 'success'
  | 'error'
  | 'warning';

export const indicator = {
  success: {
    icon: 'circle-check',
    color: 'var(--product-success-s500)',
  },
  error: {
    icon: 'circle-xmark',
    color: 'var(--product-danger-d500)',
  },
  warning: {
    icon: 'triangle-exclamation',
    color: 'var(--product-warning-w500)',
  },
};

export const ProgressIndicator = ({
  consumed,
  status,
  total,
}: ProgressIndicatorProps) => {
  return (
    <div className={`progress_container progress_container_${status}`}>
      {status !== 'loading' && (
        <Icon size="large">
          <i
            data-testid="progress-icon"
            style={{ color: indicator[status].color }}
            className={`fa-solid fa-${indicator[status].icon} fa-1x`}
          />
        </Icon>
      )}

      {status === 'loading' && (
        <div className="mx">
          <Spinner size="small" />
        </div>
      )}

      <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
        {consumed} de {total}
      </Typography>
    </div>
  );
};
