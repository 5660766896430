import clsx from 'clsx';

import { Icon, Typography } from '@portao3-web/ui';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import { CheckboxCardProps } from './CheckboxCard.interfaces';
import './CheckboxCard.styles.scss';

export const CheckboxCard = ({
  label,
  description,
  location,
  disabled = false,
}: CheckboxCardProps) => {
  const { register, watch } = useDrawerEditCustomField();

  const value = watch('location');

  return (
    <label
      className={clsx('checkbox-card-container', {
        'checkbox-card-container--checked': value?.includes(location),
        'checkbox-card-container--disabled': disabled,
      })}
    >
      <Icon size="medium">
        <i
          className={clsx(
            'fa-1x checkbox-icon',
            value?.includes(location)
              ? 'fa-solid fa-square-check'
              : 'fa-regular fa-square',
            {
              'checkbox-icon--checked': value?.includes(location),
            }
          )}
        />
      </Icon>
      <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
        {label}
      </Typography>
      <Typography tag="p" weight="p3" color="var(--product-neutral-n90)">
        {description}
      </Typography>
      <input
        type="checkbox"
        className="hidden-checkbox"
        value={location}
        {...register('location', {
          validate: (value) => {
            if (value?.length === 0) {
              return 'Ao menos uma localização do campo deve ser selecionada.';
            }
          },
        })}
        disabled={disabled}
      />
    </label>
  );
};
