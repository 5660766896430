import { Button, EmptyState } from '@portao3-web/ui';
import { EmptyStateBlockProps } from './EmptyStateBlock.interface';

export const EmptyStateBlock = ({
  button = false,
  buttonLabel,
  buttonAction,
  details,
}: EmptyStateBlockProps) => {
  return (
    <div className="w-full">
      <EmptyState {...details}>
        {button && (
          <Button onClick={buttonAction} size="large" variant="primary">
            {buttonLabel}
          </Button>
        )}
      </EmptyState>
    </div>
  );
};
