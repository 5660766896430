import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { MINUTE_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { TransactionsResponse } from '@interfaces/Transactions.interfaces';
import {
  FindTransactionListParams,
  findTransactionList,
} from '../transactions.services';
import { transactionKeys } from './transactionKeys';

export interface UseInfiniteTransactionListQueryParams
  extends Omit<FindTransactionListParams, 'next'> {
  organizationId: string | null;
}

export const useInfiniteTransactionListQuery = (
  params: UseInfiniteTransactionListQueryParams
) => {
  const { organizationId, ...rest } = params;

  const hasDate = !!rest.startDate && !!rest.endDate;

  return useInfiniteQuery<
    TransactionsResponse,
    ApiError,
    InfiniteData<TransactionsResponse, string>,
    unknown[],
    string
  >({
    queryKey: transactionKeys.list({ ...rest, organizationId }),
    queryFn: ({ pageParam }) =>
      findTransactionList({ ...rest, next: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    staleTime: MINUTE_IN_MILLISECONDS,
    placeholderData: keepPreviousData,
    enabled: !!organizationId && hasDate,
  });
};
