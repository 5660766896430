import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import '@portao3-web/ui/theme';

import App from './app/app';
import '@langs/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
