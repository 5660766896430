import { QrCodeScanner } from '@components';
import { Typography, Button } from '@portao3-web/ui';
import './Camera.styles.scss';
import { useState, useEffect } from 'react';
import { CameraProps } from '@interfaces/Card.interfaces';

export const Camera = ({
  checkCameraPermission,
  cameraStream,
  getCode,
  cameraBlock,
  children,
}: CameraProps) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    code && getCode(code);
  }, [code, getCode]);

  return (
    <>
      <div className="card_sidebar_section">
        {cameraBlock && (
          <div className="card_sidebar_permission_camera">
            <div className="card_sidebar_permission_camera_title">
              <i className="fa-regular fa-triangle-exclamation icon"></i>
              <Typography
                tag="p"
                weight="p2"
                color="var(--product-neutral-n500)"
              >
                Atenção
              </Typography>
            </div>
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              Você precisa permitir acesso à sua câmera para ser possível
              escanear o QR Code.
            </Typography>
            <Button
              type="button"
              variant="secondary"
              size="small"
              className="card_sidebar_permission_camera_button"
              onClick={checkCameraPermission}
            >
              Solicitar permissão
            </Button>
          </div>
        )}

        <div className="card_sidebar_camera">
          {cameraStream && <QrCodeScanner setCode={setCode} />}
        </div>
        {code && children}
      </div>
    </>
  );
};
