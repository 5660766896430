import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import {
  BatchPaymentStatus,
  TransactionBatchStatus,
} from '@enums/BatchPayment.enum';
import { BillingStatusEnum } from '@enums/Billing.enum';
import { StatusEnum } from '@enums/Status.enum';
import {
  AugmentedBatchPaymentStatus,
  BatchPaymentStatusExtension,
  IconType,
  StatusIconInfo,
  StatusTypeMap,
} from './StatusTag.interfaces';

const useBatchPaymentIcons = (): Record<
  AugmentedBatchPaymentStatus,
  StatusIconInfo
> => {
  const { t } = useTranslation();

  return {
    [BatchPaymentStatus.IN_VALIDATION]: {
      icon: `${ICONS.circle} text-warning-500`,
      label: t('status.IN_VALIDATION'),
    },
    [BatchPaymentStatus.PROCESSING]: {
      icon: `${ICONS['circle-dashed']} text-info-500`,
      label: t('status.PROCESSING'),
    },
    [BatchPaymentStatus.CANCELED]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.CANCELED'),
    },
    [BatchPaymentStatus.COMPLETED_SUCCESSFULLY]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.ERROR'),
    },
    [BatchPaymentStatus.COMPLETED_WITH_ERROR]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.ERROR'),
    },
    [BatchPaymentStatus.ERROR]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.ERROR'),
    },
    [BatchPaymentStatusExtension.PAID]: {
      icon: `${ICONS.circle} text-success-500`,
      label: t('status.PAID'),
    },
    [BatchPaymentStatusExtension.PARTIALLY_PAID]: {
      icon: `${ICONS.circle} text-info-500`,
      label: t('status.PARTIALLY_PAID'),
    },
  };
};

const useBatchTransactionIcons = (): Record<
  TransactionBatchStatus,
  StatusIconInfo
> => {
  const { t } = useTranslation();

  return {
    [TransactionBatchStatus.CANCELED]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.CANCELED'),
    },
    [TransactionBatchStatus.ERROR]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.transaction-error'),
    },
    [TransactionBatchStatus.EXECUTED]: {
      icon: `${ICONS.circle} text-success-500`,
      label: t('status.EXECUTED'),
    },
    [TransactionBatchStatus.IN_VALIDATION]: {
      icon: `${ICONS.circle} text-warning-500`,
      label: t('status.IN_VALIDATION'),
    },
    [TransactionBatchStatus.INITIATED]: {
      icon: `${ICONS.circle} text-info-500`,
      label: t('status.INITIATED'),
    },
    [TransactionBatchStatus.PROCESSING]: {
      icon: `${ICONS['circle-dashed']} text-info-500`,
      label: t('status.PROCESSING'),
    },
    [TransactionBatchStatus.REFUNDED]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.REFUNDED'),
    },
    [TransactionBatchStatus.SCHEDULED]: {
      icon: `${ICONS.circle} text-warning-500`,
      label: t('status.SCHEDULED'),
    },
    [TransactionBatchStatus.VALIDATION_ERROR]: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.VALIDATION_ERROR'),
    },
  };
};

const useGeneralStatusIcons = (): Record<StatusEnum, StatusIconInfo> => {
  const { t } = useTranslation();

  return {
    ACTIVE: {
      icon: `${ICONS.circle} text-success-500`,
      label: t('status.ACTIVE'),
    },
    CREATING: {
      icon: `${ICONS.circle} text-warning-500`,
      label: t('status.CREATING'),
    },
    DEACTIVATED: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.DEACTIVATED'),
    },
    APPROVED: {
      icon: `${ICONS.circle} text-success-500`,
      label: t('status.APPROVED'),
    },
    ARCHIVED: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.ARCHIVED'),
    },
    BLOCKED: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.BLOCKED'),
    },
    REPROVED: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.REPROVED'),
    },
  };
};

const useBillingIcons = (): Record<BillingStatusEnum, StatusIconInfo> => {
  const { t } = useTranslation();

  return {
    ISSUED: {
      icon: `${ICONS.circle} text-warning-500`,
      label: t('status.ISSUED'),
    },
    PAID: {
      icon: `${ICONS.circle} text-success-500`,
      label: t('status.PAID'),
    },
    OVERDUE: {
      icon: `${ICONS.circle} text-danger-500`,
      label: t('status.OVERDUE'),
    },
    PARTIALLY_PAID: {
      icon: `${ICONS.circle} text-warning-500`,
      label: t('status.PARTIALLY_PAID'),
    },
    PROCESSING: {
      icon: `${ICONS['circle-dashed']} text-info-500`,
      label: t('status.PROCESSING'),
    },
  };
};

export const statusHookMapper: {
  [K in IconType]: () => Record<StatusTypeMap[K], StatusIconInfo>;
} = {
  BATCH_TRANSACTIONS: useBatchTransactionIcons,
  BATCH_PAYMENTS: useBatchPaymentIcons,
  GENERAL: useGeneralStatusIcons,
  BILLING: useBillingIcons,
};
