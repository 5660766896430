import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { UserRoles } from '@interfaces/Roles.interface';
import api from '@services/api';

export const getRoles = async (): Promise<UserRoles> => {
  const getCurrentUser = localStorage.getItem('user') || '';
  const currentUser = JSON.parse(getCurrentUser).id;
  const response = await api.get(
    `${
      environment.identityBaseUrl
    }${realm()}/users/${currentUser}/${organization()}/${endpoint.roles}`
  );
  const { data } = response;

  return data;
};
