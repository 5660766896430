import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';

import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

import { BillingDetail, CreateBilling } from '@interfaces/Billing.interfaces';

export const createBilling = async (
  walletId: string,
  payload: CreateBilling
): Promise<BillingDetail> => {
  removeEmptyObjectAttributes(payload);

  const { data } = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/${walletId}/${endpoint.billings}`,
    payload
  );

  return data;
};
