import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { Item, OrganizationList } from '@interfaces/Organization.interfaces';
import { findOrganizationList } from '@services/organization';
import { organizationKeys } from './organizationKeys';

export const useInfiniteOrganizationListQuery = () => {
  return useInfiniteQuery<
    OrganizationList,
    ApiError,
    Item[],
    unknown[],
    string
  >({
    queryKey: organizationKeys.list(),
    queryFn: () => findOrganizationList(),
    getNextPageParam: (lastPage) => {
      return lastPage.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    retry: 1,
    placeholderData: keepPreviousData,
    select: (data) => data.pages.flatMap((page) => page.items),
  });
};
