import { SidebarFooter } from '@components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';

import { Button, Icon } from '@portao3-web/ui';
import { useTranslation } from 'react-i18next';
import './PaymentVoucher.styles.scss';

dayjs.extend(utc);

interface PaymentVoucherProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  onFinishDownload?: () => void;
  disabled?: boolean;
}

export const PaymentVoucher = ({
  onClose,
  children,
  title,
  onFinishDownload,
  disabled,
  ...props
}: PaymentVoucherProps) => {
  const { t } = useTranslation();
  const receiptRef = useRef<HTMLDivElement | null>(null);

  const downloadPdf = () => {
    if (receiptRef.current) {
      html2canvas(receiptRef.current).then((canvas) => {
        const imgWidth = 180;
        const pageHeight = 297;
        const pageHeightWithoutMargin = pageHeight - 15;
        const totalCanvasHeight = canvas.height;
        const scaleFactor = canvas.width / imgWidth;

        const doc = new jsPDF();

        const y = 10;
        let currentCanvasPosition = 0;

        while (currentCanvasPosition < totalCanvasHeight) {
          const currentPageCanvas = document.createElement('canvas');
          currentPageCanvas.width = canvas.width;
          currentPageCanvas.height = pageHeightWithoutMargin * scaleFactor;
          const ctx = currentPageCanvas.getContext('2d');

          ctx?.drawImage(
            canvas,
            0,
            currentCanvasPosition,
            canvas.width,
            pageHeightWithoutMargin * scaleFactor,
            0,
            0,
            currentPageCanvas.width,
            currentPageCanvas.height
          );

          const imgDataForPage = currentPageCanvas.toDataURL('image/png');
          doc.addImage(
            imgDataForPage,
            'PNG',
            15,
            y,
            imgWidth,
            pageHeightWithoutMargin
          );

          currentCanvasPosition += pageHeightWithoutMargin * scaleFactor;

          if (currentCanvasPosition < totalCanvasHeight) {
            doc.addPage();
          }
        }

        doc.save(title || `download-file.pdf`);
      });
    }

    onFinishDownload && onFinishDownload();
  };

  return (
    <>
      <div {...props} ref={receiptRef}>
        {children}

        <div className="mb-5" />
      </div>

      <SidebarFooter>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          data-testid="download"
          onClick={downloadPdf}
          disabled={disabled}
        >
          {t('payment-voucher.download-voucher')}
          <Icon size="xlarge">
            <i className="fa-regular fa-download"></i>
          </Icon>
        </Button>
        <Button size="large" data-testid="complete" onClick={onClose}>
          {t('general.button.close')}
        </Button>
      </SidebarFooter>
    </>
  );
};
