import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { TransactionScheduleStatus } from '@enums/SchedulePayment.enum';
import { useQueryParams } from '@hooks';
import { Button, Dialog, DropDownList, Spinner } from '@portao3-web/ui';
import { useParams } from 'react-router-dom';
import { useFutureTransactionsReport } from '../services';

export const ExportComponent = () => {
  const { t } = useTranslation();

  const { queryParams } = useQueryParams();
  const { walletId } = useParams();

  const filters = {
    walletId,
    scheduledAtEnd: queryParams.get('endDate'),
    scheduledAtStart: queryParams.get('startDate'),
    status:
      queryParams.getAll('status').length > 0
        ? (queryParams.getAll('status') as TransactionScheduleStatus[])
        : [
            TransactionScheduleStatus.CANCELLED,
            TransactionScheduleStatus.SCHEDULED,
          ],
    description: queryParams.get('search'),
  };

  const { mutate: getCustomersReport, isPending } =
    useFutureTransactionsReport();

  return (
    <Dialog variant="popover">
      <Dialog.Trigger>
        <Button size="large" variant="primary" disabled={isPending}>
          {isPending && <Spinner />}
          {t('general.export')}
          <i className={`${ICONS['chevron-down']} fa-1x`} />
        </Button>
      </Dialog.Trigger>

      <Dialog.Content>
        <DropDownList
          list={[
            {
              label: '.xlsx',
              icon: ICONS['file-spreadsheet'],
              action: () => {
                getCustomersReport({
                  type: 'xlsx',
                  ...filters,
                });
              },
            },
            {
              label: '.csv',
              icon: ICONS['file-csv'],
              action: () => {
                getCustomersReport({
                  type: 'csv',
                  ...filters,
                });
              },
            },
          ]}
        />
      </Dialog.Content>
    </Dialog>
  );
};
