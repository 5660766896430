import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Customer } from '@interfaces/Customer.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { findCustomerById } from '../findCustomerById';
import { customerKeys } from './customerKeys';

export const useCustomerQuery = (
  customerId: string | undefined,
  organizationId: string | null
) => {
  return useQuery<Customer, ApiError, Customer, unknown[]>({
    queryKey: customerKeys.detail(customerId, organizationId),
    queryFn: () => findCustomerById(customerId as string),
    placeholderData: keepPreviousData,
    enabled: !!customerId,
  });
};
