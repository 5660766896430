import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { CardItem, CardPayloadRequest } from '@interfaces/Card.interfaces';
import api from '@services/api';

export const createCard = async (
  payloadRequest: CardPayloadRequest,
  walletId: string | undefined
): Promise<CardItem> => {
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${walletId}/cards`,
    payloadRequest
  );

  const { data } = response;

  return data;
};

export const createCardPin = async (
  pin: string,
  cardId: number | string,
  walletId: string
): Promise<unknown> => {
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${walletId}/cards/${cardId}/pin`,
    { pin }
  );
  const { data } = response;

  return data;
};
