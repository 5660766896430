import { Typography } from '@portao3-web/ui';
import { CreditCardProps } from './CreditCard.interface';

import PhysicalCard from '@assets/img/physical_card.svg';
import VirtualCard from '@assets/img/virtual_card.svg';

import './CreditCard.styles.scss';

export const CreditCard = ({
  children,
  typeCard,
  titleCard,
  onClick,
  className,
}: CreditCardProps) => {
  return (
    <div
      className={`type_credit_card ${className}`}
      onClick={onClick}
      data-testid={`${typeCard}-card`}
    >
      <div className="type_credit_card_image">
        {typeCard === 'embossed' ? (
          <img src={PhysicalCard} alt="Physical card" />
        ) : (
          <img src={VirtualCard} alt="Virtual card" />
        )}
      </div>
      <div className="type_credit_card_text">
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {titleCard}
        </Typography>
        <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
          {children}
        </Typography>
      </div>
    </div>
  );
};
