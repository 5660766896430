export enum CalculateMethod {
  UNIT = 'UNIT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum DataSourceType {
  DATABASE = 'DATABASE',
  API = 'API',
}

export enum DealProductChargeMethod {
  INSTANT = 'INSTANT',
  LATER = 'LATER',
}

export enum DealProductType {
  RECURRENT = 'RECURRENT',
  ONE_TIME = 'ONE_TIME',
  ON_EVENT = 'ON_EVENT',
}

export enum DealStatus {
  OPEN = 'OPEN',
  CANCELED = 'CANCELED',
  TERMINATED = 'TERMINATED',
}

export enum DealFineAndInterestAmountType {
  FIXED_VALUE = 'FIXED_VALUE',
  PERCENTAGE = 'PERCENT',
}

export enum PaymentMethod {
  BANK_SLIP = 'BANK_SLIP',
  PIX = 'PIX',
  P2P = 'P2P',
}
