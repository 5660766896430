import { ICONS } from '@constants/icons';
import { AlertPixProps } from './AlertPix.interface';
import './AlertPix.styles.scss';

export const AlertPix = ({ children, status }: AlertPixProps) => {
  const iconStatus = () => {
    switch (status) {
      case 'success':
        return 'fa-solid fa-check-circle';
      case 'error':
        return 'fa-solid fa-circle-x';
      case 'warning':
        return ICONS['triangle-exclamation'];
      default:
        return 'fa-regular fa-check-circle';
    }
  };

  return (
    <div className={`alertPix alertPix_${status}`}>
      <div className="alertPix_icon">
        <i
          className={`${iconStatus()} alertPix_icon--${status}`}
          data-testid="icon"
        ></i>
      </div>
      {children}
    </div>
  );
};
