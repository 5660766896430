import { useTranslation } from 'react-i18next';

import { Alert } from '@components';
import { bankCodes } from '@constants/bankCodes';
import { WalletBalanceCategory } from '@enums/WalletEnum';
import { TypePayment } from '@enums/typePayment.enum';
import { Typography } from '@portao3-web/ui';
import { getFullDateByISO } from '@utils/date';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { cnpjMask, cpfMask } from '@utils/formatMasks';
import dayjs from 'dayjs';

// TODO - refactor parts of the component into smaller components

interface BankInfo {
  bankCode?: string;
  branch?: string;
  bankAccount?: string;
}

interface PixInfo {
  pixKey?: string;
}

interface CreditParty extends BankInfo, PixInfo {
  name?: string;
  document?: string;
  location?: string;
}

interface DebitParty {
  name?: string;
  document?: string;
}

interface TransactionInfo {
  amount: number;
  transactionAt: string;
  type: TypePayment;
  endToEndId?: string;
  barcode?: string;
  dueDate?: string;
  txnOriginalAmount?: number;
  txnDiscountAmount?: number;
  txnInterestAmount?: number;
  txnFineAmount?: number;
  txnMaxAmount?: number;
  txnMinAmount?: number;
  txnUpdatedAmount?: number;
  issuer?: string;
  balanceCategory?: WalletBalanceCategory;
}

type PaymentVoucherProps = {
  transactionInfo: TransactionInfo;
  creditParty?: CreditParty;
  debitParty?: DebitParty;
  internalErrorCode?: string;
};

// TODO - make sure that missing information does not break the component
export const PaymentVoucher = ({
  transactionInfo,
  creditParty,
  debitParty,
  internalErrorCode,
}: PaymentVoucherProps) => {
  const { t } = useTranslation();

  if (!transactionInfo) return null;

  const messages: Record<TypePayment, { title: string; description: string }> =
    {
      PIX: {
        title: t('payment-voucher.pix-success'),
        description: t('payment-voucher.pix-success-message', {
          value: calcCurrencyValue(transactionInfo.amount, 'BRL'),
          destination: creditParty?.name,
          date: dayjs(transactionInfo.transactionAt).format('DD/MM/YYYY'),
          time: dayjs(transactionInfo.transactionAt).format('HH:mm'),
        }),
      },
      BANK_SLIP: {
        title: t('payment-voucher.bank-slip-success'),
        description: t('payment-voucher.bank-slip-success-message', {
          value: calcCurrencyValue(transactionInfo.amount, 'BRL'),
          destination: creditParty?.name,
          date: dayjs(transactionInfo.transactionAt).format('DD/MM/YYYY'),
          time: dayjs(transactionInfo.transactionAt).format('HH:mm'),
        }),
      },
      CREDIT_CARD: {
        title: t('payment-voucher.card-success'),
        description: t('payment-voucher.pix-success-message', {
          value: calcCurrencyValue(transactionInfo.amount, 'BRL'),
          destination: creditParty?.name,
          date: dayjs(transactionInfo.transactionAt).format('DD/MM/YYYY'),
          time: dayjs(transactionInfo.transactionAt).format('HH:mm'),
        }),
      },
      P2P: {
        title: t('payment-voucher.pix-success'),
        description: t('payment-voucher.pix-success-message', {
          value: calcCurrencyValue(transactionInfo.amount, 'BRL'),
          destination: creditParty?.name || '-',
          date: dayjs(transactionInfo.transactionAt).format('DD/MM/YYYY'),
          time: dayjs(transactionInfo.transactionAt).format('HH:mm'),
        }),
      },
      FEE: {
        title: '',
        description: '',
      },
      BILLING: {
        title: '',
        description: '',
      },
    };

  return (
    <>
      {internalErrorCode && (
        <Alert status="error">
          <p className="text-p2 text-neutral-100">
            Erro ao processar pagamento
          </p>

          {internalErrorCode === 'UNKNOWN_ERROR' && (
            <p className="text-p2 text-danger-500">
              Erro ao processar pagamento do agendamento.
            </p>
          )}

          {internalErrorCode !== 'UNKNOWN_ERROR' && (
            <p className="text-p2 text-danger-500">
              {t(
                `error.CODE_ERROR.${internalErrorCode}`,
                t('error.processing-error')
              )}
            </p>
          )}
        </Alert>
      )}

      {!internalErrorCode && (
        <Alert status="success">
          <Typography tag="p" weight="p2" color="var(--product-success-s700)">
            {messages?.[transactionInfo?.type]?.title}
          </Typography>
          <Typography
            tag="p"
            weight="p2"
            color="var(--product-neutral-n100)"
            className="whitespace-pre-wrap"
          >
            {messages?.[transactionInfo?.type]?.description}
          </Typography>
        </Alert>
      )}

      <div className="flex flex-col gap-4">
        {debitParty && <DebitPartyDetails {...debitParty} />}
        {creditParty && <CreditPartyDetails {...creditParty} />}
        <TransactionInfo {...transactionInfo} />
      </div>
    </>
  );
};

const DebitPartyDetails = (debitParty: DebitParty) => {
  const { t } = useTranslation();

  return debitParty.name || debitParty.document ? (
    <>
      <div className="flex flex-col">
        <Typography
          tag="p"
          weight="p1"
          color="var(--product-neutral-n500)"
          className="accountName"
        >
          {t('payment-voucher.your-account')}
        </Typography>
        <Typography
          tag="p"
          weight="p2"
          color="var(--product-neutral-n60)"
          className="accountName"
        >
          {t('payment-voucher.origin-account')}
        </Typography>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {debitParty.name && (
          <div className="col-span-2 flex flex-col">
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              {t('general.from')}
            </Typography>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {debitParty?.name}
            </Typography>
          </div>
        )}

        {debitParty?.document && (
          <div className="flex flex-col">
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              {t('general.cpf/cnpj')}
            </Typography>

            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {debitParty.document.length === 14
                ? cnpjMask(debitParty.document)
                : cpfMask(debitParty.document)}
            </Typography>
          </div>
        )}
      </div>
      <hr className="hr" />
    </>
  ) : null;
};

const CreditPartyDetails = (creditParty: CreditParty) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col">
        <Typography
          tag="p"
          weight="p1"
          color="var(--product-neutral-n500)"
          className="accountName"
        >
          {t('payment-voucher.destination-account')}
        </Typography>
        <Typography
          tag="p"
          weight="p2"
          color="var(--product-neutral-n60)"
          className="accountName"
        >
          {t('payment-voucher.destination-account-info')}
        </Typography>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {creditParty?.name && (
          <div className="col-span-2 flex flex-col">
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              {t('general.to')}
            </Typography>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {creditParty?.name}
            </Typography>
          </div>
        )}

        {creditParty?.location && (
          <div className="col-span-2 flex flex-col">
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              {t('general.city')}
            </Typography>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {creditParty?.location}
            </Typography>
          </div>
        )}

        {creditParty?.document && (
          <div className="flex flex-col">
            <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
              {t('general.cpf/cnpj')}
            </Typography>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {creditParty?.document.length === 14
                ? cnpjMask(creditParty?.document)
                : cpfMask(creditParty?.document)}
            </Typography>
          </div>
        )}

        {creditParty?.bankAccount &&
          creditParty?.bankCode &&
          creditParty?.branch && (
            <BankDetails
              bankAccount={creditParty?.bankAccount}
              bankCode={creditParty?.bankCode}
              branch={creditParty?.branch}
            />
          )}

        {'pixKey' in creditParty && <PixDetails pixKey={creditParty?.pixKey} />}
      </div>
      <hr className="hr" />
    </>
  );
};

const BankDetails = ({ bankAccount, bankCode, branch }: BankInfo) => {
  const { t } = useTranslation();

  return (
    <>
      {bankCode && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.bank')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {bankCodes[bankCode as keyof typeof bankCodes] ?? '-'}
          </Typography>
        </div>
      )}

      {branch && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.agency')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {branch}
          </Typography>
        </div>
      )}

      {bankAccount && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.account')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {bankAccount}
          </Typography>
        </div>
      )}
    </>
  );
};

const PixDetails = ({ pixKey }: PixInfo) => {
  const { t } = useTranslation();

  return pixKey ? (
    <div className="flex flex-col">
      <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
        {t('general.pix-key')}
      </Typography>
      <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
        {pixKey}
      </Typography>
    </div>
  ) : null;
};

const TransactionInfo = ({
  endToEndId,
  amount,
  transactionAt,
  type,
  barcode,
  dueDate,
  txnDiscountAmount,
  txnFineAmount,
  txnInterestAmount,
  txnMaxAmount,
  txnMinAmount,
  txnOriginalAmount,
  txnUpdatedAmount,
  issuer,
  balanceCategory,
}: TransactionInfo) => {
  const { t } = useTranslation();

  const transactionType: Record<TypePayment, string> = {
    PIX: t('general.pix'),
    BANK_SLIP: t('general.bank-slip'),
    CREDIT_CARD: t('general.card'),
    P2P: 'Transferência entre carteiras',
    BILLING: 'transferência interna',
    FEE: 'taxa',
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="col-span-2 flex flex-col">
        <Typography
          tag="p"
          weight="p1"
          color="var(--product-neutral-n500)"
          className="accountName"
        >
          {t('payment-voucher.transfer-info')}
        </Typography>
        <Typography
          tag="p"
          weight="p2"
          color="var(--product-neutral-n60)"
          className="accountName"
        >
          {t('payment-voucher.additional-info')}
        </Typography>
      </div>
      <div className="flex flex-col">
        <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
          {t('general.type')}
        </Typography>
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {transactionType[type]}
        </Typography>
      </div>

      {issuer && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.issuer')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {issuer}
          </Typography>
        </div>
      )}

      {barcode && (
        <div className="col-span-2 flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.barcode')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {barcode}
          </Typography>
        </div>
      )}

      {balanceCategory && (
        <div className="col-span-2 flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.category')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {t(`balance-category.${balanceCategory}`, '')}
          </Typography>
        </div>
      )}

      <div className="flex flex-col">
        <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
          {t('general.value')}
        </Typography>
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {calcCurrencyValue(amount, 'BRL')}
        </Typography>
      </div>
      <div className="flex flex-col">
        <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
          {t('payment-voucher.effective-date')}
        </Typography>
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {t('payment-voucher.transaction-date', {
            date: getFullDateByISO(transactionAt),
            time: transactionAt ? dayjs(transactionAt).format('HH:mm') : '-',
          })}
        </Typography>
      </div>

      {dueDate && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('payment-voucher.due-date')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {getFullDateByISO(dueDate)}
          </Typography>
        </div>
      )}

      {txnOriginalAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('payment-voucher.original-value')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnOriginalAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {txnDiscountAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.discounts')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnDiscountAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {txnInterestAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.interest')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnInterestAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {txnFineAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('general.fine')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnFineAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {txnMaxAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('payment-voucher.max-value')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnMaxAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {txnMinAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('payment-voucher.min-value')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnMinAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {txnUpdatedAmount !== undefined && (
        <div className="flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('payment-voucher.receivable')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {calcCurrencyValue(txnUpdatedAmount, 'BRL')}
          </Typography>
        </div>
      )}

      {endToEndId && (
        <div className="col-span-2 flex flex-col">
          <Typography tag="p" weight="p3" color="var(--product-neutral-n80)">
            {t('payment-voucher.auth-code')}
          </Typography>
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {endToEndId}
          </Typography>
        </div>
      )}
    </div>
  );
};
