export const initialStateUser = {
  id: '',
  cpf: '',
  firstName: '',
  lastName: '',
  email: '',
  status: '',
  birthdate: '',
  isRoot: true,
  realm: {
    id: '',
    level: '',
  },
  organizations: [
    {
      id: '',
      legalName: '',
      tradingName: '',
      status: '',
      document: '',
    },
    {
      id: '',
      legalName: '',
      tradingName: '',
      status: '',
      document: '',
    },
    {
      id: '',
      legalName: '',
      tradingName: '',
      status: '',
      document: '',
    },
  ],
};
