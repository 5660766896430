import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import {
  CreateWallet,
  CreateWalletResponse,
} from '@interfaces/Wallets.interfaces';
import { createWallet } from '../WalletServices';
import { walletKeys } from './walletKeys';

export const useCreateWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<CreateWalletResponse, ApiError, CreateWallet, unknown>({
    mutationFn: (params: CreateWallet) => createWallet(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: walletKeys.lists() });
    },
  });
};
