import { StepNumber, Typography } from '@portao3-web/ui';
import { SidebarSectionProps } from './SidebarSection.interface';
import './SidebarSection.styles.scss';

export const SidebarSection = ({
  numberSection,
  titleSection,
  subTitleSection,
  children,
}: SidebarSectionProps) => {
  return (
    <section className="section_sidebar">
      {numberSection && (
        <div className="section_sidebar_number">
          <StepNumber number={numberSection} />
        </div>
      )}
      <div className="section_sidebar_content">
        <div className="section_sidebar_title">
          <Typography tag="p" weight="p1" color="var(--product-neutral-n500)">
            {titleSection}
          </Typography>
          {subTitleSection && (
            <Typography tag="p" weight="p2" color="var(--product-neutral-n60)">
              {subTitleSection}
            </Typography>
          )}
        </div>
        {children}
      </div>
    </section>
  );
};
