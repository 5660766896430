import { useMemo, useState } from 'react';

import {
  ClearFiltersQuery,
  SelectQuery,
  Pagination,
  SearchQuery,
} from '@components';
import {
  emptyInvoices,
  emptyInvoicesWithFilter,
  errorInvoices,
} from '@constants/emptyState';
import { regex } from '@constants/regex';
import { useQueryParams } from '@hooks';
import { InvoiceStatus } from '@interfaces/Invoices';
import { Button, Card, EmptyState, Skeleton } from '@portao3-web/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { initialStateInvoice } from '../customersManagement/customersInvoices/constants/initialStateInvoice';
import { useInfinityInvoicesCustomersQuery } from './hooks/useCustomersInvoicesQuery';

import { useInfiniteCustomerListQuery } from '@services/customers/hooks';
import { AccordionItem } from '../customersManagement/customersInvoices/components/AccordionItem';
import { useInfinityInvoicesCustomerQuery } from '../customersManagement/customersInvoices/hooks/useCustomersInvoicesQuery';
import './CustomersInvoices.styles.scss';
import { ExportComponent } from './components/ExportComponent';

dayjs.extend(utc);

export const CustomersInvoices = () => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const organizationId = localStorage.getItem('organization');

  const status = queryParams.getAll('status');
  const invoiceQuery = queryParams.get('invoice');
  const customerId = queryParams.get('customerId') as string;

  const [page, setPage] = useState(0);

  const hasFilters = status.length || invoiceQuery || customerId;

  const { data: customerList, isLoading: isLoadingCustomerList } =
    useInfiniteCustomerListQuery(organizationId, undefined, 500);

  const {
    data: invoices,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: getServiceInvoice,
  } = useInfinityInvoicesCustomersQuery(
    {
      status,
      id:
        invoiceQuery && regex.uuid.test(invoiceQuery)
          ? invoiceQuery
          : undefined,
      description:
        invoiceQuery && !regex.uuid.test(invoiceQuery)
          ? invoiceQuery
          : undefined,
    },
    !customerId
  );

  const {
    data: customerInvoices,
    isFetching: customerInvoicesLoading,
    isError: customerInvoicesError,
    fetchNextPage: fetchNextPageCustomerInvoices,
    hasNextPage: hasNextPageCustomerInvoices,
    isFetchingNextPage: isFetchingNextPageCustomerInvoices,
    refetch: getServiceCustomerInvoice,
  } = useInfinityInvoicesCustomerQuery({
    status,
    id:
      invoiceQuery && regex.uuid.test(invoiceQuery) ? invoiceQuery : undefined,
    description:
      invoiceQuery && !regex.uuid.test(invoiceQuery) ? invoiceQuery : undefined,
    customerId,
  });

  const statusOptions = [
    {
      label: t('invoices.invoiceStatus.CLOSED'),
      value: InvoiceStatus.CLOSED,
    },
    {
      label: t('invoices.invoiceStatus.PAID'),
      value: InvoiceStatus.PAID,
    },
    {
      label: t('invoices.invoiceStatus.OPEN'),
      value: InvoiceStatus.OPEN,
    },
  ];

  const customerOptions = useMemo(() => {
    return (
      customerList?.map((deal) => {
        return {
          label: deal.name,
          value: deal.id,
        };
      }) || []
    );
  }, [customerList]);

  return (
    <div className="customerInvoices">
      <div className="flex justify-between mb-4 items-center">
        <h2 className="text-h3 text-neutral-500">{t('general.invoices')}</h2>

        <div className="flex gap-2">
          <ExportComponent />
        </div>
      </div>

      <div className="mb-4 flex flex-wrap gap-3">
        <SearchQuery
          placeholder={t('invoices.id-invoice')}
          data-testid="search-invoice"
          query="invoice"
        />

        <SelectQuery
          maxSelectedShow={2}
          query="status"
          title={t('general.status')}
          options={statusOptions}
        />

        <SelectQuery
          query="customerId"
          title={t('general.client')}
          isLoading={isLoadingCustomerList}
          options={customerOptions}
          type="single"
        />

        <ClearFiltersQuery queries={['status', 'invoice', 'customerId']} />
      </div>

      <div className="customerInvoices_accordion">
        {!customerId && (
          <>
            {(isFetching
              ? initialStateInvoice.items
              : invoices?.pages?.[page]?.items || []
            )?.map((invoice, index) => {
              const accordionId = `accordion-${index}`;
              return (
                <Skeleton
                  height={60}
                  isLoading={isFetching}
                  className="mb-2"
                  key={index}
                >
                  <AccordionItem
                    isGeneral
                    invoice={invoice}
                    accordionId={accordionId}
                  />
                </Skeleton>
              );
            })}

            {invoices?.pages?.[page]?.items?.length !== 0 && !isError && (
              <Pagination
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                page={page}
                setPage={setPage}
                pageCount={invoices?.pages.length}
                className="ml-auto"
              />
            )}

            {invoices?.pages?.[page]?.items?.length === 0 && !isError && (
              <div className="customerInvoices_empty">
                <Card>
                  <EmptyState
                    direction="column"
                    title={emptyInvoices.title}
                    subtitle={
                      hasFilters
                        ? emptyInvoicesWithFilter.subtitle
                        : emptyInvoices.subtitle
                    }
                    image={emptyInvoices.image}
                  />
                </Card>
              </div>
            )}

            {isError && (
              <div className="customerInvoices_empty">
                <Card>
                  <EmptyState
                    direction="column"
                    title={errorInvoices.title}
                    subtitle={errorInvoices.subtitle}
                    image={errorInvoices.image}
                  >
                    <Button
                      type="button"
                      size="large"
                      variant="primary"
                      data-testid="try-again"
                      onClick={() => getServiceInvoice()}
                    >
                      {t('button.try-again')}
                    </Button>
                  </EmptyState>
                </Card>
              </div>
            )}
          </>
        )}

        {customerId && (
          <>
            {(customerInvoicesLoading
              ? initialStateInvoice.items
              : customerInvoices?.pages?.[page]?.items
            )?.map((invoice, index) => {
              const accordionId = `accordion-${index}`;
              return (
                <Skeleton
                  height={60}
                  isLoading={customerInvoicesLoading}
                  className="mb-2"
                  key={index}
                >
                  <AccordionItem
                    isGeneral
                    invoice={invoice}
                    accordionId={accordionId}
                  />
                </Skeleton>
              );
            })}

            {customerInvoices?.pages?.[page]?.items?.length !== 0 &&
              !customerInvoicesError && (
                <Pagination
                  fetchNextPage={fetchNextPageCustomerInvoices}
                  hasNextPage={hasNextPageCustomerInvoices}
                  isFetchingNextPage={isFetchingNextPageCustomerInvoices}
                  page={page}
                  setPage={setPage}
                  pageCount={customerInvoices?.pages.length}
                  className="ml-auto"
                />
              )}

            {customerInvoices?.pages?.[page]?.items?.length === 0 &&
              !customerInvoicesError && (
                <div className="customerInvoices_empty">
                  <Card>
                    <EmptyState
                      direction="column"
                      title={emptyInvoices.title}
                      subtitle={emptyInvoicesWithFilter.subtitle}
                      image={emptyInvoices.image}
                    />
                  </Card>
                </div>
              )}

            {customerInvoicesError && (
              <div className="customerInvoices_empty">
                <Card>
                  <EmptyState
                    direction="column"
                    title={errorInvoices.title}
                    subtitle={errorInvoices.subtitle}
                    image={errorInvoices.image}
                  >
                    <Button
                      type="button"
                      size="large"
                      variant="primary"
                      data-testid="try-again"
                      onClick={() => getServiceCustomerInvoice()}
                    >
                      {t('button.try-again')}
                    </Button>
                  </EmptyState>
                </Card>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
