import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import {
  ContextualError,
  ContextualErrorType,
  SidebarFooter,
} from '@components';
import { ICONS } from '@constants/icons';
import { SECOND_IN_MILLISECONDS } from '@constants/time';
import { CurrentUser } from '@interfaces/User.interfaces';
import { customerEvents } from '@lib/customerIo';
import { Button, Icon, InputOTP, Toastr } from '@portao3-web/ui';
import { AuthContext, useDrawer } from '@providers';
import { useConfirmMFAMutation } from '@services/mfa/hooks/useConfirmMFAMutation';
import { useGetMFAMutation } from '@services/mfa/hooks/useGetMFAQuery';
import { userKeys } from '@services/user/hooks';

export const CreateMFA = () => {
  const [copied, setCopied] = useState(false);
  const { openDrawer } = useDrawer();
  const [contextualError, setContextualError] =
    useState<ContextualErrorType>(null);

  const { t } = useTranslation();
  const { email } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const {
    mutate: getMFAMutate,
    data: secretCode,
    isPending: isLoadingQRCode,
    isError: isErrorSecretCode,
  } = useGetMFAMutation();

  const [token, setToken] = useState('');

  const handleGenerateMFA = () => {
    setContextualError(null);

    getMFAMutate(undefined, {
      onError: (error) => {
        setContextualError({
          message: t(
            `error.CODE_ERROR.${error?.response?.data?.code}`,
            t('error.generate-mfa')
          ),
          traceId: error?.response?.data?.traceId,
        });
      },
    });
  };

  const { mutate: confirmMFAMutate, isPending: isPendingConfirmMFa } =
    useConfirmMFAMutation();

  const url = `otpauth://totp/${email?.value}?secret=${secretCode}&issuer=Portao3`;

  const setTokenValue = (value: string) => {
    setToken(value);

    if (value.length === 6) {
      confirmMFAMutate(value, {
        onSuccess: () => {
          queryClient.setQueryData(
            userKeys.current(),
            (oldData: CurrentUser) => {
              return {
                ...oldData,
                hasMfa: true,
              };
            }
          );

          customerEvents.mfaCreated();

          Toastr.success(t('auth.mfa-created-successfully'));
          openDrawer('security', { flow: 'DEFAULT' });
        },
        onError: (error) => {
          setContextualError({
            message: t('error.confirm-mfa'),
            traceId: error?.response?.data?.traceId,
          });
        },
      });
    }
  };

  const handleCopy = async (secretCode: string) => {
    try {
      await navigator.clipboard.writeText(secretCode);
    } catch {
      Toastr.error(t('error.need-permission-clipboard'));
    }

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2 * SECOND_IN_MILLISECONDS);
  };

  useEffect(() => {
    handleGenerateMFA();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Spinner = () => (
    <div
      data-testid="spinner-mfa"
      className="border-t-primary-500 box-border inline-block h-10 w-10 animate-spin rounded-full border-r-4 border-t-4 border-solid border-r-transparent"
    />
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="bg-neutral-20 flex h-[158px] w-[158px] flex-col items-center justify-center gap-4 rounded-md">
          {isLoadingQRCode && <Spinner />}
          {isErrorSecretCode && (
            <>
              <Icon size="large">
                <i className="fa-solid fa-triangle-exclamation fa-xl text-neutral-80" />
              </Icon>

              <Button
                variant="secondary"
                size="small"
                className="w-min"
                onClick={() => handleGenerateMFA()}
              >
                {t('general.update')}
              </Button>
            </>
          )}
          {secretCode && <QRCode size={158} value={url} />}
        </div>

        <div className="bg-neutral-20 flex flex-1 flex-col rounded-md p-4">
          <h3 className="text-p2 text-neutral-500">{t('auth.read-qr-code')}</h3>

          <p className="text-p3 text-neutral-80">
            {t('auth.we-recommend-auth-app')}
          </p>

          <p className="text-p3 text-neutral-80 mt-auto">
            {t('auth.you-could-choose-option')}{' '}
            <span className="text-p3 font-bold text-neutral-100">
              Google Authenticator
            </span>{' '}
            ou{' '}
            <span className="text-p3 font-bold text-neutral-100">
              Microsoft Authenticator
            </span>
            .
          </p>
        </div>
      </div>

      {secretCode && (
        <div className="flex flex-col gap-4">
          <div className="border-neutral-40 flex flex-col rounded-md border border-solid p-3">
            <h3 className="text-p2 text-neutral-500">
              {t('auth.if-necessary-copy-above')}
            </h3>

            <div className="flex h-4 items-center gap-2">
              <p className="text-p3 text-neutral-90">{secretCode}</p>

              <Icon size="small">
                <i
                  role="button"
                  data-testid="copy-secret-code"
                  className={clsx({
                    [`${ICONS.copy} fa-lg text-neutral-60`]: !copied,
                    [`${ICONS.check} fa-lg text-success-300`]: copied,
                  })}
                  onClick={() => handleCopy(secretCode)}
                />
              </Icon>
            </div>
          </div>

          <div className="flex flex-col">
            <h2 className="text-p2 text-neutral-500">
              {t('auth.check-auth-code')}
            </h2>
            <p className="text-p3 text-neutral-60">{t('auth.insert-code')}</p>
          </div>

          <InputOTP
            key="input-mfa-create"
            length={6}
            disabled={isPendingConfirmMFa}
            value={token}
            setValue={setTokenValue}
            size="medium"
          />
        </div>
      )}

      <ContextualError error={contextualError} />

      <SidebarFooter>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          onClick={() => openDrawer('security', { flow: 'DEFAULT' })}
        >
          {t('button.back')}
        </Button>

        <Button
          type="submit"
          size="large"
          isLoading={isPendingConfirmMFa}
          disabled
        >
          {t('general.finish')}
        </Button>
      </SidebarFooter>
    </div>
  );
};
