export const countries = {
  AF: 'Afeganistão',
  ZA: 'África do Sul',
  AL: 'Albânia',
  DE: 'Alemanha',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antártida',
  AG: 'Antígua e Barbuda',
  SA: 'Arábia Saudita',
  DZ: 'Argélia',
  AR: 'Argentina',
  AM: 'Armênia',
  AW: 'Aruba',
  AU: 'Austrália',
  AT: 'Áustria',
  AZ: 'Azerbaijão',
  BS: 'Bahamas',
  BH: 'Bahrein',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Bélgica',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermudas',
  BO: 'Bolívia',
  BA: 'Bósnia-Herzegóvina',
  BW: 'Botsuana',
  BR: 'Brasil',
  BN: 'Brunei',
  BG: 'Bulgária',
  BF: 'Burkina Fasso',
  BI: 'Burundi',
  BT: 'Butão',
  CV: 'Cabo Verde',
  CM: 'Camarões',
  KH: 'Camboja',
  CA: 'Canadá',
  IC: 'Canárias',
  KZ: 'Cazaquistão',
  EA: 'Ceuta e Melilla',
  TD: 'Chade',
  CL: 'Chile',
  CN: 'China',
  CY: 'Chipre',
  SG: 'Cingapura',
  CO: 'Colômbia',
  KM: 'Comores',
  CG: 'Congo',
  KP: 'Coréia do Norte',
  KR: 'Coréia do Sul',
  CI: 'Costa do Marfim',
  CR: 'Costa Rica',
  HR: 'Croácia',
  CU: 'Cuba',
  CW: 'Curaçao',
  DG: 'Diego Garcia',
  DK: 'Dinamarca',
  DJ: 'Djibuti',
  DM: 'Dominica',
  EG: 'Egito',
  SV: 'El Salvador',
  AE: 'Emirados Árabes Unidos',
  EC: 'Equador',
  ER: 'Eritréia',
  SK: 'Eslováquia',
  SI: 'Eslovênia',
  ES: 'Espanha',
  US: 'Estados Unidos',
  EE: 'Estônia',
  ET: 'Etiópia',
  FJ: 'Fiji',
  PH: 'Filipinas',
  FI: 'Finlândia',
  FR: 'França',
  GA: 'Gabão',
  GM: 'Gâmbia',
  GH: 'Gana',
  GE: 'Geórgia',
  GI: 'Gibraltar',
  GB: 'Grã-Bretanha (Reino Unido, UK)',
  GD: 'Granada',
  GR: 'Grécia',
  GL: 'Groelândia',
  GP: 'Guadalupe',
  GU: 'Guam (Território dos Estados Unidos)',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GY: 'Guiana',
  GF: 'Guiana Francesa',
  GN: 'Guiné',
  GQ: 'Guiné Equatorial',
  GW: 'Guiné-Bissau',
  HT: 'Haiti',
  NL: 'Holanda',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungria',
  YE: 'Iêmen',
  BV: 'Ilha Bouvet',
  AC: 'Ilha de Ascensão',
  CP: 'Ilha de Clipperton',
  IM: 'Ilha de Man',
  CX: 'Ilha Natal',
  PN: 'Ilha Pitcairn',
  RE: 'Ilha Reunião',
  AX: 'Ilhas Aland',
  KY: 'Ilhas Cayman',
  CC: 'Ilhas Cocos',
  CK: 'Ilhas Cook',
  FO: 'Ilhas Faroes',
  GS: 'Ilhas Geórgia do Sul e Sandwich do Sul',
  HM: 'Ilhas Heard e McDonald (Território da Austrália)',
  FK: 'Ilhas Malvinas',
  MP: 'Ilhas Marianas do Norte',
  MH: 'Ilhas Marshall',
  UM: 'Ilhas Menores dos Estados Unidos',
  NF: 'Ilhas Norfolk',
  SB: 'Ilhas Salomão',
  SC: 'Ilhas Seychelles',
  TK: 'Ilhas Tokelau',
  TC: 'Ilhas Turks e Caicos',
  VI: 'Ilhas Virgens (Estados Unidos)',
  VG: 'Ilhas Virgens (Inglaterra)',
  IN: 'Índia',
  ID: 'Indonésia',
  IR: 'Irã',
  IQ: 'Iraque',
  IE: 'Irlanda',
  IS: 'Islândia',
  IL: 'Israel',
  IT: 'Itália',
  JM: 'Jamaica',
  JP: 'Japão',
  JE: 'Jersey',
  JO: 'Jordânia',
  KI: 'Kiribati',
  XK: 'Kosovo',
  KW: 'Kuait',
  LA: 'Laos',
  LS: 'Lesoto',
  LV: 'Letônia',
  LB: 'Líbano',
  LR: 'Libéria',
  LY: 'Líbia',
  LI: 'Liechtenstein',
  LT: 'Lituânia',
  LU: 'Luxemburgo',
  MO: 'Macau',
  MK: 'Macedônia (República Yugoslava)',
  MG: 'Madagascar',
  MY: 'Malásia',
  MW: 'Malaui',
  MV: 'Maldivas',
  ML: 'Mali',
  MT: 'Malta',
  MA: 'Marrocos',
  MQ: 'Martinica',
  MU: 'Maurício',
  MR: 'Mauritânia',
  YT: 'Mayotte',
  MX: 'México',
  FM: 'Micronésia',
  MZ: 'Moçambique',
  MD: 'Moldova',
  MC: 'Mônaco',
  MN: 'Mongólia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MM: 'Myanma',
  NA: 'Namíbia',
  NR: 'Nauru',
  NP: 'Nepal',
  NI: 'Nicarágua',
  NE: 'Níger',
  NG: 'Nigéria',
  NU: 'Niue',
  NO: 'Noruega',
  NC: 'Nova Caledônia',
  NZ: 'Nova Zelândia',
  OM: 'Omã',
  BQ: 'Países Baixos Caribenhos',
  PW: 'Palau',
  PS: 'Palestina',
  PA: 'Panamá',
  PG: 'Papua-Nova Guiné',
  PK: 'Paquistão',
  PY: 'Paraguai',
  PE: 'Peru',
  PF: 'Polinésia Francesa',
  PL: 'Polônia',
  PR: 'Porto Rico',
  PT: 'Portugal',
  QA: 'Qatar',
  KE: 'Quênia',
  KG: 'Quirguistão',
  CF: 'República Centro-Africana',
  CD: 'República Democrática do Congo',
  DO: 'República Dominicana',
  CZ: 'República Tcheca',
  RO: 'Romênia',
  RW: 'Ruanda',
  RU: 'Rússia (antiga URSS) - Federação Russa',
  EH: 'Saara Ocidental',
  PM: 'Saint-Pierre e Miquelon',
  AS: 'Samoa Americana',
  WS: 'Samoa Ocidental',
  SM: 'San Marino',
  SH: 'Santa Helena',
  LC: 'Santa Lúcia',
  BL: 'São Bartolomeu',
  KN: 'São Cristóvão e Névis',
  MF: 'São Martim',
  SX: 'São Martinho',
  ST: 'São Tomé e Príncipe',
  VC: 'São Vicente e Granadinas',
  SN: 'Senegal',
  SL: 'Serra Leoa',
  RS: 'Sérvia',
  SY: 'Síria',
  SO: 'Somália',
  LK: 'Sri Lanka',
  SZ: 'Suazilândia',
  SD: 'Sudão',
  SS: 'Sudão do Sul',
  SE: 'Suécia',
  CH: 'Suíça',
  SR: 'Suriname',
  SJ: 'Svalbard',
  TJ: 'Tadjiquistão',
  TH: 'Tailândia',
  TW: 'Taiwan',
  TZ: 'Tanzânia',
  IO: 'Território Britânico do Oceano índico',
  TF: 'Territórios do Sul da França',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TO: 'Tonga',
  TT: 'Trinidad e Tobago',
  TA: 'Tristão da Cunha',
  TN: 'Tunísia',
  TM: 'Turcomenistão',
  TR: 'Turquia',
  TV: 'Tuvalu',
  UA: 'Ucrânia',
  UG: 'Uganda',
  UY: 'Uruguai',
  UZ: 'Uzbequistão',
  VU: 'Vanuatu',
  VA: 'Vaticano',
  VE: 'Venezuela',
  VN: 'Vietnã',
  WF: 'Wallis e Futuna',
  ZM: 'Zâmbia',
  ZW: 'Zimbábue',
};

export const getCountryNameBySigla = (countrySigla: string | undefined) => {
  if (!countrySigla) return '-';

  const countryName = countries[countrySigla as keyof typeof countries];
  if (!countryName) {
    return countrySigla;
  }

  return countryName;
};
