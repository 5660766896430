export const menuConfig = (realmId: string) => {
  return [
    {
      title: 'general.settings',
      items: [
        {
          label: 'general.companies',
          path: `/realms/${realmId}/companies`,
          icon: 'building',
        },
        {
          label: 'general.users',
          path: `/realms/${realmId}/users`,
          icon: 'users',
        },
      ],
    },
  ];
};
