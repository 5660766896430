import { useState } from 'react';

import {
  Alert,
  MyWallet,
  NewPix,
  PaymentConfirmationPix,
  PixPaymentVoucher,
  SidebarFooter,
} from '@components';
import { PixPaymentResponse } from '@interfaces/Pix.interface';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import {
  WalletCategory,
  WalletDetailsProps,
} from '@interfaces/Wallets.interfaces';
import { Button, Typography } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useWalletQuery } from '@services/wallet/hooks';
import { useTranslation } from 'react-i18next';
import { FormPayment } from '../../../components/newPix/NewPix.interface';
import { PixPaymentVoucherSchedule } from '../../../components/paymentVoucher/components/pixPaymentVoucher/PixPaymentVoucherSchedule';

interface DrawerPixProps {
  onBack: () => void;
  walletId?: string;
}

type DrawerNewPixFlow =
  | 'selectCategory'
  | 'initiatePix'
  | 'confirmationPayment'
  | 'paymentVoucher'
  | 'schedulePaymentVoucher';

export const DrawerNewPix = ({ onBack, walletId }: DrawerPixProps) => {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const initialFlow = walletId === 'default' ? 'initiatePix' : 'selectCategory';

  const [flow, setFlow] = useState<DrawerNewPixFlow>(initialFlow);
  const organizationId = localStorage.getItem('organization');

  const [categorySelected, setCategorySelected] = useState<WalletCategory>(
    {} as WalletCategory
  );
  const [dictType, setDictType] = useState('');
  const [initiatedValues, setInitiatedValues] = useState<FormPayment | null>(
    null
  );

  const { data: wallet } = useWalletQuery({ walletId, organizationId });

  const [confirmPaymentPix, setConfirmPaymentPix] = useState<
    PixPaymentResponse | CreateTransactionSchedule
  >();

  const selectedCategory = (categoryType: WalletCategory) => {
    setCategorySelected(categoryType);

    if (categoryType.amount === 0) {
      return;
    }

    setFlow('initiatePix');
  };

  const getBalance = (
    category: WalletCategory,
    wallet?: WalletDetailsProps
  ) => {
    if (category.category) {
      const balance = wallet?.balances?.find(
        (item) => item.category === category.category
      );
      return balance?.amount ?? 0;
    }
    return wallet?.totalBalance ?? 0;
  };

  return (
    <>
      {categorySelected.amount === 0 && (
        <Alert status="error">
          <Typography tag="p" weight="p2" color="var(--product-error-d500)">
            Você não possui saldo para fazer a transação!
          </Typography>
        </Alert>
      )}

      {flow === 'selectCategory' && (
        <div className="sidebar_myWallet">
          <MyWallet
            walletCategoryList={wallet?.balances ?? []}
            totalBalance={wallet?.totalBalance ?? 0}
            setSelectedCategory={(element) => selectedCategory(element)}
            isClickable={true}
          />

          <SidebarFooter>
            <Button
              onClick={onBack}
              type="button"
              size="large"
              variant="tertiary"
            >
              {t('general.button.back')}
            </Button>
            <Button
              onClick={closeDrawer}
              type="button"
              size="large"
              variant="primary"
            >
              {t('general.button.close')}
            </Button>
          </SidebarFooter>
        </div>
      )}

      {flow === 'initiatePix' && (
        <NewPix
          goNextStep={() => setFlow('confirmationPayment')}
          back={onBack}
          confirmPayment={setConfirmPaymentPix}
          walletId={walletId}
          balance={getBalance(categorySelected, wallet)}
          setDictType={setDictType}
          setInitiatedValues={setInitiatedValues}
        />
      )}

      {flow === 'confirmationPayment' && confirmPaymentPix && (
        <PaymentConfirmationPix
          onConfirm={(isScheduling) => {
            if (isScheduling) {
              setFlow('schedulePaymentVoucher');
            } else {
              setFlow('paymentVoucher');
            }
          }}
          back={onBack}
          paymentConfirm={confirmPaymentPix as PixPaymentResponse}
          paymentVoucher={setConfirmPaymentPix}
          walletId={walletId}
          walletName={wallet?.name}
          categoryType={categorySelected.category}
          dictType={dictType}
          initiatedValues={initiatedValues}
        />
      )}

      {flow === 'paymentVoucher' && confirmPaymentPix && (
        <PixPaymentVoucher data={confirmPaymentPix as PixPaymentResponse} />
      )}

      {flow === 'schedulePaymentVoucher' && confirmPaymentPix && (
        <PixPaymentVoucherSchedule
          scheduleId={(confirmPaymentPix as CreateTransactionSchedule)?.id}
          walletId={(confirmPaymentPix as CreateTransactionSchedule)?.wallet}
        />
      )}
    </>
  );
};
