import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EditUserParams, RealmUser } from '@interfaces/User.interfaces';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { updateUser } from '@services/user';
import { userKeys } from './userKeys';

interface UpdateUserMutationParams {
  params: EditUserParams;
  pin?: string;
}

export const useUpdateUserMutation = (userId: string) => {
  const queryClient = useQueryClient();

  return useMutation<RealmUser, ApiErrorPin, UpdateUserMutationParams, unknown>(
    {
      mutationFn: ({ params, pin }: UpdateUserMutationParams) =>
        updateUser(userId, params, pin),
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: userKeys.lists() });
        queryClient.setQueryData(userKeys.detail(userId), response);
      },
    }
  );
};
