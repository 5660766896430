/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import dayjs from 'dayjs';

import { regex } from '@constants/regex';
import { removeSpecialCharacters } from './removeSpecialCharacters';

export const optionalSchema = {
  firstName: yup.string(),
  lastName: yup.string(),
  birthdate: yup
    .string()
    .test('is-date', 'error.invalid-date', (value) => {
      if (!value) return true;
      const date = new Date(value);
      return !isNaN(date.getTime());
    })
    .test('is-future-date', 'error.future-date', (value) => {
      if (!value) return true;

      const today = new Date();
      const date = new Date(value);
      return date < today;
    }),
  date: yup.string().test('is-date', 'error.invalid-date', (value) => {
    if (!value) return true;
    const date = new Date(value);
    return !isNaN(date.getTime());
  }),
  futureDate: yup
    .string()
    .test('is-date', 'error.invalid-date', (value) => {
      if (!value) return true;
      const date = new Date(value);
      return !isNaN(date.getTime());
    })
    .test('is-past-date', 'error.past-date', (value) => {
      if (!value) return true;
      const valueDate = dayjs(value).valueOf();
      const today = dayjs().valueOf();
      return valueDate > today;
    }),
  cpf: yup.string().test('is-cpf', 'error.invalid-cpf', (value) => {
    if (!value) return true;
    return regex.cpf.test(value);
  }),
  postalCode: yup
    .string()
    .test('is-postalCode', 'error.invalid-postalCode', (value) => {
      if (!value) return true;
      return regex.postalCode.test(value);
    }),
  email: yup.string().email('error.invalid-email'),
  phone: yup.string().test('is-phone', 'error.invalid-phone', (value) => {
    if (!value) return true;
    return regex.phone.test(value);
  }),
  cellphone: yup
    .string()
    .test('is-cellphone', 'error.invalid-cellphone', (value) => {
      if (!value) return true;
      return (
        regex.phone.test(value) && removeSpecialCharacters(value).length === 11
      );
    }),
  state: yup.string(),
  city: yup.string(),
  neighborhood: yup.string(),
  address: yup.string(),
  number: yup.string(),
  client: yup.string(),
  wallet: yup.string(),
  amount: yup.string(),
  interest: yup
    .string()
    .test('is-interest', 'error.invalid-interest', (value) => {
      if (!value) return true;

      const current = value.replace(/\D/g, '');

      return +current > 100 && +current <= 10000;
    }),
  fine: yup.string(),
  description: yup.string(),
  cnpj: yup.string().test('is-cnpj', 'error.invalid-cnpj', (value) => {
    if (!value) return true;
    return regex.cnpj.test(value);
  }),
  legalName: yup.string(),
  tradingName: yup.string(),
  token: yup.string().test('is-token', 'error.invalid-password', (value) => {
    if (!value) return true;
    return regex.token.test(value);
  }),
};

export const requiredSchema: typeof optionalSchema = {
  firstName: optionalSchema.firstName.required('error.required-first-name'),
  lastName: optionalSchema.lastName.required('error.required-last-name'),
  birthdate: optionalSchema.birthdate.required('error.required-birthdate'),
  date: optionalSchema.date.required('error.required-date'),
  futureDate: optionalSchema.futureDate.required('error.required-date'),
  cpf: optionalSchema.cpf.required('error.required-cpf'),
  email: optionalSchema.email.required('error.required-email'),
  phone: optionalSchema.phone.required('error.required-phone'),
  cellphone: optionalSchema.cellphone.required('error.required-cellphone'),
  postalCode: optionalSchema.postalCode.required('error.required-postalCode'),
  state: optionalSchema.state.required('error.required-state'),
  city: optionalSchema.city.required('error.required-city'),
  address: optionalSchema.address.required('error.required-address'),
  neighborhood: optionalSchema.neighborhood.required(
    'error.required-neighborhood'
  ),
  number: optionalSchema.number.required('error.required-number'),
  client: optionalSchema.client.required('error.required-client'),
  wallet: optionalSchema.wallet.required('error.required-wallet'),
  amount: optionalSchema.amount.required('error.required-amount'),
  interest: optionalSchema.interest.required('error.required-interest'),
  fine: optionalSchema.fine.required('error.required-fine'),
  description: optionalSchema.description.required(
    'error.required-description'
  ),
  cnpj: optionalSchema.cnpj.required('error.required-cnpj'),
  legalName: optionalSchema.legalName.required('error.required-legal-name'),
  tradingName: optionalSchema.tradingName.required(
    'error.required-trading-name'
  ),
  token: optionalSchema.token.required('error.required-password'),
};

export const validationSchema = (
  defaultValues: Record<string, any>,
  customValidation?: Record<string, any>
) => {
  const rules: Record<string, any> = { ...customValidation };
  const validationRulesTyped = requiredSchema as Record<string, any>;

  Object.keys(defaultValues).forEach((key) => {
    if (validationRulesTyped[key] && !rules[key]) {
      rules[key] = validationRulesTyped[key];
    }
  });

  return yup.object().shape(rules);
};
