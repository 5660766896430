import { customerEvents } from '@lib/customerIo';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { Button, Modal } from '@portao3-web/ui';
import {
  findScheduleById,
  schedulingTransactionKeys,
} from '@services/scheduling';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertPix } from '../../alerts';
import { TransactionScheduleProps } from '../interface/scheduling.interface';
import { useCancelScheduleMutation } from '../services/hooks/useCancelScheduleMutation';

dayjs.extend(utc);

interface CancelScheduleModalProps {
  scheduleDeleteData: TransactionScheduleProps;
  setScheduleDeleteData: (isOpen: TransactionScheduleProps | null) => void;
}

export const CancelScheduleModal = ({
  scheduleDeleteData,
  setScheduleDeleteData,
}: CancelScheduleModalProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [isLoadingTrackingInfo, setIsLoadingTrackingInfo] = useState(false);

  const { mutate: cancelSchedule, isPending } = useCancelScheduleMutation();

  const handleConfirmCancel = () => {
    if (!scheduleDeleteData) return;

    cancelSchedule(
      {
        schedulingId: scheduleDeleteData?.id,
        walletId: scheduleDeleteData?.wallet,
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: schedulingTransactionKeys.lists(),
          });

          queryClient.invalidateQueries({
            queryKey: schedulingTransactionKeys.detail(
              {
                scheduleId: scheduleDeleteData.id,
                walletId: scheduleDeleteData.wallet,
              },
              scheduleDeleteData.organization
            ),
          });

          try {
            setIsLoadingTrackingInfo(true);
            const response = await findScheduleById({
              scheduleId: scheduleDeleteData.id,
              walletId: scheduleDeleteData.wallet,
            });

            customerEvents.futureReleasesCanceled({
              updatedAmount: scheduleDeleteData.amount,
              scheduleDate: scheduleDeleteData.scheduledAt,
              scheduleId: scheduleDeleteData.id,
              walletId: scheduleDeleteData.wallet,
              type: scheduleDeleteData.type,
              dueDate: response?.additionalInfos?.dueDate || null,
              originalAmount: response?.additionalInfos?.originalAmount || null,
            });
          } catch {
            customerEvents.futureReleasesCanceled({
              updatedAmount: scheduleDeleteData.amount,
              scheduleDate: scheduleDeleteData.scheduledAt,
              scheduleId: scheduleDeleteData.id,
              walletId: scheduleDeleteData.wallet,
              type: scheduleDeleteData.type,
              dueDate: null,
              originalAmount: null,
            });
          } finally {
            setIsLoadingTrackingInfo(false);
          }

          setScheduleDeleteData(null);
        },
      }
    );
  };

  return (
    <Modal
      open
      closeModal={() => setScheduleDeleteData(null)}
      title={t('schedule-payment.cancel-transaction')}
    >
      <div className="w-[522px] py-4 px-6 grid grid-cols-2 grid-rows-3 gap-4">
        <p className="col-span-2 text-p1 text-neutral-80">
          {t('schedule-payment.cancel-transaction-confirm')}
        </p>

        <ValueWrapper
          className="col-span-2"
          label={t('general.description')}
          value={scheduleDeleteData.description}
        />

        <ValueWrapper
          label={'Data do agendamento'}
          value={dayjs(scheduleDeleteData.scheduledAt)
            .utc(false)
            .format('DD/MM/YYYY')}
        />

        <ValueWrapper
          label={'Tipo da transação'}
          value={t(`enum.TransactionScheduleType.${scheduleDeleteData.type}`)}
        />

        <div className="col-span-2">
          <AlertPix status={'warning'}>
            <p className="text-warning-700 text-p1">
              {t('schedule-payment.attention-permanent-action')}
            </p>
          </AlertPix>
        </div>
      </div>

      <footer className="flex gap-4 px-6 py-3 justify-end border border-solid border-neutral-40">
        <Button
          size="large"
          variant="secondary"
          onClick={() => setScheduleDeleteData(null)}
          disabled={isPending || isLoadingTrackingInfo}
        >
          {t('general.not')}
        </Button>

        <Button
          size="large"
          isLoading={isPending || isLoadingTrackingInfo}
          onClick={handleConfirmCancel}
        >
          {t('general.yes-cancel')}
        </Button>
      </footer>
    </Modal>
  );
};
