import { FinancialImpactType } from '@enums/Invoices.enum';
import { ColumnDef } from '@tanstack/react-table';
import { moneyMask } from '@utils/formatMasks';
import { useTranslation } from 'react-i18next';

export const useInvoiceAdjustmentsColumns = (): ColumnDef<{
  id: string;
  product: string;
  value: string;
  financialImpactType: FinancialImpactType;
}>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'product',
      header: 'Ajustes',
      cell: ({ cell }) => cell.getValue<string>(),
      meta: {
        width: 'auto',
      },
    },
    {
      accessorKey: 'value',
      header: t('general.value'),
      cell: ({ cell, row }) => {
        if (row.original.financialImpactType === 'DEBIT') {
          return (
            <p className="text-p2 text-success-500">
              (+){moneyMask(cell.getValue<string>())}
            </p>
          );
        }
        return (
          <p className="text-p2 text-danger-500">
            (-){moneyMask(cell.getValue<string>())}
          </p>
        );
      },
    },
  ];
};

export const useInvoiceDetailsColumns = (): ColumnDef<{
  id: string;
  product: string;
  type: string;
  value: string;
}>[] => {
  const { t } = useTranslation();

  return [
    {
      accessorKey: 'product',
      header: t('general.product/service'),
      cell: ({ cell }) => cell.getValue<string>(),
    },
    {
      accessorKey: 'type',
      header: t('general.type'),
      cell: ({ cell }) => cell.getValue<string>(),
    },
    {
      accessorKey: 'value',
      header: t('general.value'),
      cell: ({ cell }) => {
        return `${moneyMask(cell.getValue<string>())}`;
      },
    },
  ];
};
