import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { InvoiceCustomerListParams } from '@interfaces/Invoices.interfaces';
import { customersInvoicesKeys } from '@services/invoices/hooks/customersInvoicesKeys';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { getCustomersInvoicesList } from '../services/CustomersInvoicesService';
import { ListResponse } from '@interfaces/Common.interfaces';
import { InvoiceDealProps } from '@interfaces/Invoices';

export const useInfinityInvoicesCustomersQuery = (
  params: Omit<InvoiceCustomerListParams, 'customerId'>,
  enabled: boolean
) => {
  const organizationId = localStorage.getItem('organization');

  return useInfiniteQuery<
    ListResponse<InvoiceDealProps>,
    ApiError,
    InfiniteData<ListResponse<InvoiceDealProps>>,
    unknown[],
    string
  >({
    queryKey: customersInvoicesKeys.listGeneralInvoices({
      ...params,
      organizationId,
    }),
    queryFn: ({ pageParam }) =>
      getCustomersInvoicesList({ ...params, next: pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage.next && lastPage.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    enabled: enabled,
  });
};
