import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChangeStatusModal,
  ClearFiltersQuery,
  EmptyStateBlock,
  HeaderPage,
  SelectQuery,
  Pagination,
  SearchQuery,
  TableColumnsFilter,
} from '@components';
import {
  emptyCollaborators,
  emptyCollaboratorsWithFilters,
  errorState,
} from '@constants/emptyState';
import { StatusEnum } from '@enums/Status.enum';
import { UserLevelEnum } from '@enums/UserEnum';
import { useAuthentication, useQueryParams } from '@hooks';
import { customerEvents } from '@lib';
import { DrawerAuthentication } from '@modules/drawer';
import { Button, TableDemo, Toastr } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useDeactivateUserMutation } from '@services/user/hooks';
import { useQueryClient } from '@tanstack/react-query';
import './UsersManagement.styles.scss';
import { useUserColumns } from './components/usersColumns';
import { useInfiniteUserListQuery } from './services/hooks/useInfinityUserListQuery';

const getDeactivateUserText = (fullName: string) => {
  return {
    actionButtonLabel: 'Sim, excluir',
    confirmation: 'Deseja mesmo continuar?',
    disclaimer: `O usuário ${fullName} será definitivamente excluído da plataforma, e seus acessos serão bloqueados e todo o saldo de suas carteiras serão zerados e transferidos para a conta empresarial.`,
    modalTitle: 'Desativar usuário',
    warn: {
      title: 'Esta é uma ação permanente!',
      description:
        'Caso você prossiga com a exclusão deste usuário, não será possível revertê-la.',
    },
  };
};

export const UsersManagement = () => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const queryClient = useQueryClient();
  const { queryParams } = useQueryParams();

  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState({
    open: false,
    userId: '',
    fullName: '',
    email: '',
  });
  const [page, setPage] = useState<number>(0);

  const {
    pin,
    controller,
    authSubmit,
    onAuthSuccess,
    onAuthError,
    isAuthentication,
  } = useAuthentication();

  const {
    mutate: deactivateUser,
    isPending: isDeactivatingUser,
    error: deactivateUserError,
  } = useDeactivateUserMutation();

  const handleDeactivateUser = () => {
    deactivateUser(
      {
        userId: showDeactivateUserModal.userId,
        pin,
      },
      {
        onSuccess: async () => {
          Toastr.success(t('realm.user-deactivated'));

          queryClient.refetchQueries({ queryKey: ['realm', 'users'] });
          queryClient.refetchQueries({
            queryKey: ['user', { userId: showDeactivateUserModal.userId }],
          });
          setShowDeactivateUserModal({
            open: false,
            userId: '',
            fullName: '',
            email: '',
          });
          customerEvents.userDeactivated({
            id: showDeactivateUserModal.userId,
            email: showDeactivateUserModal.email,
          });

          onAuthSuccess();
        },
        onError: (error) => {
          onAuthError(error);
        },
      }
    );
  };

  const statusOptions = [
    {
      label: t('status.active'),
      value: StatusEnum.ACTIVE,
    },
    {
      label: t('status.creating'),
      value: StatusEnum.CREATING,
    },
    {
      label: t('status.deactivated'),
      value: StatusEnum.DEACTIVATED,
    },
  ];

  const securityStatusOptions = [
    {
      label: t('status.active'),
      value: 'true',
    },
    {
      label: t('general.pending'),
      value: 'false',
    },
  ];

  const levelOptions = [
    {
      label: t('general.admin'),
      value: UserLevelEnum.ADMIN,
    },
    {
      label: t('general.collaborator'),
      value: UserLevelEnum.USER,
    },
  ];

  const { columns, filterColumnsProps } = useUserColumns(
    setShowDeactivateUserModal
  );

  const filters = {
    name: queryParams.get('username') ?? undefined,
    status: queryParams.getAll('status') as StatusEnum[],
    hasMfa: queryParams.get('mfa'),
    hasPin: queryParams.get('pin'),
    level: queryParams.get('access') as UserLevelEnum,
  };

  const {
    data: userDataList,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteUserListQuery(filters);

  const hasFilters = Object.values(filters).some((value) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return !!value;
  });

  const closeDeactivateUserModal = () => {
    setShowDeactivateUserModal({
      open: false,
      userId: '',
      fullName: '',
      email: '',
    });
  };

  return (
    <>
      <div className="user_management">
        <HeaderPage
          title={t('users-management.title')}
          subTitle={t('users-management.subtitle')}
        >
          <Button
            type="button"
            size="large"
            variant="primary"
            key="edit-realm-user"
            data-testid="create-user-button"
            onClick={() => openDrawer('edit-realm-user')}
          >
            {t('realm.add-user')}
            <i className="fa-regular fa-plus fa-1x" />
          </Button>
        </HeaderPage>

        <div className="mb-4 flex flex-wrap gap-3">
          <SearchQuery placeholder={t('general.username')} query="username" />

          <SelectQuery
            onlyOptions
            query="access"
            title={t('general.access')}
            type="single"
            options={levelOptions}
          />

          <SelectQuery
            onlyOptions
            query="status"
            title={t('general.status')}
            options={statusOptions}
          />

          <SelectQuery
            onlyOptions
            query="pin"
            title={'PIN'}
            type="single"
            options={securityStatusOptions}
          />

          <SelectQuery
            onlyOptions
            query="mfa"
            title={t('general.mfa')}
            type="single"
            options={securityStatusOptions}
          />

          <ClearFiltersQuery
            queries={['status', 'username', 'access', 'mfa', 'pin']}
          />

          <TableColumnsFilter {...filterColumnsProps} />
        </div>

        <TableDemo
          columns={columns}
          data={userDataList?.pages?.[page]?.items || []}
          isLoading={isLoading || isFetchingNextPage}
          emptyState={
            <>
              {!isError && !hasFilters && (
                <EmptyStateBlock
                  details={emptyCollaborators}
                  button
                  buttonLabel="Adicionar colaborador"
                  buttonAction={() => {
                    openDrawer('edit-realm-user');
                  }}
                />
              )}

              {!isError && hasFilters && (
                <EmptyStateBlock details={emptyCollaboratorsWithFilters} />
              )}

              {isError && <EmptyStateBlock details={errorState} />}
            </>
          }
          pinLastColumn
        />

        {!isError && (
          <Pagination
            className="justify-end mt-4 ml-auto"
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            setPage={setPage}
            page={page}
            pageCount={userDataList?.pages.length}
          />
        )}
      </div>

      {showDeactivateUserModal.open && !isAuthentication && (
        <ChangeStatusModal
          open={showDeactivateUserModal.open}
          closeModal={closeDeactivateUserModal}
          changeStatus={() => authSubmit(handleDeactivateUser)}
          modalText={getDeactivateUserText(showDeactivateUserModal.fullName)}
          errorService={!!deactivateUserError}
          isLoading={isDeactivatingUser}
        />
      )}

      <DrawerAuthentication
        {...controller}
        onSubmit={handleDeactivateUser}
        isPending={isDeactivatingUser}
        onClose={() => {
          controller?.onClose();
          closeDeactivateUserModal();
        }}
      />
    </>
  );
};
