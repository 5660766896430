import { useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { getMemberGetMemberId } from '@services/user';
import { userKeys } from './userKeys';

interface MemberGetMemberIdQueryParams {
  userId: string;
  organizationId: string;
}

export const useMemberGetMemberIdQuery = ({
  organizationId,
  userId,
}: MemberGetMemberIdQueryParams) => {
  return useQuery<
    {
      id: string;
      user: string;
    },
    ApiError,
    {
      id: string;
      user: string;
    },
    unknown[]
  >({
    queryKey: userKeys.memberGetMember(userId),
    queryFn: () => getMemberGetMemberId(userId),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    retry: 1,
    enabled: !!userId && !!organizationId,
  });
};
