import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import * as React from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { cn } from '@portao3-ui/lib/utils';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    variant?: 'radio' | 'checkbox';
  }
>(({ className, variant = 'checkbox', ...props }, ref) => {
  const checkbox = variant === 'checkbox';

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        `peer h-3 w-3 shrink-0 ${
          checkbox
            ? 'rounded-[0.2rem]'
            : 'rounded-full data-[state=checked]:!border-white data-[state=checked]:ring-offset-[0.5px] data-[state=checked]:ring-1 data-[state=checked]:ring-ring'
        } border-[1.5px] border-neutral-50 ring-offset-background disabled:cursor-not-allowed disabled:opacity-30`,
        'data-[state=checked]:bg-primary-500 data-[state=checked]:text-primary-foreground data-[state=checked]:border-transparent',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        {checkbox && <Check className="h-full w-full font-bold" />}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox as ShadCheckbox };
