import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import { SecurityFlow } from '@enums/Security.enum';
import { Sidebar } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { MFAWrapper, PINWrapper, Security } from './flows';

export const DrawerSecurity = () => {
  const { t } = useTranslation();
  const { closeDrawer, params } = useDrawer();

  const flow = (params?.flow as SecurityFlow) || SecurityFlow.DEFAULT;

  const currentFlow = {
    [SecurityFlow.DEFAULT]: <Security />,
    [SecurityFlow.MFA]: <MFAWrapper />,
    [SecurityFlow.PIN]: <PINWrapper />,
  };

  return (
    <Sidebar
      open
      title={t('auth.security-and-limits')}
      icon={ICONS.shield}
      onClose={closeDrawer}
    >
      {currentFlow[flow]}
    </Sidebar>
  );
};
