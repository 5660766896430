import { ContextualErrorType } from '@components';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { useMutation } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { PixKeyFormProps } from '../components/addKey/AddKey.interfaces';
import { createPixDict } from '../services';

export const useCreatePixDictMutation = (
  setError: Dispatch<SetStateAction<ContextualErrorType>>
) => {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      walletId,
      data,
    }: {
      walletId: string;
      data: PixKeyFormProps;
    }) => createPixDict(walletId, data),
    onError: (error: ApiError) => {
      const errorInfo = error?.response?.data;

      errorInfo?.statusCode &&
        setError({
          message: t('error.default'),
          traceId: errorInfo?.traceId,
        });
    },
  });
};
