import { SkeletonProps } from './Skeleton.interface';

import './Skeleton.styles.scss';

export const Skeleton = ({
  children,
  height,
  isLoading = true,
  variant = 'rounded',
  width,
  padding,
  className,
  testId,
}: SkeletonProps) => {
  return isLoading ? (
    <div
      className={`${
        isLoading ? `skeleton skeleton--${variant} ${className}` : ''
      } `}
      style={{
        width: isLoading ? width : '',
        height: isLoading ? height : '',
        padding: isLoading ? padding : '',
        boxSizing: 'border-box',
      }}
      data-testid={testId}
    />
  ) : (
    children
  );
};
