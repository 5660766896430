import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { RealmUser } from '@interfaces/User.interfaces';
import { GetUserListProps, getUsersByRealm } from '@services/user';
import { userKeys } from './userKeys';

export const useInfiniteRealmUserListQuery = (
  params: GetUserListProps & { realmId?: string }
) => {
  const { realmId, ...rest } = params;
  return useInfiniteQuery<
    ListResponse<RealmUser>,
    ApiError,
    InfiniteData<ListResponse<RealmUser>>,
    unknown[],
    string
  >({
    queryKey: [...userKeys.realmList({ ...rest, realmId })],
    queryFn: ({ pageParam }) =>
      getUsersByRealm({
        ...rest,
        next: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage?.next && lastPage?.next !== '' ? lastPage.next : null;
    },
    initialPageParam: '',
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    placeholderData: keepPreviousData,
    enabled: !!realmId,
  });
};
