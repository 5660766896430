import { WalletBalanceCategory } from '@enums/WalletEnum';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { WalletType } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { moneyMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../../alerts';
import { SidebarSection } from '../../../sidebarSection';
import { PaymentVoucher } from '../../PaymentVoucher';

export interface P2PTransferVoucherProps {
  payerWalletType: WalletType;
  payeeWalletType: WalletType;
  payeeWalletName: string;
  payerWalletName: string;
  payeeCategory: WalletBalanceCategory;
  payerCategory: WalletBalanceCategory;
  amount: number;
}

export const P2PTransferVoucher = ({
  amount,
  payeeCategory,
  payeeWalletName,
  payerWalletName,
  payeeWalletType,
  payerWalletType,
  payerCategory,
}: P2PTransferVoucherProps) => {
  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  return (
    <PaymentVoucher
      onClose={closeDrawer}
      title={`recibo-transfer-${dayjs().format('DD/MM/YYYY')}.pdf`}
      className="flex flex-col gap-2"
    >
      <Alert status="success">
        <p className="text-p2 text-neutral-400">
          Sua transferência foi realizada com sucesso!
        </p>

        <p className="text-p2 text-neutral-100">
          Transferência de{' '}
          <strong className="text-p2 text-success-500">
            {moneyMask(amount)}
          </strong>{' '}
          para {payeeWalletName} realizada em{' '}
          {dayjs().utc(true).format('DD/MM/YYYY')} às{' '}
          {dayjs().utc(true).format('HH:mm')}.
        </p>
      </Alert>

      <div className="grid grid-cols-2 gap-4">
        <ValueWrapper
          label={t('general.date')}
          value={dayjs().utc(true).format('DD/MM/YYYY')}
        />

        <ValueWrapper
          label={t('payment-voucher.transfer-type')}
          value={t('general.transfer')}
        />
      </div>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('general.origin')}
        subTitleSection={t('payment-voucher.origin')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper label={t('general.wallet')} value={payerWalletName} />

          <ValueWrapper
            label={t('general.category')}
            value={t(`enum.WalletBalanceCategory.${payerCategory}`, '-')}
          />

          <ValueWrapper
            label={t('general.type')}
            value={t(`enum.WalletType.${payerWalletType}`, '-')}
          />
        </div>
      </SidebarSection>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('general.destination')}
        subTitleSection={t('payment-voucher.destination-info')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper label={t('general.wallet')} value={payeeWalletName} />

          <ValueWrapper
            label={t('general.category')}
            value={t(`enum.WalletBalanceCategory.${payeeCategory}`, '-')}
          />

          <ValueWrapper
            label={t('general.type')}
            value={t(`enum.WalletType.${payeeWalletType}`, '-')}
          />
        </div>
      </SidebarSection>
    </PaymentVoucher>
  );
};
