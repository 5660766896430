import { useState } from 'react';
import { Typography } from '../typography/Typography';
import { TagProps } from './Tag.interface';
import './Tag.styles.scss';

export const Tag = ({
  text,
  textColor,
  backgroundColor = 'var(--product-primary-p50)',
  borderColor,
  deleteTag = () => 0,
  showCloseButton = true,
  icon,
  className = '',
}: TagProps) => {
  const [showTag, setShowTag] = useState(true);

  const removeTag = () => {
    deleteTag();
    setShowTag(false);
  };

  return (
    showTag && (
      <div
        className={`tag gap-1 ${className}`}
        style={{
          backgroundColor: backgroundColor,
          border: borderColor ? `1px solid ${borderColor}` : 'none',
          color: textColor,
        }}
      >
        {icon}
        <Typography tag={'p'} weight="p3" color={textColor}>
          {text}
        </Typography>
        {showCloseButton && (
          <button
            data-testid="close-tag"
            className="tag_close_button"
            onClick={removeTag}
          >
            <i className="fa-regular fa-xmark" />
          </button>
        )}
      </div>
    )
  );
};
