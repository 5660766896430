import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import {
  CreateWalletResponse,
  UpdateWalletParams,
  WalletDetailsProps,
} from '@interfaces/Wallets.interfaces';
import { updateWallet } from '../WalletServices';
import { walletKeys } from './walletKeys';

export const useUpdateWalletMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    CreateWalletResponse,
    ApiError,
    UpdateWalletParams,
    unknown
  >({
    mutationFn: (params: UpdateWalletParams) => updateWallet(params),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: walletKeys.lists() });
      queryClient.setQueryData(
        walletKeys.detail({
          walletId: response.id,
          organizationId: response.organization,
        }),
        (oldData: WalletDetailsProps) => ({ ...oldData, ...response })
      );
    },
  });
};
