const MODULE_ELEVENS_CHECK_DIGIT = 11;

export const cpfValidate = (cpf: string): boolean => {
  const splitedNumber = cpf.replaceAll('.', '').split('-');
  const firstPart = splitedNumber[0].split('').reverse();
  const firstVerifyerDigit = Number(splitedNumber[1].split('')[0]);
  const secondVerifyerDigit = Number(splitedNumber[1].split('')[1]);

  let accumulator = 0;

  const computedFirstDigit = returnDigit(
    computedAccumulatorCPF(accumulator, firstPart)
  );

  firstPart.unshift(computedFirstDigit.toString());
  accumulator = 0;

  const computedSecondDigit = returnDigit(
    computedAccumulatorCPF(accumulator, firstPart)
  );

  return (
    firstVerifyerDigit !== computedFirstDigit ||
    secondVerifyerDigit !== computedSecondDigit
  );
};

const returnDigit = (value: number): number => {
  const restOfDivision = value % MODULE_ELEVENS_CHECK_DIGIT;
  return restOfDivision < 2 ? 0 : MODULE_ELEVENS_CHECK_DIGIT - restOfDivision;
};

const computedAccumulatorCPF = (accumulator: number, arrayElem: string[]) => {
  for (let i = 0; i < arrayElem.length; i++) {
    accumulator += Number(arrayElem[i]) * (i + 2);
  }

  return accumulator;
};

const computedAccumulatorCNPJ = (
  accumulator: number,
  weight: number,
  arrayElem: string[]
) => {
  for (let i = 0; i < arrayElem.length; i++) {
    weight = weight < 2 ? 9 : weight;
    accumulator += Number(arrayElem[i]) * weight;
    weight--;
  }

  return accumulator;
};

export const cnpjValidate = (cnpj: string): boolean => {
  const cleanedCnpj = cnpj.replace(/[^\d]/g, '');

  if (cleanedCnpj.length !== 14) {
    return true;
  } else {
    const firstPart = cleanedCnpj.substr(0, 12).split('');
    const verifyerDigits = cleanedCnpj.substr(12, 2).split('').map(Number);

    let accumulator = 0;
    let initialWeight = 5;
    const computedFirstDigit = returnDigit(
      computedAccumulatorCNPJ(accumulator, initialWeight, firstPart)
    );

    firstPart.push(computedFirstDigit.toString());

    accumulator = 0;
    initialWeight = 6;
    const computedSecondDigit = returnDigit(
      computedAccumulatorCNPJ(accumulator, initialWeight, firstPart)
    );

    return (
      verifyerDigits[0] !== computedFirstDigit ||
      verifyerDigits[1] !== computedSecondDigit
    );
  }
};
