import { HeaderForm } from '@components';
import { Button } from '@portao3-web/ui';
import { useNavigate } from 'react-router-dom';

export const CompletedPasswordRequest = () => {
  const history = useNavigate();

  return (
    <>
      <HeaderForm
        title="Sua senha foi redefinida com sucesso!"
        subTitle="Clique no botão abaixo para continuar."
      />

      <div className="authentication_content_right_form_inputs">
        <div className="authentication_submit">
          <Button size="large" onClick={() => history('/login')}>
            Fazer login
          </Button>
        </div>
      </div>
    </>
  );
};
