import {
  BatchPaymentStatus,
  TransactionBatchStatus,
} from '@enums/BatchPayment.enum';
import { BillingStatusEnum } from '@enums/Billing.enum';
import { StatusEnum } from '@enums/Status.enum';

export type StatusTypeMap = {
  BATCH_PAYMENTS: AugmentedBatchPaymentStatus;
  BATCH_TRANSACTIONS: TransactionBatchStatus;
  GENERAL: StatusEnum;
  BILLING: BillingStatusEnum;
};

export type IconType = keyof StatusTypeMap;

export interface StatusIconInfo {
  icon: string;
  label: string;
}

export enum BatchPaymentStatusExtension {
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
}

export type AugmentedBatchPaymentStatus =
  | BatchPaymentStatus
  | BatchPaymentStatusExtension;
