import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { WalletDetailsProps } from '@interfaces/Wallets.interfaces';
import { getWalletData } from '@services/wallet/WalletServices';
import { walletKeys } from './walletKeys';

export interface UseWalletQueryParams {
  walletId: string | undefined;
  organizationId: string | undefined | null;
  enabled?: boolean;
}

export const useWalletQuery = ({
  organizationId,
  walletId,
  enabled = true,
}: UseWalletQueryParams) => {
  return useQuery<WalletDetailsProps, ApiError, WalletDetailsProps, unknown[]>({
    queryKey: walletKeys.detail({ organizationId, walletId }),
    queryFn: () => getWalletData(walletId || ''),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    placeholderData: keepPreviousData,
    enabled: enabled && !!walletId && !!organizationId,
  });
};
