import { useState } from 'react';
import { Typography } from '..';
import { TextAreaProps } from './TextArea.interface';
import './TextArea.styles.scss';

export const TextArea = ({
  name,
  placeholder,
  label,
  register,
  validationSchema,
  classAttr,
  error = false,
  children,
  maxLength,
  showCounter = false,
  ...props
}: TextAreaProps) => {
  const [count, setCount] = useState(0);
  return (
    <div className="textarea_container">
      <div className="textarea_container_text">
        <Typography color="var(--product-neutral-n100)" tag="p" weight="p3">
          {label}
        </Typography>
      </div>
      <textarea
        id={name}
        data-testid={name}
        className={`textarea-input ${classAttr} textarea--${
          error ? 'invalid' : 'valid'
        } `}
        placeholder={placeholder}
        onPaste={(event) => {
          maxLength && setCount(event.currentTarget.textLength);
        }}
        onKeyUp={(event) => {
          maxLength && setCount(event.currentTarget.textLength);
        }}
        maxLength={maxLength}
        {...register(name, validationSchema)}
        {...props}
      />
      <div
        className={`textarea_container${children ? '_children' : ''}_counter`}
      >
        {children}
        {maxLength && showCounter && (
          <Typography color="var(--product-neutral-n100)" tag="p" weight="p3">
            {`${count}/${maxLength}`}
          </Typography>
        )}
      </div>
    </div>
  );
};
