import { Authentication, AuthenticationProps } from '../Authentication';

interface InDrawerAuthenticationProps extends AuthenticationProps {
  isOpen: boolean;
}

export const InDrawerAuthentication = (props: InDrawerAuthenticationProps) => (
  <>
    {props.isOpen && (
      <Authentication
        pin={props.pin}
        error={props.error}
        pinErrorsCount={props.pinErrorsCount}
        setPin={props.setPin}
        isPending={props.isPending}
        onClose={props.onClose}
      />
    )}
  </>
);
