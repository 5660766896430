export enum TransactionScheduleType {
  BANK_SLIP_PAYMENT = 'BANK_SLIP_PAYMENT',
  PIX_PAYMENT = 'PIX_PAYMENT',
  P2P_TRANSFER = 'P2P_TRANSFER',
  PIX_TRANSFER = 'PIX_TRANSFER',
}

export enum TransactionScheduleStatus {
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  DONE = 'DONE',
}
