import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  ContextualError,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { ErrorCodes } from '@enums/ErrorCodes.enum';
import {
  CustomFieldPostParams,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';
import { ErrorRequest } from '@interfaces/ErrorRequest.interfaces';
import {
  Button,
  ErrorMessage,
  Input,
  Sidebar,
  Spinner,
  Toastr,
  Toggle,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { createCustomField, updateCustomField } from '@services/customFields';
import {
  DrawerEditCustomFieldFormProps,
  DrawerEditCustomFieldProps,
} from './DrawerEditCustomField.interfaces';
import './DrawerEditCustomField.styles.scss';
import { CheckboxCard } from './components/checkboxCard/CheckboxCard';
import { CustomFieldTypeSelection } from './components/customFieldTypeSelection/CustomFieldTypeSelection';
import { CUSTOM_FIELD_LOCATION_CONFIG } from './constants';
import { DrawerEditCustomFieldContext } from './contexts/DrawerEditCustomFieldContext';

export const DrawerEditCustomField = ({
  field,
  hasCustomFieldsUpdates,
}: DrawerEditCustomFieldProps) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { closeDrawer } = useDrawer();

  const [hasModal, setHasModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message: '',
    traceId: '',
  });

  const form = useForm<DrawerEditCustomFieldFormProps>({
    defaultValues: {
      label: field?.label ?? '',
      required: field?.required ?? false,
      externalIdentifier: field?.externalIdentifier ?? '',
      identifier: field?.identifier ?? '',
      location: field?.location ?? [],
      type: field?.type ?? null,
      mask: field?.type === CustomFieldType.NUMBER ? field?.mask : undefined,
      description:
        field?.type === CustomFieldType.BOOLEAN
          ? field?.description
          : undefined,
      values:
        field?.type === CustomFieldType.SELECT ||
        field?.type === CustomFieldType.MULTI_SELECT
          ? field?.values
          : undefined,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = form;

  const isRequired = watch('required');
  const values = watch('values');
  const type = watch('type');

  useEffect(() => {
    if (values?.length > 1) {
      trigger('values');
    }
  }, [values, trigger]);

  useEffect(() => {
    setHasModal(isDirty);
  }, [isDirty]);

  const {
    mutateAsync: createField,
    isPending: isPendingCreateField,
    error: errorCreateField,
  } = useMutation({
    mutationFn: (data: CustomFieldPostParams) => createCustomField(data),
    onSuccess: () => {
      Toastr.success('Campo customizável criado com sucesso!');
      queryClient.refetchQueries({ queryKey: ['custom-fields'] });
      closeDrawer();
    },
    onError: (error) => {
      const errorService = error as AxiosError;
      const errorInfo = errorService?.response?.data as ErrorRequest;

      if (errorInfo?.code === ErrorCodes.RESOURCE_CONFLICT) {
        setErrorMessage({
          message: 'Já existe um campo customizável com esse nome!',
          traceId: errorInfo?.traceId,
        });
      } else {
        setErrorMessage({
          message: t(`error.CODE_ERROR.${errorInfo?.code}`, t('error.default')),
          traceId: errorInfo?.traceId,
        });
      }
    },
  });

  const {
    mutateAsync: updateField,
    isPending: isPendingUpdateField,
    error: errorUpdateField,
  } = useMutation({
    mutationFn: (data: CustomFieldPostParams) =>
      updateCustomField(field?.id ?? '', data),
    onSuccess: () => {
      Toastr.success('Campo customizável modificado com sucesso!');
      queryClient.refetchQueries({ queryKey: ['custom-fields'] });
      closeDrawer();
    },
    onError: (error) => {
      const errorService = error as AxiosError;
      const errorInfo = errorService?.response?.data as ErrorRequest;

      setErrorMessage({
        message: t('error.default'),
        traceId: errorInfo?.traceId,
      });
    },
  });

  const onSubmit = async (data: DrawerEditCustomFieldFormProps) => {
    hasCustomFieldsUpdates(false);
    const payload = {
      ...data,
      externalIdentifier:
        data.externalIdentifier === ''
          ? data.identifier
          : data.externalIdentifier,
    } as CustomFieldPostParams;
    Object.entries(payload).forEach(([key, value]) => {
      if (value === undefined) {
        delete payload[key as keyof typeof payload];
      }
    });

    field ? await updateField(payload) : await createField(payload);
    hasCustomFieldsUpdates(true);
  };

  const handleToggleRequired = (isSelected: boolean) => {
    setValue('required', isSelected);
  };

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <Sidebar
      title={`${field ? 'Editar' : 'Novo'} campo customizável`}
      icon="fa-regular fa-pen-field"
      open={true}
      onClose={handleClose}
      hasModal={hasModal}
      actionModal={actionModal}
      setActionModal={setActionModal}
    >
      <DrawerEditCustomFieldContext.Provider value={{ ...form }}>
        {(errorCreateField || errorUpdateField) && (
          <ContextualError error={errorMessage} />
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="drawer-custom-fields"
        >
          <SidebarSection
            titleSection="Descrição do campo"
            subTitleSection="Defina como deseja identificar o campo internamente."
            numberSection={1}
          >
            <Input
              label="Identificação do campo"
              name="identifier"
              register={register}
              placeholder="Ex: Departamento"
              validationSchema={{
                required: 'Identificação do campo é obrigatório',
              }}
              error={!!errors.identifier?.message}
              disabled={type === CustomFieldType.CUSTOMER}
            >
              {errors.identifier?.message && (
                <ErrorMessage message={errors.identifier.message.toString()} />
              )}
            </Input>
            <Toggle
              label="Campo obrigatório?"
              isSelected={isRequired}
              onChange={handleToggleRequired}
            />
          </SidebarSection>
          <SidebarSection
            titleSection="Onde o campo será exibido?"
            subTitleSection="Selecione qual funcionalidade o campo deverá existir."
            numberSection={2}
          >
            {errors.location?.message && (
              <Alert status="error">
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n500)"
                >
                  {errors.location.message}
                </Typography>
              </Alert>
            )}
            <div className="drawer-custom-fields_location">
              {CUSTOM_FIELD_LOCATION_CONFIG.map(
                ({ location, label, description }) => (
                  <CheckboxCard
                    label={label}
                    description={description}
                    location={location}
                    key={location}
                    disabled={type === CustomFieldType.CUSTOMER}
                  />
                )
              )}
            </div>
          </SidebarSection>
          <SidebarSection
            titleSection="Configurações do campo"
            subTitleSection="Escolha o tipo do campo e faça customizações."
            numberSection={3}
          >
            <CustomFieldTypeSelection />
          </SidebarSection>
          <SidebarFooter>
            <Button
              type="button"
              size="large"
              variant="tertiary"
              className="cancel-button"
              onClick={() => {
                setActionModal((prev) => !prev);
              }}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              size="large"
              variant="primary"
              className="save-button"
              disabled={isPendingCreateField || isPendingUpdateField}
            >
              {isPendingCreateField || isPendingUpdateField ? (
                <Spinner />
              ) : (
                'Concluir'
              )}
            </Button>
          </SidebarFooter>
        </form>
      </DrawerEditCustomFieldContext.Provider>
    </Sidebar>
  );
};
