import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import { DateRange, DayPicker } from 'react-day-picker';

import { shadButtonVariants } from '../shadButton';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { cn } from '@portao3-ui/lib/utils';
import { ptBR } from 'date-fns/locale';
import { Button } from '../../button/Button';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  onResetOrClose?: () => void;
  onSetDate?: () => void;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  onResetOrClose,
  onSetDate,
  ...props
}: CalendarProps) {
  const selectedValue = props.selected as DateRange;

  const getSecondaryButtonName = () => {
    return selectedValue ? 'Limpar' : 'Fechar';
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      lang="pt-BR"
      locale={ptBR}
      className={cn('mt-2 mr-4 rounded-lg bg-white p-3 shadow-md', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'capitalize text-p2 text-neutral-300',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          shadButtonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md capitalize w-9 text-p3',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 text-center text-neutral-100 text-p3 p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          shadButtonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 aria-selected:opacity-100 text-p3'
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => (
          <div data-testid="date-picker-previous">
            <ChevronLeft className="text-neutral-500 h-4 w-4" />
          </div>
        ),
        IconRight: () => (
          <div data-testid="date-picker-next">
            <ChevronRight className="text-neutral-500 h-4 w-4" />
          </div>
        ),
        Footer: () => (
          <tbody>
            <tr>
              <td>
                <div
                  id="action-button"
                  className="flex items-center justify-end gap-2 pt-2"
                >
                  <Button
                    onClick={onResetOrClose}
                    variant="secondary"
                    size={'small'}
                  >
                    {getSecondaryButtonName()}
                  </Button>
                  <Button
                    disabled={
                      !selectedValue ||
                      (props.mode === 'range' && !selectedValue.to)
                    }
                    onClick={onSetDate}
                    size={'small'}
                    data-testid="date-picker-apply"
                  >
                    Aplicar
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar as ShadCalendar };
