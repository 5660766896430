import { GetStatementReportParams } from '../reportService';

export const organizationKeys = {
  all: () => ['organization'],
  lists: () => [...organizationKeys.all(), 'list'],
  list: () => [...organizationKeys.lists()],
  reports: () => [...organizationKeys.all(), 'reports'],
  report: (params: GetStatementReportParams) => [
    ...organizationKeys.reports(),
    params,
  ],
};
