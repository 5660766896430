import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarFooter, SidebarSection } from '@components';
import { ICONS } from '@constants/icons';
import { Deal } from '@interfaces/BillingDeal.interfaces';
import { Button, Sidebar, TableDemo, Tag } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { moneyMask } from '@utils/formatMasks';
import { ValueWrapper } from '../drawerFundsIn/components/ValueWrapper';
import { useBillingDealColumns } from './useBillingDealColumns';

import { DealProductType } from '@enums/BillingDeal.enum';

dayjs.extend(utc);

interface DrawerBillingDealProps {
  deal: Deal;
}

export const DrawerBillingDeal = ({ deal }: DrawerBillingDealProps) => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();
  const columns = useBillingDealColumns();

  const getDayOfMonth = (date: string) => {
    const springed = date.match(/\d+/g);
    const dayOfMonth =
      springed?.length && springed?.length >= 3 ? springed[2] : 'x';

    return dayOfMonth;
  };

  const invoicePrice = useMemo(() => {
    const oneTime = deal.products
      .filter((product) => product.type === DealProductType.ONE_TIME)
      .reduce((acc, product) => acc + product.price, 0);

    const recurrent = deal.products
      .filter((product) => product.type === DealProductType.RECURRENT)
      .reduce((acc, product) => acc + product.price, 0);

    return {
      firstInvoice: oneTime + recurrent,
      recurrent,
    };
  }, [deal]);

  return (
    <Sidebar
      open
      title={t('invoices.deal-details')}
      icon={ICONS['file-lines']}
      onClose={closeDrawer}
    >
      <SidebarSection titleSection={t('invoices.info-billing-deal')}>
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-6">
            <ValueWrapper
              label={t('general.description')}
              value={deal.description}
            />
            <div />

            <ValueWrapper
              label={t('invoices.start-deal')}
              value={dayjs(deal.startAt).utc(false).format('DD/MM/YYYY')}
            />
            <ValueWrapper
              label={t('invoices.end-deal')}
              value={
                deal.endAt
                  ? dayjs(deal.endAt).utc(false).format('DD/MM/YYYY')
                  : t('invoices.dealProductType.RECURRENT')
              }
            />

            <ValueWrapper
              label={t('general.closing-date')}
              value={t('invoices.day-x-of-month', {
                day: getDayOfMonth(deal.closingAt),
              })}
            />

            <ValueWrapper
              label={t('general.date-due-date')}
              value={t('invoices.day-x-of-month', {
                day: getDayOfMonth(deal.dueAt),
              })}
            />
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-p3 text-neutral-80">
              {t('general.payment-methods')}
            </p>

            <div className="flex gap-1">
              {deal.paymentMethods.map((method, index) => (
                <Tag
                  key={index}
                  text={t(`invoices.paymentMethod.${method}`)}
                  className="text-primary-600"
                  showCloseButton={false}
                />
              ))}
            </div>
          </div>

          <TableDemo
            headerColor
            pinLastColumn
            columns={columns}
            data={deal.products}
          />

          <div className="flex w-full gap-3">
            <div className="flex w-full flex-col gap-3">
              <h2 className="text-p1 text-neutral-500">
                {t('invoices.summary-deal')}
              </h2>

              <div className="grid w-full grid-cols-2 gap-3">
                <div className="bg-neutral-20 flex w-full flex-col gap-3 rounded-sm px-6 py-4">
                  <p className="text-p2 text-neutral-500">
                    {t('invoices.first-invoice')}
                  </p>

                  <span className="border-neutral-40 w-full border-b border-solid" />

                  <div className="flex justify-between">
                    <p className="text-p1 font-semibold text-neutral-500">
                      {t('general.total')}
                    </p>

                    <p className="text-p1 font-semibold text-neutral-500">
                      {moneyMask(invoicePrice.firstInvoice)}
                    </p>
                  </div>
                </div>

                <div className="bg-neutral-20 flex w-full flex-col gap-3 rounded-sm px-6 py-4">
                  <p className="text-p2 text-neutral-500">
                    {t('general.other-invoices')}
                  </p>

                  <span className="border-neutral-40 w-full border-b border-solid" />

                  <div className="flex justify-between">
                    <p className="text-p1 font-semibold text-neutral-500">
                      {t('general.total')}
                    </p>

                    <p className="text-p1 font-semibold text-neutral-500">
                      {moneyMask(invoicePrice.recurrent)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarSection>

      <SidebarFooter>
        <Button onClick={closeDrawer} type="button" size="large">
          {t('general.button.close')}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};
