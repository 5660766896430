import { useMutation } from '@tanstack/react-query';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { getMFA } from '../getMFA';

export const useGetMFAMutation = () => {
  return useMutation<string, ApiError, undefined, unknown>({
    mutationFn: () => getMFA(),
  });
};
