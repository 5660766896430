import { useState } from 'react';
import { SlideShowProps } from './SlideShow.interface';

import './SlideShow.styles.scss';

export const SlideShow = ({ children, getPositionSlide }: SlideShowProps) => {
  const childArray = Array.isArray(children) ? children : [children];
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = (index: number) => {
    getPositionSlide && getPositionSlide(index);
    setCurrentSlide(index);
  };

  return (
    <div className="slideshow">
      <div className="slideshow_container">
        {childArray.map((child, index) => (
          <div
            key={index}
            className={`slideshow_slide ${
              index === currentSlide ? 'slideshow_slide--active' : ''
            }`}
          >
            {child}
          </div>
        ))}
      </div>
      <div className="slideshow_controls">
        <div className="slideshow_bullets">
          {childArray.map((_, index) => (
            <span
              key={index}
              className={`slideshow_bullets_bullet ${
                index === currentSlide ? 'slideshow_bullets_bullet--active' : ''
              }`}
              data-testid={`bullet-${index}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
