import { ColumnProps } from './Column.interface';
import './Column.styles.scss';

export const Column = ({
  children,
  lg = 12,
  md = 12,
  sm = 12,
  className = '',
}: ColumnProps) => {
  return (
    <div
      className={`p3-col p3-col-sm-${sm} p3-col-md-${md} p3-col-lg-${lg} ${className}`}
    >
      {children}
    </div>
  );
};
