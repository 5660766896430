import { Transaction } from '@interfaces/Transactions.interfaces';
import { cnpjMask } from './formatMasks';

export const pixConfirmed = (name?: string) => {
  return `PIX realizado${name && name !== '' ? ` p/ ${name}` : ''}`;
};

export const pixReceived = (name?: string) => {
  return `PIX recebido${name && name !== '' ? ` de ${name}` : ''}`;
};

export const pixTransaction = (item: Transaction) => {
  return item.financialImpactType === 'DEBIT' ||
    item.processingType === 'DELAYED'
    ? pixConfirmed(item.pixTransaction?.creditParty?.name)
    : pixReceived(item.pixTransaction?.debitParty?.name);
};

export const bankSlipPayment = (item: Transaction) => {
  const payeeName =
    item.boletoTransaction?.payee || item.boletoTransaction?.issuer;

  return item.financialImpactType === 'DEBIT' ||
    item.processingType === 'DELAYED'
    ? `Pgto boleto p/ ${payeeName}`
    : `Pgto recebido de ${item.boletoTransaction?.payer}`;
};

export const billingPayment = (item: Transaction) => {
  return item.financialImpactType === 'DEBIT' ||
    item.processingType === 'DELAYED'
    ? `Pagamento realizado da ${item.billingTransaction?.creditParty?.name}`
    : `Fatura recebida de ${
        item.billingTransaction?.debitParty.name
      } - ${cnpjMask(item.billingTransaction?.debitParty.document || '')}`;
};
