import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { PeriodDetailsResponse } from '@interfaces/Chart.interface';
import api from '@services/api';

export const getChart = async (
  startDate: string,
  endDate: string
): Promise<PeriodDetailsResponse> => {
  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.wallets
    }/default/aggregated-transactions?startDate=${startDate}&endDate=${endDate}`
  );

  const { data } = response;

  return data;
};
