import { useMutation } from '@tanstack/react-query';

import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { ApiErrorPin } from '@services/auth/auth.interfaces';
import {
  ScheduleTransferWalletP2PRequest,
  scheduleWalletTransferP2P,
} from '../scheduleWalletTransferP2P';

export const useScheduleWalletTransferP2P = () => {
  return useMutation<
    CreateTransactionSchedule,
    ApiErrorPin,
    ScheduleTransferWalletP2PRequest,
    unknown
  >({
    mutationFn: (payload) => scheduleWalletTransferP2P(payload),
  });
};
