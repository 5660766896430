import { TypographyProps } from './Typography.interface';
import './Typography.styles.scss';

export const Typography = ({
  tag,
  children,
  weight = 'p2',
  color,
  className = '',
  title,
  dataTestId,
}: TypographyProps) => {
  const TagComponent = tag as keyof JSX.IntrinsicElements;

  return (
    <TagComponent
      data-testid={dataTestId}
      className={`typography ${tag} weight-${weight} ${className}`}
      style={{ color: color }}
      title={title}
    >
      {children}
    </TagComponent>
  );
};
