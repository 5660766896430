import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { AxiosResponse } from 'axios';

export const getCustomersReport = async (): Promise<AxiosResponse<string>> => {
  return await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/customers/report`
  );
};
