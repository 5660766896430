import {
  CustomField,
  CustomFieldLocation,
  CustomFieldStatus,
  CustomFieldType,
} from '@interfaces/CustomFields.interfaces';

export const thead = ['Nome', 'Local', 'Tipo', 'Obrigatoriedade', 'Ações'];

export const initialState = [
  {
    id: '',
    realm: '',
    organization: '',
    version: 123,
    status: CustomFieldStatus.ACTIVE,
    label: '',
    required: true,
    identifier: '',
    externalIdentifier: '',
    location: [CustomFieldLocation.TRANSACTION],
    versions: [],
    type: CustomFieldType.TEXT,
    mask: 0,
    values: [],
  },
  {
    id: '',
    realm: '',
    organization: '',
    version: 123,
    status: CustomFieldStatus.ACTIVE,
    label: '',
    required: true,
    identifier: '',
    externalIdentifier: '',
    location: [CustomFieldLocation.TRANSACTION],
    versions: [],
    type: CustomFieldType.TEXT,
    mask: 0,
    values: [],
  },
  {
    id: '',
    realm: '',
    organization: '',
    version: 123,
    status: CustomFieldStatus.ACTIVE,
    label: '',
    required: true,
    identifier: '',
    externalIdentifier: '',
    location: [CustomFieldLocation.TRANSACTION],
    versions: [],
    type: CustomFieldType.TEXT,
  },
  {
    id: '',
    realm: '',
    organization: '',
    version: 123,
    status: CustomFieldStatus.ACTIVE,
    label: '',
    required: true,
    identifier: '',
    externalIdentifier: '',
    location: [CustomFieldLocation.TRANSACTION],
    versions: [],
    type: CustomFieldType.TEXT,
    mask: 0,
    values: [],
  },
  {
    id: '',
    realm: '',
    organization: '',
    version: 123,
    status: CustomFieldStatus.ACTIVE,
    label: '',
    required: true,
    identifier: '',
    externalIdentifier: '',
    location: [CustomFieldLocation.TRANSACTION],
    versions: [],
    type: CustomFieldType.TEXT,
    mask: 0,
    values: [],
  },
] as CustomField[];

export const textDeleteCustomField = {
  disclaimer: `Caso você opte por prosseguir, todas as configurações e visualizações de dados não estarão mais disponíveis.`,
  warn: {
    title: 'Atenção, esta será uma ação permanente!',
    description:
      'Para excluir este campo, basta confirmar sua decisão no botão abaixo.',
  },
  confirmation: 'Deseja mesmo continuar?',
  actionButtonLabel: 'Sim, excluir',
};

export const initialStateModal = {
  open: false,
  selectedItem: {} as CustomField,
};
