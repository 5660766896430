import { useEffect, useState } from 'react';
import { Button, Modal, Typography } from '..';
import { confirmationModal } from '../../constants/modal';
import { TabProps } from './Tabs.interface';
import './Tabs.styles.scss';

export const Tabs = ({
  tabs,
  start,
  modalText = confirmationModal,
  hasModal,
  setActionModal,
  actionModal,
  onTabChange,
  className = '',
}: TabProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [tabId, setTabId] = useState('');

  const [activeTab, setActiveTab] = useState(() => {
    let i = start || 0;
    for (i + 1; i < tabs.length; i++) {
      if (!tabs[i].disabled) {
        return tabs[i].id;
      }
    }
  });

  const handleTabClick = (tabId: string) => {
    setTabId(tabId);
    if (hasModal) {
      setOpenModal(true);
    } else {
      onTabChange?.(tabId);
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    actionModal && setOpenModal(false);
  }, [actionModal, hasModal]);

  const Header = (): JSX.Element => {
    return (
      <div className="tab_header">
        {tabs.map((tab) => {
          const showContent = tab.id === activeTab ? 'active' : '';
          const isDisabled = tab.disabled ? 'disabled' : '';

          return (
            <div
              data-testid={tab.id}
              key={tab.id}
              className={`tab_item ${showContent} ${isDisabled}`}
              onClick={() => handleTabClick(tab.id)}
            >
              {typeof tab.label === 'string' ? (
                <Typography tag="p" weight="p2">
                  {tab.label}
                </Typography>
              ) : (
                tab.label
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {hasModal ? (
        <Modal
          title={modalText?.titleModal}
          open={openModal}
          closeModal={setOpenModal}
        >
          <div style={{ width: 500, padding: '32px' }}>
            <Typography tag="p" weight="p1" color="#A5A5B8">
              {modalText?.textModal}
            </Typography>
            <br />
            <Typography tag="p" weight="p1" color="var(--product-neutral-n80)">
              {modalText?.confirmModal}
            </Typography>
          </div>

          <footer className="footer_modal">
            <div>
              <Button
                size="large"
                variant="tertiary"
                onClick={() => {
                  setActionModal && setActionModal(false);
                  setOpenModal(false);
                }}
              >
                Não
              </Button>
            </div>
            <div style={{ marginLeft: 10 }}>
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  setOpenModal(false);
                  setActiveTab(tabId);
                }}
              >
                {modalText?.actionConfirm}
              </Button>
            </div>
          </footer>
        </Modal>
      ) : (
        ''
      )}
      <div className={`tab ${className}`}>
        <Header />

        <div className="tab_content">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              data-testid={`tab-content-${tab.id}`}
              className={`tab_content_item ${tab.id === activeTab && 'active'}`}
            >
              {tab.id === activeTab ? tab.content : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
