import { Typography } from '..';
import { StepNumberProps } from './StepNumber.interface';
import './StepNumber.styles.scss';

export const StepNumber = ({ number }: StepNumberProps) => {
  return (
    <div className="stepnumber">
      <Typography tag="p" weight="p2">
        {number}
      </Typography>
    </div>
  );
};
