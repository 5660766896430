export const bankCodes = {
  '001': 'Banco do Brasil S.A.',
  '003': 'Banco da Amazônia S.A.',
  '004': 'Banco do Nordeste do Brasil S.A.',
  '007': 'Banco Nacional de Desenvolvimento Econômico e Social BNDES',
  '010': 'Credicoamo Crédito Rural Cooperativa',
  '011': 'Credit Suisse Hedging-Griffo Corretora de Valores S.A.',
  '012': 'Banco Inbursa S.A.',
  '014': 'Natixis Brasil S.A. Banco Múltiplo',
  '015': 'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  '016':
    'Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul',
  '017': 'BNY Mellon Banco S.A.',
  '018': 'Banco Tricury S.A.',
  '021': 'Banestes S.A. Banco do Estado do Espírito Santo',
  '024': 'Banco Bandepe S.A.',
  '025': 'Banco Alfa S.A.',
  '029': 'Banco Itaú Consignado S.A.',
  '033': 'Banco Santander (Brasil) S. A.',
  '036': 'Banco Bradesco BBI S.A.',
  '037': 'Banco do Estado do Pará S.A.',
  '040': 'Banco Cargill S.A.',
  '041': 'Banco do Estado do Rio Grande do Sul S.A.',
  '047': 'Banco do Estado de Sergipe S.A.',
  '060': 'Confidence Corretora de Câmbio S.A.',
  '062': 'Hipercard Banco Múltiplo S.A.',
  '063': 'Banco Bradescard S.A.',
  '064': 'Goldman Sachs do Brasil  Banco Múltiplo S. A.',
  '065': 'Banco AndBank (Brasil) S.A.',
  '066': 'Banco Morgan Stanley S. A.',
  '069': 'Banco Crefisa S.A.',
  '070': 'Banco de Brasília S.A.',
  '074': 'Banco J. Safra S.A.',
  '075': 'Banco ABN Amro S.A.',
  '076': 'Banco KDB do Brasil S.A.',
  '077': 'Banco Inter S.A.',
  '078': 'Haitong Banco de Investimento do Brasil S.A.',
  '079': 'Banco Original do Agronegócio S.A.',
  '080': 'BT Corretora de Câmbio Ltda.',
  '081': 'BBN Banco Brasileiro de Negocios S.A.',
  '082': 'Banco Topazio S.A.',
  '083': 'Banco da China Brasil S.A.',
  '084': 'Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.',
  '085': 'Cooperativa Central de Crédito Urbano - Cecred',
  '089': 'Cooperativa de Crédito Rural da Região da Mogiana',
  '091':
    'Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred',
  '092': 'BRK S.A. Crédito, Financiamento e Investimento',
  '093':
    'Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte',
  '094': 'Banco Finaxis S.A.',
  '095': 'Banco Confidence de Câmbio S.A.',
  '096': 'Banco BMFBovespa de Serviços de Liquidação e Custódia S/A',
  '097':
    'Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi',
  '098': 'Credialiança Cooperativa de Crédito Rural',
  '099':
    'Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.',
  '100': 'Planner Corretora de Valores S.A.',
  '101': 'Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '102':
    'XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A.',
  '104': 'Caixa Econômica Federal',
  '105': 'Lecca Crédito, Financiamento e Investimento S/A',
  '107': 'Banco Bocom BBM S.A.',
  '108': 'PortoCred S.A. Crédito, Financiamento e Investimento',
  '111': 'Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  '113': 'Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
  '114': 'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
  '117': 'Advanced Corretora de Câmbio Ltda.',
  '118': 'Standard Chartered Bank (Brasil) S.A. Banco de Investimento',
  '119': 'Banco Western Union do Brasil S.A.',
  '120': 'Banco Rodobens SA',
  '121': 'Banco Agibank S.A.',
  '122': 'Banco Bradesco BERJ S.A.',
  '124': 'Banco Woori Bank do Brasil S.A.',
  '125': 'Brasil Plural S.A. Banco Múltiplo',
  '126': 'BR Partners Banco de Investimento S.A.',
  '127': 'Codepe Corretora de Valores e Câmbio S.A.',
  '128': 'MS Bank S.A. Banco de Câmbio',
  '129': 'UBS Brasil Banco de Investimento S.A.',
  '130': 'Caruana S.A. Sociedade de Crédito, Financiamento e Investimento',
  '131': 'Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.',
  '132': 'ICBC do Brasil Banco Múltiplo S.A.',
  '133':
    'Confederação Nacional das Cooperativas Centrais de Crédito e Economia Familiar e',
  '134': 'BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '135': 'Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  '136':
    'Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil',
  '137': 'Multimoney Corretora de Câmbio Ltda',
  '138': 'Get Money Corretora de Câmbio S.A.',
  '139': 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
  '140': 'Easynvest - Título Corretora de Valores SA',
  '142': 'Broker Brasil Corretora de Câmbio Ltda.',
  '143': 'Treviso Corretora de Câmbio S.A.',
  '144': 'Bexs Banco de Câmbio S.A.',
  '145': 'Levycam - Corretora de Câmbio e Valores Ltda.',
  '146': 'Guitta Corretora de Câmbio Ltda.',
  '149': 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
  '157': 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
  '159': 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
  '163': 'Commerzbank Brasil S.A. - Banco Múltiplo',
  '169': 'Banco Olé Bonsucesso Consignado S.A.',
  '172': 'Albatross Corretora de Câmbio e Valores S.A',
  '173': 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  '174':
    'Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento',
  '177': 'Guide Investimentos S.A. Corretora de Valores',
  '180':
    'CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
  '182':
    'Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento',
  '183': 'Socred S.A. - Sociedade de Crédito ao Microempreendedor',
  '184': 'Banco Itaú BBA S.A.',
  '188': 'Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores',
  '189': 'HS Financeira S/A Crédito, Financiamento e Investimentos',
  '190':
    'Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio',
  '191': 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
  '194': 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '196': 'Fair Corretora de Câmbio S.A.',
  '197': 'Stone Pagamentos S.A.',
  '204': 'Banco Bradesco Cartões S.A.',
  '208': 'Banco BTG Pactual S.A.',
  '212': 'Banco Original S.A.',
  '213': 'Banco Arbi S.A.',
  '217': 'Banco John Deere S.A.',
  '218': 'Banco BS2 S.A.',
  '222': 'Banco Credit Agrícole Brasil S.A.',
  '224': 'Banco Fibra S.A.',
  '233': 'Banco Cifra S.A.',
  '237': 'Banco Bradesco S.A.',
  '241': 'Banco Clássico S.A.',
  '243': 'Banco Máxima S.A.',
  '246': 'Banco ABC Brasil S.A.',
  '249': 'Banco Investcred Unibanco S.A.',
  '250': 'BCV - Banco de Crédito e Varejo S/A',
  '253': 'Bexs Corretora de Câmbio S/A',
  '254': 'Parana Banco S. A.',
  '260': 'Nu Pagamentos S.A.',
  '265': 'Banco Fator S.A.',
  '266': 'Banco Cédula S.A.',
  '268': 'Barigui Companhia Hipotecária',
  '269': 'HSBC Brasil S.A. Banco de Investimento',
  '271': 'IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
  '300': 'Banco de la Nacion Argentina',
  '318': 'Banco BMG S.A.',
  '320': 'China Construction Bank (Brasil) Banco Múltiplo S/A',
  '341': 'Itaú Unibanco  S.A.',
  '366': 'Banco Société Générale Brasil S.A.',
  '370': 'Banco Mizuho do Brasil S.A.',
  '376': 'Banco J. P. Morgan S. A.',
  '389': 'Banco Mercantil do Brasil S.A.',
  '394': 'Banco Bradesco Financiamentos S.A.',
  '399': 'Kirton Bank S.A. - Banco Múltiplo',
  '412': 'Banco Capital S. A.',
  '422': 'Banco Safra S.A.',
  '456': 'Banco MUFG Brasil S.A.',
  '464': 'Banco Sumitomo Mitsui Brasileiro S.A.',
  '473': 'Banco Caixa Geral - Brasil S.A.',
  '477': 'Citibank N.A.',
  '479': 'Banco ItauBank S.A.',
  '487': 'Deutsche Bank S.A. - Banco Alemão',
  '488': 'JPMorgan Chase Bank, National Association',
  '492': 'ING Bank N.V.',
  '494': 'Banco de La Republica Oriental del Uruguay',
  '495': 'Banco de La Provincia de Buenos Aires',
  '505': 'Banco Credit Suisse (Brasil) S.A.',
  '545': 'Senso Corretora de Câmbio e Valores Mobiliários S.A.',
  '600': 'Banco Luso Brasileiro S.A.',
  '604': 'Banco Industrial do Brasil S.A.',
  '610': 'Banco VR S.A.',
  '611': 'Banco Paulista S.A.',
  '612': 'Banco Guanabara S.A.',
  '613': 'Omni Banco S.A.',
  '623': 'Banco Pan S.A.',
  '626': 'Banco Ficsa S. A.',
  '630': 'Banco Intercap S.A.',
  '633': 'Banco Rendimento S.A.',
  '634': 'Banco Triângulo S.A.',
  '637': 'Banco Sofisa S. A.',
  '641': 'Banco Alvorada S.A.',
  '643': 'Banco Pine S.A.',
  '652': 'Itaú Unibanco Holding S.A.',
  '653': 'Banco Indusval S. A.',
  '654': 'Banco A. J. Renner S.A.',
  '655': 'Banco Votorantim S.A.',
  '707': 'Banco Daycoval S.A.',
  '712': 'Banco Ourinvest S.A.',
  '719': 'Banif - Bco Internacional do Funchal (Brasil) S.A.',
  '735': 'Banco Neon S.A.',
  '739': 'Banco Cetelem S.A.',
  '741': 'Banco Ribeirão Preto S.A.',
  '743': 'Banco Semear S.A.',
  '745': 'Banco Citibank S.A.',
  '746': 'Banco Modal S.A.',
  '747': 'Banco Rabobank International Brasil S.A.',
  '748': 'Banco Cooperativo Sicredi S. A.',
  '751': 'Scotiabank Brasil S.A. Banco Múltiplo',
  '752': 'Banco BNP Paribas Brasil S.A.',
  '753': 'Novo Banco Continental S.A. - Banco Múltiplo',
  '754': 'Banco Sistema S.A.',
  '755': 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
  '756': 'Banco Cooperativo do Brasil S/A - Bancoob',
  '757': 'Banco Keb Hana do Brasil S. A.',
};
