import { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { DrawerEditCustomFieldFormProps } from '../DrawerEditCustomField.interfaces';

export const DrawerEditCustomFieldContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  UseFormReturn<DrawerEditCustomFieldFormProps, any, undefined> | undefined
>(undefined);

export const useDrawerEditCustomField = () => {
  const context = useContext(DrawerEditCustomFieldContext);

  if (context === undefined) {
    throw new Error(
      'useDrawerEditCustomField must be used within a DrawerEditCustomFieldProvider'
    );
  }

  return context;
};
