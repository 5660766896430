import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BankSlip } from '@interfaces/BankSlip.interface';
import { ValueWrapper } from '@modules/drawer/drawerFundsIn/components/ValueWrapper';
import { Typography } from '@portao3-web/ui';
import { useUser } from '@providers';
import {
  documentMask,
  documentMaskHashed,
  moneyMask,
} from '@utils/formatMasks';
import { Alert } from '../../../alerts';
import { SidebarSection } from '../../../sidebarSection';
import { PaymentVoucher } from '../../PaymentVoucher';

interface BankSlipPaymentScheduleVoucherProps {
  data: BankSlip;
  onClose: () => void;
}

export const BankSlipPaymentVoucher = ({
  data,
  onClose,
}: BankSlipPaymentScheduleVoucherProps) => {
  const user = useUser();
  const organizationId = localStorage.getItem('organization');

  const currentOrg = user.organizations.find(
    (org) => org.id === organizationId
  );

  const { t } = useTranslation();

  return (
    <PaymentVoucher
      onClose={onClose}
      title={`recibo-boleto-${dayjs().format('DD/MM/YYYY')}.pdf`}
      className="flex flex-col gap-2"
    >
      <Alert status="success">
        <Typography tag="p" weight="p2" color="var(--product-success-s700)">
          {t('payment-voucher.bank-slip-success')}
        </Typography>
        <Typography tag="p" weight="p2" color="var(--product-neutral-n100)">
          {t('payment-voucher.bank-slip-success-message', {
            value: moneyMask(data?.confirmedAmount),
            destination: data?.payee,
            date: dayjs(data?.createdAt).utc(true).format('DD/MM/YYYY'),
            time: dayjs(data?.createdAt).utc(true).format('HH:mm'),
          })}
        </Typography>
      </Alert>

      <div className="grid grid-cols-2 gap-4">
        <ValueWrapper
          label={t('general.date')}
          value={dayjs().utc(false).format('DD/MM/YYYY')}
        />
        <ValueWrapper
          label={t('payment-voucher.transfer-type')}
          value={t('general.bank-slip')}
        />
      </div>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('general.payment-details')}
        subTitleSection={t('payment-voucher.see-bank-slip-data')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper
            label={'Total pago'}
            value={moneyMask(data?.confirmedAmount)}
          />

          {data.dueDate && (
            <>
              <ValueWrapper
                label={t('payment-voucher.due-date')}
                value={
                  data?.dueDate &&
                  dayjs(data?.dueDate).utc(false).format('DD/MM/YYYY')
                }
              />

              <ValueWrapper
                label={t('general.original-amount')}
                value={moneyMask(data?.txnOriginalAmount)}
              />

              <ValueWrapper
                label={t('general.discount')}
                value={moneyMask(data?.txnDiscountAmount)}
              />

              <ValueWrapper
                label={t('general.interest')}
                value={moneyMask(data?.txnInterestAmount)}
              />

              <ValueWrapper
                label={t('general.fine')}
                value={moneyMask(data?.txnFineAmount)}
              />
            </>
          )}

          <ValueWrapper
            label={t('payment-voucher.payer-name')}
            value={data?.payer}
          />

          <ValueWrapper
            label={t('payment-voucher.payer-document')}
            value={documentMask(data?.payeeDocument)}
          />

          <ValueWrapper
            label={t('payment-voucher.payee-name')}
            value={data?.payer}
          />

          <ValueWrapper
            label={t('payment-voucher.payee-document')}
            value={documentMask(data?.payeeDocument)}
          />

          <ValueWrapper
            className="col-span-2"
            label={'Emissor'}
            value={data?.issuer}
          />

          <ValueWrapper
            className="col-span-2"
            label={t('general.barcode')}
            value={data?.barcode}
          />
        </div>
      </SidebarSection>

      <hr className="hr" />

      <SidebarSection
        titleSection={t('payment-voucher.your-account')}
        subTitleSection={t('payment-voucher.origin-account')}
      >
        <div className="grid grid-cols-2 gap-4">
          <ValueWrapper label={'De'} value={currentOrg?.legalName} />

          <ValueWrapper
            label={'CPF/CNPJ'}
            value={documentMaskHashed(currentOrg?.document)}
          />
        </div>
      </SidebarSection>
    </PaymentVoucher>
  );
};
