import {
  Button,
  ErrorMessage,
  Input,
  Spinner,
  Toastr,
  ToastrContainer,
} from '@portao3-web/ui';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  GeneralPresentationLogin,
  HeaderForm,
  StrongPassword,
} from '@components';
import { regex } from '@constants/regex';
import { AuthToken } from '@interfaces/Auth.interfaces';
import { AuthContext } from '@providers';
import { changePassword } from '@services/changePassword';
import { encryptToken } from '@utils/crypto';
import './NewPassword.styles.scss';
import { ChangePasswordProps } from '@interfaces/Password.interfaces';

export const NewPassword = () => {
  const { session, token, email } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, touchedFields },
  } = useForm<ChangePasswordProps>({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const password = watch('password');

  const submitForm: SubmitHandler<ChangePasswordProps> = async ({
    password,
  }) => {
    setLoading(!loading);
    try {
      const requestNewPassword = {
        newPassword: password,
        email: email.value,
        session: session.value,
      };
      const response = await changePassword<AuthToken>(requestNewPassword);

      setLoading(false);
      localStorage.setItem('token', JSON.stringify(encryptToken(response)));

      token.setToken(response.accessToken);
    } catch {
      setLoading(false);
      Toastr.error('Estamos em manutenção');
    }
  };

  useEffect(() => {
    if (touchedFields.confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, trigger, touchedFields]);

  useEffect(() => {
    if (!email.value || !session.value) {
      history('/');
    }
  }, [email.value, session.value, history]);

  return (
    <div className="authentication">
      <div className="authentication_content">
        <header className="authentication_img_header" />
        <GeneralPresentationLogin />
        <div className="authentication_content_right">
          <div className="authentication_content_right_form">
            <HeaderForm
              title="Defina uma nova senha"
              subTitle="Preencha os campos abaixo para finalizar."
            />

            <form
              className="authentication_content_right_form_inputs"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="authentication_submit">
                <div className="">
                  <Input
                    type="password"
                    label="Nova senha"
                    placeholder=""
                    id="password"
                    name="password"
                    register={register}
                    data-testid="password"
                    error={!!errors['password']}
                    validationSchema={{
                      required: 'Senha obrigatória',
                      pattern: {
                        value: regex.allConditions,
                        message: 'A senha deve seguir o padrão abaixo',
                      },
                    }}
                    required
                  >
                    {errors['password']?.message && (
                      <ErrorMessage
                        message={errors['password'].message.toString()}
                      />
                    )}
                  </Input>
                </div>

                <StrongPassword password={password} />

                <div className="authentication_submit_confirm_password">
                  <Input
                    label="Confirme a nova senha"
                    type="password"
                    placeholder=""
                    id="confirmPassword"
                    name="confirmPassword"
                    data-testid="confirmPassword"
                    register={register}
                    error={!!errors['confirmPassword']}
                    validationSchema={{
                      required: 'Senha obrigatória',
                      validate: (value: string) =>
                        password === value || 'As senhas devem ser iguais',
                    }}
                    required
                  >
                    {errors['confirmPassword']?.message && (
                      <ErrorMessage
                        message={errors['confirmPassword'].message.toString()}
                      />
                    )}
                  </Input>
                </div>

                <Button
                  size="large"
                  disabled={!isValid}
                  data-testid="submitForm"
                >
                  {loading ? <Spinner variant="secondary" /> : 'Concluir'}
                </Button>

                <Button
                  size="large"
                  variant="secondary"
                  data-testid="cancelButton"
                  onClick={() => history('/login')}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastrContainer />
    </div>
  );
};
