import { AccordionProps } from './Accordion.interface';

import './Accordion.styles.scss';
import { Card } from '..';

export const Accordion = ({ children, setIsOpen, isOpen }: AccordionProps) => {
  return (
    <Card className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen()}>
        {children[0]}
      </div>
      {isOpen && <div className="accordion-content">{children[1]}</div>}
    </Card>
  );
};
