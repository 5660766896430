import { CustomFieldLocation } from '@interfaces/CustomFields.interfaces';

export const CUSTOM_FIELD_TYPES_CONFIG = {
  TEXT: {
    label: 'Texto simples',
    icon: 'fa-input-text',
  },
  NUMBER: {
    label: 'Número',
    icon: 'fa-input-numeric',
  },
  DATE: {
    label: 'Data',
    icon: 'fa-calendar',
  },
  BOOLEAN: {
    label: 'Ativo/Inativo',
    icon: 'fa-toggle-on',
  },
  SELECT: {
    label: 'Seleção única',
    icon: 'fa-list-radio',
  },
  MULTI_SELECT: {
    label: 'Multi-seleção',
    icon: 'fa-list-check',
  },
  CUSTOMER: {
    label: 'Clientes',
    icon: 'fa-user-group',
  },
};

export const CUSTOM_FIELD_LOCATION_CONFIG = [
  {
    location: CustomFieldLocation.TRANSACTION,
    label: 'Transações',
    description:
      'Será aplicado nos detalhes da transação como um dado complementar.',
  },
  {
    location: CustomFieldLocation.WALLET,
    label: 'Carteiras',
    description: 'Será aplicado no formulário de criação de carteiras.',
  },
];
