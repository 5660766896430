import { useMemo } from 'react';

import {
  FinancialImpactType,
  InvoiceIdentifierType,
  InvoiceStatementStatus,
} from '@enums/Invoices.enum';
import { ListResponse } from '@interfaces/Common.interfaces';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';
import { Skeleton } from '@portao3-web/ui';
import { useCustomerStatementsQuery } from '@services/invoices/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { moneyMask, removeMoneyMask } from '@utils/formatMasks';
import { useTranslation } from 'react-i18next';

interface CalculateBillingStatementProps {
  invoiceId: string;
  invoiceAmount: number;
  statement?: ListResponse<InvoiceStatementProps> | undefined;
}

export const CalculateBillingStatement = ({
  invoiceId,
  invoiceAmount,
  statement,
}: CalculateBillingStatementProps) => {
  const { t } = useTranslation();

  const { data: statements, isLoading: isLoadingStatements } =
    useCustomerStatementsQuery({
      invoiceId: invoiceId,
      enabled: !statement,
    });

  const getTotalValueStatements = useMemo(() => {
    return statements?.items
      ? statements?.items.reduce((acc, statement) => {
          if (
            statement.financialImpactType === FinancialImpactType.DEBIT &&
            statement.identifier === InvoiceIdentifierType.INCREASE
          ) {
            return acc + statement.originalAmount;
          } else if (
            statement.financialImpactType === FinancialImpactType.CREDIT &&
            statement.identifier === InvoiceIdentifierType.DISCOUNT
          ) {
            return acc - statement.originalAmount;
          }
          return acc;
        }, 0)
      : 0;
  }, [statements?.items]);

  const getTotalAmountPayments = useMemo(() => {
    return statements?.items
      ? statements?.items.reduce((acc, statement) => {
          if (
            statement.financialImpactType === FinancialImpactType.CREDIT &&
            statement.identifier !== InvoiceIdentifierType.DISCOUNT &&
            statement.status ===
              (InvoiceStatementStatus.DONE || InvoiceStatementStatus.PAID)
          ) {
            return acc + statement.billingAmount;
          }
          return acc;
        }, 0)
      : 0;
  }, [statements?.items]);

  const finalBalanceValue = useMemo(() => {
    const total =
      getTotalValueStatements + invoiceAmount - getTotalAmountPayments;
    const objTotalValue = {
      value: total.toString(),
      signal: total < 0 ? `(-)` : total > 0 ? `(+)` : ``,
    };
    return objTotalValue;
  }, [invoiceAmount, getTotalValueStatements, getTotalAmountPayments]);

  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-p1 text-neutral-500">
        {t('general.invoice-summary')}
      </h2>

      <div className="bg-neutral-20 flex flex-col gap-3 rounded-sm px-6 py-4">
        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <p className="text-p2 text-neutral-500">Total da fatura</p>

            <Skeleton width={120} isLoading={isLoadingStatements}>
              <p className="text-p2 text-neutral-500">
                {calcCurrencyValue(invoiceAmount, 'BRL')}
              </p>
            </Skeleton>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <p className="text-p2 text-neutral-500">Pagamentos</p>

            <Skeleton width={120} isLoading={isLoadingStatements}>
              <p className="text-p2 text-neutral-500">
                {getTotalAmountPayments !== 0 && `(-)`}
                {calcCurrencyValue(getTotalAmountPayments || 0, 'BRL')}
              </p>
            </Skeleton>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex justify-between">
            <p className="text-p2 text-neutral-500">Ajustes</p>

            <Skeleton width={120} isLoading={isLoadingStatements}>
              <p className="text-p2 text-neutral-500">
                {calcCurrencyValue(getTotalValueStatements || 0, 'BRL')}
              </p>
            </Skeleton>
          </div>
        </div>

        <span className="border-neutral-40 w-full border-b border-solid" />

        <div className="flex justify-between">
          <p className="text-p1 font-semibold text-neutral-500">
            {t('general.balance')}
          </p>

          <Skeleton width={120} isLoading={isLoadingStatements}>
            <p className="text-p1 font-semibold text-neutral-500">
              {finalBalanceValue.signal}
              {moneyMask(removeMoneyMask(finalBalanceValue.value))}
            </p>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
