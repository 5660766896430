export const environment = {
  production: import.meta.env.VITE_PRODUCTION,
  sentryEnabled: import.meta.env.VITE_SENTRY_ENABLED,
  title: 'Prod Environment Heading',
  name: import.meta.env.VITE_NAME,
  expensesBaseUrl: import.meta.env.VITE_EXPENSES_BASE_URL,
  identityBaseUrl: import.meta.env.VITE_IDENTITY_BASE_URL,
  platformBaseUrl: import.meta.env.VITE_PLATFORM_BASE_URL,
  recaptchaKey: import.meta.env.VITE_RECAPTCHA_KEY,
  secretCryptoKey: import.meta.env.VITE_SECRET_CRYPTO_KEY,
  secretCryptoPin: import.meta.env.VITE_SECRET_CRYPTO_PIN,
};
