import {
  ErrorMessage,
  Input as InputUi,
  InputProps as InputUiProps,
} from '@portao3-web/ui';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface InputProps<T extends FieldValues>
  extends Omit<InputUiProps, 'register' | 'error'> {
  name: Path<T>;
  form: UseFormReturn<T, unknown, undefined>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = <T extends FieldValues>({
  name,
  form,
  onChange,
  ...props
}: InputProps<T>) => {
  const { t } = useTranslation();

  const { errors } = form.formState;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      return onChange(event);
    }

    form.setValue(name, event.target.value as PathValue<T, Path<T>>);
    form.clearErrors(name);
  };

  return (
    <InputUi
      name={name}
      form={form}
      register={form.register}
      error={!!errors[name]?.message}
      onChange={handleChange}
      {...props}
    >
      {errors[name]?.message && (
        <ErrorMessage message={t(errors[name]?.message as string, '')} />
      )}
    </InputUi>
  );
};
