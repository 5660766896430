interface TableIconProps {
  icon: string;
}

export const TableIcon = ({ icon }: TableIconProps) => {
  return (
    <div className="w-8 h-8 bg-neutral-30 rounded-full flex">
      <i className={`${icon} text-p2 text-neutral-100 m-auto`} />
    </div>
  );
};
