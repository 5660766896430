import { endpoint, organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';
import { AxiosResponse } from 'axios';

export interface GetStatementReportParams {
  startDate?: string | null;
  endDate?: string | null;
  type: string;
  walletId?: string;
}

export const getStatementReport = async ({
  startDate,
  endDate,
  type,
  walletId,
}: GetStatementReportParams): Promise<AxiosResponse<string>> => {
  const searchParams = new URLSearchParams();
  startDate && searchParams.set('startDate', startDate);
  endDate && searchParams.set('endDate', endDate);
  searchParams.set('type', type);
  walletId && searchParams.set('walletId', walletId);
  searchParams.set('limit', '10000');
  return api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/${
      endpoint.statements
    }/report?${searchParams.toString()}`
  );
};

export const getWalletReport = async (
  type: string,
  status: string | null
): Promise<AxiosResponse<string>> => {
  const params = {
    type,
    status,
  };

  removeEmptyObjectAttributes(params);

  return api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/wallets-report`,
    {
      params,
    }
  );
};
