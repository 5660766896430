import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  ContextualError,
  ContextualErrorType,
  InDrawerAuthentication,
  MyWallet,
  P2PTransferVoucherProps,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { viewCategoryWallet } from '@enums/WalletEnum';
import { useAuthentication } from '@hooks';
import { useQueryParams } from '@hooks/useQueryParams';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import { WalletCategory, WalletRole } from '@interfaces/Wallets.interfaces';
import { customerEvents } from '@lib/customerIo';
import {
  Button,
  DatePicker,
  ErrorMessage,
  Input,
  Spinner,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { schedulingTransactionKeys } from '@services/scheduling';
import { useScheduleWalletTransferP2P } from '@services/scheduling/hooks/useScheduleWalletTransferP2P';
import {
  useUpdateWalletBalanceMutation,
  useUserWalletQuery,
  useWalletQuery,
} from '@services/wallet/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { moneyMask } from '@utils/formatMasks';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './WalletController.styles.scss';

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export interface WalletControllerProps {
  setVoucherTransfer: (voucher: P2PTransferVoucherProps) => void;
  setFlow: (
    flow: 'startUpdate' | 'transferVoucher' | 'transferScheduleVoucher'
  ) => void;
  setVoucherScheduleTransfer: (
    voucher: CreateTransactionSchedule | null
  ) => void;
}

export const WalletController = ({
  setVoucherTransfer,
  setVoucherScheduleTransfer,
  setFlow,
}: WalletControllerProps) => {
  const { queryParams } = useQueryParams();
  const { closeDrawer } = useDrawer();
  const {
    controller,
    authSubmit,
    isAuthentication,
    onAuthSuccess,
    onAuthError,
    pin,
  } = useAuthentication();

  const organizationId = localStorage.getItem('organization') ?? '';
  const walletId = queryParams.get('walletId') ?? '';
  const userId = queryParams.get('userId') ?? '';

  const [selectedCategory, setSelectedCategory] = useState<WalletCategory>(
    {} as WalletCategory
  );

  const { data: walletById } = useWalletQuery({ walletId, organizationId });
  const { data: walletByUser } = useUserWalletQuery({ userId, organizationId });

  const [contextualError, setContextualError] =
    useState<ContextualErrorType>(null);

  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate: updateWalletBalance, isPending: isUpdatingWalletBalance } =
    useUpdateWalletBalanceMutation();

  const {
    mutate: scheduleTransferWalletP2P,
    isPending: isUpdatingSchedulingWalletBalance,
  } = useScheduleWalletTransferP2P();

  const wallet = walletByUser || walletById;

  const walletOwner =
    wallet?.shared &&
    wallet.shared?.find((item) => item.role === WalletRole.OWNER);

  let fullname =
    walletOwner?.lastName && walletOwner?.lastName
      ? `${walletOwner?.firstName} ${walletOwner?.lastName}`
      : '';
  if (fullname.includes('undefined')) fullname = '-';

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      amount: calcCurrencyValue(selectedCategory.amount, 'BRL') || '',
      date: dayjs().startOf('day').toDate().toISOString() as unknown as Date,
    },
  });

  const resetFields = () => {
    reset();
    clearErrors();
  };

  useEffect(() => {
    reset();
    clearErrors();
    setSelectedCategory({} as WalletCategory);
  }, [reset, clearErrors]);

  useEffect(() => {
    if (walletId === 'default') {
      closeDrawer();
    }
  }, [walletId, closeDrawer]);

  const handleSelectedCategory = (elem: WalletCategory) => {
    resetFields();
    setSelectedCategory(elem);
  };

  const handleTransferWalletValue = async () => {
    if (!wallet) return;

    updateWalletBalance(
      {
        walletId: wallet.id,
        payload: {
          category: selectedCategory.category,
          amount: Number(getValues('amount').replace(/\D/g, '')),
          pin,
        },
      },
      {
        onSuccess: (response) => {
          onAuthSuccess();
          customerEvents.walletBalanceUpdated({
            amount: Number(getValues('amount').replace(/\D/g, '')),
            id: wallet.id,
            type: selectedCategory.category,
          });

          setVoucherTransfer({
            amount: Number(getValues('amount').replace(/\D/g, '')),
            payeeWalletName: response.additionalInfos.payeeWalletName,
            payeeWalletType: response.additionalInfos.payeeWalletType,
            payerWalletName: response.additionalInfos.payerWalletName,
            payerWalletType: response.additionalInfos.payerWalletType,
            payeeCategory: response.additionalInfos.payeeWalletCategory,
            payerCategory: response.additionalInfos.payerWalletCategory,
          });

          setFlow('transferVoucher');
        },
        onError: (error) => {
          onAuthError(error);

          setContextualError({
            message: t(
              `error.CODE_ERROR.${error?.response?.data?.code}`,
              t('error.default')
            ),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const handleScheduleTransferWalletValue = async () => {
    scheduleTransferWalletP2P(
      {
        amount: Number(watch('amount').replace(/\D/g, '')),
        processingDate: dayjs(watch('date')).format('YYYY-MM-DD'),
        category: selectedCategory.category,
        description: fullname,
        walletId,
        pin,
      },
      {
        onSuccess: (data) => {
          onAuthSuccess();

          customerEvents.walletBalanceScheduled({
            amount: data.amount,
            walletId: data.wallet,
            paymentDate: data.scheduledAt,
            scheduleId: data.id,
          });

          setVoucherScheduleTransfer(data);

          queryClient.invalidateQueries({
            queryKey: schedulingTransactionKeys.lists(),
          });

          setFlow('transferScheduleVoucher');
        },
        onError: (error) => {
          onAuthError(error);

          setContextualError({
            message: t(
              `error.CODE_ERROR.${error?.response?.data?.code}`,
              t('error.default')
            ),
            traceId: error?.response?.data?.traceId,
          });
        },
      }
    );
  };

  const isPaymentToday =
    dayjs(watch('date')).utc(true).format('YYYY-MM-DD') ===
    dayjs().format('YYYY-MM-DD');

  const finishTransfer = () => {
    setContextualError(null);

    if (isPaymentToday) {
      return handleTransferWalletValue();
    }

    handleScheduleTransferWalletValue();
  };

  return (
    <>
      {!isAuthentication && (
        <>
          <ContextualError error={contextualError} />

          <SidebarSection
            numberSection={1}
            titleSection="Visualize ou ajuste o saldo por categoria"
            subTitleSection="Clique em uma categoria para aumentar o saldo."
          >
            <MyWallet
              walletCategoryList={wallet?.balances}
              totalBalance={wallet?.totalBalance}
              setSelectedCategory={(elem) => handleSelectedCategory(elem)}
              isClickable={true}
            />
          </SidebarSection>

          <form
            className="wallet_controller form_container_user_controller"
            onSubmit={handleSubmit(() => authSubmit(finishTransfer))}
          >
            {selectedCategory && selectedCategory.category && (
              <div className="form_container_user_controller_container mt-4">
                <SidebarSection
                  numberSection={2}
                  titleSection="Adicione saldo à categoria"
                  subTitleSection={`Informe o valor que deseja adicionar a categoria ${
                    viewCategoryWallet[selectedCategory.category]
                  }`}
                >
                  <Input
                    type="tel"
                    placeholder="Inserir valor"
                    label="Valor"
                    id="amount"
                    name="amount"
                    data-testid="amount"
                    register={register}
                    error={!!errors['amount']}
                    onChange={(event) => {
                      const value = moneyMask(event.target.value);
                      setValue('amount', value, { shouldDirty: true });
                    }}
                    disabled={
                      isUpdatingWalletBalance ||
                      isUpdatingSchedulingWalletBalance
                    }
                    maxLength={17}
                  >
                    {errors['amount']?.message && (
                      <ErrorMessage
                        message={errors['amount'].message.toString()}
                      />
                    )}
                  </Input>
                </SidebarSection>

                {watch('amount') &&
                  Number(watch('amount').replace(/\D/g, '')) !== 0 && (
                    <SidebarSection
                      numberSection={3}
                      titleSection="Informe a data"
                      subTitleSection="Selecione uma data para quando deseja realizar essa transferência"
                    >
                      <DatePicker
                        size="md"
                        date={watch('date')}
                        setDate={(value) =>
                          setValue('date', value || new Date())
                        }
                        disabled={{
                          before: dayjs().startOf('day').toDate(),
                        }}
                      />
                    </SidebarSection>
                  )}
              </div>
            )}

            <SidebarFooter>
              <Button
                onClick={() => {
                  resetFields();
                  closeDrawer();
                }}
                variant="tertiary"
                size="large"
                type="reset"
              >
                Cancelar
              </Button>

              <Button
                size="large"
                type="submit"
                data-testid="submit-add-balance"
                disabled={
                  watch('amount') === moneyMask('0') ||
                  isUpdatingWalletBalance ||
                  isUpdatingSchedulingWalletBalance ||
                  !selectedCategory?.category
                }
                className="form_container_user_controller_footer_button_submit"
              >
                {isUpdatingWalletBalance ||
                isUpdatingSchedulingWalletBalance ? (
                  <Spinner variant="secondary" />
                ) : isPaymentToday ? (
                  'Finalizar transferência'
                ) : (
                  'Agendar transferência'
                )}
              </Button>
            </SidebarFooter>
          </form>
        </>
      )}

      {isAuthentication && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            finishTransfer();
          }}
        >
          <InDrawerAuthentication
            isPending={
              isUpdatingSchedulingWalletBalance || isUpdatingWalletBalance
            }
            {...controller}
          />
        </form>
      )}
    </>
  );
};
