import { TransactionBatchTypes } from '@enums/BatchPayment.enum';
import { PixDictType } from '@enums/newPix.enum';

export interface CNABParsedData {
  type: TransactionBatchTypes;
  bankCode: string;
  bankAgency: string;
  bankAccount: string;
  name: string;
  transactionAt: string;
  amount: number;
  document: string;
  bankAccountDigit?: string;
  digitableLine?: string;
  pixDict?: PixDictType;
  key?: string;
}

export const parseCNAB240 = (data: string): CNABParsedData[] => {
  const lines = data.trim().replaceAll(`\r`, '').split('\n');

  const paymentsA: string[] = [];
  const paymentsB: string[] = [];

  for (let i = 1; i < lines.length - 1; i++) {
    const line = lines[i];
    const registrationType = line.substring(7, 8);
    const paymentType = line.substring(13, 14);

    if (registrationType === '3' && paymentType === 'A') {
      paymentsA.push(line);
    }

    if (registrationType === '3' && paymentType === 'B') {
      paymentsB.push(line);
    }
  }

  const pixType = {
    11: PixDictType.CPF,
    13: PixDictType.PHONE,
    14: PixDictType.CNPJ,
  };

  const content = paymentsA.map((payment: string, index) => {
    const key = paymentsB?.[index]?.substring(127, 163).trim();
    const date = payment.substring(93, 101).trim();
    const year = date.substring(4, 8);
    const month = date.substring(2, 4);
    const day = date.substring(0, 2);
    const formattedDate = `${year}-${month}-${day}`;

    const getPixPaymentType = () => {
      if (!key) return undefined;

      if (key && key.includes('@')) {
        return PixDictType.EMAIL;
      }

      if (key.length === 36 && key.includes('-')) {
        return PixDictType.EVP;
      }

      const pixTypeOption = pixType[key.length as keyof typeof pixType];

      return pixTypeOption ? pixTypeOption : undefined;
    };

    const getBatchPaymentType = () => {
      if (getPixPaymentType()) {
        return TransactionBatchTypes.PIX_DICT;
      }

      return TransactionBatchTypes.PIX_BANK_DETAILS;
    };

    const getDocument = () => {
      const document_type = paymentsB?.[index]?.substring(17, 18).trim();
      const document = paymentsB?.[index]?.substring(18, 32).trim() || '';

      if (document_type === '1') {
        return document?.slice(3);
      }

      return document;
    };

    return {
      type: getBatchPaymentType(),
      amount: +payment.substring(119, 134).trim(),
      bankCode: payment.substring(20, 23).trim(),
      bankAgency: payment.substring(24, 28).trim(),
      // adicionado verificador da conta (29, 41)
      bankAccount: payment.substring(29, 42).trim(),
      bankAccountDigit: payment.substring(42, 43).trim(),
      name: payment.substring(43, 73).trim(),
      transactionAt: formattedDate,
      document: getDocument(),
      pixDict: getPixPaymentType(),
      key:
        getBatchPaymentType() === TransactionBatchTypes.PIX_DICT
          ? key
          : undefined,
      digitableLine: undefined,
    };
  });

  return content;
};
