import { useEffect, useRef } from 'react';

import { ErrorMessage, Input, TextArea } from '@portao3-web/ui';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import '../customFieldTypeSelection/CustomFieldTypeSelection.styles.scss';

export const BooleanField = () => {
  const isMounted = useRef(false);

  const {
    resetField,
    register,
    unregister,
    formState: { errors },
  } = useDrawerEditCustomField();

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    return () => {
      resetField('label');
      resetField('externalIdentifier');
      unregister('description');
    };
  }, [resetField, unregister]);

  return (
    <div className="custom-field-config--flex">
      <Input
        name="label"
        label="Nome do campo"
        placeholder="Ex: Fora da política?"
        register={register}
        validationSchema={{ required: 'Nome do campo é obrigatório' }}
        error={!!errors.label?.message}
      >
        {errors.label?.message && (
          <ErrorMessage message={errors.label.message} />
        )}
      </Input>
      <TextArea
        name="description"
        label="Descrição"
        placeholder="Ex: A transação está fora da política estabelecida?"
        register={register}
        style={{ resize: 'vertical' }}
      />
      <Input
        name="externalIdentifier"
        label="Identificador externo (Opcional)"
        placeholder="Ex: politica-estabelecida"
        register={register}
      />
    </div>
  );
};
