import { Breadcrumb, Typography } from '@portao3-web/ui';

import './HeaderPage.styles.scss';
import { HeaderPageProps } from './HeaderPage.interface';

export const HeaderPage = ({
  title,
  subTitle,
  children,
  breadcrumbList,
  isLoading,
}: HeaderPageProps) => {
  return (
    <div className="header">
      <div className="header_title">
        <Typography tag="h2" color="var(--product-neutral-n500)">
          {title}
        </Typography>
        <Typography tag="p" weight="p1" color="var(--product-neutral-n80)">
          {subTitle}
        </Typography>
        {breadcrumbList && (
          <Breadcrumb items={breadcrumbList} isLoading={isLoading} />
        )}
      </div>
      <div className="header_buttons">{children}</div>
    </div>
  );
};
