import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContextualError, SidebarFooter } from '@components';
import { UserResponse } from '@interfaces/User.interfaces';
import { customerEvents } from '@lib/customerIo';
import { Button, InputOTP, Toastr } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useCreateUserPinMutation } from '@services/auth/hooks/useCreateUserPinMutate';
import { userKeys } from '@services/user/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { encryptPin } from '@utils/crypto';
import { onlyNumbersMask } from '@utils/formatMasks';
import {
  validateTokenRules,
  validateTokenSequence,
  validateTokenSequenceEqual,
} from '@utils/validate';
import { requiredSchema, validationSchema } from '@utils/validationSchema';
import { useState } from 'react';
import { PasswordRule } from './PasswordRule';

interface DefaultValues {
  newPin: string;
  confirmPin: string;
}

export const CreateUserAuthPin = () => {
  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const [pinValidations, setPinValidations] = useState({
    noSequential: false,
    noRepeated: false,
    allDigits: false,
  });

  const {
    mutate: createUserPinMutate,
    isPending: isCretePinPending,
    customError,
  } = useCreateUserPinMutation();

  const queryClient = useQueryClient();

  const defaultValues: DefaultValues = {
    newPin: '',
    confirmPin: '',
  };

  const customValidation = {
    newPin: requiredSchema.token,
    confirmPin: requiredSchema.token,
  };

  const form = useForm<DefaultValues>({
    defaultValues,
    resolver: yupResolver(
      validationSchema(defaultValues, customValidation)
    ) as unknown as never,
  });

  const handleChange = (key: keyof DefaultValues, value: string) => {
    form.setValue(key, onlyNumbersMask(value));
    form.clearErrors(['confirmPin', 'newPin']);
    setValidations();
  };

  const validateTokens = (data: DefaultValues) => {
    const equalPassword = data.newPin === data.confirmPin;

    if (!equalPassword) {
      form.setError('confirmPin', {
        type: 'manual',
        message: 'error.passwords-not-match',
      });

      form.setError('newPin', {
        type: 'manual',
        message: 'error.passwords-not-match',
      });

      // para rerenderizar, caso tenham ideias melhores de como fazer isso, fiquem a vontade
      form.setValue('confirmPin', data.confirmPin, {
        shouldDirty: true,
      });

      return false;
    }

    if (!validateTokenRules(data.newPin)) {
      form.setError('confirmPin', {
        type: 'manual',
        message: 'error.invalid-password',
      });

      form.setError('newPin', {
        type: 'manual',
        message: 'error.invalid-password',
      });

      form.setValue('confirmPin', data.confirmPin, {
        shouldDirty: true,
      });

      return false;
    }

    return true;
  };

  const setValidations = () => {
    const data = form.getValues();

    const someFilled = data.newPin.length === 4 || data.confirmPin.length === 4;
    const allDigits = data.newPin.length === 4 && data.confirmPin.length === 4;
    const noRepeated =
      someFilled &&
      validateTokenSequenceEqual(data.newPin) &&
      validateTokenSequenceEqual(data.confirmPin);
    const noSequential =
      someFilled &&
      validateTokenSequence(data.newPin) &&
      validateTokenSequence(data.confirmPin);

    if (allDigits) {
      validateTokens(data);
    }

    setPinValidations({
      noSequential,
      noRepeated,
      allDigits,
    });
  };

  const submit = async (data: typeof defaultValues) => {
    createUserPinMutate(
      { pin: encryptPin(data.newPin) },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            userKeys.current(),
            (oldData: UserResponse) => {
              return {
                ...oldData,
                hasPin: true,
                pinErrorsCount: 0,
              };
            }
          );

          customerEvents.pinCreated();

          Toastr.success(t('auth.pin-created-successfully'));
          openDrawer('security', { flow: 'DEFAULT' });
        },
      }
    );
  };

  const isFullFilled =
    form.watch('confirmPin').length === 4 && form.watch('newPin').length === 4;
  const isEqualPasswords = form.watch('confirmPin') === form.watch('newPin');

  const disabledSubmit =
    Object.values(pinValidations).some((value) => !value) ||
    Object.keys(form.formState.errors).length > 0 ||
    !isFullFilled ||
    !isEqualPasswords;

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <>
        <div className="mb-2 flex flex-col gap-1">
          <h2 className="text-p2 text-neutral-500">
            {t('auth.register-pin-title')}
          </h2>

          <p className="text-p2 text-neutral-100">
            {t('auth.register-pin-description')}
          </p>
        </div>

        <div className="flex flex-col gap-4 mb-4">
          <div className="flex flex-col">
            <InputOTP
              length={4}
              size="medium"
              label={t('general.password')}
              value={form.watch('newPin')}
              setValue={(value) => handleChange('newPin', value)}
              error={!!form.formState.errors.newPin}
            />
          </div>

          <div className="flex flex-col">
            <InputOTP
              length={4}
              size="medium"
              label={t('general.confirm-password')}
              value={form.watch('confirmPin')}
              setValue={(value) => handleChange('confirmPin', value)}
              error={!!form.formState.errors.confirmPin}
              autoFocus={false}
            />
          </div>
        </div>

        <ContextualError error={customError} />

        <div className="flex flex-col gap-2">
          <PasswordRule
            isValid={pinValidations.noRepeated && isFullFilled}
            isError={!pinValidations.noRepeated && isFullFilled}
            text={t('auth.pin-no-equal-sequential-digits')}
          />
          <PasswordRule
            isValid={pinValidations.noSequential && isFullFilled}
            isError={!pinValidations.noSequential && isFullFilled}
            text={t('auth.pin-no-sequential-digits')}
          />
          <PasswordRule
            isValid={pinValidations.allDigits && isFullFilled}
            isError={!pinValidations.allDigits && isFullFilled}
            text={t('auth.pin-4-digits')}
          />
          <PasswordRule
            isValid={isEqualPasswords && isFullFilled}
            isError={!isEqualPasswords && isFullFilled}
            text={t('auth.equal-passwords')}
          />
        </div>
      </>

      <SidebarFooter>
        <Button
          type="button"
          variant="tertiary"
          size="large"
          onClick={() => openDrawer('security', { flow: 'DEFAULT' })}
        >
          {t('button.back')}
        </Button>

        <Button
          type="submit"
          size="large"
          isLoading={isCretePinPending}
          disabled={disabledSubmit}
          data-testid="submitForm"
        >
          {t('general.button.create')}
        </Button>
      </SidebarFooter>
    </form>
  );
};
