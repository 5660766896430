import { getStatusStyle } from '@utils/BillingStatus';
import { ICONS } from '@constants/icons';
import { InvoiceStatementStatus } from '@enums/Invoices.enum';
import { useV4Validator } from '@hooks';
import { InvoiceDealProps, InvoiceStatus } from '@interfaces/Invoices';
import { InvoiceStatementProps } from '@interfaces/Invoices.interfaces';
import {
  DrawerGenerateInvoicePayment,
  DrawerInvoiceAdjust,
  DrawerInvoiceDetails,
} from '@modules/drawer';
import { DrawerInvoiceStatementBankSlip } from '@modules/drawer/drawerInvoiceStatement/DrawerInvoiceStatementBankSlip';
import { DrawerInvoiceStatementPix } from '@modules/drawer/drawerInvoiceStatement/DrawerInvoiceStatementPix';
import {
  Accordion,
  DropDownList,
  Icon,
  Skeleton,
  Table,
  TableBody,
  TableCol,
  TableHead,
  TableIcon,
  TableRow,
  Tag,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useCustomerStatementsQuery } from '@services/invoices/hooks';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { renderTableHeader } from '@utils/renderTableHeader';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initialStateEntries } from '../../constants/initialStateInvoice';

interface AccordionItemProps {
  invoice: InvoiceDealProps;
  accordionId: string;
  isGeneral?: boolean;
}

interface MenuOption {
  label: string;
  action: () => void;
}

export const AccordionItem = ({
  invoice,
  accordionId,
  isGeneral,
}: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const { openDrawer, closeDrawer } = useDrawer();
  const { isFilialV4 } = useV4Validator();
  const { t } = useTranslation();

  const { data: entries, isLoading: isLoadingEntries } =
    useCustomerStatementsQuery({
      invoiceId: invoice.id,
      enabled: isOpen,
    });

  const thead = [
    '&nbsp;',
    'Descrição',
    '&nbsp;',
    'Valor',
    'Histórico',
    'Status',
  ];

  const generateMenu = (invoice: InvoiceDealProps): MenuOption[] => {
    const menuOptions: MenuOption[] = [];

    const actionsMap: { [key: string]: () => void } = {
      DETAILS: () => {
        openDrawer(<DrawerInvoiceDetails invoice={invoice} />);
      },
      BANK_SLIP: () =>
        openDrawer(
          <DrawerGenerateInvoicePayment invoice={invoice} type={'bank-slip'} />
        ),
      PIX: () =>
        openDrawer(
          <DrawerGenerateInvoicePayment invoice={invoice} type={'pix'} />
        ),
      ADJUSTMENT: () => {
        openDrawer(<DrawerInvoiceAdjust invoice={invoice} />);
      },
    };

    const labelsMap: { [key: string]: string } = {
      BANK_SLIP: `${t('general.generate')} ${t(`general.bank-slip`)}`,
      PIX: `${t('general.generate')} ${t(`general.pix`)}`,
    };

    if (invoice.status !== InvoiceStatus.PAID) {
      invoice.allowedPaymentMethods.sort().forEach((option) => {
        if (actionsMap[option]) {
          menuOptions.push({
            label: labelsMap[option],
            action: actionsMap[option],
          });
        }
      });
    }

    menuOptions.unshift({
      label: t('general.details'),
      action: actionsMap['DETAILS'],
    });

    if (invoice.status === InvoiceStatus.OPEN && !isFilialV4) {
      menuOptions.push({
        label: t('general.launch-adjust'),
        action: actionsMap['ADJUSTMENT'],
      });
    }

    return menuOptions;
  };

  const statusEntries = {
    [InvoiceStatementStatus.DONE]: {
      text: t('invoices.InvoiceStatementStatus.PAID'),
      icon: ICONS['circle-check'],
      background: 'var(--product-success-s50)',
      color: 'var(--product-success-s500)',
    },
    [InvoiceStatementStatus.PAID]: {
      text: t('invoices.InvoiceStatementStatus.PAID'),
      icon: ICONS['circle-check'],
      background: 'var(--product-success-s50)',
      color: 'var(--product-success-s500)',
    },
    [InvoiceStatementStatus.CANCELED]: {
      text: t('invoices.InvoiceStatementStatus.CANCELED'),
      icon: ICONS['circle-exclamation'],
      background: 'var(--product-danger-d50)',
      color: 'var(--product-danger-d500)',
    },
    [InvoiceStatementStatus.CREATED]: {
      text: t('invoices.InvoiceStatementStatus.CREATED'),
      icon: ICONS['circle-dashed'],
      background: 'var(--product-warning-w50)',
      color: 'var(--product-warning-w700)',
    },
    [InvoiceStatementStatus.PROCESSING]: {
      text: t('invoices.InvoiceStatementStatus.PROCESSING'),
      icon: ICONS['circle-dashed'],
      background: 'var(--product-info-i50)',
      color: 'var(--product-info-i700)',
    },
  };

  const getRowDetails = (statement: InvoiceStatementProps) => {
    const isPixCreated = statement?.identifier === 'PIX_CREATED';
    const isBankSlipCreated = statement?.identifier === 'BANK_SLIP_CREATED';
    const isPayment = isPixCreated || isBankSlipCreated;

    const status = statusEntries[statement?.status];

    const textColor = isPayment
      ? 'text-neutral-100'
      : statement?.financialImpactType === 'DEBIT'
        ? 'text-success-500'
        : 'text-danger-500';

    const icon = isPixCreated
      ? ICONS.pix
      : isBankSlipCreated
        ? ICONS.barcode
        : ICONS['file-invoice'];

    const onClick = () => {
      if (isBankSlipCreated) {
        openDrawer(
          <DrawerInvoiceStatementBankSlip
            invoice={invoice}
            statement={statement}
            onClose={closeDrawer}
          />
        );
      }

      if (isPixCreated) {
        openDrawer(
          <DrawerInvoiceStatementPix
            invoice={invoice}
            statement={statement}
            onClose={closeDrawer}
          />
        );
      }
    };

    return { status, textColor, isPayment, icon, onClick };
  };

  useEffect(() => {
    const handleClickOutside = () => {
      setShowOptions(false);
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Accordion
      setIsOpen={() => {
        setIsOpen((prev) => !prev);
      }}
      isOpen={isOpen}
    >
      <div className="invoices_accordion_header" data-testid={accordionId}>
        <div className="invoices_accordion_header--info w-full">
          <div className="icon-arrow">
            <Icon size="xlarge">
              <i
                className={`fa-regular ${
                  isOpen ? `fa-chevron-up` : `fa-chevron-down`
                } text-neutral-500`}
              />
            </Icon>
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center">
              <div className="flex items-center justify-center rounded-md bg-neutral-30 h-6 w-6">
                <i className="fa-regular text-p1 text-primary-400 fa-file-invoice" />
              </div>
              <div className="invoices_accordion_header_text">
                <Typography
                  tag="p"
                  color="var(--product-neutral-n500)"
                  weight="p2"
                >
                  {invoice.description}
                </Typography>
              </div>

              <Tag
                text={t(`invoices.invoiceStatus.${invoice.status}`)}
                showCloseButton={false}
                icon={
                  <i
                    className={`fa-regular ${
                      getStatusStyle(invoice.status).icon
                    } text-p3`}
                  />
                }
                textColor={getStatusStyle(invoice.status).color}
                backgroundColor={getStatusStyle(invoice.status).background}
                className="tag-accordion-item overflow-hidden"
              />
            </div>

            <p className="text-p3 text-neutral-80">
              {invoice?.dealName}
              {isGeneral
                ? ` - ${invoice.debitParties?.[0]?.partyAccount?.name}`
                : ''}
            </p>
          </div>
        </div>
        <div className="invoices_accordion_header--info">
          <div>
            <div className="invoices_accordion_header_values">
              <i className="fa-regular fa-calendar text-lg" />
              <Typography
                tag="p"
                weight="p4"
                color="var(--product-neutral-n80)"
              >
                {t('general.closing').toUpperCase()}
              </Typography>
            </div>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {dayjs.utc(invoice.closingAt).format('DD/MM/YYYY')}
            </Typography>
          </div>

          <div>
            <div className="invoices_accordion_header_values">
              <i className="fa-regular fa-calendar text-lg" />

              <Typography
                tag="p"
                weight="p4"
                color="var(--product-neutral-n80)"
              >
                {t('general.due-date').toUpperCase()}
              </Typography>
            </div>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {dayjs.utc(invoice.dueAt).format('DD/MM/YYYY')}
            </Typography>
          </div>

          <div>
            <div className="invoices_accordion_header_values">
              <i className="fa-regular fa-dollar text-lg" />
              <Typography
                tag="p"
                weight="p4"
                color="var(--product-neutral-n80)"
              >
                {t('general.total').toUpperCase()}
              </Typography>
            </div>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
              {calcCurrencyValue(invoice.amount, 'BRL')}
            </Typography>
          </div>

          <div
            onClick={(e) => {
              document.body.click();
              e.stopPropagation();
              setShowOptions(!showOptions);
            }}
            data-ignore-accordion
            data-testid={`option-${accordionId}`}
          >
            <i className="fa-regular text-neutral-80 fa-ellipsis hover:bg-primary-50 text-p1 rounded-md px-2" />
            {showOptions && <DropDownList list={generateMenu(invoice)} />}
          </div>
        </div>
      </div>

      <div className="invoices_accordion_content">
        <Table>
          <TableHead>
            <TableRow>
              {thead.map((columnTitle: string, key: number) =>
                renderTableHeader(columnTitle, key)
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isLoadingEntries
              ? initialStateEntries.items
              : entries?.items
            )?.map((statement, index) => {
              const details = getRowDetails(statement);

              return (
                <TableRow
                  key={index}
                  className={clsx({
                    'bg-neutral-0': true,
                    'hover:bg-neutral-10 cursor-pointer': details.isPayment,
                  })}
                  onClick={details.onClick}
                >
                  <TableCol className="!bg-transparent">&nbsp;</TableCol>
                  <TableCol className="!bg-transparent">
                    <Skeleton isLoading={isLoadingEntries}>
                      <Typography
                        tag="p"
                        weight="p2"
                        color="var(--product-neutral-n500)"
                      >
                        {statement?.description}
                      </Typography>
                    </Skeleton>
                  </TableCol>
                  <TableCol className="!bg-transparent">
                    <Skeleton isLoading={isLoadingEntries}>
                      <TableIcon icon={details.icon} />
                    </Skeleton>
                  </TableCol>
                  <TableCol className="!bg-transparent">
                    <Skeleton isLoading={isLoadingEntries}>
                      <p className={`text-p2 ${details.textColor}`}>
                        {calcCurrencyValue(statement.originalAmount, 'BRL')}
                      </p>
                    </Skeleton>
                  </TableCol>
                  <TableCol className="!bg-transparent">
                    <Skeleton isLoading={isLoadingEntries}>
                      <p className="text-p4 text-neutral-500">
                        {dayjs.utc(statement.createdAt).format('DD/MM/YYYY')}
                      </p>
                    </Skeleton>
                  </TableCol>
                  <TableCol className="!bg-transparent">
                    <Skeleton isLoading={isLoadingEntries}>
                      {details.isPayment && (
                        <Tag
                          text={details.status.text}
                          showCloseButton={false}
                          icon={
                            <i className={`text-p3 ${details.status.icon}`} />
                          }
                          textColor={details.status.color}
                          backgroundColor={details.status.background}
                        />
                      )}
                    </Skeleton>
                  </TableCol>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Accordion>
  );
};
