import { StatusDescriptionEnum } from '@enums/Status.enum';
import { typeCardEnum } from '@enums/typeCard.enum';
import { capitalizeFirstLetter } from '@utils/CapitalizeFirstLetter';
import {
  Avatar,
  MasterCard,
  Skeleton,
  Status,
  Typography,
} from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import { ItemPreviewProps } from './ItemPreview.interface';
import './ItemPreview.styles.scss';

const labels = {
  name: {
    card: 'Apelido',
    wallet: 'Nome da carteira',
  },
  balance: {
    wallet: 'Saldo da carteira',
  },
  number: {
    card: 'Nº do cartão',
  },
  walletName: {
    card: 'Carteira',
  },
};

export const ItemPreview = ({
  variant,
  data,
  onClick,
  id,
  showCardDetails,
  isLoading = false,
}: ItemPreviewProps) => {
  const MAX_AVATARS_COUNT = 5;

  const isClickable = !!onClick && !isLoading;
  const clickableClass = isClickable ? 'card_list_item--clickable' : '';
  const activeClass =
    showCardDetails === id && onClick ? 'card_list_item--active' : '';

  return (
    <div
      className={`card_list_item ${activeClass} ${clickableClass}`}
      data-testid={`showDetailsCard-${id}`}
      onClick={onClick}
      role={isClickable ? 'button' : undefined}
    >
      <div className="card_list_item_info">
        <Skeleton width={'20%'} isLoading={isLoading}>
          <Status status={data.status}>
            {StatusDescriptionEnum[data.status]}
          </Status>
        </Skeleton>

        {variant === 'card' && (
          <Skeleton width={'20%'} isLoading={isLoading}>
            <div className="card_list_item_logo">
              <div className="card_list_item_logo_type_card">
                <Typography tag="p" color="var(--product-neutral-n80)">
                  {typeCardEnum[data.type]}
                </Typography>
              </div>
              <MasterCard />
            </div>
          </Skeleton>
        )}

        {variant === 'wallet' && (
          <div className="card_list_item_avatars">
            <Skeleton
              variant="circular"
              width={33}
              height={33}
              isLoading={isLoading}
            >
              <div
                className="card_list_item_avatars_container"
                data-testid="avatars-container"
              >
                {data.thumbs
                  .slice(
                    0,
                    data.thumbs.length > MAX_AVATARS_COUNT
                      ? MAX_AVATARS_COUNT
                      : data.thumbs.length
                  )
                  .map(({ username, src }, index) => (
                    <div className="item-wrapper" key={index}>
                      <Avatar
                        size="small"
                        imageSrc={src ?? ''}
                        userName={username}
                      />
                    </div>
                  ))}
                {data.thumbs.length > MAX_AVATARS_COUNT && (
                  <div style={{ marginRight: -10 }}>
                    <Avatar
                      size="small"
                      imageSrc={''}
                      userName={`+ ${data.thumbs.length - MAX_AVATARS_COUNT}`}
                    />
                  </div>
                )}
              </div>
            </Skeleton>
          </div>
        )}
      </div>

      <div className={`card_list_item_name card_list_item_name--${variant}`}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%' }}>
            <Skeleton width={'75%'} isLoading={isLoading}>
              <Typography
                tag="p"
                weight="p3"
                color="var(--product-neutral-n100)"
              >
                {labels.name[variant]}
              </Typography>
            </Skeleton>
            <Skeleton width={'75%'} isLoading={isLoading}>
              <Typography
                tag="p"
                weight="p1"
                color="var(--product-neutral-n700)"
              >
                {capitalizeFirstLetter(data.name)}
              </Typography>
            </Skeleton>
          </div>

          {variant === 'wallet' && (
            <div style={{ width: '60%', marginLeft: 10 }}>
              <Skeleton width={'70%'} isLoading={isLoading}>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n100)"
                >
                  {labels.balance[variant]}
                </Typography>
              </Skeleton>
              <Skeleton width={'70%'} isLoading={isLoading}>
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-neutral-n700)"
                >
                  {calcCurrencyValue(data.total, 'BRL')}
                </Typography>
              </Skeleton>
            </div>
          )}
        </div>

        {variant === 'card' && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Skeleton width={'60%'} isLoading={isLoading}>
                <Typography
                  tag="p"
                  weight="p3"
                  color="var(--product-neutral-n100)"
                >
                  {labels.number[variant]}
                </Typography>
              </Skeleton>
              <Skeleton width={'60%'} isLoading={isLoading}>
                <Typography
                  tag="p"
                  weight="p1"
                  color="var(--product-neutral-n700)"
                  className="card_list_item_number"
                >
                  {variant === 'card' && data.panMasked.slice(-4)}
                </Typography>
              </Skeleton>
            </div>

            {data?.walletName && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Skeleton width={'60%'} isLoading={isLoading}>
                  <Typography
                    tag="p"
                    weight="p3"
                    color="var(--product-neutral-n100)"
                  >
                    {labels.walletName[variant]}
                  </Typography>
                </Skeleton>
                <Skeleton width={'60%'} isLoading={isLoading}>
                  <Typography
                    tag="p"
                    weight="p1"
                    color="var(--product-neutral-n700)"
                  >
                    {variant === 'card' && data.walletName}
                  </Typography>
                </Skeleton>
              </div>
            )}
          </div>
        )}

        {variant === 'wallet' && (
          <Skeleton width={'60%'} isLoading={isLoading}>
            <Typography tag="p" weight="p2" color="var(--product-neutral-n100)">
              {data.owner}
            </Typography>
          </Skeleton>
        )}
      </div>
    </div>
  );
};
