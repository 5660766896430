import { useEffect, useRef, useState } from 'react';

import { Icon, Typography } from '@portao3-web/ui';
import { CustomSelectProps } from './Select.interface';
import './Select.styles.scss';

export const Select = ({
  options,
  selectItem,
  defaultValue = '',
  disabled,
  ...props
}: CustomSelectProps) => {
  const optionsRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (label: string) => {
    setSelectedOption(label);
    const selectedRoles = options.filter((option) => option.label === label)[0];
    selectItem(selectedRoles.value);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const defaultOption = options.filter(
      (option) => option.value === defaultValue
    )[0];
    setSelectedOption(defaultOption?.label ?? '');
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [defaultValue, options]);

  const Options = () => (
    <div className="borderless-select_container_options">
      <div className="borderless-select_container_options_scroll">
        {options.map((option) => (
          <div
            key={option.value}
            data-testid={`option-${option.value}`}
            className="option"
            onClick={() => handleOptionClick(option.label)}
            role="option"
            aria-selected={option.label === selectedOption}
          >
            <Typography tag="p" weight="p3" color="var(--product-neutral-n200)">
              {option.label}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div
      className={`borderless-select ${
        disabled ? 'borderless-select--disabled' : ''
      }`}
      ref={optionsRef}
    >
      <div
        className={`borderless-select_trigger ${isOpen ? 'open' : ''} `}
        onClick={disabled ? undefined : toggleOptions}
        role="listbox"
        aria-expanded={isOpen}
        {...props}
      >
        <Typography tag="p" weight="p3" color="var(--product-neutral-n200)">
          {selectedOption}
        </Typography>
        <Icon size="small">
          <i id="icon" className="fa fa-caret-down fa-lg" />
        </Icon>
      </div>
      {isOpen && <Options />}
    </div>
  );
};
