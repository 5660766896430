import { CardListParameters } from '@interfaces/Card.interfaces';

export const cardKeys = {
  all: () => ['card'],
  details: () => [...cardKeys.all(), 'detail'],
  lists: () => [...cardKeys.all(), 'list'],
  list: (params: CardListParameters) => [...cardKeys.lists(), params],
};

export const cardTransactionKeys = {
  all: () => ['card-transactions'],
  lists: () => [...cardKeys.all(), 'list'],
  list: (params: CardListParameters & { organizationId: string }) => [
    ...cardKeys.lists(),
    params,
  ],
};
