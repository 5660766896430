import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FutureTransactions, HeaderPage } from '@components';
import { useQueryParams } from '@hooks/useQueryParams';
import { Tabs } from '@portao3-web/ui';
import { useWalletQuery } from '@services/wallet/hooks';
import { capitalizeFirstLetter } from '@utils/CapitalizeFirstLetter';
import { StatementBody } from './components';
import dayjs from 'dayjs';

export const Statement = () => {
  const organizationId = localStorage.getItem('organization');

  const { queryParams, setQueryParams } = useQueryParams();
  const { walletId } = useParams();
  const { t } = useTranslation();

  const { data: wallet, isFetching } = useWalletQuery({
    organizationId,
    walletId,
  });

  const isWalletDefault = walletId === 'default';

  const breadCrumbElem = [
    {
      label: 'Carteiras',
      url: '/wallets',
      isDisabled: false,
    },
    {
      label: capitalizeFirstLetter(
        isWalletDefault ? t('general.company-account') : wallet?.name
      ),
      url: isWalletDefault ? `/company` : `/wallets/${walletId}/geral`,
      isDisabled: false,
    },
    {
      label: 'Extrato',
      url: ``,
      isDisabled: true,
    },
  ];

  const handleTabChange = (tab: string) => {
    const activeTab = tabs.find((t) => t.id === tab);
    setQueryParams({
      tab: activeTab?.id,
      transactionTypes: undefined,
      financialImpact: undefined,
      status: undefined,
    });

    if (activeTab?.id === '1') {
      setQueryParams({
        startDate: dayjs().startOf('day').subtract(3, 'hours').toISOString(),
      });
      setQueryParams({
        endDate: dayjs()
          .add(30, 'day')
          .endOf('day')
          .subtract(3, 'hours')
          .toISOString(),
      });
    } else {
      setQueryParams({
        startDate: dayjs()
          .subtract(30, 'day')
          .startOf('day')
          .subtract(3, 'hours')
          .toISOString(),
      });
      setQueryParams({
        endDate: dayjs().endOf('day').subtract(3, 'hours').toISOString(),
      });
    }
  };

  const tabs = [
    {
      id: '0',
      label: t('general.statement'),
      content: <StatementBody />,
    },
    {
      id: '1',
      label: t('general.future-transactions'),
      content: <FutureTransactions />,
    },
  ];

  return (
    <div className="flex flex-col">
      <HeaderPage
        title="Extrato"
        breadcrumbList={breadCrumbElem}
        isLoading={isFetching}
      />

      <Tabs
        tabs={tabs}
        start={Number(queryParams.get('tab'))}
        onTabChange={handleTabChange}
      />
    </div>
  );
};
