import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChangeStatusModal,
  ClearFiltersQuery,
  EmptyStateBlock,
  HeaderPage,
  Pagination,
  SearchQuery,
  SelectQuery,
  TableColumnsFilter,
} from '@components';
import {
  emptyWalletsList,
  emptyWalletsListWithFilter,
  errorState,
} from '@constants/emptyState';
import { ICONS } from '@constants/icons';
import { StatementReportFileType } from '@enums/StatementReportEnum';
import { useQueryParams } from '@hooks';
import {
  WalletDetailsProps,
  WalletStatus,
  WalletType,
} from '@interfaces/Wallets.interfaces';
import {
  Button,
  DropDownList,
  Icon,
  Spinner,
  TableDemo,
  Toastr,
} from '@portao3-web/ui';
import { useDrawer, useUser } from '@providers';
import { getWalletReport } from '@services/organization';
import { useInfiniteUserListQuery } from '@services/user/hooks';
import {
  useClearWalletBalanceMutation,
  useDeactivateWalletMutation,
  useInfiniteWalletListQuery,
} from '@services/wallet/hooks';
import { capitalizeFirstLetter } from '@utils/CapitalizeFirstLetter';
import { downloadFile } from '@utils/file';
import { normalizeString } from '@utils/normalizeString';
import { useNavigate } from 'react-router-dom';
import { customerEvents } from '../../shared/lib';
import './Wallets.styles.scss';
import { useWalletColumns } from './components/columns/useWalletColumns';
import {
  breadCrumbElem,
  initialWalletState,
  textDeactivateWallet,
  textResetBalance,
} from './constants/wallets';

export const Wallets = () => {
  const organization = localStorage.getItem('organization');

  const [wallet, setWallet] = useState<WalletDetailsProps>(
    initialWalletState as WalletDetailsProps
  );
  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    action: '',
  });
  const [loadingChangeWallet, setLoadingChangeWallet] = useState(false);
  const [showListExportTypes, setShowListExportTypes] = useState(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const { t } = useTranslation();
  const { openDrawer } = useDrawer();
  const { queryParams } = useQueryParams();
  const user = useUser();
  const navigate = useNavigate();

  const exportTypeMenuRef = useRef<HTMLDivElement | null>(null);

  const getOrderBy = () => {
    const [orderBy, order] = queryParams.get('order')?.split('-') || [
      'createdAt',
      'DESC',
    ];

    return {
      orderBy,
      order,
    };
  };

  const filters = {
    status: queryParams.get('status') as WalletStatus,
    name: normalizeString(queryParams.get('wallet')).toLowerCase(),
    orderBy: getOrderBy().orderBy,
    order: getOrderBy().order,
    ownerIds: queryParams.getAll('responsible'),
  };

  const hasFilters = filters.name || filters.status || filters.ownerIds.length;

  const orderOptions = [
    {
      label: 'A → Z',
      value: 'name-ASC',
    },
    {
      label: 'Z → A',
      value: 'name-DESC',
    },
    {
      label: t('general.newest'),
      value: 'createdAt-DESC',
    },
    {
      label: t('general.oldest'),
      value: 'createdAt-ASC',
    },
  ];

  const statusOptions = [
    {
      label: t('status.active'),
      value: WalletStatus.ACTIVE,
    },
    {
      label: t('status.DEACTIVATED'),
      value: WalletStatus.DEACTIVATED,
    },
  ];

  const dropDownReportList = [
    {
      icon: '',
      label: 'XLSX',
      action: () => {
        handleExportReport(StatementReportFileType.XLSX, filters.status);
      },
    },
    {
      icon: '',
      label: 'CSV',
      action: () => {
        handleExportReport(StatementReportFileType.CSV, filters.status);
      },
    },
  ];

  const { data: userDataList, isLoading: isLoadingResponsible } =
    useInfiniteUserListQuery(
      {
        organization,
        limit: 500,
      },
      user.isAdmin
    );

  const {
    data: walletData,
    isLoading: loadingWallets,
    isError: isErrorWalletData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteWalletListQuery({
    ...filters,
    organizationId: organization,
  });

  const { mutateAsync: clearWallet } = useClearWalletBalanceMutation();
  const { mutateAsync: deactivateWallet } = useDeactivateWalletMutation();

  const { columns, filterColumnsProps } = useWalletColumns({
    setOpenActionModal,
    setWallet,
  });

  const walletDataWithClick = useMemo(() => {
    const currentWallet = walletData?.pages?.[page]?.items || [];

    return currentWallet?.map((wallet) => ({
      ...wallet,
      onRowClick: () => {
        const walletPath =
          wallet.type === WalletType.ORGANIZATION
            ? `/company`
            : `/wallets/${wallet.id}/geral`;

        if (wallet.status === 'DEACTIVATED') return;
        user.setCurrentWallet(wallet);
        navigate(walletPath);
      },
    }));
  }, [walletData, navigate, page, user]);

  const changeWalletState = async () => {
    try {
      let realizedAction = '';
      setLoadingChangeWallet(true);
      if (openActionModal.action === 'RESET') {
        await clearWallet(wallet.id, {
          onSuccess: () => {
            realizedAction = 'zerada';
          },
        });

        customerEvents.walletBalanceUpdated({
          amount: 0,
          id: wallet.id,
          type: null,
        });
      } else if (openActionModal.action === 'DEACTIVATE') {
        await deactivateWallet(wallet.id, {
          onSuccess: () => {
            realizedAction = 'desativada';

            customerEvents.walletDeleted({
              id: wallet?.id,
              name: wallet?.name,
            });
          },
        });
      }
      setOpenActionModal({ open: false, action: '' });
      setLoadingChangeWallet(false);
      Toastr.success(`Carteira ${realizedAction} com sucesso!`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportReport = useCallback(
    async (type: string, status: string) => {
      setShowListExportTypes(false);
      setLoadingReport(true);
      try {
        const { data, headers } = await getWalletReport(type, status);

        const fileName = `wallets_${status}_${dayjs().format(
          'DD-MM-YYYY'
        )}.${type}`;

        downloadFile(type, data, fileName, headers['content-type']);
      } catch {
        Toastr.error(t('error.default'));
      } finally {
        setLoadingReport(false);
      }
    },
    [t]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      showListExportTypes &&
        exportTypeMenuRef.current &&
        !exportTypeMenuRef.current.contains(event.target as Node) &&
        setShowListExportTypes(!showListExportTypes);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showListExportTypes]);

  return (
    <div className="wallet">
      <HeaderPage title="Carteiras" breadcrumbList={breadCrumbElem}>
        <div ref={exportTypeMenuRef}>
          <Button
            size="large"
            variant={user.isAdmin ? 'secondary' : 'primary'}
            onClick={() => {
              setShowListExportTypes(!showListExportTypes);
            }}
            disabled={loadingReport}
            data-testid="export-button"
          >
            {loadingReport && <Spinner />}
            {t('general.export')}
            <Icon size="xlarge">
              <i className="fa-regular fa-download" />
            </Icon>
          </Button>
          {showListExportTypes && <DropDownList list={dropDownReportList} />}
        </div>
        {user.isAdmin && (
          <Button
            onClick={() => {
              openDrawer('edit-wallet');
            }}
            size={'large'}
            data-testid="edit-wallet"
          >
            {t('general.new')}
            <Icon size="large">
              <i className="fa-solid fa-plus" />
            </Icon>
          </Button>
        )}
      </HeaderPage>

      <div className="mb-4 flex flex-wrap gap-3">
        <SearchQuery
          data-testid="search-wallet-input"
          placeholder={t('general.wallet')}
          query="wallet"
        />

        <SelectQuery
          onlyOptions
          query="order"
          title={t('general.order')}
          type="single"
          icon={ICONS.order}
          options={orderOptions}
          required
        />

        {user.isAdmin && (
          <SelectQuery
            query="responsible"
            title={t('general.responsible')}
            isLoading={isLoadingResponsible}
            options={
              userDataList?.map((user) => ({
                label: `${user.firstName} ${user.lastName}`,
                value: user.id,
              })) || []
            }
          />
        )}

        <SelectQuery
          onlyOptions
          query="status"
          title={t('general.status')}
          type="single"
          options={statusOptions}
        />

        <ClearFiltersQuery queries={['status', 'wallet', 'responsible']} />

        <TableColumnsFilter {...filterColumnsProps} />
      </div>

      <TableDemo
        columns={columns}
        data={walletDataWithClick}
        isLoading={loadingWallets || isFetchingNextPage}
        emptyState={
          <>
            {!isErrorWalletData && !hasFilters && (
              <EmptyStateBlock details={emptyWalletsList} />
            )}

            {!isErrorWalletData && hasFilters && (
              <EmptyStateBlock details={emptyWalletsListWithFilter} />
            )}

            {isErrorWalletData && <EmptyStateBlock details={errorState} />}
          </>
        }
        pinLastColumn
      />

      <div className="flex justify-end mt-4">
        <Pagination
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          page={page}
          setPage={setPage}
          pageCount={walletData?.pages.length}
        />
      </div>

      {openActionModal.open && wallet && (
        <ChangeStatusModal
          open={openActionModal.open}
          closeModal={() => setOpenActionModal({ open: false, action: '' })}
          changeStatus={() => changeWalletState()}
          modalText={
            openActionModal.action === 'RESET'
              ? textResetBalance
              : {
                  modalTitle: `Deseja desativar a carteira: ${capitalizeFirstLetter(
                    wallet.name
                  )}?`,
                  ...textDeactivateWallet,
                }
          }
          errorService={false}
          isLoading={loadingChangeWallet}
        />
      )}
    </div>
  );
};
