import { TableHeader, Typography } from '@portao3-web/ui';

export const renderTableHeader = (elem: string, key: number) => {
  return elem === '&nbsp;' ? (
    <TableHeader key={key}>&nbsp;</TableHeader>
  ) : (
    <TableHeader key={key}>
      <Typography tag="p" weight="p2" color="var(--product-neutral-n200)">
        {elem}
      </Typography>
    </TableHeader>
  );
};
