import { StatementReportFileType } from '@enums/StatementReportEnum';

export const downloadFile = (
  type: string,
  content: string,
  fileName: string,
  contentType: string
) => {
  const blob = new Blob([content], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  if (type === StatementReportFileType.XLSX) {
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${content}`;
  }

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
