import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { ContextualErrorType } from '../../../components/alerts';
import { createCardPin } from '../createCardService';
import { cardKeys } from './cardKeys';
import { useTranslation } from 'react-i18next';

interface CreateCardPinMutation {
  pin: string;
  cardId: string;
  walletId: string;
}

export const useCreateCardPinMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [customError, setCustomError] = useState<ContextualErrorType>(null);

  const mutation = useMutation<
    unknown,
    ApiError,
    CreateCardPinMutation,
    unknown
  >({
    mutationFn: ({ cardId, pin, walletId }: CreateCardPinMutation) =>
      createCardPin(pin, cardId, walletId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cardKeys.lists() });
    },
    onError: (error: ApiError) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
