import { ShadCheckbox } from '../../../shadCheckbox';
import {
  ShadCommand,
  ShadCommandGroup,
  ShadCommandItem,
  ShadCommandList,
} from '../../../shadCommand';
import {
  ShadPopover,
  ShadPopoverContent,
  ShadPopoverTrigger,
} from '../../../shadPopover';

interface FilterOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface TableMultiSelectFilterProps {
  title?: string;
  options: FilterOption[];
  selection: string[];
  setSelection: (value: string[]) => void;
  type?: 'multi' | 'single';
  align?: 'center' | 'end' | 'start';
  children: React.ReactNode;
}

export const MultiSelectPopover = ({
  title,
  options,
  selection,
  setSelection,
  children,
  align = 'start',
  type = 'multi',
}: TableMultiSelectFilterProps) => {
  const selectedValues = new Set(selection);

  return (
    <ShadPopover>
      <ShadPopoverTrigger asChild>{children}</ShadPopoverTrigger>

      <ShadPopoverContent className="w-[200px] p-0" align={align}>
        <p className="text-p3 text-neutral-700 px-3 pt-3 pb-2">{title}</p>
        <ShadCommand>
          <ShadCommandList>
            <ShadCommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <ShadCommandItem
                    disabled={option.disabled}
                    data-testid={`select-option-${option.value}`}
                    key={option.value}
                    onSelect={() => {
                      if (type === 'multi') {
                        if (isSelected) {
                          selectedValues.delete(option.value);
                        } else {
                          selectedValues.add(option.value);
                        }
                        const filterValues = Array.from(selectedValues);
                        setSelection(filterValues.length ? filterValues : []);

                        return;
                      }

                      if (type === 'single' && isSelected) {
                        setSelection([]);
                      } else {
                        setSelection([option.value]);
                      }
                    }}
                  >
                    <ShadCheckbox
                      disabled={option.disabled}
                      checked={isSelected}
                      className="mr-2"
                    />
                    <span className="text-p3">{option.label}</span>
                  </ShadCommandItem>
                );
              })}
            </ShadCommandGroup>
          </ShadCommandList>
        </ShadCommand>
      </ShadPopoverContent>
    </ShadPopover>
  );
};
