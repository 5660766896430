import { environment } from '@environments/environment';
import api from '@services/api';
import { CardProps } from '@interfaces/Card.interfaces';
import { organization, realm } from '@constants/endpoints';
import { CancelTokenSource } from 'axios';

export const getCardList = async (
  limitPerPage: number,
  nextPage: string,
  walletId: string | undefined,
  source?: CancelTokenSource
): Promise<CardProps> => {
  const nextPageElement = nextPage ? `&next=${nextPage}` : '';
  const isWallet = walletId || 'default';
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWallet}/cards?limit=${limitPerPage}${nextPageElement}`,
    {
      cancelToken: source?.token,
    }
  );
  const { data } = response;

  return data;
};

export const blockOrUnblockCard = async (
  id: number,
  walletId: string | undefined,
  action: string //action should be: block or unblock
): Promise<[]> => {
  const isWallet = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWallet}/cards/${id}/${action}`
  );
  const { data } = response;

  return data;
};

export const archiveCard = async (
  id: number,
  walletId: string | undefined
): Promise<[]> => {
  const isWallet = walletId || 'default';
  const response = await api.post(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}/wallets/${isWallet}/cards/${id}/archive`
  );
  const { data } = response;

  return data;
};
