import { CardTypeEnum } from '@enums/Card.enum';
import { trackCustomerIoEvent } from './utils';

import { WalletBalanceCategory } from '@enums/WalletEnum';

export const customerEvents = {
  userLoggedIn: (traits: { type: string; mfa: boolean }) =>
    trackCustomerIoEvent(`User Logged In`, traits),
  userLoggedOut: () => trackCustomerIoEvent(`User Logged Out`),

  organizationSelected: (traits: { id: string; name: string }) =>
    trackCustomerIoEvent(`Organization Selected`, traits),

  walletCreated: (traits: {
    name: string;
    users: number;
    pix: boolean;
    bank_slip: boolean;
    cards: boolean;
  }) => trackCustomerIoEvent(`Wallet Created`, traits),
  walletUpdated: (traits: {
    id: string;
    name: string;
    users: number;
    pix: boolean;
    bank_slip: boolean;
    cards: boolean;
  }) => trackCustomerIoEvent(`Wallet Updated`, traits),
  walletDeleted: (traits: { id: string; name: string }) =>
    trackCustomerIoEvent(`Wallet Deleted`, traits),
  walletBalanceUpdated: (traits: {
    id: string;
    amount: number;
    type: WalletBalanceCategory | null;
  }) => trackCustomerIoEvent(`Wallet Balance Updated`, traits),

  customerCreated: (traits: { document: string }) =>
    trackCustomerIoEvent(`Customer Created`, traits),
  customerUpdated: (traits: { document: string }) =>
    trackCustomerIoEvent(`Customer Updated`, traits),
  customerDeleted: (traits: { document: string }) =>
    trackCustomerIoEvent(`Customer Deleted`, traits),

  userCreated: (traits: { email: string }) =>
    trackCustomerIoEvent(`User Created`, traits),
  userUpdated: (traits: { id: string; email: string }) =>
    trackCustomerIoEvent(`User Updated`, traits),
  userDeactivated: (traits: { id: string; email: string }) =>
    trackCustomerIoEvent(`User Deactivated`, traits),

  cardCreated: (traits: {
    cardName: string;
    cardType: CardTypeEnum;
    walletId: string;
    cardId: string;
  }) => trackCustomerIoEvent(`Card Created`, traits),
  cardUpdated: (traits: { id: string; name: string; type: CardTypeEnum }) =>
    trackCustomerIoEvent(`Card Updated`, traits),
  cardUnblocked: (traits: { id: string; name: string; type: CardTypeEnum }) =>
    trackCustomerIoEvent(`Card Unblocked`, traits),
  cardBlocked: (traits: { id: string; name: string; type: CardTypeEnum }) =>
    trackCustomerIoEvent(`Card Blocked`, traits),
  cardViewed: (traits: { id: string; name: string; type: CardTypeEnum }) =>
    trackCustomerIoEvent(`Card Viewed`, traits),
  cardDetails: (traits: { id: string; name: string; type: CardTypeEnum }) =>
    trackCustomerIoEvent(`Card Details`, traits),

  memberGetMemberLinkCreated: () => trackCustomerIoEvent(`MGM Link Created`),
  memberGetMemberLinkCopied: (traits: { link: string }) =>
    trackCustomerIoEvent(`MGM Link Copied`, traits),

  boletoConfirmed: (traits: {
    walletId: string;
    txnOriginalAmount: number;
    dueDate: string;
    txnUpdatedAmount: number;
  }) => trackCustomerIoEvent(`Boleto Confirmed`, traits),

  boletoInitiated: (traits: {
    walletId: string;
    txnOriginalAmount: number;
    dueDate: string;
    txnUpdatedAmount: number;
  }) => trackCustomerIoEvent(`Boleto Initiated`, traits),

  boletoScheduleConfirmed: (traits: {
    walletId: string;
    scheduleId: string;
    originalAmount: number;
    updatedAmount: number;
    dueDate: Date | null | string;
    scheduleDate: Date | string;
  }) => trackCustomerIoEvent(`Boleto Schedule Confirmed`, traits),

  boletoScheduleReceiptDownloaded: (traits: {
    walletId: string;
    scheduleId: string;
    originalAmount: number;
    updatedAmount: number;
    dueDate: Date | null;
    scheduleDate: Date | string;
  }) => trackCustomerIoEvent(`Boleto Schedule Receipt Downloaded`, traits),

  pixConfirmed: (traits: {
    type: string;
    amount: number | string;
    dictType?: string;
    walletId: string;
  }) => trackCustomerIoEvent(`Pix Confirmed`, traits),
  pixInitiated: (traits: {
    type: string;
    amount: number;
    dictType?: string;
    walletId: string;
  }) => trackCustomerIoEvent(`Pix Initiated`, traits),
  pixKeysCreated: (traits: { type: string; walletId: string }) =>
    trackCustomerIoEvent(`Pix Keys Created`, traits),
  pixKeysViewed: (traits: { walletId: string }) =>
    trackCustomerIoEvent(`Pix Keys Viewed`, traits),
  pixKeysDeleted: (traits: {
    walletId: string;
    pixId: string;
    pixKeyType: string;
  }) => trackCustomerIoEvent(`Pix Keys Deleted`, traits),
  pixScheduledConfirmed: (traits: {
    walletId: string;
    scheduleId: string | undefined;
    originalAmount: number;
    updatedAmount: number;
    dueDate: Date | string | null;
    pixType: string;
    initiationType: string;
    qrCodeType?: string;
    dictType?: string;
    scheduleDate: Date | string;
  }) => trackCustomerIoEvent(`Pix Scheduled Confirmed`, traits),
  pixScheduleReceiptDownloaded: (traits: {
    walletId: string;
    scheduleId: string;
    originalAmount: number;
    updatedAmount: number;
    dueDate: Date | string | null;
    pixType: string;
    scheduleDate: Date | string;
  }) => trackCustomerIoEvent(`Pix Schedule Receipt Downloaded`, traits),

  futureReleasesCanceled: (traits: {
    scheduleId: string;
    walletId: string;
    originalAmount: number | null;
    updatedAmount: number;
    dueDate: Date | null | string;
    scheduleDate: Date | null | string;
    type: string;
  }) => trackCustomerIoEvent(`Future Releases Canceled`, traits),

  walletBalanceScheduled: (traits: {
    walletId: string;
    amount: number;
    paymentDate: Date;
    scheduleId: string;
  }) => trackCustomerIoEvent(`Wallet Balance Scheduled`, traits),

  walletBalanceScheduleReceiptDownloaded: (traits: {
    walletId: string;
    amount: number;
    paymentDate: Date;
    scheduleId: string;
  }) =>
    trackCustomerIoEvent(`Wallet Balance Schedule Receipt Downloaded`, traits),

  transactionDetailsUpdated: (traits: {
    transactionId: string;
    amount: number;
    transactionStatus: number;
    type: string;
  }) => trackCustomerIoEvent(`Transaction Details Updated`, traits),
  transactionDetailsViewed: (traits: {
    transactionId: string;
    amount: number;
    transactionStatus: number;
    type: string;
  }) => trackCustomerIoEvent(`Transaction Details Viewed`, traits),

  pinCreated: () => trackCustomerIoEvent(`Pin Created`),
  mfaCreated: () => trackCustomerIoEvent(`MFA Created`),
  securityAlertSeen: (traits: { hasMfa: boolean; hasPin: boolean }) =>
    trackCustomerIoEvent(`Security Alert Seen`, traits),
};
