import { useTranslation } from 'react-i18next';

import { FutureTransactions } from '@components';
import { useQueryParams } from '@hooks/useQueryParams';
import { Tabs } from '@portao3-web/ui';
import { HeaderPage } from '../../shared/components/headerPage';
import { StatementBody } from '../statement/components/StatementBody';
import dayjs from 'dayjs';

export const Transactions = () => {
  const { t } = useTranslation();
  const { queryParams, setQueryParams } = useQueryParams();

  const tabs = [
    {
      id: '0',
      label: t('general.statement'),
      content: <StatementBody isGeneral />,
    },
    {
      id: '1',
      label: t('general.future-transactions'),
      content: <FutureTransactions isGeneral />,
    },
  ];

  const handleTabChange = (tab: string) => {
    const activeTab = tabs.find((t) => t.id === tab);
    setQueryParams({
      tab: activeTab?.id,
      transactionTypes: undefined,
      financialImpact: undefined,
      search: undefined,
      status: undefined,
    });

    if (activeTab?.id === '1') {
      setQueryParams({
        startDate: dayjs().startOf('day').subtract(3, 'hours').toISOString(),
      });
      setQueryParams({
        endDate: dayjs()
          .add(30, 'day')
          .endOf('day')
          .subtract(3, 'hours')
          .toISOString(),
      });
    } else {
      setQueryParams({
        startDate: dayjs()
          .subtract(30, 'day')
          .startOf('day')
          .subtract(3, 'hours')
          .toISOString(),
      });
      setQueryParams({
        endDate: dayjs().endOf('day').subtract(3, 'hours').toISOString(),
      });
    }
  };

  return (
    <>
      <HeaderPage
        title={t('transactions.page-title')}
        subTitle={t('transactions.page-subtitle')}
      />

      <Tabs
        tabs={tabs}
        start={Number(queryParams.get('tab'))}
        onTabChange={handleTabChange}
      />
    </>
  );
};
