import { ICONS } from '@constants/icons';
import { Icon } from '@portao3-web/ui';
import clsx from 'clsx';

interface PasswordRuleProps {
  text: string;
  isValid: boolean;
  isError: boolean;
}

export const PasswordRule = ({ isValid, text, isError }: PasswordRuleProps) => {
  return (
    <div className="flex items-center">
      <Icon size="large">
        <i
          data-testid="password-rule-icon"
          className={clsx({
            'fa-regular': true,
            'fa-circle text-neutral-80': !isValid && !isError,
            [`${ICONS['circle-check']} text-success-500`]: isValid,
            [`${ICONS['circle-x']} text-danger-500`]: isError,
          })}
        />
      </Icon>

      <p
        className={clsx({
          'text-p2 ': true,
          'text-neutral-80': !isValid && !isError,
          'text-success-500': isValid,
          'text-danger-500': isError,
        })}
      >
        {text}
      </p>
    </div>
  );
};
