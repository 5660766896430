export enum TransactionsStatus {
  REQUESTED = 'REQUESTED',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  CONFIRMED = 'CONFIRMED',
}

export const viewTransactionsStatus = {
  REQUESTED: 'REQUESTED',
  CANCELLED: 'Cancelado',
  PAID: 'Pago',
  FAILED: 'Falha',
  REFUNDED: 'Estornado',
  CONFIRMED: 'Confirmado',
};

export enum TransactionType {
  CARD = 'CARD',
  PIX = 'PIX',
  BOLETO = 'BOLETO',
  FEE = 'FEE',
  P2P = 'P2P',
}

export enum PixPaymentType {
  QR_CODE_COB = 'QR_CODE_COB',
  QR_CODE_COBV = 'QR_CODE_COBV',
  QR_CODE_STATIC = 'QR_CODE_STATIC',
}

export enum BankSlipPaymentType {
  GENERIC = 'GENERIC',
  UTILITY = 'UTILITY',
}
