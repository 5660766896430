export enum TypePayment {
  PIX = 'PIX',
  BANK_SLIP = 'BANK_SLIP',
  CREDIT_CARD = 'CREDIT_CARD',
  P2P = 'P2P',
  BILLING = 'BILLING',
  FEE = 'FEE',
}

export const typePaymentOptions = {
  [TypePayment.PIX]: 'PIX',
  [TypePayment.BANK_SLIP]: 'Boleto',
  [TypePayment.CREDIT_CARD]: 'Cartão de crédito',
  [TypePayment.P2P]: 'Transferência interna',
  [TypePayment.BILLING]: 'Fatura',
  [TypePayment.FEE]: 'Taxa',
};
