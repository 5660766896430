import { Icon, Typography } from '..';
import { PaymentMethodProps } from './PaymentMethod.interface';
import './PaymentMethod.styles.scss';

export const PaymentMethod = ({
  label,
  children,
  onClick,
  disabled = false,
}: PaymentMethodProps) => {
  const isDisabled = disabled ? 'payment--disabled' : '';

  return (
    <div
      className={`payment ${isDisabled}`}
      onClick={onClick}
      data-testid={label.toLowerCase()}
    >
      <Icon size="xlarge">{children}</Icon>
      <Typography tag="p">{label}</Typography>
    </div>
  );
};
