export const MasterCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
    >
      <circle cx="18" cy="8" r="8" fill="#A9A8CB" />
      <circle opacity="0.95" cx="8.0957" cy="8" r="8" fill="#6967A5" />
    </svg>
  );
};
