import { useEffect, useRef } from 'react';

import { NumberFieldMask } from '@interfaces/CustomFields.interfaces';
import { ErrorMessage, Input, Select } from '@portao3-web/ui';
import { useDrawerEditCustomField } from '../../contexts/DrawerEditCustomFieldContext';
import '../customFieldTypeSelection/CustomFieldTypeSelection.styles.scss';

export const NumberField = () => {
  const options = Object.entries(NumberFieldMask).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const isMounted = useRef(false);

  const form = useDrawerEditCustomField();
  const {
    resetField,
    register,
    unregister,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    return () => {
      resetField('label');
      resetField('externalIdentifier');
      unregister('mask');
    };
  }, [resetField, unregister]);

  return (
    <div className="custom-field-config--grid">
      <Input
        name="label"
        label="Nome do campo"
        placeholder="Ex: Nome do fornecedor"
        register={register}
        validationSchema={{ required: 'Nome do campo é obrigatório' }}
        error={!!errors.label?.message}
      >
        {errors.label?.message && (
          <ErrorMessage message={errors.label.message} />
        )}
      </Input>
      <Select
        name="mask"
        label="Simbolo e máscara"
        options={options}
        form={form}
        validationSchema={{ required: 'error.required-mask' }}
        defaultValue={options[0]?.value}
      />

      <Input
        name="externalIdentifier"
        label="Identificador externo (Opcional)"
        placeholder="Ex: cep-clientes"
        register={register}
      />
    </div>
  );
};
