import { CustomFieldView } from './CustomFields.interfaces';

export interface PixPaymentResponse {
  receipt: Receipt;
  environment: string;
  accountId: string;
  walletId: string;
  txnCurrency: string;
  txnAllowAmountChange: boolean;
  txnOriginalAmount: number;
  txnDiscountAmount: number;
  txnFineAmount: number;
  txnInterestAmount: number;
  txnPurchaseAmount: number;
  txnWithdrawalAmount: number;
  txnUpdatedAmount: number;
  creditParty: CreditParty;
  debitParty: DebitParty;
  confirmedAmount?: number;
  description: string;
  initiationType: string;
  status: string;
  transactionType: string;
  _id: string;
  additionalInfo: unknown[];
  expiresAt?: string;
  dueDate?: string;
  createdAt: string;
  updatedAt: string;
  qrCodeType?: string;
  __v: number;
  customFields?: CustomFieldView[];
}

export interface CreditParty {
  bankIspb: string;
  document: string;
  name: string;
  _id: string;
  key?: string;
  bankCode?: string;
  branch?: string;
  bankAccount?: string;
  street?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export interface DebitParty {
  bankIspb: string;
  accountType: string;
  accountNumber?: string;
  document: string;
  branch?: string;
  name: string;
  _id: string;
}

export interface Receipt {
  endToEndId: string;
}

export enum PixKeyType {
  EVP = 'EVP',
  // PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  CNPJ = 'CNPJ',
}

export interface PixKey {
  id: string;
  type: PixKeyType;
  value: string;
}
