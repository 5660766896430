import { brazilianRealCurrency } from './formatCurrencyNumber';

export const cpfMask = (value: string) => {
  const masked = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return masked;
};

export const cpfMaskHashed = (value: string) => {
  const masked = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**');
  return masked;
};

export const cnpjMask = (value: string | undefined) => {
  if (!value) return '';

  const masked = value
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4')
    .replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');

  return masked;
};

export const bankSlipMask = (value: string | undefined) => {
  if (!value) return '';

  const clearedValue = value.replace(/\D/g, '');

  if (clearedValue.length === 47 || clearedValue.length === 48) {
    const formattedValue = clearedValue
      .replace(/(\d{5})(\d)/, '$1.$2')
      .replace(/(\d{5})(\d)/, '$1 $2')
      .replace(/(\d{6})(\d)/, '$1.$2')
      .replace(/(\d{6})(\d)/, '$1 $2')
      .replace(/(\d{6})(\d)/, '$1.$2')
      .replace(/(\d{1})(\d{14})$/, '$1 $2');

    return formattedValue;
  }

  return clearedValue;
};

export const removeBankSlipMask = (value: string) => {
  if (!value) return '';

  return value.replace(/\D/g, '');
};

export const cnpjMaskHashed = (value: string | undefined) => {
  if (!value) return '';

  const masked = value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '***.$2.$3/$4-**'
  );
  return masked;
};

export const cepMask = (value: string) => {
  const masked = value.replace(/(\d{5})(\d{3})/, '$1-$2');
  return masked;
};

export const documentMask = (value: string | undefined) => {
  if (!value) return '';

  const clearedValue = value.replace(/\D/g, '');

  if (clearedValue.length === 14) {
    return cnpjMask(clearedValue);
  }

  if (clearedValue.length === 11) {
    return cpfMask(clearedValue);
  }

  return value;
};

export const documentMaskHashed = (value: string | undefined) => {
  if (!value) return '';

  const clearedValue = value.replace(/\D/g, '');

  if (clearedValue.length === 14) {
    return cnpjMaskHashed(clearedValue);
  }

  if (clearedValue.length === 11) {
    return cpfMaskHashed(clearedValue);
  }

  return value;
};

export const phoneMask = (value: string | undefined) => {
  if (!value) return '';

  const cleanedValue = value.replace(/[^\d]/g, '');

  if (cleanedValue.length <= 10) {
    return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else {
    return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
};

// TODO improve to library or use a better mask
export const phoneDDIMask = (value: string) => {
  return value.replace(/[^+\d]/g, '');
};

export const moneyMask = (value: string | number | null | undefined) => {
  if (!value) return brazilianRealCurrency.format(0);

  if (typeof value === 'number') {
    return brazilianRealCurrency.format(value / 100);
  }

  try {
    const money = Number(value?.replace(/\D/g, '')) / 100;
    return brazilianRealCurrency.format(money);
  } catch (error) {
    console.error('Error on moneyMask', error);
    return brazilianRealCurrency.format(0);
  }
};

export const removeMoneyMask = (value: string) => {
  const replacedValue = +value.replace(/\D/g, '');

  return replacedValue.toString();
};

export const percentMask = (value: string) => {
  const stringValue = value ? value : '';
  const percent = +stringValue.toString().replace(/\D/g, '');

  if (+percent > 10000) {
    return `100,00`;
  }

  return percent
    .toString()
    .padStart(3, '0')
    .replace(/^(\d+)(\d{2})$/, '$1,$2');
};

export const onlyNumbersMask = (value: string) => {
  return value.replace(/\D/g, '');
};
