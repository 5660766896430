import { Icon, Typography } from '@portao3-web/ui';
import { useMemo } from 'react';

import { WEAK_LENGTH_PASSWORD } from '../../constants/passwordLength';
import { regex } from '../../constants/regex';
import { StrongPasswordProps } from './StrongPassword.interface';
import './StrongPassword.styles.scss';

export const StrongPassword = ({ password }: StrongPasswordProps) => {
  const numbers = useMemo(() => regex.number, []);
  const letters = useMemo(() => regex.letters, []);
  const specialChars = useMemo(() => regex.specialCharacters, []);

  const numberAndLetter = () => {
    return numbers.test(password) && letters.test(password);
  };

  const specialCharacters = () => {
    return specialChars.test(password);
  };

  return (
    <div className="strong_password">
      <div className="suggestion_password">
        <div
          className={`suggestion_password_item ${
            password.length >= WEAK_LENGTH_PASSWORD ? 'valid' : ''
          }`}
        >
          <Icon size="large">
            {password.length >= WEAK_LENGTH_PASSWORD ? (
              <i className="fa-regular fa-circle-check"></i>
            ) : (
              <i className="fa-regular fa-circle"></i>
            )}
          </Icon>
          <Typography tag="p">Senha deve ter mais de 8 caracteres</Typography>
        </div>
        <div
          className={`suggestion_password_item ${
            numberAndLetter() ? 'valid' : ''
          }`}
        >
          <Icon size="large">
            {numberAndLetter() ? (
              <i className="fa-regular fa-circle-check"></i>
            ) : (
              <i className="fa-regular fa-circle"></i>
            )}
          </Icon>
          <Typography tag="p">
            Deve conter números e letras maiúsculas e minúsculas
          </Typography>
        </div>
        <div
          className={`suggestion_password_item ${
            specialCharacters() ? 'valid' : ''
          }`}
        >
          <Icon size="large">
            {specialCharacters() ? (
              <i className="fa-regular fa-circle-check"></i>
            ) : (
              <i className="fa-regular fa-circle"></i>
            )}
          </Icon>
          <Typography tag="p">
            Deve conter caracteres especiais (Ex: @, $, #)
          </Typography>
        </div>
      </div>
    </div>
  );
};
