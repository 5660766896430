import { useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { RealmUser } from '@interfaces/User.interfaces';
import { findUserById } from '@services/user';
import { userKeys } from './userKeys';

export const useUserQuery = (userId: string) => {
  return useQuery<RealmUser, ApiError, RealmUser, unknown[]>({
    queryKey: userKeys.detail(userId),
    queryFn: () => findUserById(userId),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    retry: 1,
    enabled: !!userId,
  });
};
