import { Button, Typography } from '@portao3-web/ui';
import { useNavigate } from 'react-router-dom';

import './ErrorPage.styles.scss';
import ErrorImage from '@assets/img/error-page.svg';
import UnauthorizedImg from '@assets/img/unauthorized.svg';

const Error = () => {
  const history = useNavigate();

  return (
    <div className="error_mask">
      <div className="error_page">
        <div className="logo" />
        <div className="box">
          <Typography tag="p" weight="p1" color="var(--product-primary-p500)">
            Erro 404
          </Typography>
          <Typography tag="h2" color="var(--product-neutral-n500)">
            Página não encontrada :(
          </Typography>

          <Typography tag="p" weight="p2" color="var(--product-neutral-n80)">
            Lamentamos, mas a página que você tentou acessar não existe ou foi
            movida. Pode ter havido um erro de digitação no endereço ou o link
            pode estar desatualizado.
          </Typography>

          <div className="actions">
            <Button variant="secondary" size="large">
              Suporte
            </Button>

            <Button variant="primary" size="large" onClick={() => history('/')}>
              Voltar para a home
            </Button>
          </div>
        </div>
        <img src={ErrorImage} alt="Error page" className="error_page_image" />
      </div>
    </div>
  );
};

const Unauthorized = () => {
  const history = useNavigate();

  return (
    <div className="error_page">
      <div className="logo" />
      <div className="box">
        <Typography tag="p" weight="p1" color="var(--product-primary-p500)">
          Erro 403
        </Typography>
        <Typography tag="h2" color="var(--product-neutral-n500)">
          Acesso negado!
        </Typography>

        <Typography tag="p" weight="p2" color="var(--product-neutral-n80)">
          Desculpe, você não tem permissão para acessar esta página ou recurso.
          Por favor, verifique suas credenciais ou entre em contato com o
          administrador.
        </Typography>

        <div className="actions">
          <Button variant="secondary" size="large">
            Suporte
          </Button>

          <Button variant="primary" size="large" onClick={() => history('/')}>
            Voltar para a home
          </Button>
        </div>
      </div>
      <img
        src={UnauthorizedImg}
        alt="Unauthorized page"
        className="error_page_image"
      />
    </div>
  );
};

const Unavailable = () => {
  const history = useNavigate();

  return (
    <div className="error_mask">
      <div className="error_page">
        <div className="logo" />
        <div className="box">
          <Typography tag="p" weight="p1" color="var(--product-primary-p500)">
            Erro 500 ou 404
          </Typography>
          <Typography tag="h2" color="var(--product-neutral-n500)">
            Serviço Indisponível
          </Typography>

          <Typography tag="p" weight="p2" color="var(--product-neutral-n80)">
            Desculpe, nossos serviços estão indisponíveis no momento. Tente
            novamente mais tarde!
          </Typography>

          <div className="actions">
            <Button variant="secondary" size="large">
              Suporte
            </Button>

            <Button variant="primary" size="large" onClick={() => history('/')}>
              Voltar para a home
            </Button>
          </div>
        </div>
        <img
          src={UnauthorizedImg}
          alt="Unauthorized page"
          className="error_page_image"
        />
      </div>
    </div>
  );
};

const ContextualError = () => {
  const history = useNavigate();

  return (
    <div className="error_page">
      <div className="logo" />
      <div className="box">
        <Typography tag="p" weight="p1" color="var(--product-primary-p500)">
          Erro 500
        </Typography>
        <Typography tag="h2" color="var(--product-neutral-n500)">
          Serviço Indisponível
        </Typography>

        <Typography tag="p" weight="p2" color="var(--product-neutral-n80)">
          Desculpe, nossos serviços estão indisponíveis no momento. Tente
          novamente mais tarde!
        </Typography>

        <div className="actions">
          <Button variant="secondary" size="large">
            Suporte
          </Button>

          <Button variant="primary" size="large" onClick={() => history('/')}>
            Voltar para a home
          </Button>
        </div>
      </div>
      <img
        src={UnauthorizedImg}
        alt="Unauthorized page"
        className="error_page_image"
      />
    </div>
  );
};

export const ErrorPage = {
  Error: Error,
  Unauthorized: Unauthorized,
  Unavailable: Unavailable,
  ContextualError: ContextualError,
};
