import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ICONS } from '@constants/icons';
import {
  TransactionScheduleStatus,
  TransactionScheduleType,
} from '@enums/SchedulePayment.enum';
import { useFilterColumns } from '@hooks/useFilterColumns';
import {
  Avatar,
  Button,
  Status,
  TableIcon,
  TableItemTitleSub,
} from '@portao3-web/ui';
import { calcCurrencyValue } from '@utils/formatCurrencyNumber';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { WalletType } from '@interfaces/Wallets.interfaces';
import utc from 'dayjs/plugin/utc';
import { TransactionScheduleProps } from '../interface/scheduling.interface';

dayjs.extend(utc);

interface useFutureTransactionsColumnsProps {
  openDeleteModal: (schedule: TransactionScheduleProps) => void;
  isGeneral?: boolean;
}

enum StatusComponent {
  ACTIVE = 'ACTIVE',
  CREATING = 'CREATING',
  APPROVED = 'APPROVED',
  REPROVED = 'REPROVED',
  BLOCKED = 'BLOCKED',
  ARCHIVED = 'ARCHIVED',
  DEACTIVATED = 'DEACTIVATED',
}

const walletTypes = {
  [WalletType.ORGANIZATION]: 'Empresarial',
  [WalletType.PERSONAL]: 'Individual',
  [WalletType.SHARED]: 'Compartilhada',
};

export const useFutureTransactionsColumns = ({
  openDeleteModal,
  isGeneral,
}: useFutureTransactionsColumnsProps) => {
  const { t } = useTranslation();

  const statusMap = {
    [TransactionScheduleStatus.SCHEDULED]: StatusComponent.CREATING,
    [TransactionScheduleStatus.CANCELLED]: StatusComponent.REPROVED,
    [TransactionScheduleStatus.DONE]: StatusComponent.APPROVED,
    [TransactionScheduleStatus.FAILED]: StatusComponent.REPROVED,
    [TransactionScheduleStatus.PROCESSING]: StatusComponent.CREATING,
  };

  const getIsNullTransaction = (status: TransactionScheduleStatus) => {
    return (
      status === TransactionScheduleStatus.FAILED ||
      status === TransactionScheduleStatus.CANCELLED
    );
  };

  const getIcon = (type: TransactionScheduleType) => {
    if (TransactionScheduleType.BANK_SLIP_PAYMENT === type) {
      return ICONS.barcode;
    }

    if (TransactionScheduleType.P2P_TRANSFER === type) {
      return ICONS.exchange;
    }

    return ICONS.pix;
  };

  const wallet: ColumnDef<TransactionScheduleProps>[] = isGeneral
    ? [
        {
          accessorKey: 'wallet',
          header: t('general.wallet'),
          cell: ({ row }) => (
            <TableItemTitleSub
              title={row.original.walletName}
              subtitle={walletTypes[row.original.walletType as WalletType]}
              className={clsx({
                'line-through': getIsNullTransaction(row.original.status),
              })}
            />
          ),
        },
      ]
    : [];

  const columns: ColumnDef<TransactionScheduleProps>[] = [
    {
      accessorKey: 'effectiveAt',
      header: t('general.date'),
      cell: ({ row }) => (
        <p
          className={clsx({
            'text-p2': true,
            'line-through': getIsNullTransaction(row.original.status),
          })}
        >
          {dayjs(row.original.scheduledAt).utc(false).format('DD/MM/YYYY')}
        </p>
      ),
      meta: {
        width: 120,
        filter: {
          disabled: true,
        },
      },
    },
    {
      id: 'transactionType',
      header: '',
      cell: ({ row }) => {
        return <TableIcon icon={getIcon(row.original.type)} />;
      },
    },
    {
      id: 'transactionName',
      header: t('general.description'),
      cell: ({ row }) => {
        return (
          <TableItemTitleSub
            title={row.original.description}
            className={clsx({
              'line-through': getIsNullTransaction(row.original.status),
            })}
          />
        );
      },
      meta: {
        width: 'auto',
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'avatar-responsible',
      header: '',
      cell: ({ row }) => {
        return (
          <Avatar
            className="pl-4"
            imageSrc=""
            userName={
              typeof row.original?.createdByName === 'string'
                ? row.original?.createdByName
                : ''
            }
            size="small"
          />
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'responsible',
      header: t('general.realized-by'),
      cell: ({ row }) => {
        return (
          <p
            className={clsx({
              'text-p2': true,
              'line-through': getIsNullTransaction(row.original.status),
            })}
          >
            {typeof row.original?.createdByName === 'string'
              ? row.original?.createdByName
              : ''}
          </p>
        );
      },
      meta: {
        width: 160,
      },
    },
    ...wallet,
    {
      accessorKey: 'status',
      header: t('general.status'),
      cell: ({ row }) => {
        return (
          <Status status={statusMap[row.original.status]}>
            {t(`enum.TransactionScheduleStatus.${row.original.status}`)}
          </Status>
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
    {
      accessorKey: 'amount',
      header: t('general.value'),
      cell: ({ row }) => {
        return (
          <span
            className={clsx({
              'text-danger-500': true,
              'line-through': getIsNullTransaction(row.original.status),
            })}
            style={{ font: 'inherit' }}
          >
            {calcCurrencyValue(row.original.amount, 'BRL')}
          </span>
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
    {
      id: 'actions',
      header: t('general.actions'),
      cell: ({ row }) => {
        const disabled = getIsNullTransaction(row.original.status);

        return (
          <div className="flex justify-center">
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="w-6"
              data-testid="cancel-schedule"
              disabled={disabled}
              onClick={(event) => {
                event.stopPropagation();
                openDeleteModal(row.original);
              }}
            >
              <i
                className={clsx({
                  [`${ICONS.trash} text-p3 text-neutral-100`]: true,
                  'text-neutral-50': disabled,
                })}
              />
            </Button>
          </div>
        );
      },
      meta: {
        filter: {
          disabled: true,
        },
      },
    },
  ];

  return useFilterColumns(
    isGeneral
      ? 'general-future-transactions-columns'
      : 'wallet-future-transactions-columns',
    columns
  );
};
