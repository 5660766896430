import { useEffect, useMemo, useRef } from 'react';

import { Typography } from '@portao3-web/ui';
import { Alert } from '../alert';
import { ContextualErrorProps } from './ContextualError.interface';

export const ContextualError = ({ error }: ContextualErrorProps) => {
  const errorRef = useRef<HTMLDivElement>(null);

  const formatedTraceId = useMemo(() => {
    if (error?.traceId) {
      const parts = error.traceId.split('-');
      const lastPart = parts[parts.length - 1];
      const initialBlocks = parts.slice(0, -1).join('-');

      const matches = lastPart.match(/.{1,5}/g);
      const lastPartMatches = matches ? matches.join('/') : '';

      const formatttedString = `${initialBlocks}-${lastPartMatches}`;

      return formatttedString;
    }
    return '';
  }, [error?.traceId]);

  useEffect(() => {
    if (errorRef?.current) {
      errorRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [error]);

  return error ? (
    <Alert status="error">
      <div className="flex w-full flex-col gap-2" ref={errorRef}>
        {typeof error.message === 'string' ? (
          <Typography
            dataTestId="alert-error-message"
            tag="p"
            weight="p2"
            color="var(--product-neutral-n500)"
          >
            {error.message}
          </Typography>
        ) : (
          error.message
        )}

        {error.traceId && (
          <Typography tag="p" weight="p4" color="var(--product-neutral-n80)">
            ID: {formatedTraceId}
          </Typography>
        )}
      </div>
    </Alert>
  ) : null;
};
