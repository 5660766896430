// {{platformUrl}}/realms/:realmId/organizations/:organizationId/wallets/:walletId/transfers/schedule

import { organization, realm } from '@constants/endpoints';
import { WalletBalanceCategory } from '@enums/WalletEnum';
import { environment } from '@environments/environment';
import { CreateTransactionSchedule } from '@interfaces/Schedule.interfaces';
import api from '@services/api';

export interface ScheduleTransferWalletP2PRequest {
  amount: number;
  processingDate: Date | string;
  category: WalletBalanceCategory;
  description: string;
  walletId: string;
  pin?: string;
}

export const scheduleWalletTransferP2P = async (
  props: ScheduleTransferWalletP2PRequest
): Promise<CreateTransactionSchedule> => {
  const { walletId, pin, ...params } = props;
  const response = await api.post(
    `${environment.platformBaseUrl}${realm()}/${organization()}/wallets/${walletId}/transfers/schedule`,
    params,
    { headers: { pin } }
  );

  const { data } = response;

  return data;
};
