import {
  WalletDetailsProps,
  WalletStatus,
  WalletType,
} from '@interfaces/Wallets.interfaces';

export const textDeactivateWallet = {
  disclaimer: `Caso você opte por prosseguir, todas as transações pendentes serão interrompidas,
    os cartões vinculados a ela serão cancelados e você precisará de um nova carteira para transacionar.`,
  warn: {
    title: 'Atenção, esta será uma ação permanente!',
    description:
      'Para desativar esta carteira, basta confirmar sua decisão no botão abaixo.',
  },
  confirmation: 'Deseja mesmo desativar?',
  actionButtonLabel: 'Sim, desativar',
};

export const textResetBalance = {
  modalTitle: 'Deseja zerar o saldo da carteira?',
  disclaimer: `Caso você opte por prosseguir, todo o saldo presente nas categorias desta carteira
    serão movidos para a conta principal da organização.`,
  warn: {
    title: 'Atenção, esta será uma ação permanente!',
    description:
      'Para zerar esta carteira, basta confirmar sua decisão no botão abaixo.',
  },
  confirmation: 'Deseja mesmo zerar?',
  actionButtonLabel: 'Sim, zerar',
};

export const INITIAL_BREADCRUMB_PATH_LENGTH = 1;

export const initialWalletState = {
  id: '',
  name: '',
  realm: '',
  type: '',
  owner: {
    id: '',
    type: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  shared: [],
  settings: {
    payment: {
      pix: false,
      bankSlip: false,
      card: false,
    },
    notification: {
      balanceLimit: {
        amount: 0,
        email: false,
        isActive: false,
      },
    },
  },
  customFields: [],
  currency: '',
  organization: '',
  createdAt: new Date(),
  status: '',
  balances: [],
  totalBalance: -1,
};

export const initialWalletsList: WalletDetailsProps[] = [
  {
    id: '1',
    name: '',
    realm: '',
    type: WalletType.SHARED,
    shared: [],
    settings: {
      payment: {
        pix: false,
        bankSlip: false,
        card: false,
      },
      notification: {
        balanceLimit: {
          amount: 0,
          email: false,
          isActive: false,
        },
      },
    },
    customFields: [],
    currency: '',
    organization: '',
    status: WalletStatus.ACTIVE,
    balances: [],
    totalBalance: -1,
    createdAt: new Date(),
  },
  {
    id: '2',
    name: '',
    realm: '',
    type: WalletType.SHARED,
    shared: [],
    settings: {
      payment: {
        pix: false,
        bankSlip: false,
        card: false,
      },
      notification: {
        balanceLimit: {
          amount: 0,
          email: false,
          isActive: false,
        },
      },
    },
    createdAt: new Date(),
    currency: '',
    organization: '',
    status: WalletStatus.ACTIVE,
    balances: [],
    customFields: [],
    totalBalance: -1,
  },
  {
    id: '3',
    name: '',
    realm: '',
    type: WalletType.SHARED,
    shared: [],
    createdAt: new Date(),
    settings: {
      payment: {
        pix: false,
        bankSlip: false,
        card: false,
      },
      notification: {
        balanceLimit: {
          amount: 0,
          email: false,
          isActive: false,
        },
      },
    },
    customFields: [],
    currency: '',
    organization: '',
    status: WalletStatus.ACTIVE,
    balances: [],
    totalBalance: -1,
  },
];

export const thead = [
  '',
  'Carteira',
  'Responsável',
  'Tipo',
  'Status',
  'Saldo',
  'Ações',
];

export const walletTypes = {
  [WalletType.ORGANIZATION]: 'Empresarial',
  [WalletType.PERSONAL]: 'Individual',
  [WalletType.SHARED]: 'Compartilhada',
};

export const breadCrumbElem = [
  {
    label: 'Carteiras',
    url: '',
    isDisabled: true,
  },
  {
    label: 'Todas as carteiras',
    url: '/wallets',
    isDisabled: false,
  },
];
