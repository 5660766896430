import { useTranslation } from 'react-i18next';

import { RealmMenu, StandardLoggedNavbar, StyledNavLink } from '@components';
import { Grid } from '@portao3-web/ui';
import { useUser } from '../UserProvider';
import { RealmRouterProviderProps } from './RealmRouterProvider.interface';
import { menuConfig } from './menuConfig';

export const RealmRouterProvider = ({ children }: RealmRouterProviderProps) => {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <div className="flex">
      <RealmMenu>
        <StyledNavLink
          to="/organizations"
          icon="arrow-left"
          label={t('button.back')}
          hideActiveStyle
        />
        {menuConfig(user.realm.id).map((section) => (
          <RealmMenu.Section title={t(section.title, '')} key={section.title}>
            {section.items.map(({ icon, label, path }) => (
              <StyledNavLink
                to={path}
                icon={icon}
                label={t(label, '')}
                key={path}
              />
            ))}
          </RealmMenu.Section>
        ))}
      </RealmMenu>

      <div className="boxNavbar">
        <StandardLoggedNavbar />
      </div>

      <div className="content">
        <Grid>
          <div className="content_page">{children}</div>
        </Grid>
      </div>
    </div>
  );
};
