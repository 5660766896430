export const TransactionMCCType = {
  1: 'fa-regular fa-bag-shopping',
  2: 'fa-regular fa-shirt',
  3: 'fa-regular fa-paintbrush',
  4: 'fa-regular fa-paintbrush',
  5: 'fa-regular fa-building-columns',
  6: 'fa-regular fa-car',
  7: 'fa-regular fa-car',
  8: 'fa-regular fa-briefcase',
  9: 'fa-regular fa-briefcase',
  10: 'fa-regular fa-shirt',
  11: 'fa-regular fa-screwdriver-wrench',
  12: 'fa-regular fa-circle-dollar',
  13: 'fa-regular fa-bullhorn',
  14: 'fa-regular fa-plate-utensils',
  15: 'fa-regular fa-book',
  16: 'fa-regular fa-screwdriver-wrench',
  17: 'fa-regular fa-masks-theater',
  18: 'fa-regular fa-circle-dollar',
  19: 'fa-regular fa-martini-glass',
  20: 'fa-regular fa-building-columns',
  21: 'fa-regular fa-briefcase-medical',
  22: 'fa-regular fa-briefcase-medical',
  23: 'fa-regular fa-screwdriver-wrench',
  24: 'fa-regular fa-couch',
  25: 'fa-regular fa-shield',
  26: 'fa-regular fa-briefcase',
  27: 'fa-regular fa-hotel',
  28: 'fa-brands fa-cc-visa',
  29: 'fa-regular fa-briefcase',
  30: 'fa-regular fa-camera-retro',
  31: 'fa-regular fa-briefcase',
  32: 'fa-regular fa-gamepad-modern',
  33: 'fa-regular fa-credit-card',
  34: 'fa-regular fa-circle-dollar',
  35: 'fa-regular fa-anchor',
  36: 'fa-regular fa-screwdriver-wrench',
  37: 'fa-regular fa-ear',
  38: 'fa-regular fa-shield',
  39: 'fa-regular fa-bag-shopping',
  40: 'fa-regular fa-credit-card',
  41: 'fa-regular fa-satellite-dish',
  42: 'fa-regular fa-satellite-dish',
  43: 'fa-regular fa-bus',
  44: 'fa-regular fa-plane',
  46: 'fa-regular fa-car',
  47: 'fa-regular fa-hotel',
};

export function mccToIcon(mcc: string) {
  switch (mcc) {
    // Bag Shopping
    case '5931':
    case '5932':
    case '5933':
    case '5937':
      return TransactionMCCType[1];
    // Shirt
    case '5137':
    case '5139':
    case '5611':
    case '5621':
    case '5631':
    case '5641':
    case '5651':
    case '5655':
    case '5661':
    case '5681':
    case '5691':
    case '5698':
    case '5699':
      return TransactionMCCType[2];

    // Paintbrush
    case '5945':
    case '5949':
    case '5970':
      return TransactionMCCType[3];

    case '5971':
    case '5972':
      return TransactionMCCType[4];

    // Building Columns
    case '5300':
    case '7278':
    case '7997':
    case '8398':
    case '8641':
    case '8651':
    case '8661':
    case '8675':
    case '8699':
      return TransactionMCCType[5];

    case '5511':
    case '5531':
    case '5935':
    case '7534':
      return TransactionMCCType[6];

    case '5512':
    case '5521':
    case '5532':
    case '5533':
    case '5541':
    case '5542':
    case '5552':
    case '5599':
    case '7523':
    case '7535':
    case '7538':
    case '7549':
      return TransactionMCCType[7];

    // Briefcase
    case '6540':
    case '7399':
      return TransactionMCCType[8];

    case '2741':
    case '2791':
    case '2842':
    case '5013':
    case '5021':
    case '5039':
    case '5044':
    case '5045':
    case '5046':
    case '5047':
    case '5051':
    case '5065':
    case '5072':
    case '5074':
    case '5085':
    case '5099':
    case '5111':
    case '5122':
    case '5131':
    case '5169':
    case '5172':
    case '5192':
    case '5193':
    case '5199':
    case '5272':
    case '5815':
    case '6536':
    case '6537':
    case '7375':
    case '7379':
    case '7829':
    case '8734':
    case '9950':
      return TransactionMCCType[9];

    case '7210':
    case '7211':
    case '7216':
    case '7217':
    case '7342':
    case '7349':
    case '7542':
      return TransactionMCCType[10];

    // Screwdriver Wrench
    case '780':
    case '1520':
    case '1711':
    case '1731':
    case '1740':
    case '1750':
    case '1761':
    case '1771':
    case '1799':
      return TransactionMCCType[11];

    // Circle Dollar
    case '5309':
    case '5310':
    case '5311':
    case '5331':
    case '5399':
      return TransactionMCCType[12];

    // Bullhorn
    case '5960':
    case '5962':
    case '5963':
    case '5964':
    case '5965':
    case '5966':
    case '5967':
    case '5968':
    case '5969':
      return TransactionMCCType[13];

    // Plate Utensils
    case '5811':
    case '5812':
    case '5813':
    case '5814':
      return TransactionMCCType[14];

    // Book
    case '8211':
    case '8220':
    case '8241':
    case '8244':
    case '8249':
    case '8299':
      return TransactionMCCType[15];

    case '5734':
    case '5732':
      return TransactionMCCType[16];

    // Masks Theater
    case '5735':
    case '5816':
    case '5817':
    case '5818':
    case '7832':
    case '7922':
    case '7929':
    case '7941':
    case '7996':
    case '7998':
      return TransactionMCCType[17];

    case '4829':
    case '5262':
    case '6010':
    case '6011':
    case '6012':
    case '6051':
    case '6211':
      return TransactionMCCType[18];

    // Martini Glass
    case '5411':
    case '5422':
    case '5441':
    case '5451':
    case '5462':
    case '5499':
    case '5921':
      return TransactionMCCType[19];

    case '9222':
    case '9311':
    case '9399':
    case '9402':
    case '9405':
    case '9406':
      return TransactionMCCType[20];

    // Briefcase Medical
    case '4119':
    case '5912':
    case '5976':
    case '8011':
    case '8021':
    case '8031':
    case '8041':
    case '8042':
    case '8043':
    case '8049':
    case '8062':
    case '8071':
    case '8099':
      return TransactionMCCType[21];

    case '8050':
      return TransactionMCCType[22];

    // Paint Roller
    case '5198':
    case '5211':
    case '5231':
    case '5251':
    case '5261':
    case '5983':
      return TransactionMCCType[23];

    // Couch
    case '5200':
    case '5712':
    case '5713':
    case '5714':
    case '5718':
    case '5719':
    case '5722':
      return TransactionMCCType[24];

    // Shield
    case '6300':
      return TransactionMCCType[25];

    case '8111':
    case '9211':
    case '9223':
      return TransactionMCCType[26];

    case '3837':
    case '3838':
    case '7012':
      return TransactionMCCType[27];

    case '9700':
    case '9701':
    case '9702':
      return TransactionMCCType[28];

    case '5977':
    case '7230':
    case '7251':
    case '7273':
    case '7276':
    case '7277':
    case '7297':
    case '7298':
    case '7299':
    case '7321':
    case '7361':
    case '7392':
    case '8351':
      return TransactionMCCType[29];

    case '5946':
    case '7221':
    case '7333':
    case '7338':
    case '7395':
      return TransactionMCCType[30];

    case '742':
    case '763':
    case '7261':
    case '7311':
    case '7339':
    case '7372':
    case '7801':
    case '7802':
    case '8911':
    case '8931':
    case '8999':
      return TransactionMCCType[31];

    case '5271':
    case '5561':
    case '5592':
    case '5598':
    case '5940':
    case '5998':
    case '7032':
    case '7033':
    case '7800':
    case '7911':
    case '7932':
    case '7933':
    case '7991':
    case '7992':
    case '7993':
    case '7994':
    case '7995':
    case '7999':
      return TransactionMCCType[32];

    case '4723':
    case '9751':
    case '9752':
      return TransactionMCCType[33];

    case 's4457':
    case '6513':
    case '7296':
    case '7394':
    case '7513':
    case '7519':
    case '7841':
      return TransactionMCCType[34];

    case '4468':
      return TransactionMCCType[35];

    case '5697':
    case '7531':
    case '7622':
    case '7623':
    case '7629':
    case '7631':
    case '7641':
    case '7692':
    case '7699':
      return TransactionMCCType[36];

    case '5975':
      return TransactionMCCType[37];

    case '7393':
      return TransactionMCCType[38];

    case '4225':
    case '4900':
    case '5094':
    case '5551':
    case '5571':
    case '5733':
    case '5941':
    case '5942':
    case '5943':
    case '5944':
    case '5947':
    case '5948':
    case '5950':
    case '5973':
    case '5978':
    case '5992':
    case '5993':
    case '5994':
    case '5995':
    case '5996':
    case '5997':
    case '5999':
      return TransactionMCCType[39];

    case '3069':
    case '4411':
    case '4511':
    case '7011':
    case '7512':
      return TransactionMCCType[40];

    // Satellite Dish
    case '4899':
      return TransactionMCCType[41];

    case '4812':
    case '4814':
    case '4816':
    case '4821':
      return TransactionMCCType[42];

    // Bus
    case '3056':
    case '4011':
    case '4111':
    case '4112':
    case '4121':
    case '4131':
    case '4214':
    case '4215':
    case '4582':
    case '4722':
    case '4784':
    case '4789':
      return TransactionMCCType[43];

    case '3000':
    case '3001':
    case '3002':
    case '3003':
    case '3004':
    case '3005':
    case '3006':
    case '3007':
    case '3008':
    case '3009':
    case '3010':
    case '3011':
    case '3012':
    case '3013':
    case '3014':
    case '3015':
    case '3016':
    case '3017':
    case '3018':
    case '3019':
    case '3020':
    case '3021':
    case '3022':
    case '3023':
    case '3024':
    case '3025':
    case '3026':
    case '3027':
    case '3028':
    case '3029':
    case '3030':
    case '3031':
    case '3032':
    case '3033':
    case '3034':
    case '3035':
    case '3036':
    case '3037':
    case '3038':
    case '3039':
    case '3040':
    case '3041':
    case '3042':
    case '3043':
    case '3044':
    case '3045':
    case '3046':
    case '3047':
    case '3048':
    case '3049':
    case '3050':
    case '3051':
    case '3052':
    case '3053':
    case '3054':
    case '3055':
    case '3057':
    case '3058':
    case '3059':
    case '3060':
    case '3061':
    case '3062':
    case '3063':
    case '3064':
    case '3065':
    case '3066':
    case '3067':
    case '3068':
    case '3071':
    case '3072':
    case '3075':
    case '3076':
    case '3077':
    case '3078':
    case '3079':
    case '3082':
    case '3083':
    case '3084':
    case '3085':
    case '3086':
    case '3087':
    case '3088':
    case '3089':
    case '3090':
    case '3094':
    case '3096':
    case '3097':
    case '3098':
    case '3099':
    case '3100':
    case '3102':
    case '3103':
    case '3106':
    case '3110':
    case '3111':
    case '3112':
    case '3115':
    case '3117':
    case '3118':
    case '3125':
    case '3126':
    case '3127':
    case '3129':
    case '3130':
    case '3131':
    case '3132':
    case '3133':
    case '3135':
    case '3136':
    case '3137':
    case '3138':
    case '3143':
    case '3144':
    case '3145':
    case '3146':
    case '3148':
    case '3151':
    case '3154':
    case '3156':
    case '3159':
    case '3161':
    case '3164':
    case '3165':
    case '3167':
    case '3170':
    case '3171':
    case '3172':
    case '3174':
    case '3175':
    case '3176':
    case '3177':
    case '3178':
    case '3180':
    case '3181':
    case '3182':
    case '3183':
    case '3184':
    case '3185':
    case '3186':
    case '3187':
    case '3188':
    case '3190':
    case '3191':
    case '3193':
    case '3196':
    case '3197':
    case '3200':
    case '3203':
    case '3204':
    case '3206':
    case '3211':
    case '3212':
    case '3213':
    case '3215':
    case '3216':
    case '3217':
    case '3218':
    case '3219':
    case '3220':
    case '3221':
    case '3222':
    case '3223':
    case '3226':
    case '3228':
    case '3229':
    case '3231':
    case '3233':
    case '3234':
    case '3235':
    case '3236':
    case '3238':
    case '3239':
    case '3240':
    case '3241':
    case '3242':
    case '3243':
    case '3245':
    case '3246':
    case '3247':
    case '3248':
    case '3251':
    case '3252':
    case '3253':
    case '3254':
    case '3256':
    case '3259':
    case '3260':
    case '3261':
    case '3262':
    case '3263':
    case '3266':
    case '3267':
    case '3280':
    case '3282':
    case '3284':
    case '3285':
    case '3286':
    case '3287':
    case '3292':
    case '3293':
    case '3294':
    case '3295':
    case '3296':
    case '3297':
    case '3298':
    case '3299':
    case '3300':
    case '3301':
    case '3302':
      return TransactionMCCType[44];

    case '3351':
    case '3352':
    case '3353':
    case '3354':
    case '3355':
    case '3357':
    case '3359':
    case '3360':
    case '3361':
    case '3362':
    case '3364':
    case '3366':
    case '3368':
    case '3370':
    case '3374':
    case '3376':
    case '3380':
    case '3381':
    case '3385':
    case '3386':
    case '3387':
    case '3388':
    case '3389':
    case '3390':
    case '3391':
    case '3393':
    case '3394':
    case '3395':
    case '3396':
    case '3398':
    case '3400':
    case '3405':
    case '3409':
    case '3412':
    case '3414':
    case '3420':
    case '3421':
    case '3423':
    case '3425':
    case '3427':
    case '3428':
    case '3429':
    case '3430':
    case '3431':
    case '3432':
    case '3433':
    case '3434':
    case '3435':
    case '3436':
    case '3437':
    case '3438':
    case '3439':
      return TransactionMCCType[46];

    // Ear
    case '2838':
    case '3501':
    case '3502':
    case '3503':
    case '3504':
    case '3505':
    case '3506':
    case '3507':
    case '3508':
    case '3509':
    case '3510':
    case '3511':
    case '3512':
    case '3513':
    case '3514':
    case '3515':
    case '3516':
    case '3517':
    case '3518':
    case '3519':
    case '3520':
    case '3521':
    case '3522':
    case '3523':
    case '3524':
    case '3525':
    case '3526':
    case '3527':
    case '3528':
    case '3529':
    case '3530':
    case '3531':
    case '3532':
    case '3533':
    case '3534':
    case '3535':
    case '3536':
    case '3537':
    case '3538':
    case '3539':
    case '3540':
    case '3541':
    case '3542':
    case '3543':
    case '3544':
    case '3545':
    case '3546':
    case '3548':
    case '3549':
    case '3550':
    case '3551':
    case '3552':
    case '3553':
    case '3554':
    case '3555':
    case '3556':
    case '3557':
    case '3558':
    case '3559':
    case '3560':
    case '3561':
    case '3562':
    case '3563':
    case '3564':
    case '3565':
    case '3566':
    case '3567':
    case '3568':
    case '3569':
    case '3570':
    case '3571':
    case '3572':
    case '3573':
    case '3574':
    case '3575':
    case '3576':
    case '3577':
    case '3578':
    case '3579':
    case '3580':
    case '3581':
    case '3582':
    case '3583':
    case '3584':
    case '3586':
    case '3587':
    case '3588':
    case '3589':
    case '3590':
    case '3591':
    case '3592':
    case '3593':
    case '3594':
    case '3595':
    case '3596':
    case '3597':
    case '3598':
    case '3599':
    case '3602':
    case '3603':
    case '3604':
    case '3607':
    case '3608':
    case '3609':
    case '3612':
    case '3613':
    case '3614':
    case '3615':
    case '3617':
    case '3618':
    case '3619':
    case '3620':
    case '3621':
    case '3622':
    case '3623':
    case '3624':
    case '3625':
    case '3626':
    case '3627':
    case '3628':
    case '3629':
    case '3630':
    case '3631':
    case '3632':
    case '3633':
    case '3634':
    case '3635':
    case '3636':
    case '3637':
    case '3638':
    case '3639':
    case '3640':
    case '3641':
    case '3642':
    case '3643':
    case '3644':
    case '3645':
    case '3646':
    case '3647':
    case '3648':
    case '3649':
    case '3650':
    case '3651':
    case '3652':
    case '3653':
    case '3654':
    case '3655':
    case '3656':
    case '3657':
    case '3658':
    case '3659':
    case '3660':
    case '3661':
    case '3662':
    case '3663':
    case '3664':
    case '3665':
    case '3666':
    case '3667':
    case '3668':
    case '3669':
    case '3670':
    case '3671':
    case '3672':
    case '3673':
    case '3674':
    case '3675':
    case '3676':
    case '3677':
    case '3678':
    case '3679':
    case '3680':
    case '3681':
    case '3682':
    case '3683':
    case '3684':
    case '3685':
    case '3686':
    case '3687':
    case '3688':
    case '3689':
    case '3690':
    case '3691':
    case '3692':
    case '3693':
    case '3694':
    case '3695':
    case '3696':
    case '3697':
    case '3698':
    case '3699':
    case '3700':
    case '3701':
    case '3702':
    case '3703':
    case '3704':
    case '3705':
    case '3706':
    case '3707':
    case '3708':
    case '3709':
    case '3710':
    case '3711':
    case '3712':
    case '3713':
    case '3714':
    case '3715':
    case '3716':
    case '3717':
    case '3718':
    case '3719':
    case '3720':
    case '3721':
    case '3722':
    case '3723':
    case '3724':
    case '3725':
    case '3726':
    case '3727':
    case '3728':
    case '3729':
    case '3730':
    case '3731':
    case '3732':
    case '3734':
    case '3735':
    case '3736':
    case '3737':
    case '3738':
    case '3739':
    case '3740':
    case '3741':
    case '3742':
    case '3743':
    case '3744':
    case '3745':
    case '3746':
    case '3747':
    case '3748':
    case '3749':
    case '3750':
    case '3751':
    case '3752':
    case '3753':
    case '3754':
    case '3755':
    case '3757':
    case '3758':
    case '3759':
    case '3760':
    case '3761':
    case '3762':
    case '3763':
    case '3764':
    case '3765':
    case '3766':
    case '3767':
    case '3768':
    case '3769':
    case '3770':
    case '3771':
    case '3772':
    case '3773':
    case '3774':
    case '3775':
    case '3776':
    case '3777':
    case '3778':
    case '3779':
    case '3780':
    case '3781':
    case '3782':
    case '3783':
    case '3784':
    case '3785':
    case '3786':
    case '3787':
    case '3788':
    case '3789':
    case '3790':
    case '3791':
    case '3792':
    case '3793':
    case '3794':
    case '3795':
    case '3796':
    case '3797':
    case '3798':
    case '3799':
    case '3800':
    case '3802':
    case '3807':
    case '3808':
    case '3811':
    case '3812':
    case '3813':
    case '3814':
    case '3815':
    case '3818':
    case '3822':
    case '3823':
    case '3824':
    case '3825':
    case '3826':
    case '3827':
    case '3834':
    case '3835':
    case '3836':
      return TransactionMCCType[47];

    // Default Case - Hotel
    default:
      return TransactionMCCType[40];
  }
}
