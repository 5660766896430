import { SidebarFooterProps } from './Sidebar.interface';

import './SidebarFooter.styles.scss';

export const SidebarFooter = ({ children }: SidebarFooterProps) => {
  const childArray = Array.isArray(children) ? children : [children];

  return (
    <div className="sidebar_footer">
      <footer className="sidebar_footer_elements">
        {childArray.map((child, index) => (
          <div className="sidebar_footer_button" key={index}>
            {child}
          </div>
        ))}
      </footer>
    </div>
  );
};
