import { faker as fakerDefault } from '@faker-js/faker/locale/pt_BR';

const generateCPF = () => {
  let cpf = '';

  for (let i = 0; i < 9; i++) {
    cpf += Math.floor(Math.random() * 10);
  }

  let sum = 0;
  let weight = 10;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * weight;
    weight--;
  }

  let digit1 = 11 - (sum % 11);
  if (digit1 > 9) {
    digit1 = 0;
  }

  sum = 0;
  weight = 11;

  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * weight;
    weight--;
  }

  sum += digit1 * 2;
  let digit2 = 11 - (sum % 11);
  if (digit2 > 9) {
    digit2 = 0;
  }

  cpf += digit1.toString() + digit2.toString();

  return cpf;
};

const generateCNPJ = () => {
  let cnpj = '';

  for (let i = 0; i < 12; i++) {
    cnpj += Math.floor(Math.random() * 10);
  }

  let sum = 0;
  let position = 5;

  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * position;
    position--;
    if (position === 1) {
      position = 9;
    }
  }

  let digit = 11 - (sum % 11);
  cnpj += digit === 10 || digit === 11 ? '0' : digit.toString();

  sum = 0;
  position = 6;

  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * position;
    position--;
    if (position === 1) {
      position = 9;
    }
  }

  digit = 11 - (sum % 11);
  cnpj += digit === 10 || digit === 11 ? '0' : digit.toString();

  return cnpj;
};

const randomAddress = [
  {
    city: 'São Paulo',
    complement: 'Ap 123',
    neighborhood: 'Jardim Santo André',
    number: '123',
    postalCode: '08390230',
    state: 'SP',
    street: 'Rua Professora Jaçanã Altair',
  },
  {
    city: 'Florianópolis',
    neighborhood: 'Centro',
    number: '2468',
    postalCode: '88015902',
    state: 'SC',
    street: 'Rua Bocaiúva',
    complement: 'Sala 123',
  },
  {
    city: 'Rio de Janeiro',
    neighborhood: 'Copacabana',
    number: '123',
    postalCode: '22040002',
    state: 'RJ',
    street: 'Rua Barata Ribeiro',
    complement: '',
  },
  {
    city: 'Curitiba',
    neighborhood: 'Batel',
    number: '123',
    postalCode: '80420-063',
    state: 'PR',
    street: 'Rua Comendador Araújo ',
    complement: 'Apt 123',
  },
  {
    city: 'Porto Alegre',
    neighborhood: 'Mont Serrat',
    number: '1238',
    postalCode: '90450000',
    state: 'RS',
    street: 'Rua Anita Garibaldi',
    complement: 'Lado árvore',
  },
  {
    city: 'Belo Horizonte',
    neighborhood: 'Boa Viagem',
    number: '982',
    postalCode: '30140071',
    state: 'MG',
    street: 'Rua dos Aimorés',
    complement: 'Ap 123',
  },
  {
    city: 'Salvador',
    neighborhood: 'Barra',
    number: '470',
    postalCode: '40140100',
    state: 'BA',
    street: 'Rua Engenheiro Milton Oliveira',
    complement: 'Casa',
  },
];

const getFullAddress = () => {
  const index = Math.floor(Math.random() * randomAddress.length);

  return randomAddress[index];
};

export const faker = {
  ...fakerDefault,
  document: {
    cpf: generateCPF,
    cnpj: generateCNPJ,
  },
  address: getFullAddress,
};
