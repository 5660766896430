import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ApiErrorPin } from '@services/auth/auth.interfaces';
import { deactivateUser } from '@services/user';
import { userKeys } from './userKeys';

export interface DeactivateUserProps {
  userId: string;
  pin?: string;
}

export const useDeactivateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, ApiErrorPin, DeactivateUserProps, unknown>({
    mutationFn: ({ userId, pin }: DeactivateUserProps) =>
      deactivateUser(userId, pin),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: userKeys.lists() });
      queryClient.invalidateQueries({
        queryKey: userKeys.detail(variables.userId),
      });
    },
  });
};
