import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Skeleton, Typography } from '..';
import imageLogo from '../../assets/img/logo-p3.svg';
import { MenuItem, MenuList, MenuProps } from '../../interfaces';
import './Menu.styles.scss';
import { DropDown } from './components/DropDown';

export const Menu = ({
  data,
  setSearchWallet,
  isLoading,
  createWallet,
  skeletonLoading,
  trackCreateWalletUserAction,
  trackSearchWalletUserAction,
  trackShowAllWalletUserAction,
}: MenuProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [menu, setMenu] = useState<MenuList[]>([]);

  const clickOpenCloseSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setMenu(data);
  }, [data]);

  return (
    <div className="sidebar_menu">
      <div className="sidebar_content">
        <div className="sidebar_menu__box_logo">
          <NavLink to={'/'}>
            <img
              src={imageLogo}
              alt="Logo portão 3"
              className="sidebar_menu__box_logo__image"
            />
          </NavLink>
          <div
            className="sidebar_menu__box_logo_button"
            data-testid="logo-button"
            onClick={clickOpenCloseSidebar}
          >
            <div className="sidebar_menu__box_logo_button--open-close">
              {isExpanded && <i className="fa-solid fa-chevron-left"></i>}
              {!isExpanded && <i className="fa-solid fa-chevron-right"></i>}
            </div>
          </div>
        </div>
        <div className="sidebar_menu_items">
          <div className="box_item">
            {menu.map((item: MenuList, indexMenuList: number) => (
              <div key={indexMenuList} className="box_item_section">
                <Skeleton width={100} isLoading={skeletonLoading}>
                  <p className="box_item_section_title">
                    {item.title.toUpperCase()}
                  </p>
                </Skeleton>
                <Skeleton width={100} isLoading={skeletonLoading}>
                  {item.dropDown?.listItems && (
                    <DropDown
                      listItems={item.dropDown.listItems}
                      setSearchWallet={setSearchWallet}
                      isLoading={isLoading}
                      createWallet={createWallet}
                      trackCreateWalletUserAction={trackCreateWalletUserAction}
                      trackSearchWalletUserAction={trackSearchWalletUserAction}
                      trackShowAllWalletUserAction={
                        trackShowAllWalletUserAction
                      }
                    />
                  )}
                </Skeleton>
                <div className="box_item_list">
                  {item.menuList.map(
                    (menuItem: MenuItem, indexMenuItem: number) => (
                      <NavLink
                        key={indexMenuItem}
                        to={menuItem.url}
                        className={({ isActive }) =>
                          isActive
                            ? 'box_item_list_item box_item_list_item_selected'
                            : 'box_item_list_item'
                        }
                        end
                      >
                        <Skeleton width={100} isLoading={skeletonLoading}>
                          <span className="box_item_list_item_link">
                            <Typography tag="p" weight="p1">
                              <i
                                className={`fa-regular fa-${menuItem.icon} box_item_list_item_icon`}
                              />
                            </Typography>
                            <Typography tag="p" weight="p1">
                              {menuItem.label}
                            </Typography>
                          </span>
                        </Skeleton>
                      </NavLink>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
