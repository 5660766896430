import { REGEXP_ONLY_DIGITS } from 'input-otp';
import {
  InputOTPGroup,
  InputOTP as InputOTPShad,
  InputOTPSlot,
} from '../shadInputOTP/shadInputOTP';

export interface InputOtpProps {
  length: number;
  value: string;
  setValue: (value: string) => void;
  hiddenPassword?: boolean;
  error?: boolean;
  disabled?: boolean;
  label?: string;
  autoFocus?: boolean;
  size?: 'medium' | 'large';
}

export const InputOTP = ({
  length,
  value,
  setValue,
  hiddenPassword,
  error,
  disabled,
  label,
  autoFocus = true,
  size = 'large',
}: InputOtpProps) => {
  const sizeClass = {
    container: size === 'medium' ? '!h-14' : '',
    slot: size === 'medium' ? 'h-14 w-12' : '',
  };

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label htmlFor="otp-container" className="text-p3 text-neutral-80">
          {label}
        </label>
      )}

      <InputOTPShad
        key="otp-container"
        name="otp-container"
        className={sizeClass.container}
        disabled={disabled}
        maxLength={length}
        value={value}
        onChange={(value) => setValue(value)}
        autoFocus={autoFocus}
        pattern={REGEXP_ONLY_DIGITS}
      >
        <InputOTPGroup className={sizeClass.container} key="otp-group">
          {Array.from({ length }).map((_, index) => (
            <InputOTPSlot
              className={sizeClass.slot}
              error={error}
              hidden={hiddenPassword}
              key={`otp-item${index}`}
              index={index}
            />
          ))}
        </InputOTPGroup>
      </InputOTPShad>
    </div>
  );
};
