import { BillingListParams } from './useInfiniteBillingListQuery';

export const billingKeys = {
  all: () => ['billing'],
  details: () => [...billingKeys.all(), 'detail'],
  detail: (walletId: string, billingId: string) => [
    ...billingKeys.details(),
    { walletId, billingId },
  ],
  lists: () => [...billingKeys.all(), 'list'],
  list: (params: BillingListParams) => [...billingKeys.lists(), params],
};
