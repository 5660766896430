import { forwardRef } from 'react';
import { Spinner } from '../spinner/Spinner';
import { ButtonProps } from './Button.interface';
import './Button.styles.scss';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'primary',
      className = '',
      size,
      isLoading,
      ...props
    }: ButtonProps,
    ref
  ) => {
    return (
      <button
        style={{
          color: props.disabled ? 'var(--product-neutral-n50)' : ``,
        }}
        className={`button button--${variant} button--${size} ${className}`}
        {...props}
        ref={ref}
        disabled={isLoading || props.disabled}
      >
        {isLoading ? <Spinner /> : children}
      </button>
    );
  }
);

Button.displayName = 'Button';
