import clsx from 'clsx';
import dayjs from 'dayjs';
import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { MEGA_BYTE } from '@constants/fileSize';
import { regex } from '@constants/regex';
import { UserContext } from '@providers';
import { UploadedFile } from '@interfaces/File.interface';
import {
  Button,
  Dialog,
  Icon,
  ProgressBar,
  Spinner,
  Typography,
} from '@portao3-web/ui';
import { normalizeString } from '@utils/normalizeString';
import { useTranslation } from 'react-i18next';
import {
  DragAndDropProps,
  FileCardProps,
  FileUploadContextProps,
  FileUploadRootProps,
  InputButtonProps,
} from './FileUpload.interface';
import './FileUpload.styles.scss';
import { MAX_FILE_SIZE_ALLOWED_IN_MB } from './constants';

const FILE_MIME = {
  jpeg: 'image/jpeg',
  png: 'image/png',
  jpg: 'image/jpg',
  pdf: 'application/pdf',
  txt: 'text/plain',
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ret: '',
};

const PREVIEW_ACCEPT_TYPES = ['jpeg', 'png', 'jpg', 'pdf'];

const initialState: FileUploadContextProps = {
  fileView: null,
  setFileView: () => null,
  uploadedFiles: [],
  setUploadedFiles: () => null,
  removeFile: () => null,
  handleFileUpload: () => null,
  handleFiles: () => null,
  maxFiles: 0,
  maxFileSize: 0,
  fileAccept: '',
};

const FileUploadContext = createContext<FileUploadContextProps>(initialState);

const FileUploadRoot = ({
  children,
  defaultFiles,
  maxFiles = 5,
  maxFileSizeInMB = MAX_FILE_SIZE_ALLOWED_IN_MB,
  fileAccept,
  name,
  setValue,
  deleteFile,
}: FileUploadRootProps) => {
  const { firstName, lastName } = useContext(UserContext);

  const [fileView, setFileView] = useState<UploadedFile | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(
    defaultFiles ?? []
  );

  const maxFileSize = Math.min(maxFileSizeInMB, MAX_FILE_SIZE_ALLOWED_IN_MB);

  const removeFile = (file: UploadedFile) => {
    setUploadedFiles((prev) => prev.filter((f) => f !== file));
    file.link && URL.revokeObjectURL(file.link);
    deleteFile(file);
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    const filesCount = files.length + uploadedFiles.length;
    if (filesCount > maxFiles) return;
    if (files) handleFiles(files);
  };

  const handleFiles = async (files: File[]) => {
    const formattedFiles: UploadedFile[] = [];

    for (const file of files) {
      if (file.size > maxFileSize * MEGA_BYTE) return;

      const url = URL.createObjectURL(file);

      formattedFiles.push({
        file: file,
        link: url,
        firstName: `${firstName}`,
        lastName: `${lastName}`,
        createdat: new Date(),
        filename: normalizeString(file.name),
      });
    }

    setUploadedFiles((prev) => [...prev, ...formattedFiles]);
  };

  useEffect(() => {
    if (!name || !setValue) return;
    setValue(name, uploadedFiles);
  }, [uploadedFiles, name, setValue]);

  return (
    <FileUploadContext.Provider
      value={{
        fileView,
        setFileView,
        uploadedFiles,
        setUploadedFiles,
        removeFile,
        handleFileUpload,
        handleFiles,
        maxFiles,
        maxFileSize,
        fileAccept,
      }}
    >
      {typeof children === 'function' ? children({ uploadedFiles }) : children}
      {fileView && createPortal(<FileView />, document.body)}
    </FileUploadContext.Provider>
  );
};

const useFileUpload = () => {
  const context = useContext(FileUploadContext);

  if (!context) {
    throw new Error('useFileUpload must be used within a FileUploadContext');
  }

  return context;
};

const DragAndDrop = ({ onDrop, onClick }: DragAndDropProps) => {
  const {
    uploadedFiles,
    handleFileUpload,
    handleFiles,
    maxFiles,
    maxFileSize,
    fileAccept,
  } = useFileUpload();
  const { t } = useTranslation();

  const filteredFileAccept = fileAccept
    ?.replaceAll(' ', '')
    ?.replaceAll(',', '')
    ?.split('.')
    ?.filter((type) => type !== '');

  const ACCEPTED_FILE_TYPES = filteredFileAccept?.map((type) => {
    const typed = type as keyof typeof FILE_MIME;

    return FILE_MIME[typed] ? FILE_MIME[typed] : '';
  });

  const isDisabled = uploadedFiles.length >= maxFiles;

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState(false);

  const handleOnDragEnter = () => {
    setIsDraggingOver(true);
  };

  const handleOnDragOver = (event: DragEvent) => {
    event.preventDefault();

    const filesCount = event.dataTransfer.items.length;

    if (filesCount + uploadedFiles.length > maxFiles) {
      setIsDraggingOver(false);
      setInvalidFiles(true);
      return;
    }

    for (let i = 0; i < filesCount; i++) {
      const type = event.dataTransfer.items[i].type;

      if (!ACCEPTED_FILE_TYPES.includes(type)) {
        setIsDraggingOver(false);
        setInvalidFiles(true);
        return;
      }
    }

    setIsDraggingOver(true);
  };

  const handleOnDragLeave = () => {
    setIsDraggingOver(false);
    setInvalidFiles(false);
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    onDrop?.();

    if (invalidFiles) {
      setInvalidFiles(false);
      return;
    } else {
      setIsDraggingOver(false);
    }

    const files = Array.from(event.dataTransfer.files);

    if (files) {
      handleFiles(files);
    }
  };

  return (
    <label
      className={clsx('file-input-dnd', {
        'file-input-dnd--dragging': isDraggingOver,
        'file-input-dnd--invalid-file-type': invalidFiles,
        'file-input-dnd--disabled': isDisabled,
      })}
      onDragEnter={handleOnDragEnter}
      onDragOver={handleOnDragOver}
      onDragLeave={handleOnDragLeave}
      onDrop={handleDrop}
    >
      <div
        className={clsx('file-input-dnd_icon', {
          'file-input-dnd_icon--dragging': isDraggingOver,
          'file-input-dnd_icon--invalid-file-type': invalidFiles,
        })}
      >
        <i className="fa-regular fa-upload " />
      </div>
      <div className="file-input-dnd_description-wrapper">
        <Typography
          tag="p"
          weight="p2"
          color={clsx({
            'var(--product-neutral-n200)': !isDraggingOver && !invalidFiles,
            'var(--product-primary-p400)': isDraggingOver,
            'var(--product-danger-d400)': invalidFiles,
          })}
        >
          {t('file-upload.instructions')}
        </Typography>
        <Typography
          tag="p"
          weight="p2"
          color={clsx({
            'var(--product-neutral-n80)': !isDraggingOver && !invalidFiles,
            'var(--product-primary-p200)': isDraggingOver,
            'var(--product-danger-d200)': invalidFiles,
          })}
        >
          {t('file-upload.max', {
            maxFiles,
            maxFileSize,
            fileAccept,
          })}
        </Typography>
      </div>
      <input
        type="file"
        multiple={maxFiles > 1}
        accept={fileAccept}
        onChange={handleFileUpload}
        disabled={isDisabled}
        style={{ display: 'none' }}
        data-testid="file-input-dnd"
        onClick={onClick}
      />
    </label>
  );
};

const InputButton = ({ onClick }: InputButtonProps) => {
  const { handleFileUpload, uploadedFiles, maxFiles, fileAccept } =
    useFileUpload();

  const isDisabled = uploadedFiles.length >= maxFiles;

  if (maxFiles <= 1) return <></>;

  return (
    <label
      className={clsx('file-input-button', {
        'file-input-button--disabled': isDisabled,
      })}
    >
      <Typography
        tag="p"
        weight="p3"
        color={`${
          isDisabled
            ? 'var(--product-primary-p100)'
            : 'var(--product-primary-p500)'
        }`}
      >
        Upload
      </Typography>
      <div
        className={clsx('file-input-button_icon', {
          'file-input-button_icon--disabled': isDisabled,
        })}
      >
        <i className="fa-regular fa-upload fa-xs" />
      </div>
      <input
        type="file"
        multiple={maxFiles > 1}
        accept={fileAccept}
        onChange={handleFileUpload}
        disabled={isDisabled}
        style={{ display: 'none' }}
        data-testid="file-input-button"
        onClick={onClick}
      />
    </label>
  );
};

const FileCard = ({ file, cardState = 'loaded' }: FileCardProps) => {
  const { setFileView, removeFile } = useFileUpload();

  if (!file.filename) return null;

  const filenameParts = file.filename.split('.');
  const fileType = filenameParts[filenameParts.length - 1];

  const hasPreview = PREVIEW_ACCEPT_TYPES.some((type) => type === fileType);

  const handleRemoveFile = (file: UploadedFile, event: MouseEvent) => {
    event.stopPropagation();
    removeFile(file);
  };

  const handleViewFile = (event: MouseEvent) => {
    const element = event.target as HTMLElement;
    const closest = element.closest('[data-stop-propagation]');
    if (closest) return;
    if (cardState !== 'loaded' || !hasPreview) return;

    setFileView(file);
  };

  return (
    <div
      className={clsx('uploaded-file-preview', {
        'uploaded-file-preview--loading': cardState === 'loading',
        'uploaded-file-preview--error': cardState === 'error',
      })}
      onClick={handleViewFile}
      data-testid="file-card"
    >
      <div className="uploaded-file-preview_info-container">
        {cardState === 'loaded' && regex.image.test(file.filename ?? '') && (
          <img src={file.link} alt={file.filename} className="item-preview" />
        )}
        {cardState === 'loaded' && regex.pdf.test(file.filename ?? '') && (
          <object
            data={file.link}
            type="application/pdf"
            className="item-preview"
            aria-label={file.filename}
          />
        )}
        {cardState === 'loading' && (
          <div className="uploaded-file-preview_spinner">
            <Spinner />
          </div>
        )}
        {cardState === 'error' && (
          <div className="uploaded-file-preview_icons_error">
            <i className="fa-regular fa-image" />
          </div>
        )}
        <div className="item-description">
          <Typography
            tag="p"
            weight="p3"
            className="item-description_name"
            color={clsx({
              'var(--product-primary-p500)': cardState === 'loaded',
              'var(--product-neutral-n100)': cardState === 'loading',
              'var(--product-danger-d500)': cardState === 'error',
            })}
          >
            {normalizeString(file.filename)}
          </Typography>
          {cardState === 'loaded' && (
            <Typography tag="p" weight="p3" color="var(--product-neutral-n100)">
              Enviado por {file.firstName} {file.lastName} em{' '}
              {dayjs(file.createdat).format('DD/MM/YYYY [às] HH:mm')}
            </Typography>
          )}
          {cardState === 'loading' && (
            <ProgressBar
              total={100}
              balance={[{ status: 'consumed', value: 100 }]}
            />
          )}
        </div>
      </div>
      <div className="uploaded-file-preview_icons">
        {cardState === 'loaded' && hasPreview && (
          <Button
            variant="tertiary"
            size="small"
            type="button"
            className="uploaded-file-preview_icons_wrapper"
          >
            <i className="fa-regular fa-eye" />
          </Button>
        )}
        {(cardState === 'error' || cardState === 'loaded') && (
          <Dialog variant="popover">
            <Dialog.Trigger>
              <Button
                variant="tertiary"
                size="small"
                type="button"
                className={clsx('uploaded-file-preview_icons_remove-button', {
                  'uploaded-file-preview_icons_remove-button--error':
                    cardState === 'error',
                })}
                data-testid="remove-file-button"
                data-stop-propagation
              >
                <i className="fa-regular fa-trash" />
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <div className="uploaded-file-preview_icons_delete-popover">
                <Typography
                  tag="p"
                  weight="p2"
                  color="var(--product-neutral-n80)"
                >
                  Tem certeza de que deseja remover este arquivo?
                </Typography>
                <div className="uploaded-file-preview_icons_delete-popover_buttons">
                  <Button
                    type="button"
                    variant="secondary"
                    size="medium"
                    onClick={() => document.body.click()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    size="medium"
                    onClick={(event) => handleRemoveFile(file, event)}
                  >
                    Confirmar
                  </Button>
                </div>
              </div>
            </Dialog.Content>
          </Dialog>
        )}
        {cardState === 'loading' && (
          <Button
            variant="tertiary"
            size="small"
            type="button"
            className="uploaded-file-preview_icons_remove-button"
            onClick={(event) => handleRemoveFile(file, event)}
          >
            <i className="fa-regular fa-close" />
          </Button>
        )}
      </div>
    </div>
  );
};

const FileView = () => {
  const { fileView, setFileView } = useFileUpload();

  if (!fileView || !fileView.filename) return null;

  return (
    <div className="file-view" data-testid="file-view">
      <div className="file-view_overlay" />
      <div
        className={clsx('file-view_wrapper', {
          'file-view_wrapper_object': regex.pdf.test(fileView.filename),
        })}
      >
        <button
          onClick={() => setFileView(null)}
          className="file-view_wrapper_close-button"
          title="Fechar"
        >
          <Icon size="xlarge">
            <i className="fa fa-close" />
          </Icon>
        </button>
        {regex.image.test(fileView.filename) && (
          <img
            src={fileView.link}
            alt={fileView.filename}
            className="file-view_wrapper_item--img"
          />
        )}
        {regex.pdf.test(fileView.filename) && (
          <object
            data={fileView.link}
            type="application/pdf"
            aria-label={fileView.filename}
            className="file-view_wrapper_item--object"
          />
        )}
      </div>
    </div>
  );
};

export const FileUpload = Object.assign(FileUploadRoot, {
  DragAndDrop,
  FileCard,
  Button: InputButton,
});
