import { WalletRole } from '@interfaces/Wallets.interfaces';
import { Avatar, Icon, Typography } from '@portao3-web/ui';
import { Select } from '../select/Select';
import { UserAccessCardProps } from './UserAccessCard.interfaces';
import './UserAccessCard.styles.scss';

export const UserAccessCard = ({
  user,
  selectItem,
  removeItem,
  disabled = false,
}: UserAccessCardProps) => {
  const { email, id, firstName, lastName, thumbnail } = user;
  const fullName = `${firstName} ${lastName}`;

  const handleRemoveUser = () => {
    removeItem && removeItem(id);
  };

  return (
    <div className="user-access-card">
      <Avatar imageSrc={thumbnail ?? ''} userName={fullName} />
      <div className="user-access-card_info">
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {fullName}
        </Typography>
        <Typography tag="p" weight="p3" color="var(--product-neutral-n100)">
          {email}
        </Typography>
      </div>
      <Select
        defaultValue={user.role}
        selectItem={selectItem}
        options={[
          { label: 'Editor', value: WalletRole.OWNER },
          { label: 'Usuário', value: WalletRole.USER },
          { label: 'Leitor', value: WalletRole.READ_ONLY },
        ]}
        disabled={disabled}
        data-testid={`select-${id}`}
      />
      {!disabled && (
        <button
          type="button"
          className="user-access-card_remove-button"
          onClick={handleRemoveUser}
          data-testid={`remove-button-${id}`}
        >
          <Icon size="xlarge">
            <i className="fa-regular fa-trash" />
          </Icon>
        </button>
      )}
    </div>
  );
};
