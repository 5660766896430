import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { downloadFile } from '@utils/file';
import dayjs from 'dayjs';
import {
  GetFutureTransactionsExport,
  getFutureTransactionsReport,
} from '../futureTransactionsReport';

export const useFutureTransactionsReport = () => {
  return useMutation<
    AxiosResponse<string>,
    ApiError,
    GetFutureTransactionsExport,
    unknown
  >({
    mutationFn: (props) => getFutureTransactionsReport(props),
    onSuccess: (response, { type, scheduledAtEnd, scheduledAtStart }) => {
      const { data, headers } = response;
      const startDate = dayjs(scheduledAtEnd).utc(false).format('DD-MM-YYYY');
      const endDate = dayjs(scheduledAtStart).utc(false).format('DD-MM-YYYY');

      const fileName = `future_transactions_${startDate}_to_${endDate}.${type}`;

      downloadFile(type, data, fileName, headers['content-type']);
    },
  });
};
