import { CurrentUser } from './../interfaces/User.interfaces';

export async function initAtlas(user: CurrentUser) {
  const windowAlias: Window & typeof globalThis = window;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const atlas = (windowAlias as any).Atlas;
  atlas.call('identify', {
    userId: user.id,
    name: user.firstName,
    email: user.email,
    phoneNumber: '',
    userHash: user.external?.atlasUserHash,
    account: {
      name: user.realm.name,
      externalId: user.realm.id,
      products: 'Platform v2',
    },
  });
}
