import { createContext } from 'react';
import { ContextProps } from './Auth.interface';

export const startContext = {
  session: {
    value: '',
    setSession: () => '',
  },
  token: {
    value: '',
    setToken: () => '',
  },
  email: {
    value: '',
    setEmail: () => '',
  },
  tokenRecoveryPassword: {
    value: '',
    setTokenRecoveryPassword: () => '',
  },
  logout: () => '',
};

export const AuthContext = createContext<ContextProps>(startContext);
