import { endpoint, organization, realm } from '@constants/endpoints';
import { limitPerPage } from '@constants/limitPerPage';
import { environment } from '@environments/environment';
import api from '@services/api';
import { removeEmptyObjectAttributes } from '@utils/removeEmptyObjectAttributes';

import {
  Deal,
  FindBillingDealListParams,
} from '@interfaces/BillingDeal.interfaces';
import { ListResponse } from '@interfaces/Common.interfaces';

export const findBillingDealList = async (
  params: FindBillingDealListParams
): Promise<ListResponse<Deal>> => {
  const { customerId, ...rest } = params;
  const paramsCopy = { ...rest };
  paramsCopy.limit = paramsCopy.limit || limitPerPage;
  removeEmptyObjectAttributes(paramsCopy);

  const response = await api.get(
    `${environment.platformBaseUrl}${realm()}/${organization()}/customers/${customerId}/${
      endpoint.billingDeals
    }`,
    { params: paramsCopy }
  );

  const { data } = response;

  return data;
};
