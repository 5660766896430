import clsx from 'clsx';

import { TableColProps } from './TableCol.interface';
import './TableCol.styles.scss';

export const TableCol = ({ children, className, ...props }: TableColProps) => {
  return (
    <td className={clsx('td', className)} {...props}>
      {children}
    </td>
  );
};
