import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, ContextualError, SidebarFooter } from '@components';
import { TransactionBatchStatus } from '@enums/BatchPayment.enum';
import { useQueryParams } from '@hooks';
import {
  Button,
  ProgressIndicator,
  ProgressIndicatorStatus,
  Spinner,
  TableDemo,
  Typography,
} from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { batchPaymentKeys } from '@services/batchPayment/hooks';
import { useInfiniteBatchTransactionsListQuery } from '@services/batchPayment/hooks/UseInfiniteBatchTransactionsListQuery';
import { useConfirmBatchTransactionsMutation } from '@services/batchPayment/hooks/useConfirmBatchTransactionsMutation';
import { useParams } from 'react-router-dom';
import { DrawerBatchPaymentFlow } from '../DrawerBatchPayment.interfaces';
import { useReviewColumns } from './TableColumns';

interface BatchReviewProps {
  setFlow: Dispatch<SetStateAction<DrawerBatchPaymentFlow>>;
}

export const BatchReview = ({ setFlow }: BatchReviewProps) => {
  const { setQueryParams } = useQueryParams();
  const [rowSelection, setRowSelection] = useState({});
  const { params, removeParams, closeDrawer } = useDrawer();
  const { t } = useTranslation();
  const { walletId } = useParams();
  const queryClient = useQueryClient();

  const batchId = params?.batchId as string;
  const batchStatus = params?.batchStatus as TransactionBatchStatus;
  const transactionIds = Object.keys(rowSelection);
  const selectedCount = transactionIds.length;

  const { data: batchTransactionsList } = useInfiniteBatchTransactionsListQuery(
    {
      batchId,
      walletId: walletId || 'default',
      params: {
        limit: 9999,
      },
      refetchStatus: TransactionBatchStatus.IN_VALIDATION,
    }
  );

  const invalidateBatchTransactionsList = () => {
    queryClient.invalidateQueries({
      queryKey: batchPaymentKeys.detail(batchId, walletId || 'default', {
        limit: 9999,
      }),
    });
  };

  const {
    mutate: confirmTransactionsMutate,
    isPending: isConfirmTransactionsPending,
    customError,
  } = useConfirmBatchTransactionsMutation(invalidateBatchTransactionsList);

  const statusProgress = useMemo(() => {
    let status: ProgressIndicatorStatus = 'loading';

    const initiatedCount =
      batchTransactionsList?.reduce((acc, consumed) => {
        const initiatedCount =
          consumed.status === TransactionBatchStatus.INITIATED;
        return acc + (initiatedCount ? 1 : 0);
      }, 0) || 0;

    const finishedValidation = batchTransactionsList?.every(
      (item) => item.status !== TransactionBatchStatus.IN_VALIDATION
    );

    const errorCount =
      batchTransactionsList?.reduce((acc, consumed) => {
        const count =
          consumed.status === TransactionBatchStatus.VALIDATION_ERROR;
        return acc + (count ? 1 : 0);
      }, 0) || 0;

    const allError = errorCount === batchTransactionsList?.length;
    const hasError = errorCount > 0;

    if (finishedValidation) {
      if (hasError && !allError) {
        status = 'warning';
      }

      if (allError) {
        status = 'error';
      }

      if (!hasError) {
        status = 'success';
      }
    }

    return {
      status,
      count: initiatedCount,
      finish: finishedValidation,
    };
  }, [batchTransactionsList]);

  const handleSubmit = () => {
    const transactionIds = Object.keys(rowSelection);

    confirmTransactionsMutate(
      {
        batchId,
        walletId: walletId || 'default',
        transactionIds,
      },
      {
        onSuccess() {
          setFlow('DETAIL');
          setQueryParams({ batchStatus: TransactionBatchStatus.PROCESSING });
        },
      }
    );
  };

  const handleBack = () => {
    setFlow('IDENTITY');
    removeParams(['batchId']);
  };

  const transactionRows = useMemo(() => {
    const batchValidatedList = batchTransactionsList?.filter(
      (item) => item.status !== TransactionBatchStatus.IN_VALIDATION
    );

    const donuts = batchValidatedList?.map((transaction) => {
      const disabledCheckbox =
        transaction.status === TransactionBatchStatus.VALIDATION_ERROR;

      return { ...transaction, disabledCheckbox };
    });

    return donuts;
  }, [batchTransactionsList]);

  const isSubmitDisabled =
    isConfirmTransactionsPending ||
    statusProgress?.status === 'error' ||
    !statusProgress?.finish ||
    Object.keys(rowSelection).length === 0;
  return (
    <>
      <ContextualError error={customError} />

      <Alert status="info">
        <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
          {t('transactions.review-files')}
        </Typography>
      </Alert>

      {(statusProgress.status === 'error' ||
        statusProgress.status === 'warning') && (
        <Alert status="error">
          <Typography tag="p" weight="p2" color="var(--product-neutral-n500)">
            {t('transactions.account-not-found')}
          </Typography>
        </Alert>
      )}

      <TableDemo
        title={t('transactions.data-review')}
        headerComponent={
          <>
            {batchTransactionsList && (
              <ProgressIndicator
                consumed={statusProgress.count}
                status={statusProgress.status}
                total={batchTransactionsList?.length || 0}
              />
            )}
          </>
        }
        columns={useReviewColumns()}
        data={transactionRows}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        defaultChecked={statusProgress.finish}
        loadMoreButtonLabel={t('general.load-more')}
        emptyState={t('general.processing')}
      />

      <SidebarFooter>
        <Button
          type="button"
          size="large"
          variant="tertiary"
          className="button_submit"
          onClick={batchStatus ? closeDrawer : handleBack}
        >
          {batchStatus ? t('general.button.close') : t('button.back')}
        </Button>

        <Button
          type="submit"
          size="large"
          className="button_submit"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
        >
          {isConfirmTransactionsPending ? (
            <Spinner variant="secondary" />
          ) : (
            `${t('general.process-payment')} ${
              selectedCount > 0 ? `(${selectedCount})` : ''
            }`
          )}
        </Button>
      </SidebarFooter>
    </>
  );
};
