import { ContextualErrorType } from '@components';
import { ListResponse } from '@interfaces/Common.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import {
  CreateStatementProps,
  Entry,
  InvoiceStatementProps,
} from '@interfaces/Invoices.interfaces';
import {
  createAdjustmentStatement,
  getInvoiceEntries,
  findStatementsInvoiceList,
} from '@services/invoices';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  customersInvoiceStatementsKeys,
  customersInvoicesKeys,
} from './customersInvoicesKeys';

interface UseCustomerInvoiceQueryParams {
  invoiceId: string;
  enabled?: boolean;
  buyer?: boolean;
}

export const useCustomerInvoiceEntriesQuery = ({
  invoiceId,
  enabled,
}: UseCustomerInvoiceQueryParams) => {
  return useQuery<
    ListResponse<Entry>,
    ApiError,
    ListResponse<Entry>,
    unknown[]
  >({
    queryKey: customersInvoicesKeys.list(invoiceId),
    queryFn: () => getInvoiceEntries(invoiceId),
    enabled: !!enabled,
  });
};

export const useCustomerStatementsQuery = ({
  invoiceId,
  enabled,
  buyer,
}: UseCustomerInvoiceQueryParams) => {
  return useQuery<
    ListResponse<InvoiceStatementProps>,
    ApiError,
    ListResponse<InvoiceStatementProps>,
    unknown[]
  >({
    queryKey: customersInvoiceStatementsKeys.list(invoiceId, buyer),
    queryFn: () => findStatementsInvoiceList(invoiceId, buyer),
    enabled: !!enabled,
  });
};

export const useCreateAdjustmentStatementMutation = () => {
  const { t } = useTranslation();

  const [customError, setCustomError] = useState<ContextualErrorType>(null);
  const queryClient = useQueryClient();

  const mutation = useMutation<
    ListResponse<InvoiceStatementProps>,
    ApiError,
    { invoiceId: string; payload: CreateStatementProps },
    unknown
  >({
    mutationFn: ({ invoiceId, payload }) =>
      createAdjustmentStatement(invoiceId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: customersInvoiceStatementsKeys.lists(),
      });
    },
    onError: (error: ApiError) => {
      setCustomError({
        message: t(
          `error.CODE_ERROR.${error?.response?.data?.code}`,
          t('error.default')
        ),
        traceId: error?.response?.data?.traceId,
      });
    },
  });

  return { ...mutation, customError };
};
