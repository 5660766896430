import { useQuery } from '@tanstack/react-query';

import { HOUR_IN_MILLISECONDS } from '@constants/time';
import { CardListParameters, CardProps } from '@interfaces/Card.interfaces';
import { ApiError } from '@interfaces/ErrorRequest.interfaces';
import { findCardList } from '@services/cards';
import { cardKeys } from './cardKeys';

export const useCardListQuery = ({
  nextPage,
  status,
  walletId = '',
  query,
}: CardListParameters) => {
  return useQuery<CardProps, ApiError, CardProps, unknown[]>({
    queryKey: cardKeys.list({ nextPage, status, walletId, query }),
    queryFn: () => findCardList({ walletId, status, nextPage, query }),
    staleTime: HOUR_IN_MILLISECONDS,
    refetchInterval: HOUR_IN_MILLISECONDS,
    refetchOnMount: true,
  });
};
