import clsx from 'clsx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { HTMLAttributes } from 'react';
dayjs.extend(utc);

interface TableDateProps {
  date: string | Date;
  className?: HTMLAttributes<HTMLParagraphElement>['className'];
}

export const TableDate = ({ date, className }: TableDateProps) => {
  const day = dayjs(date).utc(true).format('DD/MM/YYYY');
  const hours = dayjs(date).utc(true).format('HH:mm');

  return (
    <div className="flex flex-col">
      <p className={clsx('text-p2', className)}>{day}</p>
      <p className={clsx('text-p3 text-neutral-100', className)}>{hours}</p>
    </div>
  );
};
