import {
  emptyFutureTransactions,
  emptyFutureTransactionsWithFilter,
  errorState,
} from '@constants/emptyState';
import { TransactionScheduleStatus } from '@enums/SchedulePayment.enum';
import { useQueryParams } from '@hooks';
import { TableDemo } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ClearFiltersQuery,
  DatePickerRangeQuery,
  EmptyStateBlock,
  SelectQuery,
  Pagination,
  SearchQuery,
} from '../tableHelpers';
import { TableColumnsFilter } from '../tableHelpers/tableColumnsFilter';
import {
  CancelScheduleModal,
  ExportComponent,
  ScheduleTransactionVoucher,
  useFutureTransactionsColumns,
} from './components';
import { TransactionScheduleProps } from './interface/scheduling.interface';
import { useInfiniteScheduleList } from './services/hooks/useInfiniteScheduleList';

interface FutureTransactionsProps {
  isGeneral?: boolean;
}

export const FutureTransactions = ({ isGeneral }: FutureTransactionsProps) => {
  const { queryParams } = useQueryParams();
  const { openDrawer } = useDrawer();
  const { walletId } = useParams();
  const { t } = useTranslation();
  const [deleteModalData, setDeleteModalData] =
    useState<TransactionScheduleProps | null>(null);
  const [page, setPage] = useState(0);

  const openDeleteModal = (schedule: TransactionScheduleProps) => {
    setDeleteModalData(schedule);
  };

  const { columns, filterColumnsProps } = useFutureTransactionsColumns({
    openDeleteModal,
    isGeneral,
  });

  const {
    data: schedulingData,
    isFetching,
    isFetchingNextPage,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScheduleList({
    scheduledAtEnd: queryParams.get('endDate'),
    scheduledAtStart: queryParams.get('startDate'),
    status:
      queryParams.getAll('status').length > 0
        ? (queryParams.getAll('status') as TransactionScheduleStatus[])
        : [
            TransactionScheduleStatus.CANCELLED,
            TransactionScheduleStatus.SCHEDULED,
          ],
    walletId: walletId,
    description: queryParams.get('search'),
  });

  const currentPageData = useMemo(() => {
    const handleOpenVoucher = (voucher: TransactionScheduleProps) => {
      openDrawer(
        <ScheduleTransactionVoucher
          walletId={voucher.wallet}
          type={voucher.type}
          scheduleId={voucher.id}
        />
      );
    };

    return (
      schedulingData?.pages?.[page]?.items?.map((voucher) => ({
        ...voucher,
        onRowClick: () => handleOpenVoucher(voucher),
      })) || []
    );
  }, [schedulingData, page, openDrawer]);

  const filters = {
    search: queryParams.get('search') ?? undefined,
    status: queryParams.getAll('status') as string[],
  };

  const hasFilters = Object.values(filters).some((filter) => filter);

  const statusOptions = [
    {
      label: t('enum.TransactionScheduleStatus.CANCELLED'),
      value: TransactionScheduleStatus.CANCELLED,
    },
    {
      label: t('enum.TransactionScheduleStatus.SCHEDULED'),
      value: TransactionScheduleStatus.SCHEDULED,
    },
  ];

  return (
    <div>
      <div className="flex justify-between mb-4 items-center">
        <h2 className="text-h3 text-neutral-500">
          {t('general.future-transactions')}
        </h2>

        <div className="flex ml-auto gap-4">
          <DatePickerRangeQuery
            disabled={{
              before: new Date(),
            }}
          />

          <ExportComponent />
        </div>
      </div>

      <div className="flex gap-3 mb-4 flex-wrap">
        <SearchQuery placeholder={t('general.search')} query="search" />

        <SelectQuery
          onlyOptions
          query="status"
          title={t('general.status')}
          options={statusOptions}
        />

        <ClearFiltersQuery queries={['search', 'status']} />

        <TableColumnsFilter {...filterColumnsProps} />
      </div>

      <TableDemo
        columns={columns}
        data={currentPageData}
        isLoading={isFetching || isFetchingNextPage}
        emptyState={
          <>
            {!isError && !hasFilters && (
              <EmptyStateBlock details={emptyFutureTransactions} />
            )}

            {!isError && hasFilters && (
              <EmptyStateBlock details={emptyFutureTransactionsWithFilter} />
            )}

            {isError && <EmptyStateBlock details={errorState} />}
          </>
        }
        pinLastColumn
      />

      <Pagination
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        page={page}
        setPage={setPage}
        pageCount={schedulingData?.pages.length}
        className="flex justify-end mt-4"
      />

      {deleteModalData && (
        <CancelScheduleModal
          scheduleDeleteData={deleteModalData}
          setScheduleDeleteData={setDeleteModalData}
        />
      )}
    </div>
  );
};
