import * as Sentry from '@sentry/react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { environment } from '@environments/environment';
import { Customers } from '@pages/customer/Customers';
import { CustomerDetails } from '@pages/customer/customersManagement/customersDetails';
import { ToastrContainer } from '@portao3-web/ui';
import { AuthProvider } from '@providers/AuthProvider/AuthProvider';
import { DrawerProvider } from '@providers/DrawerProvider';
import { OrgRouterProvider } from '@providers/OrgRouterProvider';
import { ReactAriaRouterProvider } from '@providers/ReactAriaRouterProvider';
import { ReactQueryProvider } from '@providers/ReactQueryProvider';
import { RealmRouterProvider } from '@providers/RealmRouterProvider';
import { UserProvider } from '@providers/UserProvider';
import React from 'react';
import { Inspector, gotoVSCode } from 'react-dev-inspector';
import './app.styles.scss';
import {
  Cards,
  Company,
  ErrorPage,
  ForgotPassword,
  Login,
  Mfa,
  NewPassword,
  Organizations,
  Preferences,
  RealmCompanies,
  RealmUsers,
  Statement,
  UsersManagement,
  Wallets,
} from './pages';
import { ActiveMFA } from './pages/mfa/ActiveMFA';
import { Transactions } from './pages/transactions/Transactions';
import { SelectedWallet } from './pages/wallets/components/selectedWallet/SelectedWallet';

Sentry.init({
  dsn: 'https://bb1f43a7fc10ce24d747df67ec4433d7@o359692.ingest.us.sentry.io/4507410253479936',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllInputs: true,
      maskAllText: false,
    }),
    Sentry.browserTracingIntegration(),
  ],
  tracePropagationTargets: [
    'https://api.dev.3pers.com.br',
    'https://api.identity.dev.3pers.com.br',
    'https://api.platform.dev.3pers.com.br',
    'https://api.v2.portao3.com.br',
    'https://api.identity.v2.portao3.com.br',
    'https://api.platform.v2.portao3.com.br',
  ],
  environment: environment.name,
  enabled: environment.sentryEnabled,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  const routes = {
    '/': <Route path="/" key="/" element={<Navigate to="/login" />} />,
    login: <Route path="login" key="login" element={<Login />} />,
    'new-password': (
      <Route path="new-password" key="new-password" element={<NewPassword />} />
    ),
    'active-mfa': (
      <Route path="active-mfa" key="active-mfa" element={<ActiveMFA />} />
    ),
    mfa: <Route path="mfa" key="mfa" element={<Mfa />} />,
    'forgot-password': (
      <Route
        path="forgot-password"
        key="forgot-password"
        element={<ForgotPassword />}
      />
    ),

    '/*': <Route path="/*" key="/*" element={<ErrorPage.Error />} />,
  };

  const authenticatedRoutes: {
    [key: string]: { component: JSX.Element; isAdmin?: boolean };
  } = {
    '/': { component: <Navigate to="/wallets?order=createdAt-DESC" /> },
    'wallets/:walletId/statement': { component: <Statement /> },
    'wallets/:walletId/cards': { component: <Cards /> },
    wallets: { component: <Wallets /> },
    'wallets/:walletId/:walletPage': {
      component: <SelectedWallet />,
    },
    'wallets/:walletId': { component: <SelectedWallet /> },
    company: { component: <Company />, isAdmin: true },
    cards: { component: <Cards />, isAdmin: true },
    users: { component: <UsersManagement />, isAdmin: true },
    preferences: { component: <Preferences />, isAdmin: true },
    customers: { component: <Customers />, isAdmin: true },
    'customers/:customerId': { component: <CustomerDetails />, isAdmin: true },
    transactions: { component: <Transactions />, isAdmin: true },
    unauthorized: { component: <ErrorPage.Unauthorized /> },
    unavailable: { component: <ErrorPage.Unavailable /> },
    'wallets/default/*': { component: <Navigate to="/company" /> },
    '/*': { component: <ErrorPage.Error /> },
  };

  const authRoutes = (isAdmin: boolean) => [
    <Route
      path="organizations"
      key="organizations"
      element={<Organizations />}
    />,
    ...Object.entries(authenticatedRoutes).map(([key, value]) => (
      <Route
        path={key}
        key={key}
        element={
          !value.isAdmin || (value.isAdmin && isAdmin) ? (
            <DrawerProvider>
              <OrgRouterProvider>{value.component}</OrgRouterProvider>
            </DrawerProvider>
          ) : (
            <ErrorPage.Unauthorized />
          )
        }
      />
    )),
  ];

  const realmRoutes: {
    [key: string]: JSX.Element;
  } = {
    'realms/:realmId/companies': <RealmCompanies />,
    'realms/:realmId/users': <RealmUsers />,
  };

  const realmAuthRoutes = (isRealmAdmin: boolean) => {
    return [
      ...Object.entries(realmRoutes).map(([key, value]) => (
        <Route
          path={key}
          key={key}
          element={
            isRealmAdmin ? (
              <DrawerProvider>
                <RealmRouterProvider>{value}</RealmRouterProvider>
              </DrawerProvider>
            ) : (
              <ErrorPage.Unauthorized />
            )
          }
        />
      )),
    ];
  };

  return (
    <BrowserRouter>
      <ReactQueryProvider>
        <AuthProvider>
          {({ isLogged }) => (
            <ReactAriaRouterProvider>
              {isLogged ? (
                <UserProvider>
                  {({ isAdmin, isRealmAdmin }) => (
                    <SentryRoutes>
                      {[
                        ...authRoutes(isAdmin),
                        ...realmAuthRoutes(isRealmAdmin),
                      ]}
                    </SentryRoutes>
                  )}
                </UserProvider>
              ) : (
                <SentryRoutes>{Object.values(routes)}</SentryRoutes>
              )}
            </ReactAriaRouterProvider>
          )}
        </AuthProvider>
      </ReactQueryProvider>

      <Inspector
        keys={['Command', 'Shift', 'E']}
        onInspectElement={gotoVSCode}
      />
      <ToastrContainer />
    </BrowserRouter>
  );
}

export default App;
