import clsx from 'clsx';

import { Icon } from '@portao3-web/ui';
import { useTranslation } from 'react-i18next';

export interface TagProps {
  status: 'active' | 'pending' | 'blocked';
}

export const Tag = ({ status }: TagProps) => {
  const { t } = useTranslation();

  const tagOptions = {
    active: {
      status: t('general.active'),
      icon: 'fa-solid fa-circle-check text-success-400',
      className: 'bg-success-50 text-success-400',
    },
    pending: {
      status: t('general.pending'),
      icon: 'fa-solid fa-triangle-exclamation text-warning-500',
      className: 'bg-neutral-20 text-neutral-100',
    },
    blocked: {
      status: t('general.blocked'),
      icon: 'fa-solid fa-circle-xmark text-danger-400',
      className: 'bg-danger-50 text-danger-400',
    },
  };

  return (
    <div
      className={clsx({
        'flex  w-min items-center justify-center gap-1 rounded px-2 py-[2px]':
          true,
        [tagOptions[status].className]: true,
      })}
    >
      <Icon size="small">
        <i
          className={clsx({
            [tagOptions[status].icon]: true,
            'fa-sm': true,
          })}
        />
      </Icon>

      <span className="text-p5 uppercase">{tagOptions[status].status}</span>
    </div>
  );
};
