export interface Option {
  label: string;
  value: string;
}

export enum CustomFieldLocation {
  TRANSACTION = 'TRANSACTION',
  SOLICITATION = 'SOLICITATION',
  WALLET = 'WALLET',
  CARD = 'CARD',
}

export enum CustomFieldLocationText {
  TRANSACTION = 'Transações',
  SOLICITATION = 'Solicitações',
  WALLET = 'Carteiras',
  CARD = 'Cartões',
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  CUSTOMER = 'CUSTOMER',
}

export enum CustomFieldTypesText {
  TEXT = 'Texto',
  NUMBER = 'Número',
  DATE = 'Data',
  BOOLEAN = 'Ativo/Inativo',
  SELECT = 'Seleção única',
  MULTI_SELECT = 'Multi-Seleção',
  CUSTOMER = 'Cliente',
}

export enum CustomFieldStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum NumberFieldMask {
  NONE = 'Nenhum',
  BRL = 'R$',
  USD = '$',
  EUR = '€',
  PERCENT = '%',
}

export interface NumberField {
  mask: keyof NumberFieldMask;
}

export interface BooleanField {
  description?: string;
}

export interface SelectField {
  values: Option[];
}

export interface CustomFieldUnionType
  extends NumberField,
    BooleanField,
    SelectField {
  type: CustomFieldType;
}

export interface CustomFieldVersion extends CustomFieldUnionType {
  id: string;
  realm: string;
  organization: string;
  version: number;
  status: CustomFieldStatus;
  label: string;
  required: boolean;
  identifier: string;
  externalIdentifier: string;
  location: CustomFieldLocation[];
}

export interface CustomField extends CustomFieldUnionType {
  id: string;
  realm: string;
  organization: string;
  version: number;
  status: CustomFieldStatus;
  label: string;
  required: boolean;
  identifier: string;
  externalIdentifier: string;
  location: CustomFieldLocation[];
  versions: CustomFieldVersion[];
}

export interface CustomFieldPostParams extends CustomFieldUnionType {
  label: string;
  required: boolean;
  identifier: string;
  externalIdentifier: string;
  location: CustomFieldLocation[];
  status?: CustomFieldStatus;
}

export interface CustomFieldArrayProps {
  items: CustomField[];
  nextPage: string;
}

export interface CustomFieldGetParams {
  limit?: string;
  location?: CustomFieldLocation;
}

export interface CustomFieldView {
  id: string;
  label?: string;
  identifier?: string;
  values: string[];
  version?: number;
}
