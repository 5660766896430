import { FindBatchTransactionsListPayload } from '@interfaces/BatchPayments.interfaces';
import { UseInfiniteBatchListParams } from './useInfiniteBatchListQuery';

export const batchPaymentKeys = {
  all: () => ['batch-payment'],
  details: () => [...batchPaymentKeys.all(), 'detail'],
  detail: (
    id: string,
    walletId: string,
    params?: FindBatchTransactionsListPayload
  ) => [...batchPaymentKeys.details(), id, walletId, params],
  lists: () => [...batchPaymentKeys.all(), 'list'],
  list: (params: UseInfiniteBatchListParams) => [
    ...batchPaymentKeys.lists(),
    params,
  ],
};
