/**
 * Retorna a data formatada no formato 'dd/mm/yyyy'.
 * @param date A data a ser formatada no padrão ISO 8601.
 * @returns A data formatada no formato 'dd/mm/yyyy'.
 */
export const getFullDateByISO = (date: string | Date): string => {
  if (!date) return '-';

  const getOnlyDate = date.toString().slice(0, 10);

  const [year, month, day] = getOnlyDate.split('-');
  return `${day}/${month}/${year}`;
};
