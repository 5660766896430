import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import * as React from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { cn } from '@portao3-ui/lib/utils';
import { Matcher } from 'react-day-picker';
import { ShadButton } from '../shadButton';
import { ShadCalendar } from '../shadCalendar';

export function DatePicker({
  size = 'lg',
  className,
  date,
  setDate,
  disabled,
}: React.HTMLAttributes<HTMLDivElement> & {
  size?: 'md' | 'lg';
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  disabled?: Matcher | Matcher[] | undefined;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const onOpenChange = () => {
    setIsOpen(!isOpen);
  };

  const isLarge = size === 'lg';

  const textSize = isLarge ? 'text-p2' : 'text-p3';
  const triggerSize = isLarge ? 'w-[200px] h-10' : 'w-[180px] h-8';

  return (
    <div className={cn('grid', className)}>
      <Popover key={'calendar-range'} onOpenChange={onOpenChange} open={isOpen}>
        <PopoverTrigger key={'calendar-trigger'} asChild>
          <ShadButton
            id="date"
            variant={'outline'}
            data-testid="date-picker-trigger"
            className={cn(
              `p-0 border-primary-500 ${triggerSize} items-center justify-start border`,
              !date && 'text-p3 text-muted-foreground'
            )}
          >
            <div className="flex w-full items-center justify-start">
              <i
                className={`fa-regular fa-calendar ${textSize} text-primary-500 mr-2`}
              />
              {date ? (
                <span className={`${textSize} text-primary-500 font-light`}>
                  {dayjs(date).format('DD/MM/YYYY')}{' '}
                  {dayjs(date).startOf('day')?.isSame(dayjs().startOf('day'))
                    ? '(Hoje)'
                    : dayjs(date)
                          .startOf('day')
                          ?.isSame(dayjs().add(1, 'day').startOf('day'))
                      ? '(Amanhã)'
                      : ''}
                </span>
              ) : (
                <span className={`${textSize} text-primary-500  font-light`}>
                  Escolha uma data
                </span>
              )}
            </div>
          </ShadButton>
        </PopoverTrigger>

        <PopoverContent
          key={'calendar-picker-container'}
          className="z-50 w-auto p-0"
          align="start"
        >
          <ShadCalendar
            key={'calendar-picker'}
            initialFocus
            disabled={disabled}
            selected={date}
            onDayClick={setDate}
            onResetOrClose={() => setDate(undefined)}
            onSetDate={() => setIsOpen(false)}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
