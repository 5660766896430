import { organization, realm } from '@constants/endpoints';
import { environment } from '@environments/environment';
import { TransactionsResponse } from '@interfaces/Transactions.interfaces';
import api from '@services/api';

export const getTransactions = async (
  endpoint: string,
  startDate: string,
  endDate: string,
  limitPerPage: number,
  nextPage: string
): Promise<TransactionsResponse> => {
  const nextPageElement = nextPage ? `&next=${nextPage}` : '';
  const response = await api.get(
    `${
      environment.platformBaseUrl
    }${realm()}/${organization()}${endpoint}/transactions?startDate=${startDate}&endDate=${endDate}&limit=${limitPerPage}${nextPageElement}`
  );

  const { data } = response;

  return data;
};
