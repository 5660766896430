import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  ContextualError,
  SidebarFooter,
  SidebarSection,
} from '@components';
import { Button, Input, Spinner, Toastr, Typography } from '@portao3-web/ui';
import { useDrawer } from '@providers';
import { useCreateCardPinMutation } from '@services/cards/hooks/useCreateCardPinMutation';
import { PinProps, SidebarPinProps } from './SidebarPin.interfaces';
import './SidebarPin.styles.scss';
import clsx from 'clsx';

export const SidebarPin = ({
  actionModal,
  cardId,
  walletId,
}: SidebarPinProps) => {
  const { closeDrawer } = useDrawer();
  const { t } = useTranslation();

  const [inputType, setInputType] = useState('password');
  const [cardPin, setCardPin] = useState<PinProps>({
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: '',
  });

  const { register, handleSubmit, reset } = useForm<PinProps>({
    mode: 'onChange',
  });

  const {
    mutate: createCardPin,
    isPending: isCreatingCardPin,
    customError: createCardPinError,
  } = useCreateCardPinMutation();

  const pastPin = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('text');
    const sanitizedValue = pastedValue.replace(/[^\d]/g, '');

    setCardPin({
      digit1: sanitizedValue.charAt(0),
      digit2: sanitizedValue.charAt(1),
      digit3: sanitizedValue.charAt(2),
      digit4: sanitizedValue.charAt(3),
    });
  };

  const handleDigitChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    digit: number
  ) => {
    const { value } = event.target;

    if (/^\d*$/.test(value)) {
      setCardPin((prevPin) => ({
        ...prevPin,
        [`digit${digit}`]: value,
      }));

      value !== ''
        ? document.getElementById(`digit${digit + 1}`)?.focus()
        : document.getElementById(`digit${digit - 1}`)?.focus();
    }
  };

  const disableButton = () => {
    return (
      (cardPin.digit1 && cardPin.digit2 && cardPin.digit3 && cardPin.digit4) ===
      ''
    );
  };

  const submitForm = async (formData: PinProps) => {
    createCardPin(
      {
        cardId,
        pin: Object.values(formData).join(''),
        walletId: walletId ?? 'default',
      },
      {
        onSuccess: () => {
          reset();
          Toastr.success(t('card.pin-change'));
          closeDrawer();
        },
      }
    );
  };

  useEffect(() => {
    if (!cardId || !walletId) {
      closeDrawer();
    }
  }, [cardId, walletId, closeDrawer]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      {!createCardPinError && (
        <div className="mb-6">
          <Alert status="success">
            <Typography tag="p" weight="p3" color="var(--product-success-s700)">
              <span className="bold_text">
                Seu novo cartão foi adicionado com sucesso!
              </span>{' '}
              Para que ele funcione corretamente, defina uma senha de 4 dígitos
              abaixo:
            </Typography>
          </Alert>
        </div>
      )}

      <ContextualError error={createCardPinError} />

      <SidebarSection
        numberSection={1}
        titleSection="Defina a senha para o seu cartão"
        subTitleSection="Sua senha precisa conter exatamente 4 números."
      >
        <div className="card_pin">
          <div className="card_pin_number">
            <Input
              type={inputType}
              label=""
              id="digit1"
              maxLength={1}
              value={cardPin.digit1}
              name="digit1"
              register={register}
              data-testid="digit1"
              onPaste={pastPin}
              onChange={(e) => handleDigitChange(e, 1)}
              classAttr="card_pin_number_input"
            />
          </div>
          <div className="card_pin_number">
            <Input
              type={inputType}
              label=""
              id="digit2"
              value={cardPin.digit2}
              maxLength={1}
              name="digit2"
              register={register}
              data-testid="digit2"
              onPaste={pastPin}
              onChange={(e) => handleDigitChange(e, 2)}
              classAttr="card_pin_number_input"
            />
          </div>
          <div className="card_pin_number">
            <Input
              type={inputType}
              label=""
              id="digit3"
              maxLength={1}
              name="digit3"
              value={cardPin.digit3}
              register={register}
              data-testid="digit3"
              onPaste={pastPin}
              onChange={(e) => handleDigitChange(e, 3)}
              classAttr="card_pin_number_input"
            />
          </div>
          <div className="card_pin_number">
            <Input
              type={inputType}
              label=""
              id="digit4"
              maxLength={1}
              name="digit4"
              value={cardPin.digit4}
              register={register}
              data-testid="digit4"
              onPaste={pastPin}
              onChange={(e) => handleDigitChange(e, 4)}
              classAttr="card_pin_number_input"
            />
          </div>
          <Button
            type="button"
            variant="tertiary"
            size="small"
            className="card_pin_eye"
            onClick={() => {
              setInputType((prev) =>
                prev === 'password' ? 'text' : 'password'
              );
            }}
          >
            <i
              className={clsx('fa-solid icon icon--medium', {
                'fa-eye-slash': inputType !== 'password',
                'fa-eye': inputType === 'password',
              })}
            />
          </Button>
        </div>
      </SidebarSection>
      <SidebarFooter>
        <Button
          type="button"
          onClick={() => {
            actionModal(true);
          }}
          variant="tertiary"
          size="large"
        >
          Fechar
        </Button>
        <Button
          type="submit"
          size="large"
          disabled={disableButton() || isCreatingCardPin}
          data-testid="submitForm"
          className="button_submit"
        >
          {isCreatingCardPin ? <Spinner variant="secondary" /> : 'Concluir'}
        </Button>
      </SidebarFooter>
    </form>
  );
};
