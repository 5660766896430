import { CopyComponent } from '@components';
import { CardTypeEnum } from '@enums/Card.enum';
import { CardDetailsResponse } from '@interfaces/CardDetails.interface';
import { Skeleton, Typography } from '@portao3-web/ui';
import { formatCardNumber } from '@utils/formatCardNumber';
import { generateCardName } from '@utils/removeSpecialCharacters';
import { useTranslation } from 'react-i18next';
import './CardDetails.styles.scss';

export interface CardDetailsProps {
  cardNumber: string;
  cardType: CardTypeEnum;
  walletId: string;
  cardDetails: CardDetailsResponse | undefined;
  showDetailsCard: boolean;
  isLoading: boolean;
  ownerName: string;
}

export const CardDetails = ({
  cardNumber,
  cardType,
  cardDetails,
  showDetailsCard,
  isLoading,
  ownerName,
}: CardDetailsProps) => {
  const { t } = useTranslation();

  return (
    <div className={`card_details_individual_show_details`}>
      <div className="box_details_card">
        <div className="card_details_individual_show_details_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Nº do cartão
          </Typography>
          <Skeleton width={160} isLoading={isLoading || !cardNumber}>
            <CopyComponent
              cardDetails={cardDetails}
              showDetailsCard={showDetailsCard}
              id="card-pan"
            >
              {cardDetails && showDetailsCard
                ? formatCardNumber(cardDetails.pan)
                : `**** **** **** ${cardNumber}`}
            </CopyComponent>
          </Skeleton>
        </div>
        <div className="card_details_individual_show_details_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Data de expiração
          </Typography>
          <Skeleton width={30} isLoading={isLoading || !cardNumber}>
            <CopyComponent
              cardDetails={cardDetails}
              showDetailsCard={showDetailsCard}
              id="card-expiryDate"
            >
              {cardDetails && showDetailsCard
                ? cardDetails.expiryDate
                : '**/****'}
            </CopyComponent>
          </Skeleton>
        </div>
        <div className="card_details_individual_show_details_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            CVV
          </Typography>
          <div className="card_details_individual_show_details_info_card_cvv">
            <Skeleton width={1} isLoading={isLoading || !cardNumber}>
              <CopyComponent
                cardDetails={cardDetails}
                showDetailsCard={showDetailsCard}
                id="card-cvv"
              >
                {cardDetails && showDetailsCard ? cardDetails.cvv : '***'}
              </CopyComponent>
            </Skeleton>
          </div>
        </div>

        <div className="card_details_individual_show_details_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Tipo
          </Typography>
          <Skeleton width={35} isLoading={!cardNumber}>
            <div className="card_details_individual_show_details_info_card_cvv">
              <Typography tag="p" className="card_number" weight="p2">
                {t(`general.${cardType.toLowerCase()}`, '')}
              </Typography>
            </div>
          </Skeleton>
        </div>

        <div className="card_details_individual_show_details_info_card">
          <Typography tag="p" className="card_number_text" weight="p3">
            Nome do titular
          </Typography>
          <Skeleton width={30} isLoading={isLoading || !cardNumber}>
            <CopyComponent
              cardDetails={cardDetails}
              showDetailsCard={showDetailsCard}
              id="card-name-titular"
            >
              {generateCardName(ownerName).toUpperCase()}
            </CopyComponent>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};
