export const ICONS = {
  pix: 'fa-brands fa-pix',
  barcode: 'fa-regular fa-barcode',
  eye: 'fa-regular fa-eye',
  'credit-card': 'fa-regular fa-credit-card',
  'funds-in': 'fa-regular fa-grid-round-2-plus',
  billing: 'fa-regular fa-file-invoice',
  qrcode: 'fa-regular fa-qrcode',
  paperclip: 'fa-regular fa-paperclip',
  'chevron-down': 'fa-regular fa-chevron-down',
  circle: 'fa-solid fa-circle',
  'circle-dashed': 'fa-regular fa-circle-dashed',
  download: 'fa-regular fa-download',
  x: 'fa-solid fa-x',
  cog: 'fa-regular fa-cog',
  billingShield: 'fa-regular fa-building-shield',
  arrowBrackets: 'fa-regular fa-arrow-right-from-bracket',
  buildingCircleArrowRight: 'fa-regular fa-building-circle-arrow-right',
  shield: 'fa-regular fa-shield',
  'chevron-right': 'fa-regular fa-chevron-right',
  'chevron-left': 'fa-regular fa-chevron-left',
  message: 'fa-regular fa-message',
  wpp: 'fa-brands fa-whatsapp',
  copy: 'fa-regular fa-copy',
  arrowLeft: 'fa-regular fa-arrow-left',
  globe: 'fa-regular fa-globe',
  check: 'fa-regular fa-check',
  'check-circle': 'fa-regular fa-check-circle',
  'check-circle-solid': 'fa-solid fa-check-circle',
  'face-smile-plus': 'fa-regular fa-face-smile-plus',
  code: 'fa-regular fa-code',
  'file-invoice': 'fa-regular fa-file-invoice',
  'circle-exclamation': 'fa-regular fa-circle-exclamation',
  'file-lines': 'fa-regular fa-file-lines',
  loading: 'fa-solid fa-spinner-third animate-spin',
  fee: 'fa-regular fa-file-invoice-dollar',
  'circle-check': 'fa-regular fa-circle-check',
  'triangle-exclamation': 'fa-solid fa-triangle-exclamation',
  fingerprint: 'fa-solid fa-fingerprint',
  'shield-keyhole': 'fa-solid fa-shield-keyhole',
  trash: 'fa-regular fa-trash',
  pencil: 'fa-regular fa-pencil',
  'circle-x': 'fa-regular fa-circle-x',
  order: 'fa-regular fa-sort',
  calendar: 'fa-regular fa-calendar',
  wallet: 'fa-regular fa-wallet',
  exchange: 'fa fa-exchange-alt',
  'file-spreadsheet': 'fa-regular fa-file-spreadsheet',
  'file-csv': 'fa-regular fa-file-csv',
};
