import { FindTransactionParams } from '../transactions.services';
import { UseInfiniteTransactionListQueryParams } from './useInfiniteTransactionListQuery';

export const transactionKeys = {
  all: () => ['transaction'],
  details: () => [...transactionKeys.all(), 'details'],
  detail: (params: FindTransactionParams) => [
    ...transactionKeys.details(),
    params,
  ],
  lists: () => [...transactionKeys.all(), 'list'],
  list: (params: UseInfiniteTransactionListQueryParams) => [
    ...transactionKeys.lists(),
    params,
  ],
};
